import { Box, styled } from "@mui/material";

export const KanbanColumn = styled(Box)(({ theme }) => ({
  background: "transparent",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  minWidth: "16.25rem",
  width: "16.25rem",
  padding: theme.spacing(2),
  borderRadius: "0.625rem",

  "& .kanban-column-title": {
    "& .MuiChip-root": {
      padding: "10px 20px",
      height: "unset",
      width: "fit-content",
      justifyContent: "flex-start",
      textTransform: "capitalize",

      "& .MuiChip-label": {
        lineHeight: "18px",
        fontWeight: 500,
        fontSize: "1rem",
        padding: 0,
      },
    },
  },
}));
