// ** React Imports
import { useState } from "react";

// ** MUI Imports
import {
  Box,
  Input,
  Button,
  Drawer,
  IconButton,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  LinearProgress,
  FormControl,
  FormHelperText
} from "@mui/material";

// ** Icons Imports
import Minus from "mdi-material-ui/Minus";
import Close from "mdi-material-ui/Close";

// ** Third Party Components
import { EditorState, convertToRaw } from "draft-js";
import { Editor as ReactDraftWysiwyg } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import { Form, Formik, FormikHelpers } from "formik";

// ** Styled Component Imports
import { EditorWrapper } from "../../../comps/@core/styles/libs/react-draft-wysiwyg";

// ** Types
import { MailComposeType } from "../../../types/emailTypes";
import { MailFormDataType, mailFormValidationSchema } from "../../../types/mailAccountsType";

// ** Styles
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { sendSmtpMail } from "../../../store/apps/email";
import { useAlertContext } from "../../../hooks/useAlertContext";
import { ArrowCollapse, ArrowExpand } from "mdi-material-ui";
import { useSettings } from "../../../hooks/useSettings";
import { useTranslation } from "react-i18next";


const ComposePopup = (props: MailComposeType) => {
  // ** Props
  const {
    mdAbove,
    composeOpen,
    composePopupWidth,
    toggleComposeOpen,
    connectedSmtpAccounts,
    dispatch,
    composeInitialValues: initialValues
  } = props;

  const { t } = useTranslation();
  const { settings } = useSettings();

  const { navigationSize, collapsedNavigationSize, navCollapsed } = settings;
  const navWidth = navCollapsed ? collapsedNavigationSize : navigationSize;

  // ** States
  const [messageValue, setMessageValue] = useState(EditorState.createEmpty());
  const [maxView, setMaxView] = useState(false);
  const toggleMaxView = () => setMaxView((prev) => !prev);

  // hooks
  const { showSuccessAlert, showErrorAlert } = useAlertContext()

  const handlePopupClose = () => {
    toggleComposeOpen();
    setMessageValue(EditorState.createEmpty());
    setMaxView(false);
  };

  const handleMinimize = () => {
    toggleComposeOpen();
    setMessageValue(messageValue);
    setMaxView(false);
  };

  const sendMail = async (values: MailFormDataType) => {
    return await dispatch(sendSmtpMail(values));
  }

  const onSubmit = async (
    values: MailFormDataType,
    formikHelpers: FormikHelpers<MailFormDataType>
  ) => {
    const { setSubmitting, resetForm } = formikHelpers;

    await sendMail(values).then((res: any) => {
      const { meta } = res
      const { requestStatus } = meta

      if (requestStatus === 'fulfilled') {
        showSuccessAlert(`Mail sent to ${values.to}`)
        setSubmitting(false);
        resetForm();
        handlePopupClose();
      }

      if (requestStatus === 'rejected') {
        showErrorAlert(`Something went wrong, check console`)
        setSubmitting(false);
        console.log(res)
      }
    })
  };

  return (
    <Drawer
      hideBackdrop
      anchor="bottom"
      open={composeOpen}
      variant="temporary"
      onClose={toggleComposeOpen}
      sx={{
        top: "auto",
        left: "auto",
        right: mdAbove ? "1.5rem" : "1rem",
        bottom: "1.5rem",
        display: "block",
        zIndex: (theme) => `${theme.zIndex.drawer} + 1`,
        "& .MuiDrawer-paper": {
          borderRadius: 1,
          position: "static",
          width: maxView ? `calc(calc(100vw - ${navWidth}px) - ${mdAbove ? "1.5rem" : "1rem"})` : composePopupWidth,
          height: maxView ? 'calc(calc(100vh - 64px) - 1.5rem)' : 'auto', // 64px = appbar height
        },
      }}
    >
      <Formik enableReinitialize {...{ initialValues, validationSchema: mailFormValidationSchema, onSubmit }}>
        {({
          isSubmitting,
          submitForm,
          handleBlur,
          setFieldValue,
          errors,
          touched,
          values
        }) => (
          <Form style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}>
            {/* NAV */}
            <Box
              sx={{
                px: 4,
                py: 2.5,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: (theme) =>
                  `rgba(${theme.palette.customColors.main}, 0.08)`,
              }}
            >
              <Typography sx={{ fontWeight: 600, color: "text.secondary" }}>
                {t("Compose Mail")}
              </Typography>

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  sx={{ p: 1, mr: 2, color: "action.active" }}
                  onClick={handleMinimize}
                >
                  <Minus fontSize="small" />
                </IconButton>

                <IconButton
                  sx={{ p: 1, mr: 2, color: "action.active" }}
                  onClick={toggleMaxView}
                >
                  {maxView ? <ArrowCollapse fontSize="small" /> : <ArrowExpand fontSize="small" />}
                </IconButton>

                <IconButton
                  sx={{ p: 1, color: "action.active" }}
                  onClick={handlePopupClose}
                >
                  <Close fontSize="small" />
                </IconButton>
              </Box>
            </Box>

            {/* HEADER: FROM  */}
            <Box
              sx={{
                py: 1,
                px: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
              }}
            >
              <Box
                sx={{ width: "100%", display: "flex", alignItems: "center" }}
              >
                <Box>
                  <InputLabel
                    sx={{ mr: 3, color: "text.disabled" }}
                    htmlFor="email-from-select"
                  >
                    {`${t("From")}:`}
                  </InputLabel>
                </Box>

                <FormControl fullWidth>
                  <Select
                    fullWidth
                    name="from"
                    value={values.from}
                    onChange={(e) => {
                      setFieldValue("from", e.target.value as string);
                    }}
                    onBlur={handleBlur}
                    type="email"
                  >
                    {connectedSmtpAccounts.map((acc, index: number) => (
                      <MenuItem key={index} value={acc.user}>
                        {acc.user}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{touched.from && errors.from}</FormHelperText>
                </FormControl>
              </Box>
            </Box>

            {/* HEADER: TO  */}
            <Box
              sx={{
                py: 1,
                px: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
              }}
            >
              <Box
                sx={{ width: "100%", display: "flex", alignItems: "center" }}
              >
                <Box>
                  <InputLabel
                    sx={{ mr: 3, color: "text.disabled" }}
                    htmlFor="email-to-input"
                  >
                    {`${t("To")}:`}
                  </InputLabel>
                </Box>

                <FormControl fullWidth error={touched.to && Boolean(errors.to)}>
                  <Input
                    fullWidth
                    type="email"
                    name="to"
                    value={values.to}
                    onChange={(e) => setFieldValue("to", e.target.value)}
                    onBlur={handleBlur}
                    id="email-to-input"
                    sx={{
                      "&:before, &:after": { display: "none" },
                      "& .MuiInput-input": { py: 1.875 },
                    }}
                  />
                  <FormHelperText>{touched.to && errors.to}</FormHelperText>
                </FormControl>
              </Box>
            </Box>

            {/* HEADER: SUBJECT */}
            <Box
              sx={{
                py: 1,
                px: 4,
                display: "flex",
                alignItems: "center",
                borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
              }}
            >
              <Box>
                <InputLabel
                  sx={{ mr: 3, color: "text.disabled" }}
                  htmlFor="email-subject-input"
                >
                  {`${t("Subject")}:`}
                </InputLabel>
              </Box>

              <FormControl fullWidth error={touched.subject && Boolean(errors.subject)}>
                <Input
                  fullWidth
                  name="subject"
                  value={values.subject}
                  onChange={(e) => setFieldValue("subject", e.target.value)}
                  onBlur={handleBlur}
                  id="email-subject-input"
                  sx={{
                    "&:before, &:after": { display: "none" },
                    "& .MuiInput-input": { py: 1.875 },
                  }}
                />
                <FormHelperText>
                  {touched.subject && errors.subject}
                </FormHelperText>
              </FormControl>
            </Box>

            {/* TOOLBAR AND MAIL BODY */}
            <EditorWrapper
              sx={{
                flex: '1 1 0',

                "& .rdw-editor-wrapper": {
                  border: "0 !important",
                },
                "& .rdw-editor-toolbar": {
                  p: "0.35rem 1rem !important",
                  "& .rdw-option-wrapper": {
                    minWidth: "1.25rem",
                    borderRadius: "4px !important",
                  },
                  "& .rdw-inline-wrapper, & .rdw-text-align-wrapper": {
                    mb: 0,
                  },
                },
                "& .rdw-editor-main": {
                  px: "1.25rem",
                },
              }}
            >
              <ReactDraftWysiwyg
                editorState={messageValue}
                onEditorStateChange={(editorState) => {
                  setMessageValue(editorState);

                  const html = draftToHtml(
                    convertToRaw(editorState.getCurrentContent())
                  );
                  setFieldValue("html", html);
                }}
                placeholder={t("Message")}
                toolbar={{
                  options: ["inline", "textAlign"],
                  inline: {
                    inDropdown: false,
                    options: ["bold", "italic", "underline", "strikethrough"],
                  },
                }}
              />
            </EditorWrapper>

            {isSubmitting && <LinearProgress />}

            {/* FOOTER: SEND */}
            <Box
              sx={{
                px: 4,
                py: 3.5,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                borderTop: (theme) => `1px solid ${theme.palette.divider}`,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Button onClick={submitForm} variant="contained">
                  {t("Send")}
                </Button>

                {/* <IconButton size='small' sx={{ ml: 3.5 }}>
            <Attachment sx={{ fontSize: '1.25rem' }} />
          </IconButton> */}
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Drawer>
  );
};

export default ComposePopup;
