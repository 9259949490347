import { Card, Box, CardContent, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

import { productStatusObj, productOnSaleObj } from "../list";

import { Chip } from "../../../comps/chip";

import { ICompany } from "../../../hooks/useCompanies";

import { ThemeColor } from "../../../comps/@core/layouts/types";
import { ProductT } from "../../../types/productTypes";
import { Avatar } from "../../../comps/avatar";

interface Props {
  product: ProductT;
  company: ICompany | undefined;
  toggleEditProductDialog: () => void;
  isVariobelOnlyField: boolean;
}

const Image = ({ src }: { src: string }) => (
  <Avatar
    skin="light"
    variant="rounded"
    src={src}
    sx={{
      width: "100%",
      height: "auto",
    }}
  />
);
const ViewProductLeft = (props: Props) => {
  const { product, company, toggleEditProductDialog, isVariobelOnlyField } = props;

  const { t } = useTranslation();

  return (
    <Card>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
          }}
        >
          {product.name}
        </Typography>

        <Box
          py={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
        >
          {/* variobel */}
          {isVariobelOnlyField && (
            <Image src={product.images.length ? product.images[0] : ""} />
          )}

          {/* porez */}
          {!isVariobelOnlyField && <Image src={product.image} />}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ mr: 2, color: "text.primary" }}
            >
              {`${t("Price")}:`}
            </Typography>

            <Typography variant="body2">{`${"€"} ${product.price}`}</Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ mr: 2, color: "text.primary" }}
            >
              {`${t("Company")}:`}
            </Typography>

            <Chip
              skin="light"
              size="small"
              label={company ? company.name : ""}
              color={(company ? company.colorCode : "error") as ThemeColor}
              sx={{
                textTransform: "capitalize",
                "& .MuiChip-label": { lineHeight: "18px" },
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ mr: 2, color: "text.primary" }}
            >
              Status
            </Typography>

            <Chip
              skin="light"
              size="small"
              label={product.status}
              color={productStatusObj[product.status]}
              sx={{
                textTransform: "capitalize",
                "& .MuiChip-label": { lineHeight: "18px" },
              }}
            />
          </Box>

          {isVariobelOnlyField && (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ mr: 2, color: "text.primary" }}
                >
                  {`${t("On Sale")}:`}
                </Typography>

                <Chip
                  skin="light"
                  size="small"
                  label={String(product.onSale)}
                  color={productOnSaleObj[String(product.onSale)]}
                  sx={{
                    textTransform: "capitalize",
                    "& .MuiChip-label": { lineHeight: "18px" },
                  }}
                />
              </Box>
            </>
          )}
        </Box>

        <Button variant="contained" onClick={toggleEditProductDialog}>
          Edit
        </Button>
      </CardContent>
    </Card>
  );
};

export default ViewProductLeft;
