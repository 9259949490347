import { Grid } from "@mui/material";
import ProductForm from "../../comps/products/form";

interface Props {
  id: string;
  handleClose: () => void;
}

const EditProductScreen = (props: Props) => {
  const { id, handleClose } = props;
  return (
    <Grid container>
      <Grid item xs={12}>
        <ProductForm type="edit" id={id} handleClose={handleClose} />
      </Grid>
    </Grid>
  );
};

export default EditProductScreen;
