import { Box, styled } from "@mui/material";

export const WallpaperSelectBaseContentWrapper = styled(Box)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  border: `1px solid ${theme.palette.primary.main}`,
  padding: theme.spacing(2),
  maxHeight: "450px",
  height: "450px",
  overflowY: "auto",

  "& .reactEasyCrop_Container": {
    overflow: "unset"
  }
})) as typeof Box;
