import { useNavigate } from "react-router-dom";
import { Spinner } from "../comps/utilities/spinner";
import { useEffect } from "react";

export const HomeScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/customer/list', { replace: true })
  }, [navigate])

  return <Spinner />
};
