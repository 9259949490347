import { Grid } from "@mui/material";
// import { IFile } from "../../../../../../../models/ecomm/productTable";
import { WallpaperImage } from "./wallpaper-image";
import { IFile } from "../../../types";

interface Props {
  localWallpapers: IFile[];
  wallpaper: any;
}
export const WallpaperImages = (props: Props) => {
  const { wallpaper, localWallpapers } = props;

  return (
    <Grid container spacing={2}>
      {localWallpapers.map((wallpaperFile, i) => {
        const selected = wallpaperFile.name === wallpaper.name;

        return (
          <Grid key={i} item xs={4}>
            <WallpaperImage
              wallpaperFile={wallpaperFile}
              selected={selected} />
          </Grid>
        );
      })}
    </Grid>
  );
};
