import { Grid, FormControl, MenuItem, Select, InputLabel } from "@mui/material";
import { Control, FieldErrors, Controller } from 'react-hook-form';
import { PorezProductFormInputsT, ProductFormInputs } from '../../../types/productTypes';
import FormFieldError from "./form-field-error";

interface Props {
  control: Control<ProductFormInputs | PorezProductFormInputsT, any>;
  t: any;
  errors: FieldErrors<ProductFormInputs | PorezProductFormInputsT>;
  statuses: string[];
}

const StatusField = ({ control, t, errors, statuses }: Props) => (
  <Grid item xs={12} sm={2}>
    <FormControl fullWidth>
      <Controller
        name="status"
        control={control}
        rules={{ required: true }}
        render={({ field: { value, onChange } }) => (
          <FormControl>
            <InputLabel>{t("Product state")}</InputLabel>
            <Select label={t("Product state")} value={value} onChange={onChange}>
              {statuses.map((status, index) => (
                <MenuItem key={index} value={status}>
                  {t(status).toLocaleUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )} />
      {errors.status && <FormFieldError />}
    </FormControl>
  </Grid>
);

export default StatusField;
