import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { fetchData } from "../store/apps/orderStatus";

const useOrderStatuses = () => {
  const dispatch = useDispatch<AppDispatch>();
  const orderStatuses = useSelector(
    (state: RootState) => state.orderStatus.data
  );

  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);

  const orderStatusesObj = orderStatuses.reduce(
    (accumulator, { name, colorCode }) => ({
      ...accumulator,
      [name]: colorCode,
    }),
    {}
  );

  return orderStatusesObj;
};

export default useOrderStatuses;

// get every order and their kanban position
export const useOrderStatusPosition = () => {
  const dispatch = useDispatch<AppDispatch>();
  const orderStatuses = useSelector(
    (state: RootState) => state.orderStatus.data
  );

  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);

  const orderStatusesObj = orderStatuses.reduce(
    (accumulator, { name, positionInKanban }) => ({
      ...accumulator,
      [name]: positionInKanban,
    }),
    {}
  );

  return orderStatusesObj as { [key: string]: number };
}
