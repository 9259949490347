import { useEffect } from "react";
import { AddOrderListLineItemType, LineItemType } from "../../../../../../../../../types/orderTypes";
import { ProductType } from "../../../../../../../../../types/productTypes";

// Custom hook to control order item dimension variables

export function useOrderItemDimensions(
  currentOrderItem: LineItemType | AddOrderListLineItemType | undefined,
  handleWallpaperDetailsUpdate: (data: any) => void,
) {
  useEffect(() => {
    if (currentOrderItem) {
      const { product, glassWidth, glassHeight } = currentOrderItem;
      const { minHeight, maxHeight, minWidth, maxWidth } = product as ProductType;

      const widthInterval = [minWidth, maxWidth];
      const heightInterval = [minHeight, maxHeight];

      const _currentProductDimensions = {
        width: glassWidth,
        height: glassHeight,
      };

      handleWallpaperDetailsUpdate({
        orderItemDimensions: _currentProductDimensions,
        orderItemWidthInterval: widthInterval,
        orderItemHeightInterval: heightInterval
      })
    }
  }, [
    currentOrderItem,
    handleWallpaperDetailsUpdate
  ]);
}
