import { TimelineEntriesType } from "./customTimelineTypes";
import { DateType } from "./reactDatepickerTypes";

export const PriceAdjusterPrefix = ["%", "€"];
export type PriceAdjusterPrefixT = typeof PriceAdjusterPrefix[number];

export type PriceAdjusterT = {
  value: number;
  prefix: PriceAdjusterPrefixT
}

interface BaseProductT {
  [key: string]: any;
  name: string;
  category: string | null;
  company: string;
  status: string;
  price: number;
}

export interface ProductFormInputs extends BaseProductT {
  description: string;
  images: string[];

  onSale: boolean;
  saleEnds: DateType;

  saleDiscount: PriceAdjusterT; // uregistered/guest customer
  wholesaleDiscount: PriceAdjusterT;
  customerDiscount: PriceAdjusterT;

  retailMargin: PriceAdjusterT; // unregistered/guest customer
  wholesaleCustomerMargin: PriceAdjusterT;
  retailCustomerMargin: PriceAdjusterT;

  minWidth: number;
  maxWidth: number;
  minHeight: number;
  maxHeight: number;

  width: number;
  height: number;
}
export interface ProductType extends ProductFormInputs {
  _id: string;
  timelineEntries: TimelineEntriesType[];
}

export const porezProductConditions = ["old", "new"]
type PorezProductConditionT = typeof porezProductConditions[number];

export interface PorezProductFormInputsT extends BaseProductT {
  code: string;
  surface: string;
  image: string;
  thickness: number[];
  condition: PorezProductConditionT
}
export interface PorezProductT extends PorezProductFormInputsT{
  _id: string;
}

export type ProductFormT = ProductFormInputs | PorezProductFormInputsT;
export type ProductT = PorezProductT | ProductType;