import {
  Button, styled
} from "@mui/material";

export const TabSubmitButton = styled(Button)(({ theme}) => ({
  minWidth: "122px",
  textTransform: "none",
  borderRadius: theme.spacing(1),
  boxShadow: "none",
  padding: "8px 24px",
})) as typeof Button;
