import React, { useState, Dispatch, SetStateAction } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "@hello-pangea/dnd";
import {
  Box,
  LinearProgress,
} from "@mui/material";
import {
  KanbanBoardStateT,
  KanbanColumnT,
} from "../../../types/orderStatusTypes";
import { Chip } from "../../chip";
import { ThemeColor } from "../../@core/layouts/types";
import { OrderType } from "../../../types/orderTypes";
import { KanbanItem, KanbanItemContent } from "./kanban-item";
import { KanbanColumn } from "./kanban-column";
import { reorderBoardColumn, moveBetweenBoardColumns } from "./helpers";
import PerfectScrollbar from "react-perfect-scrollbar";
import LoadingCircularProgress from "../../utilities/loading-circular-progress";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store";
import { updateOrder } from "../../../store/apps/order";
import { useAlertContext } from "../../../hooks/useAlertContext";
import { useTranslation } from "react-i18next";
import KanbanItemPreview from "./kanban-item-preview";
import UseBgColor, { UseBgColorType } from "../../../hooks/useBgColor";

interface Props {
  loading: boolean;
  kanbanColumns: KanbanColumnT[];
  kanbanBoardState: KanbanBoardStateT;
  setKanbanBoardState: Dispatch<SetStateAction<KanbanBoardStateT>>;
}

const KanbanBoard = (props: Props) => {
  const { loading, kanbanColumns, kanbanBoardState, setKanbanBoardState } =
    props;

  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { showSuccessAlert, showErrorAlert } = useAlertContext();
  const bgColors = UseBgColor();

  const draggingColors: UseBgColorType = {
    primary: { ...bgColors.primaryLight },
    secondary: { ...bgColors.secondaryLight },
    success: { ...bgColors.successLight },
    error: { ...bgColors.errorLight },
    warning: { ...bgColors.warningLight },
    info: { ...bgColors.infoLight },
  };

  const draggingStyle = (color:  ThemeColor) => {
    return draggingColors[color];
  }

  const [openKanbanItemDrawer, setKanbanItemDrawer] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<OrderType | null>(
    null
  );
  const [isItemOrderStatusUpdating, setIsItemOrderStatusUpdating] = useState(false);

  const openKanbanItem = (item: OrderType) => {
    setKanbanItemDrawer(true);
    setSelectedOrder(item);
  };

  const closeKanbanItemDrawer = () => {
    setKanbanItemDrawer(false);
    setSelectedOrder(null);
  };

  const updateOrderStatus = async (order: OrderType, newStatusID: string) => {
    setIsItemOrderStatusUpdating(true);

    dispatch(
      updateOrder({
        id: order._id,
        data: {
          orderStatus: newStatusID,
          orderStatusHistory: [
            ...order.orderStatusHistory,
            {
              status: newStatusID,
              modifiedOn: new Date(),
            },
          ],
        },
      })
    )
    .then((res) => {
      showSuccessAlert(t("Order has been updated"));
    })
    .catch((err) => {
      showErrorAlert(t("Error during update! Check console"));
    })
    .finally(() => {
      setIsItemOrderStatusUpdating(false);
    });
  }

  function onDragEnd(result: DropResult) {
    const { source: sourceColumn, destination: destinationColumn } = result;

    // dropped outside the board
    if (!destinationColumn) {
      return;
    }

    // column index
    const sInd = +sourceColumn.droppableId;
    const dInd = +destinationColumn.droppableId;


    // sourceColumn.index is the index of the item within the column

    if (sInd === dInd) {
      const items = reorderBoardColumn(
        kanbanBoardState[sInd],
        sourceColumn.index,
        destinationColumn.index
      );
      const newState = [...kanbanBoardState];
      newState[sInd] = items;
      setKanbanBoardState(newState);
    } else {
      const result = moveBetweenBoardColumns(
        kanbanBoardState[sInd],
        kanbanBoardState[dInd],
        sourceColumn,
        destinationColumn
      );
      const newState = [...kanbanBoardState];
      newState[sInd] = result[sInd];
      newState[dInd] = result[dInd];

      setKanbanBoardState(newState.filter((group) => group.length));
      const toBeUpdatedOrder = result[dInd][destinationColumn.index]
      const newStatus = kanbanColumns[dInd]
      updateOrderStatus(toBeUpdatedOrder, newStatus._id);
    }
  }

  return (
    <Box className="orders-kanban" sx={{ display: "grid" }}>
      {isItemOrderStatusUpdating && <LinearProgress sx={{ mt: 4 }} />}

      <PerfectScrollbar
        options={{
          suppressScrollY: false,
        }}
        style={{ display: "flex", gap: "15px", marginTop: "1rem" }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          {Boolean(kanbanColumns.length) &&
            kanbanBoardState.map((column, ind) => {
              const columnInfo = kanbanColumns[ind];

              // index works becuase we sorted columns by "positionInKanban"
              // sorting was done while building board in parent component
              return (
                <Droppable
                  key={ind}
                  droppableId={`${ind}`}
                  isDropDisabled={isItemOrderStatusUpdating}
                >
                  {(provided, snapshot) => (
                    <KanbanColumn
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      sx={{
                        ...(snapshot.isDraggingOver &&
                          draggingStyle(columnInfo.colorCode as ThemeColor)),
                      }}
                    >
                      <Box className="kanban-column-title">
                        <Chip
                          skin="light"
                          size="small"
                          label={columnInfo.name}
                          color={columnInfo.colorCode as ThemeColor}
                        />
                      </Box>

                      {column.map((item, index) => (
                        <Draggable
                          key={item._id}
                          draggableId={item._id}
                          index={index}
                          isDragDisabled={isItemOrderStatusUpdating}
                        >
                          {(provided, snapshot) => (
                            <KanbanItem
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{ ...provided.draggableProps.style }}
                              onClick={() => openKanbanItem(item)}
                            >
                              <KanbanItemContent
                                {...{ order: item, columnInfo }}
                              />
                            </KanbanItem>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </KanbanColumn>
                  )}
                </Droppable>
              );
            })}
        </DragDropContext>
      </PerfectScrollbar>

      <KanbanItemPreview
        {...{
          open: openKanbanItemDrawer,
          onClose: closeKanbanItemDrawer,
          order: selectedOrder,
          setOrder: setSelectedOrder,
        }}
      />
      <LoadingCircularProgress {...{ loading }} />
    </Box>
  );
};

export default KanbanBoard;
