// ** React Imports
import { ReactNode } from "react";
import { Outlet } from "react-router-dom";

// ** MUI Imports
import { Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// ** Layout Imports
// !Do not remove this Layout import
import Layout from "../@core/layouts/Layout";

// ** Navigation Imports
import NavItems from "../../navigation";
import AppBarContent from "./components/vertical/AppBarContent";

// ** Hook Import
import { useSettings } from "../../hooks/useSettings";

interface Props {
  children?: ReactNode;
}

const UseLayout = ({ children }: Props) => {
  // ** Hooks
  const { settings, saveSettings } = useSettings();
  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  return (
    <Layout
      hidden={hidden}
      settings={settings}
      saveSettings={saveSettings}
      {...{
        // ** Navigation Items
        verticalNavItems: NavItems(),

        // ** AppBar Content
        verticalAppBarContent: (props) => (
          <AppBarContent
            hidden={hidden}
            settings={settings}
            saveSettings={saveSettings}
            toggleNavVisibility={props.toggleNavVisibility}
          />
        ),
      }}
    >
      {children ? children : <Outlet />}
    </Layout>
  );
};

export default UseLayout;
