import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardContent, Button, Box, LinearProgress } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import FileUploader from "../../comps/utilities/file-uploader";
import { useAlertContext } from "../../hooks/useAlertContext";
import { usePapaParse } from "react-papaparse";
import { DataGrid } from "@mui/x-data-grid";
import * as XLSX from 'xlsx';
import { useSettings } from "../../hooks/useSettings";
import { getDefaultCompany } from "../../store/apps/companies";
import { AppDispatch } from "../../store";
import { useDispatch } from "react-redux";
import { PorezProductFormInputsT } from "../../types/productTypes";
import { createProduct } from "../../store/apps/product";
import prepareUploadData from "../../utils/products/import/prepareUploadData";
import { useNavigate } from "react-router-dom";

const ImportProductsScreen = () => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [data, setData] = useState<any[]>([]);
  const [columns, setColumns] = useState<{ field: string }[]>([]);
  const [companyID, setCompanyID] = useState("");
  const [porezProductUploadData, setPorezProductUploadData] = useState<PorezProductFormInputsT[]>([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { readString } = usePapaParse();
  const { showErrorAlert, showSuccessAlert } = useAlertContext();
  const dispatch = useDispatch<AppDispatch>();
  const { settings } = useSettings();
  const { configuredCompany } = settings;
  const isVariobelOnlyField = configuredCompany === "variobel";

  useEffect(() => {
    dispatch(getDefaultCompany())
      .unwrap()
      .then((res) => {
        const data = res.company;
        setCompanyID(data._id);
      });
  }, [dispatch]);

  const processCsvData = (data: string, encoding: string) => {
    readString(data, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      encoding,
      transformHeader: (header) => header.toLowerCase().replace(/\W/g, ""),
      error: (error) => {
        console.error("This error occured during parsing:", error);
      },
      complete: (results) => {
        const {
          meta: { fields },
          errors,
          data,
        } = results as any;

        const columns = fields.map((item: string) => ({ field: item, minWidth: 200, flex: 1 }));
        setColumns(columns);

        if (errors.length) {
          console.error(errors);
          showErrorAlert(`${errors.length} products preview failed`);
        }

        if (data.length) {
          const tableData = Array.from(data).map((item: any, index) => ({
            ...item,
            id: index + 1,
          }));
          setData(tableData);
          showSuccessAlert(`Preview of ${data.length} products`);
        }
      },
    });
  };

  const handleCsvProcessing = async (file: File) => {
    let reader: FileReader = new FileReader();
    const encoding = "UTF-8";

    reader.onload = (_event: Event) => {
      const data = reader.result as string
      processCsvData(data, encoding);
    };

    reader.readAsText(file, encoding);
  }

  const handleSheetProcessing = async (file: File) => {
    let reader = new FileReader();

    reader.onload = () => {
      let data = reader.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];

      const encoding = "UTF-8";
      const dataParse = XLSX.utils.sheet_to_csv(ws);
      const json = XLSX.utils.sheet_to_json(ws);

      prepareUploadData(
        isVariobelOnlyField,
        companyID,
        json,
        setPorezProductUploadData
      );

      processCsvData(dataParse, encoding);
    };

    reader.readAsBinaryString(file);
  };

  const handlFileProcessing = async (file: File) => {
    setPorezProductUploadData([]);

    const spreadsheetFileTypes = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];

    const isSpreadsheet = spreadsheetFileTypes.includes(file.type);

    if (isSpreadsheet) {
      return handleSheetProcessing(file)
    }

    return handleCsvProcessing(file)
  };

  const handleProductCreate = async () => {
    setLoading(true);

    await porezProductUploadData.forEach(async (productData) => {
      await dispatch(createProduct(productData))
        .unwrap()
        .catch(() => {
          showErrorAlert(t("Failed to create Product"));
        });
    })

    showSuccessAlert(t("Products created"));
    navigate("/products");
    setLoading(false);
  }

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: 5000000, // TODO: add loader

    accept: {
      "text/csv": [], // .csv
      "text/plain": [], // .txt
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [], // .xlsx
      "application/vnd.ms-excel": [] // .xls
    },

    onDrop: (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      handlFileProcessing(file);
      document.querySelector('.dropzone')?.classList.remove('dropzone-hover');
    },

    onDropRejected: (fileRejections) => {
      const error = fileRejections[0];
      showErrorAlert(error.errors[0].message);
    },

    onDragEnter: (event) => {
      document.querySelector('.dropzone')?.classList.add('dropzone-hover');
    },
  });

  return (
    <Card>
      <CardHeader
        title="Import Products into Admin"
        sx={{ mb: 1, textAlign: "center" }}
      />
      <CardContent
        sx={{
          display: "grid",
          gap: 5,
        }}
      >
        <FileUploader
          {...{
            getRootProps,
            getInputProps,
            allowedFilesText: t("Allowed *.csv, *.txt, *.xlsx, *.xls"),
            mainText: "Drag and drop a file or click to select.",
            maxFileSizeText: t("One file with a max size of 1MB."),
          }}
        />

        <DataGrid
          autoHeight
          rows={data}
          columns={columns}
          disableSelectionOnClick
          pagination
          rowsPerPageOptions={[10, 25, 50]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
        />

        {loading && <LinearProgress />}

        <Box sx={{ marginLeft: "auto" }}>
          <Button
            variant="contained"
            disabled={porezProductUploadData.length === 0 || loading}
            onClick={handleProductCreate}
          >
            Save Products
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ImportProductsScreen;
