import { format } from "date-fns";
import { TextField } from "@mui/material";
import { forwardRef } from "react";

interface Props {
  dates: Date[];
  label: string;
  end: number | Date;
  start: number | Date;
  setDates?: (value: Date[]) => void;
}

const DatePickerInput = forwardRef((props: Props, ref) => {
  const startDate =
    props.start !== null ? format(props.start, "MM/dd/yyyy") : "";
  const endDate =
    props.end !== null ? ` - ${format(props.end, "MM/dd/yyyy")}` : null;

  const value = `${startDate}${endDate !== null ? endDate : ""}`;

  if (props.start === null && props.dates.length && props.setDates) {
    props.setDates([]);
  }

  const updatedProps = { ...props };
  delete updatedProps.setDates;

  return (
    <TextField
      fullWidth
      inputRef={ref}
      {...updatedProps}
      label={props.label || ""}
      value={value}
    />
  );
});

export default DatePickerInput