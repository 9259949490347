// ** React Imports
import { useEffect, useState } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";

// ** Third Party Imports
import parse from "html-react-parser";

// ** Type Imports
import { ProductType } from "../../../types/productTypes";
import { BasketPriceTypeTemp } from "../../../types/orderTypes";
import { calculateOnSaleDiscountValue } from "../../../helpers/calculateDiscount";
import { Customer } from "../../../types/customerTypes";
import { currencyFormatter } from "../../../helpers/currency-formatter";

interface ProductCardProps {
  product: ProductType;
  handleSelectProduct: (
    newProduct: ProductType
  ) => void;
  customer: Customer;
  basketPrice?: BasketPriceTypeTemp;
}

export const ProductCard = ({
  product,
  handleSelectProduct,
  customer,
  basketPrice
}: ProductCardProps) => {
  const [hover, setHover] = useState(false);
  const [discountedPrice, setDiscountedPrice] = useState(0)

  useEffect(() => {
    const discountValue = calculateOnSaleDiscountValue(product, customer);
    const _discountedPrice = product.price - discountValue;

    setDiscountedPrice(_discountedPrice);
  }, [product, customer]);

  const getPriceWithTax = (price: number) => {
    if (!basketPrice) return price;

    const taxAmount = (basketPrice.taxRate / 100) * price;
    const priceWithDPH = price + taxAmount;

    return currencyFormatter(priceWithDPH);
  }
  return (
    <Card
      sx={{ minWidth: 275, cursor: "pointer", position: "relative" }}
      elevation={hover ? 8 : 2}
      onClick={() => {
        handleSelectProduct(product);
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {product.onSale && (
        <Box
          sx={{
            position: "absolute",
            background: (theme) => theme.palette.secondary.main,
            color: "white",
            padding: "0.3rem 115px",
            width: 300,
            transform: "rotateZ(-45deg)",
            transformOrigin: "150px 150px",
            left: 0,
            top: 0,
            zIndex: 11,
          }}
        >
          ON SALE
        </Box>
      )}

      <Box
        sx={{
          width: "100%",
          height: "250px",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <CardMedia
          component="img"
          height="250"
          sx={{
            position: "absolute",
            top: "0px",
            left: "0px",
            transform: hover ? "scale(0.95)" : "scale(0.85)",
            /*making the scaling smooth*/
            transition: "transform 0.5s ease",
          }}
          image={(product.images?.length && product.images[0]) || "/images/placeholders/product.png"}
          alt={product.name}
        />
      </Box>

      <CardContent>
        <Typography sx={{ fontSize: 16 }} color="text.primary" gutterBottom>
          {product.name}
        </Typography>

        {product.description && (
          <Typography variant="caption" color="text.secondary">
            {parse(product.description)}
          </Typography>
        )}
        <Stack
          direction="row"
          alignItems="baseline"
          justifyContent="center"
        >
          <Typography variant="body2" mr={1}>od</Typography>

          <Typography variant="h6" sx={{ fontWeight: 700 }}>
            {!product.onSale && getPriceWithTax(product.price)}

            {product.onSale && (
              <>
                <Typography
                  component="span"
                  sx={{ fontSize: "0.85rem" }}
                  mr={0.25}
                >
                  <s>{getPriceWithTax(product.price)}</s>
                </Typography>
                {getPriceWithTax(discountedPrice)}
              </>
            )}
          </Typography>

          <Typography variant="body2">
            €/m<sup>2</sup>
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};
