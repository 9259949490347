// ** React Imports
import { Fragment } from "react";

// ** MUI Imports
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Typography from "@mui/material/Typography";

// ** Third Party Imports
import { useTranslation } from "react-i18next";

// ** Types
import { Company } from "../../../types/customerTypes";

interface CustomerViewCompaniesScreenProps {
  companyData: Company;
}

export const CustomerViewCompaniesScreen = ({
  companyData,
}: CustomerViewCompaniesScreenProps) => {
  // ** Hooks
  const { t } = useTranslation();

  return (
    <Fragment>
      <Card sx={{ mb: 0 }}>
        <CardHeader
          title={t("Company")}
          titleTypographyProps={{ variant: "h6" }}
        />
        <CardContent>
          <Grid container direction="column" spacing={6}>
            <Grid container item rowSpacing={3} columnSpacing={6}>
              <Grid item xs={12} lg={6}>
                <TableContainer>
                  <Table size="small" sx={{ width: "95%" }}>
                    <TableBody
                      sx={{
                        "& .MuiTableCell-root": {
                          border: 0,
                          pt: 2,
                          pb: 2,
                          pl: "0 !important",
                          pr: "0 !important",
                          "&:first-of-type": {
                            width: 148,
                          },
                        },
                      }}
                    >
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            {t("Name")}:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {companyData.name}
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            {t("Company identification number")}:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {companyData.ico}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            {t("DIC")}:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {companyData.dic}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography
                            variant="subtitle2"
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            {t("ICDPH")}:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {companyData.icdph}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Fragment>
  );
};
