// ** Redux Imports
import { Dispatch } from "redux";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";
import { BaseProductCategoryT, ProductCategoryT } from "../../../types/product-category-type";

interface Redux {
  getState: any;
  dispatch: Dispatch<any>;
}

export const fetchProductCategories = createAsyncThunk(
  "productCategories/get-all",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_HOST}/product-categories/get-all`,
      { withCredentials: true }

    );

    return response.data;
  }
);

export const createProductCategory = createAsyncThunk(
  "productCategories/create",
  async (
    data: BaseProductCategoryT,
    { dispatch }: Redux
  ) => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_HOST}/product-categories/create`,
      {
        ...data,
      },
      { withCredentials: true }
    );

    dispatch(fetchProductCategories());
    return response.data;
  }
);

export const getProductCategory = createAsyncThunk(
  "productCategories/get",

  async (id: number | string, { getState, dispatch }: Redux) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_HOST}/product-categories/get/${id}`,
      { withCredentials: true }
    );

    dispatch(fetchProductCategories());
    return response.data;
  }
);

export const getProductsInCategory = createAsyncThunk(
  "productCategories/get-all-products",

  async (id: string, { getState, dispatch }: Redux) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_HOST}/product-categories/get-all-products/${id}`,
      { withCredentials: true }
    );

    dispatch(fetchProductCategories());
    return response.data;
  }
);


export const getNonCatgorizedProducts = createAsyncThunk(
  "productCategories/get-null-products",

  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_HOST}/product-categories/get-null-products`,
      { withCredentials: true }
    );

    return response.data;
  }
);

export const updateProductCategory = createAsyncThunk(
  "productCategories/update",

  async (
    args: {
      id: string,
      data: Partial<ProductCategoryT>
    },
    { dispatch }: Redux
  ) => {
    const response = await axios.patch(
      `${process.env.REACT_APP_SERVER_HOST}/product-categories/update/${args.id}`,
      {
        ...args.data,
      },
      { withCredentials: true }
    );

    dispatch(fetchProductCategories());
    return response.data;
  }
);

export const deleteProductCategory = createAsyncThunk(
  "productCategories/delete",

  async (id: number | string, { dispatch }: Redux) => {
    const response = await axios.delete(
      `${process.env.REACT_APP_SERVER_HOST}/product-categories/delete/${id}`,
      { withCredentials: true }
    );

    await dispatch(fetchProductCategories());
    return response.data;
  }
);

export const productCategoriesSlice = createSlice({
  name: "productCategories",
  initialState: {
    data: [] as ProductCategoryT[]
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProductCategories.fulfilled, (state, action) => {
      state.data = action.payload.productCategories;
    });
  },
});

export default productCategoriesSlice.reducer;
