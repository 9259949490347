// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

// ** Types Imports
import { Dispatch } from "redux";
import { CompanyDataType } from "../../../types/companyTypes";

interface ReduxType {
  getState: any;
  dispatch: Dispatch<any>;
}

export const fetchCompanies = createAsyncThunk("company/getAll", async () => {
  const config = {
    method: "get",
    url: `${process.env.REACT_APP_SERVER_HOST}/company/get-all`,
    withCredentials: true,
  };

  const response = await axios(config);
  return response.data;
});

export const createCompany = createAsyncThunk(
  "company/create",
  async (data: CompanyDataType, { dispatch }: ReduxType) => {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_SERVER_HOST}/company/create`,
      data,
      withCredentials: true,
    };

    const response = await axios(config);

    dispatch(fetchCompanies());
    return response.data;
  }
);

export const getCompany = createAsyncThunk(
  "company/get",
  async (id: string, { dispatch }: ReduxType) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_SERVER_HOST}/company/get/${id}`,
      withCredentials: true,
    };

    const response = await axios(config);

    dispatch(fetchCompanies());
    return response.data;
  }
);

export const getDefaultCompany = createAsyncThunk(
  "company/getDefault",
  async (_, { dispatch }: ReduxType) => {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_SERVER_HOST}/company/get-default`,
      withCredentials: true,
    };

    const response = await axios(config);

    dispatch(fetchCompanies());
    return response.data;
  }
);

export const updateCompany = createAsyncThunk(
  "company/update",
  async (
    params: { data: CompanyDataType; id: string },
    { dispatch }: ReduxType
  ) => {
    const { id, data } = params;

    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_SERVER_HOST}/company/update/${id}`,
      data,
      withCredentials: true,
    };

    const response = await axios(config);

    dispatch(fetchCompanies());
    return response.data;
  }
);

export const deleteCompany = createAsyncThunk(
  "company/delete",
  async (id: string, { dispatch }: ReduxType) => {
    const config = {
      method: "delete",
      url: `${process.env.REACT_APP_SERVER_HOST}/company/delete/${id}`,
      withCredentials: true,
    };

    const response = await axios(config);

    await dispatch(fetchCompanies());
    return response.data;
  }
);

export const appCompaniesSlice = createSlice({
  name: "appCompanies",
  initialState: {
    companies: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCompanies.fulfilled, (state, action) => {
      state.companies = action.payload.companies;
    });
  },
});

export default appCompaniesSlice.reducer;
