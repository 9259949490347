import {
  Card,
} from "@mui/material";

import {
  DataGrid,
  GridSelectionModel,
} from "@mui/x-data-grid";

import React, { useEffect, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useAlertContext } from "../../hooks/useAlertContext";

import {
  Customer,
  CustomerData,
  customerSchema,
} from "../../types/customerTypes";

import {
  AddOrderLineItemT,
  BasketPriceTypeTemp,
  OrderCreateType,
} from "../../types/orderTypes";
import { AppDispatch, RootState } from "../../store";

import { useDispatch, useSelector } from "react-redux";
import { fetchData as fetchProducts } from "../../store/apps/product";
import {
  addCustomer,
  fetchData as fetchCustomers,
} from "../../store/apps/customer";
import { getDefaultCompany } from "../../store/apps/companies";
import { createOrder } from "../../store/apps/order";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { defaultCustomerFormValues } from "../customer/list";
import { CustomerFormDialog } from "../../comps/customers/customerDialog";

import {
  INITIAL_ADD_POREZ_PRODUCT_ITEM,
  INITIAL_ADD_PRODUCT_BASKET_PRICE,
  INITIAL_ADD_VARIOBEL_PRODUCT_ITEM,
  handleAddProductLineItemUpdate,
  isVariobelLineItem,
  updateBasketPriceWithDiscounts,
  updateItemPricing,
  updateProdcutPricingWithMargins,
} from "../../comps/order/add-edit/helpers";

import { getColumns } from "../../comps/order/add-edit/table-columns";
import AddOrderSummary from "../../comps/order/add-edit/add/add-order-summary";
import AddOrderTableHeader from "../../comps/order/add-edit/add/add-order-table-header";
import { ProductType } from "../../types/productTypes";
import { useSettings } from "../../hooks/useSettings";

const AddOrderScreen = () => {
  const { showErrorAlert, showSuccessAlert } = useAlertContext();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { customer: customers } = useSelector(
    (state: RootState) => state
  );
  const navigate = useNavigate();

  const { settings } = useSettings();
  const { configuredCompany } = settings;

  const isVariobelOnlyField = configuredCompany === "variobel"

  const addCustomerForm = useForm<CustomerData>({
    defaultValues: defaultCustomerFormValues,
    resolver: yupResolver(customerSchema),
  });

  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(
    null
  );
  const [pageSize, setPageSize] = useState<number>(25);

  const initialDataItem = useMemo(
    () =>
      isVariobelOnlyField
        ? INITIAL_ADD_VARIOBEL_PRODUCT_ITEM
        : INITIAL_ADD_POREZ_PRODUCT_ITEM,
    [isVariobelOnlyField]
  );

  const [itemList, setItemList] = useState<AddOrderLineItemT[]>([initialDataItem]);

  const [selectedListItems, setSelectedListItems] =
    useState<GridSelectionModel>([]);
  const [searchValue, setSearchValue] = useState<string>("");

  const [basketPrice, setBasketPrice] = useState<BasketPriceTypeTemp>(
    INITIAL_ADD_PRODUCT_BASKET_PRICE
  );

  const [tax, setTax] = useState(0);
  const [companyID, setCompanyID] = useState("");
  const [order, setOrder] = useState<OrderCreateType>();

  const [previewOrderOpen, setPreviewOrderOpen] = useState(false);
  const [isOrderBeingCreated, setIsOrderBeingCreated] = useState(false);
  const [addCustomerOpen, setAddCustomerOpen] = useState(false);
  const [products, setProducts] = useState<ProductType[]>([]);


  // fetch products
  // apply margins to products
  useEffect(() => {
    dispatch(fetchProducts())
      .unwrap()
      .then((res) => {
        if (!isVariobelOnlyField) {
          return setProducts(res.products);
        }

        const _products = (res.products as ProductType[]).map((product) =>
          updateProdcutPricingWithMargins(product, selectedCustomer)
        );

        setProducts(_products);
      });
  }, [dispatch, selectedCustomer, isVariobelOnlyField]);

  // fetch customers
  useEffect(() => {
    dispatch(fetchCustomers({ search: "" }));
  }, [dispatch]);

  // get company to get tax
  useEffect(() => {
    dispatch(getDefaultCompany())
      .unwrap()
      .then((res) => {
        const data = res.company;
        setCompanyID(data._id);
        setTax(data.taxRate);
      });
  }, [dispatch]);

  // update basketPrice with company tax
  useEffect(() => {
    if (tax) {
      setBasketPrice({
        ...INITIAL_ADD_PRODUCT_BASKET_PRICE,
        taxRate: tax
      })
    }
  }, [tax])

  // update order state
  useEffect(() => {
    if (selectedCustomer && companyID) {
      const _basketPrice = updateBasketPriceWithDiscounts(
        selectedCustomer,
        itemList,
        basketPrice
      );

      const _order = {
        company: companyID,
        customerId: selectedCustomer._id,
        lineItems: itemList.map((item) => {
          // prevents null product error
          if (!item.product) return item;

          return {
            ...item,
            productId: item.product["_id"],
          };
        }),
        basketPrice: _basketPrice,
      };

      // unknown is because of added variable (productId) because of order create logic
      setOrder(_order  as OrderCreateType);
    }
  }, [itemList, selectedCustomer, basketPrice, companyID]);

  const togglePreviewOrder = () => setPreviewOrderOpen((prev) => !prev);
  const toggleAddCustomerDialog = () => setAddCustomerOpen(!addCustomerOpen);

  const handleAddCustomer = async (data: CustomerData) => {
    await dispatch(addCustomer({ ...data }));
    toggleAddCustomerDialog();
    addCustomerForm.reset();
  };

  const handleAddItem = () => {
    const getId = () => {
      if (itemList.length) {
        return (itemList.at(-1) as AddOrderLineItemT).id + 1;
      }

      return 0;
    };

    const _data = [
      ...itemList,
      {
        ...initialDataItem,
        id: getId(),
      },
    ];

    setItemList(_data);
  };

  const handleDeleteItem = () => {
    const _items = itemList.filter(
      (item) => selectedListItems.indexOf(item.id) === -1
    );

    setItemList(_items);
  };

  const handleCopyItem = () => {
    const copiedItems = [] as AddOrderLineItemT[];

    selectedListItems.forEach((id) => {
      let row = itemList.find(
        (row) => row.id === id
      ) as AddOrderLineItemT;
      copiedItems.push(row);
    });

    let _items = [...itemList, ...copiedItems];

    // Update id so it's unique
    _items = _items.map((row, i) => ({
      ...row,
      id: i + 1,
    }));

    setSelectedListItems([]);
    setItemList(_items);
  };

  const handleOptionClick = (obj: Customer) => {
    setSelectedCustomer(obj);
  };

  const handleOrderCreate = () => {
    if (order) {

      // we dont need the full product to be in the payload (used during other steps though)
      // only id, which was in a useEffect above
      const parsedLineItems = order.lineItems.map((item) => {
        const { product, ...rest } = item;
        return rest;
      }) as OrderCreateType["lineItems"];

      setIsOrderBeingCreated(true);

      dispatch(createOrder({ ...order, lineItems: parsedLineItems }))
        .unwrap()
        .then((res) => {
          showSuccessAlert(
            t(`Order #${res.order.orderNumber} Successfully Created`)
          );
          togglePreviewOrder();
          navigate("/order/list");
        })
        .catch((err) => {
          showErrorAlert(t("Error during order creation"));
          console.log(err);
        })
        .finally(() => {
          setIsOrderBeingCreated(false);
        });
    }
  };

  const columns = useMemo(
    () =>
      getColumns<AddOrderLineItemT>({
        t,
        showErrorAlert,
        itemList,
        setItemList,
        products,
        tax,
        type: "add",
        basketPrice,
        setBasketPrice,
        customer: selectedCustomer,
        isVariobelOnlyField,
      }),
    [
      t,
      showErrorAlert,
      itemList,
      products,
      tax,
      basketPrice,
      setBasketPrice,
      selectedCustomer,
      isVariobelOnlyField,
    ]
  );

  const isCreateReady = () => {
    if (!selectedCustomer) return false;

    let ready = true;
    if (!itemList.length) return false;

    itemList.forEach((item) => {
      if (!item.product) {
        ready = false;
      }
    });

    return ready;
  };

  return (
    <>
      <Card>
        <AddOrderTableHeader
          {...{
            t,
            handleAddItem,
            handleDeleteItem,
            handleCopyItem,
            selectedListItems,
            searchValue,
            setSearchValue,
            handleOptionClick,
            isCreateReady,
            togglePreviewOrder,
            toggleAddCustomerDialog,
            options: Array.from(customers.data).reverse() as Customer[],
          }}
        />

        <DataGrid
          autoHeight
          sx={{
            "& .MuiDataGrid-columnHeaders": { borderRadius: 0 },
            "& .MuiDataGrid-cell--editable": { cursor: "pointer" },
          }}
          disableSelectionOnClick
          checkboxSelection
          selectionModel={selectedListItems}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectedListItems(newSelectionModel);
          }}
          rows={itemList}
          columns={columns}
          pagination
          rowsPerPageOptions={[10, 25, 50]}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
          experimentalFeatures={{ newEditingApi: true }}
          processRowUpdate={(updatedRow) => {
            if(!isVariobelLineItem(updatedRow)) return updatedRow;

            if (updatedRow.product) {
              const { ppm2, ppu, price } = updateItemPricing(updatedRow);

              updatedRow.ppm2 = ppm2;
              updatedRow.ppu = ppu;
              updatedRow.price = price;

              handleAddProductLineItemUpdate(
                updatedRow,
                itemList,
                setItemList,
                tax,
                basketPrice,
                setBasketPrice
              );
            }

            return updatedRow;
          }}
        />

        {selectedCustomer && (
          <AddOrderSummary
            {...{
              customer: selectedCustomer,
              open: previewOrderOpen,
              handleClose: togglePreviewOrder,
              itemList,
              basketPrice,
              isOrderBeingCreated,
              handleOrderCreate,
            }}
          />
        )}

        <CustomerFormDialog
          title={t("Add customer")}
          form={addCustomerForm}
          open={addCustomerOpen}
          toggle={toggleAddCustomerDialog}
          onSubmit={handleAddCustomer}
        />
      </Card>
    </>
  );
};

export default AddOrderScreen;
