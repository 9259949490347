import {
  useRef,
  useCallback,
  SyntheticEvent,
  ChangeEvent,
  useEffect,
} from "react";
import { Area } from "react-easy-crop";

import { getTabs, tabList } from "./helpers";

import {
  useWallapaperSelectBaseT,
} from "./types";

import {
  useCurrentOrderItem,
  useOrderItemDimensions,
  useOrderItemErrors,
  useDebouncedOrderItemDimensions,
  useUpdateCurrentOrderItem,
  useCropAspectRatio,
  useSpreadWallpaperStyles,
  useImageResolutionWarning,
} from "./hooks";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../../store";
import {
  updateCustomWallpaperDetailsState,
  updateCustomWallpaperState,
  updateLocalWallpaperDetailsState,
  updateLocalWallpaperState,
} from "../../../../../../../../store/apps/print-wallpaper";

const useWallapaperSelectBase = (props: useWallapaperSelectBaseT) => {
  const {
    type,
    rotation,
    selectCroppedImage,
    isCropMode,
    preview,
    selectedLineItem,
  } = props;

  const dispatch = useDispatch();
  const localWallpaperStateDetails = useSelector((state: RootState) => state.localWallpaperState.details);
  const customWallpaperStateDetails = useSelector((state: RootState) => state.customWallpaperState.details);

  const getDetails = () => {
    if (type === "manual") return localWallpaperStateDetails;
    return customWallpaperStateDetails;
  }

  const details = getDetails();

  const containerRef = useRef<HTMLDivElement | null>(null);

  const {
    currentOrderItem,
    tabs,
    tab,
    flip,
    spreadWallpaper,
    previewWallpaperSpread,
    doorsToSpread,
    isSelectedImageClear,
    orderItemDimensions,
    debouncedOrderItemDimensions,
    orderItemWidthInterval,
    orderItemHeightInterval,
    isOrderItemDimensionsError,
  } = details;

  const handleWallpaperUpdate = useCallback((data: any) => {
    if (type === "manual") {
      dispatch(updateLocalWallpaperState(data));
    } else {
      dispatch(updateCustomWallpaperState(data));
    }
  }, [dispatch, type]);

  const handleWallpaperDetailsUpdate = useCallback((data: any) => {
    if (type === "manual") {
      dispatch(updateLocalWallpaperDetailsState(data));
    } else {
      dispatch(updateCustomWallpaperDetailsState(data));
    }
  }, [dispatch, type]);

  // init
  useEffect(() => {
    const _tabs = getTabs(tabList, type);

    handleWallpaperDetailsUpdate({
      tabs: _tabs,
      tab: _tabs[0].value
    })
  }, [handleWallpaperDetailsUpdate, type])

  const handleTabChange = (_: SyntheticEvent, newValue: string) => {
    handleWallpaperDetailsUpdate({
      tab: newValue
    })
  };

  const handleOrderItemDimensionsChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value, name } = event.target;
    const _orderItemDimensions = {
      ...orderItemDimensions,
      [name]: Number(value),
    };
    handleWallpaperDetailsUpdate({
      orderItemDimensions: _orderItemDimensions
    })
  };

  const handleSpreadWallaperChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleWallpaperDetailsUpdate({
      spreadWallpaper: event.target.checked
    })
  };

  const handlePreviewWallpaperSpreadChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    handleWallpaperDetailsUpdate({
      previewWallpaperSpread: event.target.checked
    })
  };

  const handleZoom = (
    event: Event,
    value: number | number[],
    activeThumb: number
  ) => {
    handleWallpaperUpdate({  zoom: value as number })
  };

  const flipHorizontal = () => {
    handleWallpaperDetailsUpdate({
      flip: {
        horizontal: !details.flip.horizontal,
        vertical: details.flip.vertical,
      }
    })

    const updated = 360 - rotation;
    handleWallpaperUpdate({  rotation: updated })
  };

  const flipVertical = () => {
    handleWallpaperDetailsUpdate({
      flip: {
        horizontal: details.flip.horizontal,
        vertical: !details.flip.vertical,
      }
    })

    const updated = 360 - rotation;
    handleWallpaperUpdate({  rotation: updated })
  };

  const rotateRight = () => {
    const updated = rotation + 90;
    handleWallpaperUpdate({  rotation: updated })
  };

  const rotateLeft = () => {
    const updated = rotation - 90;
    handleWallpaperUpdate({  rotation: updated })
  };

  const handleDoorsSpreadSlider = (
    event: Event,
    value: number | number[],
    activeThumb: number
  ) => {
    handleWallpaperDetailsUpdate({
      doorsToSpread: value as number
    })
  };

  // Update order items' quanity and dimensions
  const updateOrderItem = () => {
    if (spreadWallpaper) {
      selectedLineItem.qty = doorsToSpread;
    }

    selectedLineItem.glassWidth = orderItemDimensions.width;
    selectedLineItem.glassHeight = orderItemDimensions.height;
  };

  const handleCroppedImageSelect = () => {
    selectCroppedImage();
    updateOrderItem();
  };

  const onCropComplete = useCallback(
    (croppedArea: Area, croppedAreaPixels: Area) => {

      handleWallpaperUpdate({ croppedAreaPixels })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // set current order item being edited
  useCurrentOrderItem(selectedLineItem, handleWallpaperDetailsUpdate);

  // Control order item dimension variables
  useOrderItemDimensions(
    currentOrderItem,
    handleWallpaperDetailsUpdate
  );

  // Control order item error variables
  useOrderItemErrors(
    orderItemDimensions,
    orderItemWidthInterval,
    orderItemHeightInterval,
    handleWallpaperDetailsUpdate
  );

  // Control update of debounced orderItemDimensions
  useDebouncedOrderItemDimensions(
    orderItemDimensions,
    handleWallpaperDetailsUpdate
  );

  // Use debounced orderItemDimensions to update currentOrderItem
  useUpdateCurrentOrderItem(
    currentOrderItem,
    debouncedOrderItemDimensions,
    handleWallpaperDetailsUpdate
  );

  // Control crop area aspect ratio
  // Init to be based on glass width & height
  // Update based on no. of spread doors
  useCropAspectRatio(
    currentOrderItem,
    doorsToSpread,
    handleWallpaperUpdate,
    spreadWallpaper
  );

  // Control spread wallpaper styles
  useSpreadWallpaperStyles(
    containerRef,
    isCropMode,
    spreadWallpaper,
    doorsToSpread,
    previewWallpaperSpread
  );

  // Update state value for use in parent alone
  // useIsWallpaperSpread(setIsWallpaperSpread, spreadWallpaper);
  useEffect(() => {
    handleWallpaperUpdate({
      isWallpaperSpread: spreadWallpaper
    })
  }, [handleWallpaperUpdate, spreadWallpaper]);

  // Control alert warning of selected image resolution for print
  useImageResolutionWarning(type, preview, handleWallpaperDetailsUpdate);

  return {
    containerRef,
    tabs,
    tab,
    flip,
    isSelectedImageClear,
    isOrderItemDimensionsError,
    handleTabChange,
    handleOrderItemDimensionsChange,
    spreadWallpaper,
    handleSpreadWallaperChange,
    previewWallpaperSpread,
    handlePreviewWallpaperSpreadChange,
    orderItemDimensions,
    doorsToSpread,
    handleZoom,
    flipHorizontal,
    flipVertical,
    rotateRight,
    rotateLeft,
    handleDoorsSpreadSlider,
    handleCroppedImageSelect,
    onCropComplete,
  };
};

export default useWallapaperSelectBase;
