// ** React Imports
import { ReactNode, ReactElement, useEffect } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";

// ** Hooks Import
import { useAuth } from "../../hooks/useAuth";

interface AuthGuardProps {
  children: ReactNode;
  fallback: ReactElement | null;
}

export const AuthGuard = (props: AuthGuardProps) => {
  const { children, fallback } = props;
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!auth.user /* && !window.localStorage.getItem("userData") */) {
      if (location.pathname !== "/") {
        navigate(
          {
            pathname: "/login",
            search: createSearchParams({
              returnUrl: location.pathname,
            }).toString(),
          },
          { replace: true }
        );
      } else {
        navigate("/login", { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (auth.loading /* || !auth.user */) {
    return fallback;
  }

  return <>{children}</>;
};
