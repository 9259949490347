// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";
import { TranslationType } from "../../../types/translationTypes";

// ** Types Imports
import { Dispatch } from 'redux'

interface ReduxType {
  getState: any
  dispatch: Dispatch<any>
}

// ** Fetch Order Statuses
export const fetchLocales = createAsyncThunk("locales/getAll", async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_HOST}/locales/get-all`,
    {
      withCredentials: true,
    }
  );

  return response.data;
});

export const addToLocales = createAsyncThunk(
  "locales/addTranslation",
  async (data: TranslationType, { dispatch }: ReduxType) => {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_SERVER_HOST}/locales/add`,
      data,
      withCredentials: true,
    };

    const response = await axios(config);

    dispatch(fetchLocales());
    return response.data;
  }
);

export const updateLocales = createAsyncThunk(
  "locales/updateTranslation",
  async (params: {data: TranslationType, id: number}, { dispatch }: ReduxType) => {
    const { id, data } = params

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_SERVER_HOST}/locales/update/${id}`,
      data,
      withCredentials: true,
    };

    const response = await axios(config);

    dispatch(fetchLocales());
    return response.data;
  }
);

export const appOrderStatusesSlice = createSlice({
  name: "appLocales",
  initialState: {
    locales: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchLocales.fulfilled,
        (state, action) => {
          state.locales = action.payload;
        }
      )
  },
});

export default appOrderStatusesSlice.reducer;
