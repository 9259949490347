// ** React Imports
import { Fragment, useState, SyntheticEvent, ReactNode, Dispatch } from 'react'

// ** MUI Imports
import Menu from '@mui/material/Menu'
import Avatar from '@mui/material/Avatar'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

// ** Icons Import
import Circle from 'mdi-material-ui/Circle'
import ChevronLeft from 'mdi-material-ui/ChevronLeft'
import ChevronRight from 'mdi-material-ui/ChevronRight'
import ShareOutline from 'mdi-material-ui/ShareOutline'
import LabelOutline from 'mdi-material-ui/LabelOutline'
import DotsVertical from 'mdi-material-ui/DotsVertical'
import EmailOutline from 'mdi-material-ui/EmailOutline'
import FolderOutline from 'mdi-material-ui/FolderOutline'

// ** Third Party Imports
import PerfectScrollbar from 'react-perfect-scrollbar'

// ** Hooks
import { useSettings } from '../../../hooks/useSettings'

// ** Custom Components Imports
import Sidebar from '../../../comps/@core/components/sidebar'

// ** Types
import {
  MailDetailsType,
  MailFoldersArrType} from '../../../types/emailTypes'
import { MailFormDataType, RecentMessagesType } from '../../../types/mailAccountsType'
import { useTranslation } from 'react-i18next'

const MailCardMenu = ({
  toggleComposeOpen,
  setComposeInitialValues,
  mail
}: {
  toggleComposeOpen: () => void;
  setComposeInitialValues: Dispatch<MailFormDataType>;
  mail: RecentMessagesType
}) => {
  const [mailMenuAnchorEl, setMailMenuAnchorEl] = useState<null | HTMLElement>(
    null
  );

  const { t } = useTranslation();
  const openMailMenu = Boolean(mailMenuAnchorEl);

  const handleMailMenuClick = (event: SyntheticEvent) => {
    setMailMenuAnchorEl(event.currentTarget as HTMLElement);
  };
  const handleMailMenuClose = () => {
    setMailMenuAnchorEl(null);
  };

  const handleReplyInitialDataFill = () => {
    const isReply = mail.subject.startsWith("Re: ");
    const subject = isReply ? mail.subject : "Re: ".concat(mail.subject);
    const to = mail.from.split("<")[1].slice(0, -1);
    const from = mail.to;

    toggleComposeOpen();
    setComposeInitialValues({
      from,
      to,
      subject,
      html: "",
    });
  };

  return (
    <>
      <IconButton size="small" onClick={handleMailMenuClick}>
        <DotsVertical fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={mailMenuAnchorEl}
        open={openMailMenu}
        onClose={handleMailMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleReplyInitialDataFill}>
          <ShareOutline fontSize="small" sx={{ mr: 2 }} />
          {t("Reply")}
        </MenuItem>
      </Menu>
    </>
  );
};

const MailDetails = (props: MailDetailsType) => {
  // ** Props
  const {
    setComposeInitialValues,
    mail,
    hidden,
    // folders,
    // dispatch,
    // direction,
    // updateMail,
    foldersObj,
    labelColors,
    // routeParams,
    // paginateMail,
    // handleStarMail,
    mailDetailsOpen,
    // handleLabelUpdate,
    // handleFolderUpdate,
    setMailDetailsOpen,
    toggleComposeOpen
  } = props

  // ** State
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showReplies, setShowReplies] = useState<boolean>(false)
  const [labelAnchorEl, setLabelAnchorEl] = useState<null | HTMLElement>(null)
  const [folderAnchorEl, setFolderAnchorEl] = useState<null | HTMLElement>(null)

  // ** Hook
  const { settings } = useSettings()

  // ** Vars
  const openLabelMenu = Boolean(labelAnchorEl)
  const openFolderMenu = Boolean(folderAnchorEl)

  // const handleMoveToTrash = () => {
  //   dispatch(updateMail({ emailIds: [mail.id], dataToUpdate: { folder: 'trash' } }))
  //   setMailDetailsOpen(false)
  // }

  const handleLabelMenuClick = (event: SyntheticEvent) => {
    setLabelAnchorEl(event.currentTarget as HTMLElement)
  }
  const handleLabelMenuClose = () => {
    setLabelAnchorEl(null)
  }

  const handleFolderMenuClick = (event: SyntheticEvent) => {
    setFolderAnchorEl(event.currentTarget as HTMLElement)
  }
  const handleFolderMenuClose = () => {
    setFolderAnchorEl(null)
    setMailDetailsOpen(false)
  }

  const handleReadMail = () => {
    // dispatch(updateMail({ emailIds: [mail.id], dataToUpdate: { isRead: false } }))
    setMailDetailsOpen(false)
  }
  const renderLabelsMenu = () => {
    return Object.entries(labelColors).map(([key, value]: string[]) => {
      return (
        <MenuItem
          key={key}
          sx={{ display: 'flex', alignItems: 'center' }}
          onClick={() => {
            // handleLabelUpdate([mail.id], key as MailLabelType)
            handleLabelMenuClose()
          }}
        >
          <Circle sx={{ mr: 2, fontSize: '0.75rem', color: `${value}.main` }} />
          <Typography sx={{ textTransform: 'capitalize' }}>{key}</Typography>
        </MenuItem>
      )
    })
  }

  const renderFoldersMenu = () => {
    return foldersObj['inbox'].map((folder: MailFoldersArrType) => {
      return (
        <MenuItem
          key={folder.name}
          sx={{ display: 'flex', alignItems: 'center' }}
          onClick={() => {
            // handleFolderUpdate(mail.id, folder.name)
            handleFolderMenuClose()
          }}
        >
          {folder.icon}
          <Typography sx={{ textTransform: 'capitalize' }}>{folder.name}</Typography>
        </MenuItem>
      )
    })

    // if (routeParams && routeParams.folder && !routeParams.label && foldersObj[routeParams.folder]) {
    //   return foldersObj[routeParams.folder].map((folder: MailFoldersArrType) => {
    //     return (
    //       <MenuItem
    //         key={folder.name}
    //         sx={{ display: 'flex', alignItems: 'center' }}
    //         onClick={() => {
    //           handleFolderUpdate(mail.id, folder.name)
    //           handleFolderMenuClose()
    //         }}
    //       >
    //         {folder.icon}
    //         <Typography sx={{ textTransform: 'capitalize' }}>{folder.name}</Typography>
    //       </MenuItem>
    //     )
    //   })
    // } else if (routeParams && routeParams.label) {
    //   return folders.map((folder: MailFoldersArrType) => {
    //     return (
    //       <MenuItem
    //         key={folder.name}
    //         sx={{ display: 'flex', alignItems: 'center' }}
    //         onClick={() => {
    //           handleFolderUpdate(mail.id, folder.name)
    //           handleFolderMenuClose()
    //         }}
    //       >
    //         {folder.icon}
    //         <Typography sx={{ textTransform: 'capitalize' }}>{folder.name}</Typography>
    //       </MenuItem>
    //     )
    //   })
    // } else {
    //   return foldersObj['inbox'].map((folder: MailFoldersArrType) => {
    //     return (
    //       <MenuItem
    //         key={folder.name}
    //         sx={{ display: 'flex', alignItems: 'center' }}
    //         onClick={() => {
    //           handleFolderUpdate(mail.id, folder.name)
    //           handleFolderMenuClose()
    //         }}
    //       >
    //         {folder.icon}
    //         <Typography sx={{ textTransform: 'capitalize' }}>{folder.name}</Typography>
    //       </MenuItem>
    //     )
    //   })
    // }
  }

  const PrevMailIcon = /* direction === 'rtl' ? ChevronRight : */ ChevronLeft
  const NextMailIcon = /* direction === 'rtl' ? ChevronLeft : */ ChevronRight
  const GoBackIcon = PrevMailIcon

  const ScrollWrapper = ({ children }: { children: ReactNode }) => {
    if (hidden) {
      return <Box sx={{ height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>{children}</Box>
    } else {
      return <PerfectScrollbar options={{ wheelPropagation: false }}>{children}</PerfectScrollbar>
    }
  }

  return (
    <Sidebar
      hideBackdrop
      direction="right"
      show={mailDetailsOpen}
      sx={{ zIndex: 1, width: "100%", overflow: "hidden" }}
      onClose={() => {
        setMailDetailsOpen(false);
        // setShowReplies(false)
      }}
    >
      {mail ? (
        <Fragment>
          {/* MESSAGE SUBJECT AND OPTIONS */}
          <Box
            sx={{
              px: 2.6,
              py: [2.25, 3],
              backgroundColor: "background.paper",
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: ["flex-start", "center"],
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  overflow: "hidden",
                  alignItems: "center",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                <IconButton
                  sx={{ mr: 3 }}
                  onClick={() => {
                    setMailDetailsOpen(false);
                    setShowReplies(false);
                  }}
                >
                  <GoBackIcon
                    sx={{ color: "text.primary", fontSize: "1.5rem" }}
                  />
                </IconButton>
                <Box
                  sx={{
                    display: "flex",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    flexDirection: ["column", "row"],
                  }}
                >
                  <Typography noWrap sx={{ mr: 5 }}>
                    {mail.subject}
                  </Typography>

                  {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {mail.labels && mail.labels.length
                      ? mail.labels.map((label: MailLabelType) => {
                          return (
                            <CustomChip
                              key={label}
                              size='small'
                              skin='light'
                              label={label}
                              color={labelColors[label] as ThemeColor}
                              sx={{
                                height: 20,
                                textTransform: 'capitalize',
                                '&:not(:last-of-type)': { mr: 2 },
                                '& .MuiChip-label': { fontWeight: 500 }
                              }}
                            />
                          )
                        })
                      : null}
                  </Box> */}
                </Box>
              </Box>

              <Box sx={{ display: "flex" }}>
                <IconButton
                  size="small"
                  // disabled={!mail.hasPreviousMail}
                  // sx={{ color: mail.hasPreviousMail ? 'text.primary' : 'text.secondary' }}
                  // onClick={() => dispatch(paginateMail({ dir: 'previous', emailId: mail.id }))}
                >
                  <PrevMailIcon />
                </IconButton>
                <IconButton
                  size="small"
                  // disabled={!mail.hasNextMail}
                  // sx={{ color: mail.hasNextMail ? 'text.primary' : 'text.secondary' }}
                  // onClick={() => dispatch(paginateMail({ dir: 'next', emailId: mail.id }))}
                >
                  <NextMailIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>

          {/* TOP OPTIONS */}
          <Box
            sx={{
              backgroundColor: "background.paper",
              p: (theme) => theme.spacing(3, 2, 3, 3),
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {/* {routeParams && routeParams.folder !== 'trash' ? (
                  <IconButton size='small' onClick={handleMoveToTrash}>
                    <DeleteOutline sx={{ mr: 1 }} />
                  </IconButton>
                ) : null} */}

                <IconButton size="small" onClick={handleReadMail}>
                  <EmailOutline sx={{ mr: 1 }} />
                </IconButton>
                <IconButton size="small" onClick={handleFolderMenuClick}>
                  <FolderOutline sx={{ mr: 1 }} />
                </IconButton>

                <Menu
                  open={openLabelMenu}
                  anchorEl={labelAnchorEl}
                  onClose={handleLabelMenuClose}
                  PaperProps={{ style: { minWidth: "9rem" } }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  {renderLabelsMenu()}
                </Menu>

                <IconButton size="small" onClick={handleLabelMenuClick}>
                  <LabelOutline />
                </IconButton>

                <Menu
                  open={openFolderMenu}
                  anchorEl={folderAnchorEl}
                  onClose={() => setFolderAnchorEl(null)}
                  PaperProps={{ style: { minWidth: "9rem" } }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  {renderFoldersMenu()}
                </Menu>
              </Box>

              {/* <Box>
                <IconButton
                  size='small'
                  onClick={e => handleStarMail(e, mail.id, !mail.isStarred)}
                  sx={{ mr: 1, ...(mail.isStarred ? { color: 'warning.main' } : {}) }}
                >
                  <StarOutline />
                </IconButton>
                {mail.replies.length ? (
                  <IconButton size='small' onClick={() => (showReplies ? setShowReplies(false) : setShowReplies(true))}>
                    {showReplies ? (
                      <ArrowCollapseVertical sx={{ mr: 1, fontSize: '1.375rem' }} />
                    ) : (
                      <ArrowExpandVertical sx={{ mr: 1, fontSize: '1.375rem' }} />
                    )}
                  </IconButton>
                ) : null}
                <IconButton size='small'>
                  <DotsVertical />
                </IconButton>
              </Box> */}
            </Box>
          </Box>

          {/* MESSAGE BODY */}
          <Box
            sx={{
              height: "calc(100% - 7.75rem)",
              backgroundColor: (theme) => theme.palette.action.hover,
            }}
          >
            <ScrollWrapper>
              <Box
                sx={{
                  py: 4,
                  px: 5,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                {/* REPLIES HEADER */}
                {/* {mail.replies.length && !showReplies ? (
                  <Typography
                    onClick={() => setShowReplies(true)}
                    sx={{ mt: 1, mb: 5, color: 'text.secondary', cursor: 'pointer' }}
                  >
                    {mail.replies.length} Earlier Messages
                  </Typography>
                ) : null} */}

                {/* REPLIES BODY */}
                {/* {showReplies
                  ? mail.replies.map((reply: MailType, index: number) => {
                      return (
                        <Box
                          key={index}
                          sx={{
                            mb: 4,
                            boxShadow: 6,
                            width: '100%',
                            borderRadius: 1,
                            backgroundColor: 'background.paper',
                            border: theme => `1px solid ${theme.palette.divider}`
                          }}
                        >
                          <Box sx={{ p: 5 }}>
                            <Box
                              sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }}
                            >
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar
                                  alt={reply.from.name}
                                  src={reply.from.avatar}
                                  sx={{ width: '2.375rem', height: '2.375rem', mr: 3 }}
                                />
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                  <Typography sx={{ color: 'text.secondary' }}>{reply.from.name}</Typography>
                                  <Typography variant='body2' sx={{ color: 'text.disabled' }}>
                                    {reply.from.email}
                                  </Typography>
                                </Box>
                              </Box>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant='body2' sx={{ mr: 1.75, color: 'text.disabled' }}>
                                  {new Date(reply.time).toDateString()}{' '}
                                  {new Date(reply.time).toLocaleTimeString('en-US', {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: true
                                  })}
                                </Typography>
                                {mail.attachments.length ? (
                                  <IconButton size='small' sx={{ mr: 0.5 }}>
                                    <Attachment fontSize='small' />
                                  </IconButton>
                                ) : null}
                                <IconButton size='small'>
                                  <DotsVertical fontSize='small' />
                                </IconButton>
                              </Box>
                            </Box>
                          </Box>
                          <Divider sx={{ m: 0 }} />
                          <Box sx={{ px: 5, py: 0 }}>
                            <Box sx={{ color: 'text.secondary' }} dangerouslySetInnerHTML={{ __html: reply.message }} />
                          </Box>
                          {reply.attachments.length ? (
                            <Fragment>
                              <Divider sx={{ m: 0 }} />
                              <Box sx={{ p: 5 }}>
                                <Typography variant='body2'>Attachments</Typography>
                                <List>
                                  {reply.attachments.map((item: MailAttachmentType) => {
                                    return (
                                      <ListItem disableGutters key={item.fileName}>
                                        <ListItemIcon>
                                          <img src={item.thumbnail} alt={item.fileName} width='24' height='24' />
                                        </ListItemIcon>
                                        <Typography>{item.fileName}</Typography>
                                      </ListItem>
                                    )
                                  })}
                                </List>
                              </Box>
                            </Fragment>
                          ) : null}
                        </Box>
                      )
                    })
                  : null}

                {mail.replies.length && !showReplies ? (
                  <Fragment>
                    <HiddenReplyBack sx={{ cursor: 'pointer' }} onClick={() => setShowReplies(true)} />
                    <HiddenReplyFront sx={{ cursor: 'pointer' }} onClick={() => setShowReplies(true)} />
                  </Fragment>
                ) : null} */}

                {/* MESSAGE BODY CONTENT */}
                <Box
                  sx={{
                    mb: 4,
                    width: "100%",
                    height: "100%",
                    borderRadius: 1,
                    overflow: "visible",
                    position: "relative",
                    backgroundColor: "background.paper",
                    boxShadow: settings.skin === "bordered" ? 0 : 6,
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <Box sx={{ p: 5 }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          // alt={mail.from.name}
                          // src={mail.from.avatar}
                          sx={{ width: "2.375rem", height: "2.375rem", mr: 3 }}
                        />
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <Typography sx={{ color: "text.secondary" }}>
                            {mail.from}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ color: "text.disabled" }}
                          >
                            {mail.to}
                          </Typography>
                        </Box>
                      </Box>

                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="body2"
                          sx={{ mr: 1.75, color: "text.disabled" }}
                        >
                          {`${new Date(mail.date).toDateString()} ${new Date(
                            mail.date
                          ).toLocaleTimeString("en-US", {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })}`}
                        </Typography>

                        {/* ATTACHMENTS LENGTH */}
                        {/* {mail.attachments.length ? (
                          <IconButton size='small' sx={{ mr: 0.5, color: 'action.active' }}>
                            <Attachment sx={{ fontSize: '1.25rem' }} />
                          </IconButton>
                        ) : null} */}
                        <MailCardMenu
                          {...{
                            toggleComposeOpen,
                            setComposeInitialValues,
                            mail,
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>

                  <Divider sx={{ m: 0 }} />

                  {/* CONTENT */}
                  <Box sx={{ p: 5 }}>
                    <Box
                      sx={{ color: "text.secondary" }}
                      dangerouslySetInnerHTML={{ __html: mail.body }}
                    />
                  </Box>

                  {/* ATTACHMENT LIST */}
                  {/* {mail.attachments.length ? (
                    <Fragment>
                      <Divider sx={{ m: 0 }} />
                      <Box sx={{ p: 5 }}>
                        <Typography variant='body2'>Attachments</Typography>
                        <List>
                          {mail.attachments.map((item: MailAttachmentType) => {
                            return (
                              <ListItem disableGutters key={item.fileName}>
                                <ListItemIcon>
                                  <img src={item.thumbnail} alt={item.fileName} width='24' height='24' />
                                </ListItemIcon>
                                <Typography>{item.fileName}</Typography>
                              </ListItem>
                            )
                          })}
                        </List>
                      </Box>
                    </Fragment>
                  ) : null} */}
                </Box>

                {/* ACTION ITEMS */}
                {/* <Box
                  sx={{
                    p: 5,
                    width: '100%',
                    borderRadius: 1,
                    border: '1px solid',
                    borderColor: 'divider',
                    backgroundColor: 'background.paper',
                    boxShadow: settings.skin === 'bordered' ? 0 : 6
                  }}
                >
                  <Typography sx={{ color: 'text.secondary' }}>
                    Click here to{' '}
                    <Typography
                      component='span'
                      sx={{ cursor: 'pointer', color: 'primary.main', fontWeight: 'inherit' }}
                    >
                      Reply
                    </Typography>{' '}
                    or{' '}
                    <Typography
                      component='span'
                      sx={{ cursor: 'pointer', color: 'primary.main', fontWeight: 'inherit' }}
                    >
                      Forward
                    </Typography>
                  </Typography>
                </Box> */}
              </Box>
            </ScrollWrapper>
          </Box>
        </Fragment>
      ) : null}
    </Sidebar>
  );
}

export default MailDetails
