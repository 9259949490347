import i18next from "i18next";
import * as yup from "yup";

yup.setLocale({
  // use constant translation keys for messages without values
  mixed: {
    required: () => i18next.t("this_field_must_not_be_empty"),
    notOneOf: ({ value }) => {
      if (value === false) {
        return i18next.t("this_field_must_be_true")
      }

      return i18next.t("this_field_must_not_be_empty")
    },
  },

  // use functions to generate an error object that includes the value from the schema
  string: {
    email: () => i18next.t("please_provide_a_valid_email"),
    min: ({ min }) => i18next.t(`field_min_length`, {val: min}),
    max: ({ max }) => i18next.t('field_max_length', {val: max}),
  },
});

export default yup;
