// ** React Imports
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";

// ** MUI Imports
import Icon from "@mui/material/Icon";
import Dialog from "@mui/material/Dialog";
import { DataGrid, GridSelectionModel } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

// ** Third Party Imports
import { useTranslation } from "react-i18next";

// ** Hook Imports
import { useAlertContext } from "../../../hooks/useAlertContext";

// ** Type Imports
import { BasketPriceTypeTemp, OrderLineItemT, OrderType } from "../../../types/orderTypes";
import { ProductType } from "../../../types/productTypes";

import { getColumns } from "../add-edit/table-columns";
import { AppDispatch } from "../../../store";
import { useDispatch } from "react-redux";
import { updateOrder } from "../../../store/apps/order";
import { fetchData as fetchProducts } from "../../../store/apps/product";
import { getDefaultCompany } from "../../../store/apps/companies";
import { Box, LinearProgress } from "@mui/material";
import { INITIAL_ADD_POREZ_PRODUCT_ITEM, INITIAL_ADD_VARIOBEL_PRODUCT_ITEM, calculateBaseBasketPrice, isVariobelLineItem, updateBasketPriceWithDiscounts, updateItemPricing, updateProdcutPricingWithMargins } from "../add-edit/helpers";
import ObjectID from "bson-objectid";
import { Customer } from "../../../types/customerTypes";
import { useSettings } from "../../../hooks/useSettings";

interface EditOrderDialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  order: OrderType;
  setOrder: Dispatch<SetStateAction<OrderType | null>>;
}

export const EditOrderDialog = (props: EditOrderDialogProps) => {
  // ** Props
  const { open, setOpen, order, setOrder } = props;

  // ** States
  const [pageSize, setPageSize] = useState<number>(25);
  const [products, setProducts] = useState<ProductType[]>([]);

  const [itemList, setItemList] = useState<OrderLineItemT[]>(order.lineItems);
  const [basketPrice, setBasketPrice] = useState<BasketPriceTypeTemp>(order.basketPrice);
  const [customer] = useState<Customer>(order.customer);

  const [tax, setTax] = useState(0);
  const [updating, setUpdating] = useState(false);
  const [selectedListItems, setSelectedListItems] =
    useState<GridSelectionModel>([]);
  // ** Hooks
  const { t } = useTranslation();
  const { showSuccessAlert, showErrorAlert } = useAlertContext();
  const dispatch = useDispatch<AppDispatch>();

  const { settings } = useSettings();
  const { configuredCompany } = settings;

  const isVariobelOnlyField = configuredCompany === "variobel"

  // ** Handlers
  const handleClose = () => {
    setOpen(!open);
  };

  const handleOrderUpdate = async () => {
    setUpdating(true);

    await dispatch(
      updateOrder({
        id: order._id,
        data: {
          lineItems: itemList,
          basketPrice
        },
      })
    )
      .unwrap()
      .then((res) => {
        const orderData = res.order as OrderType;
        setOrder(orderData);
        showSuccessAlert(t("Order has been updated"));
      })
      .catch((err) => {
        Promise.reject(err);
        showErrorAlert(t("Error during update! Check console"));
      });

    setUpdating(false);
  };

  const handleAddItem = () => {
    const _id = new ObjectID();
    const initialDataItem = isVariobelOnlyField ? INITIAL_ADD_VARIOBEL_PRODUCT_ITEM : INITIAL_ADD_POREZ_PRODUCT_ITEM

    const newItem = {
      ...initialDataItem,
      _id,
      product: products[0],
    };

    // id is not needed in OrderLineItemT
    const { id, ...rest } = newItem;

    // unknown because MongoDB expects ID to be 24 Hex characters String or a number
    // in this case its a number
    const _data = [...itemList, { ...rest }] as unknown as OrderLineItemT[];
    setItemList(_data);
  };

  const handleDeleteItem = () => {
    const _items = itemList.filter(
      (item) => selectedListItems.indexOf(item._id) === -1
    );

    setItemList(_items);
  };

  const handleCopyItem = () => {
    const copiedItems = [] as OrderLineItemT[];

    selectedListItems.forEach((id) => {
      let row = itemList.find((row) => row._id === id) as OrderLineItemT;
      copiedItems.push(row);
    });

    let _items = [...itemList, ...copiedItems];

    const _id = new ObjectID();

    // Update id so it's unique
    _items = _items.map((row, i) => ({
      ...row,
      _id,
    })) as unknown as OrderLineItemT[];

    setSelectedListItems([]);
    setItemList(_items);
  };

  // fetch products
  useEffect(() => {
    dispatch(fetchProducts())
      .unwrap()
      .then((res) => {
        if (!isVariobelOnlyField) {
          return setProducts(res.products);
        }

        const _products = (res.products as ProductType[]).map((product) =>
          updateProdcutPricingWithMargins(product, customer)
        );

        setProducts(_products);
      });
  }, [customer, dispatch, isVariobelOnlyField]);

  // get company to get tax
  useEffect(() => {
    dispatch(getDefaultCompany())
      .unwrap()
      .then((res) => {
        const data = res.company;
        setTax(data.taxRate);
      });
  }, [dispatch]);

  const columns = useMemo(
    () =>
      getColumns<OrderLineItemT>({
        t,
        showErrorAlert,
        itemList,
        setItemList,
        products,
        tax,
        type: "edit",
        customer,
        basketPrice,
        isVariobelOnlyField,
      }),
    [
      t,
      showErrorAlert,
      products,
      itemList,
      tax,
      customer,
      basketPrice,
      isVariobelOnlyField,
    ]
  );

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      scroll="body"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle sx={{ fontSize: "1.5rem", pb: 0 }}>
        {t("Edit order")}
        <IconButton
          size="small"
          onClick={handleClose}
          sx={{ position: "absolute", right: "1rem", top: "1rem" }}
        >
          <Icon>close</Icon>
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ pt: (theme) => `${theme.spacing(2)} !important` }}>
        <Box sx={{ display: "flex", gap: 2, py: 1 }}>
          <IconButton onClick={handleAddItem}>
            <Icon>add</Icon>
          </IconButton>

          <IconButton
            onClick={handleDeleteItem}
            disabled={selectedListItems.length === 0}
          >
            <Icon>delete</Icon>
          </IconButton>

          <IconButton
            onClick={handleCopyItem}
            disabled={selectedListItems.length === 0}
          >
            <Icon>content_copy</Icon>
          </IconButton>
        </Box>

        <DataGrid
          autoHeight
          disableSelectionOnClick
          checkboxSelection
          selectionModel={selectedListItems}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectedListItems(newSelectionModel);
          }}
          rows={itemList}
          columns={columns}
          pagination
          pageSize={pageSize}
          onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
          experimentalFeatures={{ newEditingApi: true }}
          processRowUpdate={(updatedRow) => {
            if(!isVariobelLineItem(updatedRow)) return updatedRow;

            const { ppm2, ppu, price } = updateItemPricing(updatedRow);

            updatedRow.ppm2 = ppm2;
            updatedRow.ppu = ppu;
            updatedRow.price = price;

            const updatedItems = itemList.map((item) => {
              if (item._id === updatedRow._id) {
                return updatedRow;
              }

              return item;
            });

            const baseBasketPrice = calculateBaseBasketPrice(
              updatedItems,
              tax,
              basketPrice
            );
            const basketPriceWithDiscounts = updateBasketPriceWithDiscounts(
              customer,
              updatedItems,
              baseBasketPrice
            );

            setItemList(updatedItems);
            setBasketPrice(basketPriceWithDiscounts);

            return updatedRow;
          }}
        />
      </DialogContent>

      {updating && <LinearProgress />}

      <DialogActions>
        <Button
          disabled={updating}
          variant="contained"
          onClick={handleOrderUpdate}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};
