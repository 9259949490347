import {
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { Icon } from "@mui/material";

import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  open: boolean;
  company: string;
  handleClose: () => void;
  handleDelete: () => void;
}

const DeleteCompanyDialog = (props: Props) => {
  const { open, company, handleClose, handleDelete } = props;
  const { t } = useTranslation();
  

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      scroll="body"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        Delete Company
        <IconButton
          size="small"
          onClick={handleClose}
          sx={{ position: "absolute", right: "1rem", top: "1rem" }}
        >
          <Icon>close</Icon>
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Typography textAlign="center">
          {t("Are you sure you want this delete Company?")}
        </Typography>
        <Typography textAlign="center" color="error" fontWeight={700}>
          {company}
        </Typography>
      </DialogContent>

      <DialogActions>
        <Button
          size="large"
          type="submit"
          sx={{ mr: 2 }}
          variant="contained"
          onClick={handleDelete}
        >
          Yes
        </Button>
        <Button
          size="large"
          color="secondary"
          variant="outlined"
          onClick={handleClose}
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteCompanyDialog
