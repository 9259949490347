import React, { ReactNode, useState } from "react";
import { ACLObj, AppAbilityT, buildAbilityFor } from "../../configs/acl";
import NotAuthorized from "../401";
import { AbilityContext } from "../layouts/components/acl/Can";
import { useAuth } from "../../hooks/useAuth";

interface Props {
  children: ReactNode;
  guestGuard: boolean;
  aclAbilities: ACLObj;
}

const AclGuard = (props: Props) => {
  const { aclAbilities, children, guestGuard } = props;

  const [ability, setAbility] = useState<AppAbilityT | undefined>(undefined);

  const auth = useAuth();
  // const router = useRouter()

  // If guestGuard is true and user is not logged in or its an error page, render the page without checking access
  if (guestGuard /* || router.route === '/404' || router.route === '/500' || router.route === '/' */) {
    return <>{children}</>
  }

  // User is logged in, build ability for the user based on his role
  if (auth.user && auth.user.role && !ability) {
    setAbility(buildAbilityFor(auth.user.role, aclAbilities.subject))
  }

  // Check the access of current user and render pages
  if (ability && ability.can(aclAbilities.action, aclAbilities.subject)) {
    return (
      <AbilityContext.Provider value={ability}>
        {children}
      </AbilityContext.Provider>
    );
  }

  // Render Not Authorized component if the current user has limited access
  return (
    <NotAuthorized />
  );
};

export default AclGuard;
