import { FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';

const FormFieldError = () => {
  const { t } = useTranslation();

  return (
    <FormHelperText sx={{ color: "error.main" }}>
      {t("This field cannot be empty")}
    </FormHelperText>
  );
}

export default FormFieldError