// ** MUI Imports
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";

// ** Third Party Imports
import { useTranslation } from "react-i18next";
import DialogContainer from "../dialogContainer";
import AdminForm from "./adminForm";
import { useState } from "react";

interface Props {}

export const TableHeader = (props: Props) => {
  const { t } = useTranslation();

  const [openAddDialog, setOpenAddDialog] = useState(false);

  const toggleAddDialog = () => setOpenAddDialog((prev) => !prev);

  return (
    <Box
      sx={{
        p: 5,
        pb: 3,
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Button
        sx={{ mr: 4, mb: 2 }}
        color="secondary"
        variant="outlined"
        startIcon={<Icon>ios_share</Icon>}
      >
        {t("Export")}
      </Button>

      <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
        <Button sx={{ mb: 2 }} variant="contained" onClick={toggleAddDialog}>
          {t("Add admin")}
        </Button>

        <DialogContainer handleClose={toggleAddDialog} open={openAddDialog}>
          <AdminForm />
        </DialogContainer>
      </Box>
    </Box>
  );
};
