// ** Redux Imports
import { Dispatch } from "redux";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

// ** Types Imports
import { OrderCreateType, OrderFormInputs, OrderType } from "../../../types/orderTypes";

interface DataQueries {
  after: string;
  before: string;
  search: string;
  status: string;
  company: string;
}

interface Redux {
  getState: any;
  dispatch: Dispatch<any>;
}

interface AppOrderState {
  data: OrderType[];
  total: number;
  queries: DataQueries;
  allData: OrderType[];
}

const defaultDataQueries: DataQueries = {
  after: "",
  before: "",
  search: "",
  status: "",
  company: "",
};

const initialState: AppOrderState = {
  data: [],
  total: 0,
  queries: defaultDataQueries,
  allData: [],
};

// ** Fetch Orders
export const fetchData = createAsyncThunk(
  "order/fetchData",
  async (optionalQueries: Partial<DataQueries>) => {
    const queries = { ...defaultDataQueries, ...optionalQueries };

    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_HOST}/order/get-all?after=${queries.after}&before=${queries.before}&search=${queries.search}&status=${queries.status}&company=${queries.company}`,
      { withCredentials: true }
    );

    return { ...response.data, queries };
  }
);

// ** Fetch Order
export const getOrder = createAsyncThunk(
  "order/get",

  async (id: number | string, { getState, dispatch }: Redux) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_HOST}/order/get/${id}`,
      { withCredentials: true }
    );

    return response.data;
  }
);

// ** Update Order
export const updateOrder = createAsyncThunk(
  "order/update",

  async (
    args: {
      id: number | string;
      data: OrderFormInputs;
    },
    { getState, dispatch }: Redux
  ) => {
    const response = await axios.patch(
      `${process.env.REACT_APP_SERVER_HOST}/order/update/${args.id}`,
      {
        ...args.data,
      },
      { withCredentials: true }
    );

    await dispatch(fetchData(getState().order.queries));

    return response.data;
  }
);

export const deleteOrder = createAsyncThunk(
  "order/delete",

  async (id: number | string, { getState, dispatch }: Redux) => {
    const response = await axios.delete(
      `${process.env.REACT_APP_SERVER_HOST}/order/delete/${id}`,
      { withCredentials: true }
    );

    await dispatch(fetchData(getState().order.queries));

    return response.data;
  }
);

export const createOrder = createAsyncThunk(
  "order/add",

  async (data: OrderCreateType, { getState, dispatch }: Redux) => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_HOST}/order/create`,
      data,
      { withCredentials: true }
    );

    await dispatch(fetchData(getState().order.queries));

    return response.data;
  }
);

export const appOrderSlice = createSlice({
  name: "appOrder",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchData.fulfilled, (state, action) => {
      state.data = action.payload.orders;
      state.queries = action.payload.queries;
      state.allData = action.payload.allData;
      state.total = action.payload.orders.length;
    });
  },
});

export default appOrderSlice.reducer;
