import {
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";

import {
  Box,
  Typography,
  Button,
} from "@mui/material";

import { Link } from "react-router-dom";
import relativeTime from "../../../../helpers/getRelativeTime";

import { CustomerOrderCreateDataType, CustomerTimelineItemType } from "../../../../types/customerTypes";

interface Props extends CustomerTimelineItemType {
  handleFilter: () => void;
}

const OrderCreatedTimelineItem = (props: Props) => {
  const { data, handleFilter } = props;
  const { time, data: orderData } = data;
  const { id, orderNumber } = orderData as CustomerOrderCreateDataType;

  return (
    <TimelineItem sx={{ minHeight: 0 }}>
      <TimelineSeparator sx={{ px: "4px" }}>
        <TimelineDot color="info" />
        <TimelineConnector />
      </TimelineSeparator>

      <TimelineContent
        sx={{
          mt: 0,
          mb: (theme) => `${theme.spacing(3)} !important`,

          "&:hover": {
            cursor: "pointer",
            "& .order-filter-button": {
              display: "flex",
              transition: "all 0.3s ease-in-out",
              fontSize: "0.55rem",
              fontWeight: 700,
              minWidth: 0
            },
          },
        }}
      >
        <Box
          sx={{
            mb: 3,
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography
              sx={{
                mr: 2,
                fontWeight: 600,
                a: {
                  ":hover": {
                    textDecoration: "underline",
                  },
                },
              }}
            >
              Order <Link to={`/order/preview/${id}`}>#{orderNumber}</Link>{" "}
              <i>Created</i>
            </Typography>
            <Button
              variant="outlined"
              size="small"
              className="order-filter-button"
              sx={{ display: "none" }}
              onClick={handleFilter}
            >
              Filter
            </Button>
          </Box>

          {!time && (
            <Typography variant="caption" sx={{ color: "text.disabled" }}>
              NA
            </Typography>
          )}

          {time && (
            <Typography variant="caption" sx={{ color: "text.disabled" }}>
              {relativeTime(new Date(), new Date(time))}
            </Typography>
          )}
        </Box>

        <Typography variant="body2" sx={{ mb: 2 }}>
          Invoice hasn't been paid
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
};

export default OrderCreatedTimelineItem;
