import { Box, Typography, styled } from "@mui/material";
import { OrderType } from "../../../types/orderTypes";
import { Link } from "react-router-dom";
import { Chip } from "../../chip";
import { currencyFormatter } from "../../../helpers/currency-formatter";
import relativeTime from "../../../helpers/getRelativeTime";
import { ThemeColor } from "../../@core/layouts/types";
import { KanbanColumnT } from "../../../types/orderStatusTypes";

interface Props {
  order: OrderType;
  columnInfo: KanbanColumnT;
}

export const KanbanItem = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  boxShadow: "0 0.125rem 0.625rem 0 rgba(20, 21, 33, 0.18)",
  width: "16.25rem",
  minHeight: "134px",
  padding: "1rem",
  borderRadius: "0.625rem",
  display: "flex",
  flexDirection: "column",
  transition: "all 0.3s cubic-bezier(0.23, 1, 0.32, 1)",

  ":hover": {
    cursor: "move",
    boxShadow: "rgba(0, 0, 0, 0.1) 0 4px 20px 0",
  },

  justifyContent: "space-between",
}));

export const KanbanItemContent = (props: Props) => {
  const { order, columnInfo } = props;

  return (
    <>
      <Typography
        component={Link}
        to={`/order/preview/${order._id}`}
        sx={{
          fontWeight: 500,
          fontStyle: "italic",
          "&:hover": { textDecoration: "underline" },
        }}
      >
        #{order.orderNumber}
      </Typography>
      <Typography variant="body2" sx={{ textTransform: "capitalize" }}>
        {order.customer?.firstName} {order.customer?.lastName}
      </Typography>

      <Box
        sx={{
          marginLeft: "auto",
          gap: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <Chip
          skin="light"
          size="small"
          label={currencyFormatter(order.basketPrice.price)}
          color={columnInfo.colorCode as ThemeColor}
        />

        <Typography variant="body2">
          {relativeTime(new Date(), new Date(order.lastModified))}
        </Typography>
      </Box>
    </>
  );
};
