import { LineItemType, AddOrderListLineItemType } from "../../../../types/orderTypes";


export const ownWallpaperThumb = (lineItem: LineItemType | AddOrderListLineItemType) => {
  if (lineItem.print.type !== "ownWallpaper") return;
  const obj = JSON.parse(lineItem.print.value);

  if (Object.hasOwn(obj, "cropped")) {
    return obj.cropped;
  }

  return obj.original;
};
