import {
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";

import {
  Box,
  Typography,
  Button,
} from "@mui/material";

import { Link } from "react-router-dom";

import { TimelineEntriesType, TimelineEntryTypeKeyType, timelineEntryType } from "../../../../types/customTimelineTypes";
import { CustomerOrderManualEntriesDataType, CustomerTimelineItemType } from "../../../../types/customerTypes";
import { Admin } from "../../../../types/adminTypes";
import { RootState } from "../../../../store";

import { getInitials } from "../../../../helpers/get-initials";
import relativeTime from "../../../../helpers/getRelativeTime";

import { Avatar } from "../../../avatar";

interface Props extends CustomerTimelineItemType {
  handleFilter: () => void;
  admins: RootState['admin'];
}

const OrderEntryTimelineItem = (props: Props) => {
  const { data, admins, handleFilter  } = props;

  const {
    id,
    orderNumber,
    data: entryData,
  } = data.data as CustomerOrderManualEntriesDataType;
  const { type, text, time, admin } =
    entryData as TimelineEntriesType;

  const Icon =
    timelineEntryType[type as TimelineEntryTypeKeyType];

  const currentAdmin = (admins.data as Admin[]).find(
    (_admin) => _admin._id === admin
  );

  return (
    <TimelineItem>
      <TimelineSeparator>
        <Icon color="primary" fontSize="small" />
        <TimelineConnector />
      </TimelineSeparator>

      <TimelineContent
        sx={{
          mt: 0,
          pt: 0,
          mb: (theme) => `${theme.spacing(3)} !important`,

          "&:hover": {
            cursor: "pointer",
            "& .order-filter-button": {
              display: "flex",
              transition: "all 0.3s ease-in-out",
              fontSize: "0.55rem",
              fontWeight: 700,
              minWidth: 0
            },

            "& .entry-user": {
              display: "contents",
              transition: "all 0.3s ease-in-out",
            },
          },
        }}
      >
        <Box
          sx={{
            mb: 3,
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography
              sx={{
                mr: 2,
                fontWeight: 600,
                textTransform: "capitalize",
                a: {
                  ":hover": {
                    textDecoration: "underline",
                  },
                },
              }}
            >
              Order{" "}
              <Link to={`/order/preview/${id}`}>
                #{orderNumber}
              </Link>{" "}
              <i>{type}</i> Entry
            </Typography>
            <Button
              variant="outlined"
              size="small"
              className="order-filter-button"
              sx={{ display: "none" }}
              onClick={handleFilter}
            >
              Filter
            </Button>
          </Box>

          {!time && (
            <Typography
              variant="caption"
              sx={{ color: "text.disabled" }}
            >
              NA
            </Typography>
          )}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            {admin && currentAdmin && (
              <Box
                sx={{ display: "none" }}
                className="entry-user"
              >
                {currentAdmin.avatar ? (
                  <Avatar
                    alt="Profile Pic"
                    src={currentAdmin.avatar}
                    skin="light"
                    sx={{
                      width: 24,
                      height: 24,
                      fontWeight: 600,
                      fontSize: "0.75rem",
                      textTransform: "uppercase",
                    }}
                  />
                ) : (
                  <Avatar
                    skin="light"
                    sx={{
                      width: 24,
                      height: 24,
                      fontWeight: 600,
                      fontSize: "0.75rem",
                      textTransform: "uppercase",
                    }}
                  >
                    {getInitials(currentAdmin.displayName)}
                  </Avatar>
                )}
                <Typography
                  variant="caption"
                  sx={{ color: "text.disabled" }}
                >
                  {currentAdmin.displayName} created this{" "}
                </Typography>
              </Box>
            )}
            {time && (
              <Typography
                variant="caption"
                sx={{ color: "text.disabled" }}
              >
                {relativeTime(new Date(), new Date(time))}
              </Typography>
            )}
          </Box>
        </Box>

        <Typography
          variant="body2"
          sx={{ mb: 2, textTransform: "capitalize" }}
        >
          Text: {text}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}

export default OrderEntryTimelineItem