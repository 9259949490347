// ** React Imports
import { useState, useEffect } from "react";

// ** MUI Imports
import {
  Box,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";

// ** Icon Imports
import { InformationOutline } from "mdi-material-ui";

// ** Fake-Data Imports
import { rolesArr } from "../@fake-db/roles/static-data";

// ** Props
interface Props {
  dialogTitle: string;
  toggleAddDialog: () => any;
}

const RolePermissionForm = (props: Props) => {
  const { dialogTitle, toggleAddDialog } = props;

  // ** States
  const [selectedCheckbox, setSelectedCheckbox] = useState<string[]>([]);
  const [isIndeterminateCheckbox, setIsIndeterminateCheckbox] =
    useState<boolean>(false);

  const togglePermission = (id: string) => {
    const arr = selectedCheckbox;
    if (selectedCheckbox.includes(id)) {
      arr.splice(arr.indexOf(id), 1);
      setSelectedCheckbox([...arr]);
    } else {
      arr.push(id);
      setSelectedCheckbox([...arr]);
    }
  };

  const handleSelectAllCheckbox = () => {
    if (isIndeterminateCheckbox) {
      setSelectedCheckbox([]);
    } else {
      rolesArr.forEach((row) => {
        const id = row.toLowerCase().split(" ").join("-");
        togglePermission(`${id}-read`);
        togglePermission(`${id}-write`);
        togglePermission(`${id}-create`);
      });
    }
  };

  useEffect(() => {
    if (
      selectedCheckbox.length > 0 &&
      selectedCheckbox.length < rolesArr.length * 3
    ) {
      setIsIndeterminateCheckbox(true);
    } else {
      setIsIndeterminateCheckbox(false);
    }
  }, [selectedCheckbox]);

  return (
    <Card sx={{ p: { xs: 6, sm: 12 } }}>
      <DialogTitle sx={{ textAlign: "center" }}>
        <Typography variant="h5" component="span">
          {`${dialogTitle} Role`}
        </Typography>
        <Typography variant="body2">Set Role Permissions</Typography>
      </DialogTitle>
      <Box sx={{ my: 4 }}>
        <FormControl fullWidth>
          <TextField label="Role Name" placeholder="Enter Role Name" />
        </FormControl>
      </Box>
      <Typography variant="h6">Role Permissions</Typography>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ pl: "0 !important" }}>
                <Box
                  sx={{
                    display: "flex",
                    fontSize: "0.875rem",
                    whiteSpace: "nowrap",
                    alignItems: "center",
                    textTransform: "capitalize",
                    "& svg": { ml: 1, cursor: "pointer" },
                  }}
                >
                  Customer Access
                  <Tooltip
                    placement="top"
                    title="Allows a full access to the system"
                  >
                    <Box sx={{ display: "flex" }}>
                      <InformationOutline fontSize="small" />
                    </Box>
                  </Tooltip>
                </Box>
              </TableCell>
              <TableCell colSpan={3}>
                <FormControlLabel
                  label="Select All"
                  sx={{
                    "& .MuiTypography-root": {
                      textTransform: "capitalize",
                    },
                  }}
                  control={
                    <Checkbox
                      size="small"
                      onChange={handleSelectAllCheckbox}
                      indeterminate={isIndeterminateCheckbox}
                      checked={selectedCheckbox.length === rolesArr.length * 3}
                    />
                  }
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rolesArr.map((i: string, index: number) => {
              const id = i.toLowerCase().split(" ").join("-");

              return (
                <TableRow
                  key={index}
                  sx={{
                    "& .MuiTableCell-root:first-of-type": {
                      pl: "0 !important",
                    },
                  }}
                >
                  <TableCell
                    sx={{
                      fontWeight: 600,
                      whiteSpace: "nowrap",
                      color: (theme) =>
                        `${theme.palette.text.primary} !important`,
                    }}
                  >
                    {i}
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      label="Read"
                      control={
                        <Checkbox
                          size="small"
                          id={`${id}-read`}
                          onChange={() => togglePermission(`${id}-read`)}
                          checked={selectedCheckbox.includes(`${id}-read`)}
                        />
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      label="Write"
                      control={
                        <Checkbox
                          size="small"
                          id={`${id}-write`}
                          onChange={() => togglePermission(`${id}-write`)}
                          checked={selectedCheckbox.includes(`${id}-write`)}
                        />
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <FormControlLabel
                      label="Create"
                      control={
                        <Checkbox
                          size="small"
                          id={`${id}-create`}
                          onChange={() => togglePermission(`${id}-create`)}
                          checked={selectedCheckbox.includes(`${id}-create`)}
                        />
                      }
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <DialogActions sx={{ pt: 5, display: "flex", justifyContent: "center" }}>
        <Box className="demo-space-x">
          <Button
            size="large"
            type="submit"
            variant="contained"
            onClick={toggleAddDialog}
          >
            Submit
          </Button>
          <Button
            size="large"
            color="secondary"
            variant="outlined"
            onClick={toggleAddDialog}
          >
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </Card>
  );
};

export default RolePermissionForm;
