// ** Toolkit imports
import { configureStore } from "@reduxjs/toolkit";

// ** Reducers
import admin from "./apps/admin";
import customer from "./apps/customer";
import order from "./apps/order";
import orderStatus from "./apps/orderStatus";
import product from "./apps/product";
import email from "./apps/email";
import locales from "./apps/locales";
import companies from "./apps/companies";
import search from "./apps/search";
import {
  localWallpaperState,
  customWallpaperState,
} from "./apps/print-wallpaper";
import productCategories from "./apps/product-categories";


// TODO: https://redux-toolkit.js.org/rtk-query/overview

export const store = configureStore({
  reducer: {
    admin,
    customer,
    order,
    orderStatus,
    product,
    email,
    locales,
    companies,
    search,
    localWallpaperState,
    customWallpaperState,
    productCategories,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
