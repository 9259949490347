// ** MUI Imports
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

// ** Third Party Imports
import { useTranslation } from "react-i18next";
import TableExporter from "../../table/table-exporter";
import { useAbility } from "@casl/react";
import { AbilityContext } from "../../layouts/components/acl/Can";

interface Props {
  toggle: () => void;
  exportData: any[];
  exportFilename: string;
}

export const TableHeader = (props: Props) => {
  // ** Props
  const { toggle, exportData, exportFilename } = props;

  // ** Hooks
  const { t } = useTranslation();
  const ability = useAbility(AbilityContext);

  const canCreate = ability.can('create', 'customer');

  return (
    <Box
      sx={{
        p: 5,
        pb: 3,
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <TableExporter disabled={!canCreate} {...{ exportData, exportFilename }} />

      <Button sx={{ mb: 2 }} disabled={!canCreate} onClick={toggle} variant="contained">
        {t("Add customer")}
      </Button>
    </Box>
  );
};
