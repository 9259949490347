import { useEffect } from "react";
import {
  AddOrderListLineItemType,
  LineItemType,
} from "../../../../../../../../../types/orderTypes";

// Custom hook to set current order item being edited

export function useCurrentOrderItem(
  selectedLineItem: LineItemType | AddOrderListLineItemType,
  handleWallpaperDetailsUpdate: (data: any) => void
) {
  useEffect(() => {
    handleWallpaperDetailsUpdate({
      currentOrderItem: selectedLineItem,
    });
  }, [selectedLineItem, handleWallpaperDetailsUpdate]);
}
