import {
  Box,
  Button,
  Card,
  Grid,
  Icon,
  Typography,
  TextField,
} from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColumns } from "@mui/x-data-grid";

import { useEffect, useState, ChangeEvent, useCallback, useMemo } from "react";

import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { fetchLocales } from "../../../store/apps/locales";

import { TranslationType } from "../../../types/translationTypes";

import Translations from "../../../comps/layouts/components/Translations";
import TransalationDialog from "./components/TranslationDialog";
import { matchSorter } from "match-sorter";

interface CellType {
  row: TranslationType;
}

const TranslationsSettingsScreen = () => {
  const [pageSize, setPageSize] = useState<number>(50);
  const [searchValue, setSearchValue] = useState<string>("");
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);
  const [isLocaleLoading, setIsLocaleLoading] = useState<boolean>(true);
  const [translations, setTranslations] = useState<TranslationType[]>([]);
  const [selectedLocale, setSelectedLocale] = useState<TranslationType>();

  const toggleOpenAddDialog = () => setOpenAddDialog((prev) => !prev);

  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { locales } = useSelector((state: RootState) => state.locales);

  // fetch translations on init
  useEffect(() => {
    (async () => {
      setIsLocaleLoading(true);
      await dispatch(fetchLocales());
      setIsLocaleLoading(false);
    })();
  }, [dispatch]);

  // filter translations
  useEffect(() => {
    if (locales.length > 0) {
      const words = matchSorter(locales, searchValue, {
        keys: ["en", "sk", "cs"],
        threshold: matchSorter.rankings.CONTAINS,
      });
      setTranslations(words);
    }
  }, [locales, searchValue]);

  const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const toggleOpenEditDialog = useCallback(() => {
    setOpenEditDialog(!openEditDialog);
  }, [openEditDialog]);

  const columns = useMemo<GridColumns<TranslationType>>(
    () => [
      // id needs to be displayed so we can have
      // the default sort be the most recent addition
      { field: "id", headerName: "ID", width: 90 },
      {
        flex: 0.25,
        minWidth: 100,
        field: "cs",
        headerName: t("Czech (cs)"),
        renderCell: ({ row }: CellType) => (
          <Typography variant="body2">{row.cs}</Typography>
        ),
      },
      {
        flex: 0.25,
        minWidth: 100,
        field: "en",
        headerName: t("English (en)"),
        renderCell: ({ row }: CellType) => (
          <Typography variant="body2">{row.en}</Typography>
        ),
      },
      {
        flex: 0.25,
        minWidth: 100,
        field: "sk",
        headerName: t("Slovak (sk)"),
        renderCell: ({ row }: CellType) => (
          <Typography variant="body2">{row.sk}</Typography>
        ),
      },
      {
        flex: 0.1,
        minWidth: 80,
        sortable: false,
        field: "actions",
        headerName: t("Actions"),
        type: "actions",
        getActions: ({ row }: CellType) => [
          <GridActionsCellItem
            // showInMenu
            sx={{ "& .MuiListItemIcon-root": { minWidth: 0 } }}
            icon={<Icon fontSize="small">edit</Icon>}
            label={t("Edit")}
            onClick={() => {
              setSelectedLocale(row);
              toggleOpenEditDialog();
            }}
          />,
        ],
      },
    ],
    [t, toggleOpenEditDialog]
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <Box
            sx={{
              p: 5,
              pt: 7,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6" fontWeight={600}>
                <Translations text="Translations" />
              </Typography>

              <Box>
                <TextField
                  size="small"
                  sx={{ mr: 6, mb: 2 }}
                  placeholder={t("Search for a translation")}
                  value={searchValue}
                  onChange={handleSearchInput}
                />

                <Button
                  variant="contained"
                  onClick={toggleOpenAddDialog}
                  startIcon={<Icon>add</Icon>}
                >
                  <Translations text="Add" />
                </Button>
              </Box>
            </Box>
          </Box>

          <DataGrid
            autoHeight
            rows={translations}
            getRowId={(row) => row.id}
            columns={columns}
            initialState={{
              sorting: {
                sortModel: [{ field: "id", sort: "desc" }],
              },
            }}
            disableSelectionOnClick
            loading={isLocaleLoading}
            pagination
            rowsPerPageOptions={[50, 100, 200]}
            pageSize={Number(pageSize)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
          />

          <TransalationDialog
            open={openAddDialog}
            handleClose={toggleOpenAddDialog}
            translations={translations}
            dispatch={dispatch}
            setIsLocaleLoading={setIsLocaleLoading}
          />

          {selectedLocale && (
            <TransalationDialog
              open={openEditDialog}
              handleClose={toggleOpenEditDialog}
              type="edit"
              editID={selectedLocale.id}
              editableValues={{
                cs: selectedLocale.cs,
                en: selectedLocale.en,
                sk: selectedLocale.sk,
              }}
              translations={translations}
              dispatch={dispatch}
              setIsLocaleLoading={setIsLocaleLoading}
            />
          )}
        </Card>
      </Grid>
    </Grid>
  );
};

export default TranslationsSettingsScreen;
