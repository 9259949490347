import {
  Avatar,
  Badge,
  Icon,
  Tooltip,
} from "@mui/material";

import {
  AddOrderListLineItemType,
  LineItemType,
} from "../../../types/orderTypes";

interface Props {
  lineItem: LineItemType | AddOrderListLineItemType;
  src: () => void;
  isWallpaperSpread: boolean
}

interface WallpaperImageProps {
  isWallpaperSpread: boolean;
  wallSrc: string;
}

const WallpaperImage = (props: WallpaperImageProps) => {
  const { wallSrc, isWallpaperSpread } = props;

  return (
    <Badge
      overlap="circular"
      invisible={!isWallpaperSpread}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      badgeContent={<Icon>door_sliding</Icon>}
      sx={{
        "& .MuiBadge-badge": {
          top: "5%",
          left: "5%",
        },
      }}
    >
      <Avatar
        variant="rounded"
        src={wallSrc}
        sx={{
          mr: 3,
          width: 34,
          height: 34,
        }}
      >
        {""}{" "}
      </Avatar>
    </Badge>
  );
};

export const PrintThumbImage = (props: Props) => {
  const {
    lineItem, src, isWallpaperSpread
  } = props;

  if (lineItem.print.type === "color") {
    return (
      <>
        <Tooltip title="ONYX">
          <Avatar
            variant="rounded"
            sx={{
              mr: 3,
              width: 34,
              height: 34,
              backgroundColor: JSON.parse(lineItem.print.value).onyxMatch.hex,
            }}
          >
            {" "}
          </Avatar>
        </Tooltip>

        <Tooltip title="HEX">
          <Avatar
            variant="rounded"
            sx={{
              mr: 3,
              width: 34,
              height: 34,
              backgroundColor: JSON.parse(lineItem.print.value).hex,
            }}
          >
            {" "}
          </Avatar>
        </Tooltip>
      </>
    );
  }

  if (lineItem.print.type === "ownWallpaper") {
    return (
      <WallpaperImage
        isWallpaperSpread={isWallpaperSpread}
        wallSrc={String(src)}
      />
    );
  }

  return (
    <WallpaperImage
      isWallpaperSpread={isWallpaperSpread}
      wallSrc={JSON.parse(lineItem.print.value).file}
    />
  );
};
