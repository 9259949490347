// ** React Imports
import { ReactNode } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// ** Navigation Imports
import {
  NavLink,
  NavGroup,
  NavSectionTitle,
} from "./comps/@core/layouts/types";
import navigation from "./navigation";

// ** Config Imports
import "./configs/i18n";

// ** Component Imports
import UseLayout from "./comps/layouts/useLayout";
import ThemeComponent from "./comps/@core/theme/ThemeComponent";
import { AuthGuard } from "./comps/auth/authGuard";
import { GuestGuard } from "./comps/auth/guestGuard";
import { Spinner } from "./comps/utilities/spinner";

// ** Contexts
import { AuthProvider } from "./context/AuthContext";
import { SettingsConsumer, SettingsProvider } from "./context/settingsContext";

// ** React Perfect Scrollbar Style
import "react-perfect-scrollbar/dist/css/styles.css";

// ** Styled Components
import ReactHotToast from "./comps/@core/styles/libs/react-hot-toast";

// ** Third Party Import
import { Toaster } from "react-hot-toast";
import { AlertProvider } from "./context/AlertContext";
import { useStatusGuard } from "./hooks/useStatusGuard";
import ServerDown from "./comps/503";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./screens/fallback";
import { defaultACLObj } from "./configs/acl";
import AclGuard from "./comps/auth/aclGuard";

type GuardProps = {
  authGuard: boolean;
  guestGuard: boolean;
  children: ReactNode;
};

const Guard = ({ children, authGuard, guestGuard }: GuardProps) => {
  const { loading, isServerOk } = useStatusGuard();

  if (loading) return <Spinner />

  if (!loading) {
    if (!isServerOk) return <ServerDown />

    if (guestGuard) {
      return <GuestGuard fallback={<Spinner />}>{children}</GuestGuard>;
    } else if (!guestGuard && !authGuard) {
      return <>{children}</>;
    } else {
      return <AuthGuard fallback={<Spinner />}>{children}</AuthGuard>;
    }
  }

  return null
};

const resolveNavItemComponent = (
  item: NavGroup | NavLink | NavSectionTitle
) => {
  if ((item as NavSectionTitle).sectionTitle) return false;
  if ((item as NavGroup).children) return false;

  return true;
};

const buildRoute = (route: any, routeIndex: number) => {
  const useLayout = route.useLayout ?? true;
  const authGuard = route.authGuard ?? true;
  const guestGuard = route.guestGuard ?? false;
  const aclAbilities = route.acl ?? defaultACLObj;

  if (useLayout)
    return (
      <Route
        key={routeIndex}
        path={route.path}
        element={
          <Guard {...{ guestGuard, authGuard }}>
            <AclGuard {...{ aclAbilities, guestGuard }}>
              <UseLayout>
                <route.element />
              </UseLayout>
            </AclGuard>
          </Guard>
        }
      />
    );

  return (
    <Route
      key={routeIndex}
      path={route.path}
      element={
        <Guard {...{ guestGuard, authGuard }}>
          <AclGuard {...{ aclAbilities, guestGuard }}>
            <route.element />
          </AclGuard>
        </Guard>
      }
    />
  );
}

// Recursive function to handle nested children route rendering
const handleNestedChildrenRoutes = (childRoute: any) => {
  return childRoute.children?.map((nestedRoute: any, nestedIndex: number) => {
    const isNestedRouteNavLink = resolveNavItemComponent(nestedRoute);

    if (!isNestedRouteNavLink) return handleNestedChildrenRoutes(nestedRoute);

    return buildRoute(nestedRoute, nestedIndex)
  });
};

function renderRoute(route: any, index: number): JSX.Element {
  const isNavLink = resolveNavItemComponent(route);

  if (!isNavLink) return handleNestedChildrenRoutes(route);

  return buildRoute(route, index)
}

const appRoutes = navigation();

function App() {
  return (
      <SettingsProvider>
        <SettingsConsumer>
          {({ settings }) => {
            return (
              <ThemeComponent settings={settings}>
                <Router>
                  <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <AuthProvider>
                      <AlertProvider>
                        <Routes>
                          {appRoutes.map((route, index) =>
                            renderRoute(route, index)
                          )}
                        </Routes>
                      </AlertProvider>
                    </AuthProvider>
                  </ErrorBoundary>
                </Router>

                <ReactHotToast>
                  <Toaster
                    position={settings.toastPosition}
                    toastOptions={{ className: "react-hot-toast" }}
                  />
                </ReactHotToast>
              </ThemeComponent>
            );
          }}
        </SettingsConsumer>
      </SettingsProvider>
  );
}

export default App;
