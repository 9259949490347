// ** React Imports
import { Dispatch, MouseEvent, SetStateAction, useState } from "react";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Icon, Box, Button, Menu, MenuItem } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid";

import Translations from "../../layouts/components/Translations";
import { AppDispatch } from "../../../store";
import { useDispatch } from "react-redux";
import { copyProduct } from "../../../store/apps/product";
import TableExporter from "../../table/table-exporter";
import { useAbility } from "@casl/react";
import { AbilityContext } from "../../layouts/components/acl/Can";

interface Props {
  selectedRows: GridRowId[];
  setSelectedRows: Dispatch<SetStateAction<GridRowId[]>>;
  exportData: any[];
  exportFilename: string;
  toggleDeleteProductDialog: () => void;
}

export const ProductTableHeader = (props: Props) => {
  const {
    selectedRows,
    setSelectedRows,
    exportData,
    exportFilename,
    toggleDeleteProductDialog,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const ability = useAbility(AbilityContext);

  const canCreate = ability.can('create', 'product');

  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const [copying, setCopying] = useState(false);

    const handleActionsMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
      setActionsMenuAnchorEl(event.currentTarget);
    };

    const handleActionsMenuClose = () => {
      setActionsMenuAnchorEl(null);
    };

    const handleCopy = async () => {
      setCopying(true);
      await dispatch(copyProduct(selectedRows as string[]))
        .unwrap()
        .then(() => setSelectedRows([]));
      setCopying(false);
      handleActionsMenuClose();
    };

    return (
      <Box
        sx={{
          p: 5,
          pt: 7,
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button
            color="inherit"
            variant="outlined"
            aria-haspopup="true"
            disabled={(selectedRows && selectedRows.length === 0) || !canCreate}
            onClick={handleActionsMenuClick}
            endIcon={<Icon>expand_more</Icon>}
            aria-expanded={Boolean(actionsMenuAnchorEl) ? "true" : undefined}
            aria-controls={
              Boolean(actionsMenuAnchorEl) ? "product-list-actions" : undefined
            }
          >
            {t("Actions")}
          </Button>

          <Menu
            open={Boolean(actionsMenuAnchorEl)}
            anchorEl={actionsMenuAnchorEl}
            onClose={handleActionsMenuClose}
            id="product-list-actions"
            PaperProps={{
              sx: {
                minWidth: actionsMenuAnchorEl?.offsetWidth,
                maxWidth: actionsMenuAnchorEl?.offsetWidth,
              },
            }}
          >
            <MenuItem onClick={handleCopy}>
              {copying ? t("Copying...") : t("Copy")}
            </MenuItem>


            <MenuItem onClick={toggleDeleteProductDialog}>
              {t("Delete")}
            </MenuItem>

          </Menu>

          <Box
            gap={3}
            sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
          >
            <TableExporter
              disabled={!canCreate}
              {...{ exportData, exportFilename }}
            />

            <Button
              disabled={!canCreate}
              variant="contained"
              component={Link}
              to="/products/new"
              startIcon={<Icon>add</Icon>}
            >
              <Translations text="Add product" />
            </Button>
          </Box>
        </Box>
      </Box>
    );
};
