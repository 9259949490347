// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { fetchData as fetchAdmins } from "../admin";
import { fetchData as fetchCustomers } from "../customer";
import { fetchData as fetchOrders } from "../order";
import { fetchData as fetchProducts } from "../product";
import { AppDispatch, RootState } from "../..";
import { Admin } from "../../../types/adminTypes";
import { Customer } from "../../../types/customerTypes";
import { OrderType } from "../../../types/orderTypes";
import { ProductType } from "../../../types/productTypes";
import { MutatedSearchAdminType, MutatedSearchCustomerType, MutatedSearchOrderType, MutatedSearchProductType, SearchDataType, TempSearchDataKeyType, TempSearchDataType } from "../../../types/AppSearchTypes";

// TODO: export and use in other stores
// https://redux-toolkit.js.org/usage/usage-with-typescript#defining-a-pre-typed-createasyncthunk
const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  // rejectValue: string
  // extra: { s: string; n: number }
}>();

const initialState = {
  data: [] as SearchDataType,
};

// ** Fetch Products
export const fetchSearchData = createAppAsyncThunk(
  "search/getAll",
  async (_, { getState, dispatch }) => {
    await dispatch(fetchAdmins({ search: "" }));
    await dispatch(fetchCustomers({ search: "" }));
    await dispatch(
      fetchOrders({
        company: "",
        after: "",
        before: "",
        search: "",
        status: "",
      })
    );
    await dispatch(fetchProducts());

    const { admin, customer, order, product } = getState();

    const adminData = admin.data.map(({ _id, displayName, email }: Admin) => ({
      _id,
      displayName,
      email,
    }));
    const customerData = customer.data.map(
      ({ _id, firstName, lastName, email, phone }: Customer) => ({
        _id,
        name: `${firstName} ${lastName}`,
        email,
        phone,
      })
    );
    const orderData = order.data.map(
      ({
        _id,
        company,
        customer: { _id: customerId, firstName, lastName, email, phone },
        orderNumber,
      }: OrderType) => ({
        _id,
        company,
        customer: {
          _id: customerId,
          name: `${firstName} ${lastName}`,
          email,
          phone,
        },
        orderNumber,
      })
    );
    const productData = product.data.map(
      ({ _id, name, company, images, status }: ProductType) => ({
        _id,
        name,
        company,
        images,
        status,
      })
    );

    const _response: TempSearchDataType = {
      users: adminData,
      customers: customerData,
      orders: orderData,
      products: productData,
    };

    const response: Array<
      {
        category: TempSearchDataKeyType;
      } & Partial<
        MutatedSearchAdminType &
          MutatedSearchCustomerType &
          MutatedSearchOrderType &
          MutatedSearchProductType
      >
    > = [];

    Object.keys(_response).forEach((key) => {
      _response[key as TempSearchDataKeyType].forEach((item) => {
        const data = {
          ...item,
          category: key as TempSearchDataKeyType,
        };

        response.push(data);
      });
    });

    return response;
  }
);

export const appSearchSlice = createSlice({
  name: "appSearch",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchSearchData.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

export default appSearchSlice.reducer;
