import React from "react";
import { FieldError, UseFormSetValue } from "react-hook-form";
import { TFunction } from "react-i18next";

import { ChevronDown } from "mdi-material-ui";
import {
  TextField,
  Button,
  InputAdornment,
  Menu,
  MenuItem,
} from "@mui/material";

import insertedFields from "../../../utils/insertedStatusMailFields";

import { OrderStatusData } from "../../../types/orderStatusTypes";

interface Props {
  value: string;
  onChange: (...event: any[]) => void;
  setValue: UseFormSetValue<OrderStatusData>;
  t: TFunction<"translation", undefined>;
  error: FieldError | undefined;
}

const EmailSubjectField = (props: Props) => {
  const { value, setValue, t, onChange, error } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleAnchorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAnchorClose = () => {
    setAnchorEl(null);
  };

  const items = insertedFields
    .filter((field) => field.field === "subject")
    .map((field) => ({
      ...field,
      text: t(field.text),
    }));

  const handleInsert = (insertedValue: string) => {
    const newValue = value.concat(` ${insertedValue}`);
    setValue("emailSubject", newValue);
    setAnchorEl(null);
  }

  return (
    <TextField
      label={`${t("Subject")}`}
      required
      value={value}
      onChange={onChange}
      error={Boolean(error)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Button
              id="status-subject-menu-button"
              size="small"
              variant="contained"
              endIcon={<ChevronDown />}
              onClick={handleAnchorClick}
              sx={{ textTransform: "none" }}
            >
              Insert
            </Button>

            <Menu
              id="status-subject-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleAnchorClose}
              MenuListProps={{
                "aria-labelledby": "status-subject-menu-button",
              }}
            >
              {items.map((item) => (
                <MenuItem
                  key={item.value}
                  onClick={() => handleInsert(item.value)}
                >
                  {item.text}
                </MenuItem>
              ))}
            </Menu>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default EmailSubjectField;
