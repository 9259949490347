import React from "react";
import { PorezProductFormInputsT } from "../../../types/productTypes";
import { arraysEqual } from "../../compareArray";

type JSONDataT = {
  [key: string]: any,

  __EMPTY: string,
  __EMPTY_1: string,
  __EMPTY_2: string,
  __EMPTY_3: number,
  __EMPTY_4: number,
  __EMPTY_5: string,
  __EMPTY_6: string | number | undefined,
  __EMPTY_7: string | undefined,
  __EMPTY_8: string | undefined
}

type CleanedDataT = {
  [key: string]: any,

  name: string,
  code: string,
  surface: string,
  category: number,
  price: number,
  thickness_10: string,
  thickness_18: string | number | undefined,
  conditiion: string | undefined,
  image: string | undefined
}

const prepareUploadData = (
  isVariobelOnlyField: boolean,
  companyID: string,
  json: unknown[],
  setPorezProductUploadData: React.Dispatch<
    React.SetStateAction<PorezProductFormInputsT[]>
  >
) => {
  const titles = [
    "NÁZOV", // name
    "KÓD", // code
    "POVRCH", // surface
    "CENOVÁ KAT.", // category
    "CENA DEKORU", //price
    "DOSTUPNOSŤ 10mm", // thickness 10mm
    "DOSTUP. 18mm", // thickness 18mm
    "STAV", // condition
    "CESTA DEKORU", // image
  ];

  const data = json.map((row: any) => Object.values(row));

  // _EMPTY comes from how the xls formats the json
  const newDataObjectKeys = {
    "__EMPTY": "name",
    "__EMPTY_1": "code",
    "__EMPTY_2": "surface",
    "__EMPTY_3": "category",
    "__EMPTY_4": "price",
    "__EMPTY_5": "thickness_10",
    "__EMPTY_6": "thickness_18",
    "__EMPTY_7": "condition",
    "__EMPTY_8": "image"
  }

  const cleanedData = (json as JSONDataT[]).map((row) => {
    const updatedRowObject = {} as CleanedDataT;

    for (const [oldKey, newKey] of Object.entries(newDataObjectKeys)) {
      updatedRowObject[newKey] = row[oldKey];
    }

    return updatedRowObject;
  })

  const sliceData = cleanedData.slice(1);

  const xlsFormatMatch = arraysEqual(titles, data[0]);

  if (!xlsFormatMatch || isVariobelOnlyField || companyID === "") return;

  const formattedData = sliceData.map((row: any) => {
    const { thickness_18, thickness_10, condition, image, ...rest } = row;

    const updatedRow = {
      ...rest,
      company: companyID,
      status: "draft",
      condition: condition === "NOVÝ" ? "new" : "old",
      image: image ?? "",

      // don't have the time to create new category
      // also not clear if its the same mechanism as variobel
      category: null,

      // .xlsx file is not clear for 18mm column
      // so will use [10] as default
      thickness: [10]
    };

    return updatedRow as PorezProductFormInputsT;
  });

  setPorezProductUploadData(formattedData);
};

export default prepareUploadData;