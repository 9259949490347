import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Point } from "react-easy-crop";
import { IsOrderItemDimensionsErrorType, ITabs } from "../../../comps/order/add-edit/print-dialog/tabs/custom-wallpaper-tab/components/wallpaper-select-base/types";
import { AddOrderListLineItemType, LineItemType } from "../../../types/orderTypes";
import { IFile } from "../../../comps/order/add-edit/print-dialog/types";

export interface WallpaperStateI {
  zoom: number;
  rotation: number;
  croppedAreaPixels?: any;
  cropAspectRatio: number;
  isCropMode: boolean;
  isWallpaperSpread: boolean;
  preview?: File | undefined;
  crop: Point | undefined;
  imgSrc: string;
  croppedImage: any;
  type:  "custom" | "manual"

  details: {
    currentOrderItem: LineItemType | AddOrderListLineItemType | undefined;
    tabs: ITabs[];
    tab: string;
    flip: {
      horizontal: boolean;
      vertical: boolean;
    };
    spreadWallpaper: boolean;
    previewWallpaperSpread: boolean;
    doorsToSpread: number;
    isSelectedImageClear: boolean;
    orderItemDimensions: {
      width: number;
      height: number;
    };
    debouncedOrderItemDimensions: {
      width: number;
      height: number;
    };
    orderItemWidthInterval: number[];
    orderItemHeightInterval: number[];
    isOrderItemDimensionsError: IsOrderItemDimensionsErrorType;
  }
}

export interface localWallpaperStateI extends WallpaperStateI {
  localWallpapers: IFile[];
  wallpaper: any;
}

export interface customWallpaperStateI extends WallpaperStateI {
  wallpaper: any;
  isCropUploaded: boolean;
  uploadingWallpaper: boolean;
  cropWallpaperImgData?: any;
}

const initialState = {
  zoom: 1,
  rotation: 0,
  croppedAreaPixels: undefined,
  cropAspectRatio: 1,
  isCropMode: true,
  isWallpaperSpread: false,
  preview: undefined,
  crop: { x: 0, y: 0 },
  imgSrc: "",
  croppedImage: "",

  details: {
    tabs: [],
    tab: "",

    currentOrderItem: undefined,

    // flip crop image
    flip: { horizontal: false, vertical: false },

    // spread wallpaper across multiple glass panels
    spreadWallpaper: false,

    // overlay ui elements that indicate spread sections
    previewWallpaperSpread: true,

    // no of doors to spread the wallpaper
    doorsToSpread: 2,

    // boolean used to control alert if seleected image matches desired specs
    // specs: minimum 5000px height, 3000DPI
    isSelectedImageClear: true,

    // order item variables
    // there is a debounce because the dimesions are changed via an input field
    // those dimensions are then used to update currentOrderItem
    orderItemDimensions: {
      width: 0,
      height: 0,
    },
    debouncedOrderItemDimensions: {
      width: 0,
      height: 0,
    },
    orderItemWidthInterval: [0, 1],
    orderItemHeightInterval: [0, 1],
    isOrderItemDimensionsError: {
      width: { value: false, msg: "" },
      height: { value: false, msg: "" },
    },
  }
};

const localWallpaperSlice = createSlice({
  name: "localWallpaper",
  initialState: {
    ...initialState,
    type: "manual",
    localWallpapers: [],
    wallpaper: null,
  } as localWallpaperStateI,
  reducers: {
    updateLocalWallpaperState: (
      state,
      action: PayloadAction<Partial<localWallpaperStateI>>
    ) => {
      return { ...state, ...action.payload };
    },
    updateLocalWallpaperDetailsState: (
      state,
      action: PayloadAction<Partial<localWallpaperStateI["details"]>>
    ) => {
      return {
        ...state,
        details: {
          ...state.details,
          ...action.payload,
        },
      };
    }
  },
});

const customWallpaperSlice = createSlice({
  name: "customWallpaper",
  initialState: {
    ...initialState,
    type: "custom",
    wallpaper: null,
    isCropUploaded: false,
    uploadingWallpaper: false,
    cropWallpaperImgData: undefined,
  } as customWallpaperStateI,
  reducers: {
    updateCustomWallpaperState: (
      state,
      action: PayloadAction<Partial<customWallpaperStateI>>
    ) => {
      return { ...state, ...action.payload };
    },
    updateCustomWallpaperDetailsState: (
      state,
      action: PayloadAction<Partial<customWallpaperStateI["details"]>>
    ) => {
      return {
        ...state,
        details: {
          ...state.details,
          ...action.payload,
        },
      };
    }
  },
});

export const { updateLocalWallpaperState, updateLocalWallpaperDetailsState } = localWallpaperSlice.actions;
export const { updateCustomWallpaperState, updateCustomWallpaperDetailsState } = customWallpaperSlice.actions;

export const localWallpaperState = localWallpaperSlice.reducer;
export const customWallpaperState = customWallpaperSlice.reducer;
