// ** React Imports
import { Fragment, useState } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Typography, { TypographyProps } from "@mui/material/Typography";

// ** Icons Imports
import Close from "mdi-material-ui/Close";
import FileDocumentOutline from "mdi-material-ui/FileDocumentOutline";

// ** Third Party Components
import { useDropzone } from "react-dropzone";
import axios from "axios";
import { Spinner } from "../../../../comps/utilities/spinner";

import { useAlertContext } from "../../../../hooks/useAlertContext";

import { UseFormSetValue } from "react-hook-form";
import Translations from "../../../../comps/layouts/components/Translations";
import { CompanyDataType } from "../../../../types/companyTypes";
import { t } from "i18next";

interface FileProp {
  name: string;
  type: string;
  size: number;
}

// Styled component for the upload image inside the dropzone area
const Img = styled("img")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    marginRight: theme.spacing(10),
  },
  [theme.breakpoints.down("md")]: {
    marginBottom: theme.spacing(4),
  },
  [theme.breakpoints.down("sm")]: {
    width: 250,
  },
}));

// Styled component for the heading inside the dropzone area
const HeadingTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(4),
  },
}));

interface Props {
  setValue: UseFormSetValue<CompanyDataType>;
}

const CompanyLogoUploader = ({ setValue }: Props) => {
  // ** State
  const [files, setFiles] = useState<File[]>([]);

  // ** Hooks
  const { showSuccessAlert, showErrorAlert } = useAlertContext();
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: 2000000,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onDrop: (acceptedFiles: File[]) => {
      setFiles(acceptedFiles.map((file: File) => Object.assign(file)));
    },
    onDropRejected: () => {
      showErrorAlert("You can only upload 1 file with a maximum size of 2 MB.");
    },
  });

  const renderFilePreview = (file: FileProp) => {
    if (file.type.startsWith("image")) {
      return (
        <img
          width={38}
          height={38}
          alt={file.name}
          src={URL.createObjectURL(file as any)}
        />
      );
    } else {
      return <FileDocumentOutline />;
    }
  };

  const handleRemoveFile = (file: FileProp) => {
    const uploadedFiles = files;
    const filtered = uploadedFiles.filter(
      (i: FileProp) => i.name !== file.name
    );
    setFiles([...filtered]);
  };

  const fileList = files.map((file: FileProp) => (
    <ListItem key={file.name}>
      <div className="file-details">
        <div className="file-preview">{renderFilePreview(file)}</div>
        <div>
          <Typography className="file-name">{file.name}</Typography>
          <Typography className="file-size" variant="body2">
            {Math.round(file.size / 100) / 10 > 1000
              ? `${(Math.round(file.size / 100) / 10000).toFixed(1)} mb`
              : `${(Math.round(file.size / 100) / 10).toFixed(1)} kb`}
          </Typography>
        </div>
      </div>
      <IconButton onClick={() => handleRemoveFile(file)}>
        <Close fontSize="small" />
      </IconButton>
    </ListItem>
  ));

  const handleRemoveAllFiles = () => {
    setFiles([]);
  };

  const [uploading, setUploading] = useState(false);

  const handleImageUpload = async () => {
    setUploading(true);

    const form = new FormData();
    files.forEach((image) => {
      form.append("image", image);
      form.append("folder", "companies");
    });

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_SERVER_HOST}/upload/single`,
      data: form,
      timeout: 180000,
    };

    axios(config)
      .then((res) => {
        const _data = res.data;

        const result = _data;
        if (result.url) setValue("logo", result.url);

        showSuccessAlert("Image Uploaded!");
        setUploading(false);

        handleRemoveAllFiles();
      })
      .catch((err) => {
        console.error(err);
        showErrorAlert("Error during Upload! Check console");
        setUploading(false);
      });
  };

  if (uploading) return <Spinner />;

  return (
    <Fragment>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />

        <Box
          sx={{
            display: "flex",
            flexDirection: ["column", "column", "row"],
            alignItems: "center",
          }}
        >
          <Img width={300} alt="Upload img" src="/images/misc/upload.png" />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: ["center", "center", "inherit"],
            }}
          >
            <HeadingTypography variant="h5">
              <Translations
                text={t("Drop the company logo here or click to upload")}
              />
            </HeadingTypography>

            <Typography color="textSecondary">
              <Translations
                text={t(
                  "You can add images only in *.jpeg, *.jpg, *.png format"
                )}
              />
            </Typography>

            <Typography color="textSecondary">
              <Translations text="Size jedného súboru nesmie presiahnúť 2 MB" />
            </Typography>
          </Box>
        </Box>
      </div>

      {files.length ? (
        <Fragment>
          <List>{fileList}</List>

          <div className="buttons">
            <Button
              color="error"
              variant="outlined"
              onClick={handleRemoveAllFiles}
            >
              <Translations text={t("Remove")} />
            </Button>

            <Button variant="contained" onClick={handleImageUpload}>
              <Translations text={t("Upload")} />
            </Button>
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default CompanyLogoUploader;
