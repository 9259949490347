import { useEffect, useRef, useState } from "react";

import {
  Card,
  Grid,
  TextField,
  CardHeader,
  CardContent,
  FormControl,
  FormHelperText,
  Divider,
  Button,
  InputAdornment,
  Popover,
  Box,
  Typography,
  Icon,
  Select,
  MenuItem,
  InputLabel,
  LinearProgress,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { CompanyDataType } from "../../../../types/companyTypes";
import { AppDispatch } from "../../../../store";
import { ThemeColor } from "../../../../comps/@core/layouts/types";

import DropzoneWrapper from "../../../../comps/@core/styles/libs/react-dropzone";
import CompanyLogoUploader from "./uploader";
import { Chip } from "../../../../comps/chip";
import {
  createCompany,
  getCompany,
  updateCompany,
} from "../../../../store/apps/companies";
import { useAlertContext } from "../../../../hooks/useAlertContext";
// import { Link } from "react-router-dom";

interface Props {
  type: "create" | "edit";
  id?: string;
  handleClose?: () => void;
}

const colors: ThemeColor[] = [
  "primary",
  "secondary",
  "error",
  "warning",
  "info",
  "success",
];

const productCurrencies = ["€"];

const defaultValues: CompanyDataType = {
  name: "",
  logo: "",
  address: "",
  colorCode: "",
  bankDetails: [
    {
      name: "",
      accountNumber: "",
      iban: "",
      swift: "",
    },
  ],
  ico: "",
  dic: "",
  icdph: "",
  companyRegNumber: "",
  productCurrency: ["€"],
  taxRate: 0,
  isDefault: false,
};

const CompanyForm = (props: Props) => {
  const { type, id, handleClose } = props;

  const colorPickerAnchorEl = useRef<HTMLDivElement | null>(null);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [numberOfBanks, setNumberOfBanks] = useState(1);

  const incrementNumberOfBanks = () => setNumberOfBanks((prev) => ++prev);

  // ** Hooks
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
    formState,
  } = useForm<CompanyDataType>({ defaultValues });
  const { isSubmitting } = formState;

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    if (type === "edit" && id) {
      (async () =>
        dispatch(getCompany(id)).then((res) => {
          const { _id, _v, ...rest } = res.payload.company;

          const bankNumber = (rest as CompanyDataType).bankDetails
            ?.length as number;
          setNumberOfBanks(bankNumber);

          reset(rest);
        }))();
    }
  }, [dispatch, id, reset, setValue, type]);

  const { t } = useTranslation();
  const { showErrorAlert, showSuccessAlert } = useAlertContext();

  const watchedLogo = watch("logo");
  const watchedData = watch();

  const onSubmit = () => {
    if (type === "create") {
      return dispatch(createCompany(watchedData))
        .then(() => {
          navigate("/company/list");
          showSuccessAlert(t("Company created"));
        })
        .catch(() => {
          showErrorAlert(t("Failed to create Company"));
        });
    }

    return dispatch(updateCompany({ id: id as string, data: watchedData }))
      .then(() => {
        handleClose && handleClose();
        showSuccessAlert(t("Company updated"));
      })
      .catch(() => {
        showErrorAlert(t("Failed to update Company"));
      });
  };

  const headerTitle = () => {
    if (type === "create") return t("Create Company");
    if (type === "edit") return t("Update Company");
    return t("View Company");
  };


  return (
    <Card
      sx={
        type === "edit"
          ? {
              boxShadow: "none",
            }
          : {}
      }
    >
      <CardHeader
        title={headerTitle()}
        titleTypographyProps={{ variant: "h6" }}
        sx={{ mb: 1 }}
      />

      <CardContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={5} sx={{ mb: 10 }}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      value={value}
                      label={t("Company Name")}
                      onChange={onChange}
                      placeholder=""
                      error={Boolean(errors.name)}
                    />
                  )}
                />
                {errors.name && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {t("")}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Controller
                  name="colorCode"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <>
                      <TextField
                        ref={colorPickerAnchorEl}
                        required
                        InputProps={{
                          readOnly: true,
                          ...(value && {
                            startAdornment: (
                              <InputAdornment position="start">
                                <Chip
                                  size="small"
                                  color={value as ThemeColor}
                                  sx={{ width: 28, height: 28 }}
                                />
                              </InputAdornment>
                            ),
                          }),
                        }}
                        label={t("Company color")}
                        onClick={() => {
                          setDisplayColorPicker(true);
                        }}
                        error={Boolean(errors.colorCode)}
                      />
                      <Popover
                        open={displayColorPicker}
                        anchorEl={colorPickerAnchorEl.current}
                        PaperProps={{
                          sx: {
                            minWidth: colorPickerAnchorEl.current?.offsetWidth,
                            maxWidth: colorPickerAnchorEl.current?.offsetWidth,
                            padding: 5,
                          },
                        }}
                        onClose={() => {
                          setDisplayColorPicker(false);
                        }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        disableAutoFocus
                        disableEnforceFocus
                      >
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3 }}>
                          {colors.map((color) => (
                            <Chip
                              size="small"
                              color={color}
                              sx={{ width: 28, height: 28 }}
                              clickable
                              onClick={() => {
                                onChange(color);
                                setDisplayColorPicker(false);
                              }}
                            />
                          ))}
                        </Box>
                      </Popover>
                    </>
                  )}
                />
                {errors.colorCode && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {t("")}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <Controller
                  name="address"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      value={value}
                      label={t("Company Address")}
                      onChange={onChange}
                      placeholder=""
                      multiline
                      rows={5}
                      error={Boolean(errors.address)}
                    />
                  )}
                />
                {errors.address && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {t("")}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Controller
                  name="companyRegNumber"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      value={value}
                      label={t("Company Reg. Number")}
                      onChange={onChange}
                      placeholder=""
                      error={Boolean(errors.companyRegNumber)}
                    />
                  )}
                />
                {errors.companyRegNumber && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {t("")}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Controller
                  name="taxRate"
                  control={control}
                  rules={{
                    required: true,
                    validate: (value) => value > 0,
                  }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      value={value}
                      label={`${t("Tax Rate")}`}
                      type="number"
                      onChange={onChange}
                      placeholder=""
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                      error={Boolean(errors.taxRate)}
                    />
                  )}
                />
                {errors.taxRate && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {t("Musí byť väčšia ako 0")}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Controller
                  name="ico"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      value={value}
                      label={t("Company identification number")}
                      onChange={onChange}
                      placeholder=""
                      error={Boolean(errors.ico)}
                    />
                  )}
                />
                {errors.ico && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {t("")}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Controller
                  name="dic"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      value={value}
                      label={t("Company tax identification number")}
                      onChange={onChange}
                      placeholder=""
                      error={Boolean(errors.dic)}
                    />
                  )}
                />
                {errors.dic && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {t("")}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Controller
                  name="icdph"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      value={value}
                      label={t("VAT number")}
                      onChange={onChange}
                      placeholder=""
                      error={Boolean(errors.icdph)}
                    />
                  )}
                />
                {errors.icdph && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {t("")}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Controller
                  name="productCurrency"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { value, onChange } }) => (
                    <FormControl>
                      <FormControl>
                        <InputLabel>{t("Product Currency")}</InputLabel>

                        <Select
                          label={t("Product Currency")}
                          value={value}
                          onChange={onChange}
                        >
                          {productCurrencies.map((status, index) => (
                            <MenuItem key={index} value={status}>
                              {t(status).toLocaleUpperCase()}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </FormControl>
                  )}
                />
                {errors.productCurrency && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {t("")}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            {/* image & uploader */}
            <Grid item container spacing={2}>
              {watchedLogo && (
                <Grid item xs={12}>
                  <img
                    src={watchedLogo}
                    alt="product"
                    width={200}
                    height={200}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <DropzoneWrapper>
                  <CompanyLogoUploader setValue={setValue} />
                </DropzoneWrapper>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider textAlign="left">
                <Typography variant="caption">{t("Bank Details")}</Typography>
              </Divider>
            </Grid>

            <Grid item container xs={12} spacing={5}>
              {Array.from({ length: numberOfBanks }).map((_, i) => (
                <>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <Controller
                        name={`bankDetails.${i}.name`}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange } }) => (
                          <TextField
                            value={value}
                            label={t("Account Holder's Name")}
                            onChange={onChange}
                            placeholder=""
                            error={Boolean(errors.bankDetails)}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <Controller
                        name={`bankDetails.${i}.accountNumber`}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange } }) => (
                          <TextField
                            value={value}
                            label={t("Account Number")}
                            onChange={onChange}
                            placeholder=""
                            error={Boolean(errors.bankDetails)}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <Controller
                        name={`bankDetails.${i}.iban`}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange } }) => (
                          <TextField
                            value={value}
                            label={t("Account IBAN")}
                            onChange={onChange}
                            placeholder=""
                            error={Boolean(errors.bankDetails)}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <Controller
                        name={`bankDetails.${i}.swift`}
                        control={control}
                        rules={{ required: true }}
                        render={({ field: { value, onChange } }) => (
                          <TextField
                            value={value}
                            label={t("Account SWIFT")}
                            onChange={onChange}
                            placeholder=""
                            error={Boolean(errors.bankDetails)}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  {numberOfBanks !== 1 && (
                    <Grid item xs={12}>
                      <Divider textAlign="center">
                        <Typography variant="caption">
                          {`${t("Bank No")}. ${i + 1}`}
                        </Typography>
                      </Divider>
                    </Grid>
                  )}
                </>
              ))}

              <Grid item xs={12} textAlign="right">
                <Button
                  variant="outlined"
                  startIcon={<Icon>add</Icon>}
                  onClick={incrementNumberOfBanks}
                >
                  {t("Add Bank")}
                </Button>
              </Grid>
            </Grid>

            <Grid container item>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Controller
                    name="isDefault"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <FormControlLabel
                        label="Default (only one can be default)"
                        sx={errors.isDefault ? { color: "error.main" } : null}
                        control={
                          <Checkbox
                            required
                            checked={value}
                            onChange={onChange}
                            sx={
                              errors.isDefault ? { color: "error.main" } : null
                            }
                          />
                        }
                      />
                    )}
                  />
                  {errors.isDefault && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.isDefault.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            {/* Submit button */}
            <Grid item xs={12} my={5}>
              {isSubmitting && <LinearProgress sx={{ mb: 2.5 }} />}

              <Button
                type="submit"
                variant="contained"
                sx={{ textTransform: "capitalize" }}
              >
                {type !== "create" ? t("Update Company") : t("Create Company")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default CompanyForm;
