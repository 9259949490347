// ** React Imports
import {
  useEffect,
  useCallback,
  useRef,
  useState,
  ChangeEvent,
  ReactNode,
} from "react";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// ** MUI Imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import MuiDialog from "@mui/material/Dialog";
import ListItem from "@mui/material/ListItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";
import ListItemButton from "@mui/material/ListItemButton";
import InputAdornment from "@mui/material/InputAdornment";

import MuiAutocomplete, {
  AutocompleteRenderInputParams,
} from "@mui/material/Autocomplete";
import { FilterOptionsState } from "@mui/material/useAutocomplete";

// ** Icons Imports
import Tab from "mdi-material-ui/Tab";
import Close from "mdi-material-ui/Close";
import Magnify from "mdi-material-ui/Magnify";
import ChartDonut from "mdi-material-ui/ChartDonut";
import AccountOutline from "mdi-material-ui/AccountOutline";
import GestureTapButton from "mdi-material-ui/GestureTapButton";
import FileRemoveOutline from "mdi-material-ui/FileRemoveOutline";
import FormatListCheckbox from "mdi-material-ui/FormatListCheckbox";
import ChartTimelineVariant from "mdi-material-ui/ChartTimelineVariant";
import SubdirectoryArrowLeft from "mdi-material-ui/SubdirectoryArrowLeft";
import {
  Account,
  AccountCircle,
  BellCog,
  CartArrowRight,
  NetworkPos,
} from "mdi-material-ui";

// ** Types Imports
import { Settings } from "../../../context/settingsContext";
import {
  SearchDataType,
  SearchDatumType,
  TempSearchDataKeyType,
} from "../../../types/AppSearchTypes";
import { AppDispatch, RootState } from "../../../store";

// ** Configs Imports
import themeConfig from "../../../configs/themeConfig";

// ** Custom Components Imports
import UserIcon from "./UserIcon";

import { fetchSearchData } from "../../../store/apps/search";
import { matchSorter } from "match-sorter";
import { useTranslation } from "react-i18next";

interface Props {
  hidden: boolean;
  settings: Settings;
}

interface DefaultSuggestionsProps {
  setOpenDialog: (val: boolean) => void;
}

interface NoResultProps {
  value: string;
  setOpenDialog: (val: boolean) => void;
}

interface DefaultSuggestionsType {
  category: string;
  suggestions: {
    link: string;
    icon: ReactNode;
    suggestion: string;
  }[];
}

const defaultSuggestionsData: DefaultSuggestionsType[] = [
  {
    category: "Orders",
    suggestions: [
      {
        suggestion: "Recent Orders",
        link: "/order/list/",
        icon: (
          <ChartTimelineVariant
            fontSize="small"
            sx={{ mr: 2.5, color: "text.primary" }}
          />
        ),
      },
      {
        suggestion: "Processing Orders",
        link: "/order/list?status=0",
        icon: (
          <NetworkPos
            fontSize="small"
            sx={{ mr: 2.5, color: "text.primary" }}
          />
        ),
      },
      {
        suggestion: "Order Statuses",
        link: "/settings/orderStatus/list",
        icon: (
          <BellCog fontSize="small" sx={{ mr: 2.5, color: "text.primary" }} />
        ),
      },
    ],
  },
  {
    category: "Customers",
    suggestions: [
      {
        suggestion: "Customer List",
        link: "/customer/list/",
        icon: (
          <FormatListCheckbox
            fontSize="small"
            sx={{ mr: 2.5, color: "text.primary" }}
          />
        ),
      },
      {
        suggestion: "Registered Customers",
        link: "/customer/list?status=registered",
        icon: (
          <AccountCircle
            fontSize="small"
            sx={{ mr: 2.5, color: "text.primary" }}
          />
        ),
      },
      {
        suggestion: "Guest Customers",
        link: "/customer/list?status=guest",
        icon: (
          <Account fontSize="small" sx={{ mr: 2.5, color: "text.primary" }} />
        ),
      },
    ],
  },
  {
    category: "Products",
    suggestions: [
      {
        suggestion: "Product List",
        link: "/products/",
        icon: (
          <FormatListCheckbox
            fontSize="small"
            sx={{ mr: 2.5, color: "text.primary" }}
          />
        ),
      },
      {
        suggestion: "Active Products",
        link: "/products?status=published",
        icon: (
          <CartArrowRight
            fontSize="small"
            sx={{ mr: 2.5, color: "text.primary" }}
          />
        ),
      },
      {
        suggestion: "Variobel.sk Products",
        link: "/products?company=0",
        icon: <Tab fontSize="small" sx={{ mr: 2.5, color: "text.primary" }} />,
      },
      {
        suggestion: "Variobel.cz Products",
        link: "/products?company=1",
        icon: (
          <GestureTapButton
            fontSize="small"
            sx={{ mr: 2.5, color: "text.primary" }}
          />
        ),
      },
    ],
  },
  {
    category: "Users",
    suggestions: [
      {
        suggestion: "User List",
        link: "/admin/list/",
        icon: (
          <FormatListCheckbox
            fontSize="small"
            sx={{ mr: 2.5, color: "text.primary" }}
          />
        ),
      },
    ],
  },
];

const categoryTitle: { [k: string]: string } = {
  orders: "Orders",
  customers: "Customers",
  products: "Products",
  users: "Users",
};

// ** Styled Autocomplete component
const Autocomplete = styled(MuiAutocomplete)(({ theme }) => ({
  "& fieldset": {
    border: 0,
  },
  "& + .MuiAutocomplete-popper": {
    borderTop: `1px solid ${theme.palette.divider}`,
    "& .MuiAutocomplete-listbox": {
      paddingTop: 0,
      height: "100%",
      maxHeight: "inherit",
      "& .MuiListSubheader-root": {
        top: 0,
        fontWeight: 400,
        lineHeight: "15px",
        fontSize: "0.75rem",
        letterSpacing: "1px",
        color: theme.palette.text.disabled,
        padding: theme.spacing(3.75, 6, 0.75),
      },
    },
    "& .MuiAutocomplete-paper": {
      border: 0,
      height: "100%",
      borderRadius: 0,
      boxShadow: "none",
    },
    "& .MuiListItem-root.suggestion": {
      padding: 0,
      "& .MuiListItemSecondaryAction-root": {
        display: "flex",
      },
      "&.Mui-focused.Mui-focusVisible, &:hover": {
        backgroundColor: theme.palette.action.hover,
      },
      "& .MuiListItemButton-root: hover": {
        backgroundColor: "transparent",
      },
      "&:not(:hover)": {
        "& .MuiListItemSecondaryAction-root": {
          display: "none",
        },
        "&.Mui-focused, &.Mui-focused.Mui-focusVisible:not(:hover)": {
          "& .MuiListItemSecondaryAction-root": {
            display: "flex",
          },
        },
        [theme.breakpoints.down("sm")]: {
          "&.Mui-focused:not(.Mui-focusVisible) .MuiListItemSecondaryAction-root":
            {
              display: "none",
            },
        },
      },
    },
    "& .MuiAutocomplete-noOptions": {
      display: "grid",
      minHeight: "100%",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      padding: theme.spacing(10),
    },
  },
}));

// ** Styled Dialog component
const Dialog = styled(MuiDialog)({
  "& .MuiBackdrop-root": {
    backdropFilter: "blur(4px)",
  },
  "& .MuiDialog-paper": {
    overflow: "hidden",
    "&:not(.MuiDialog-paperFullScreen)": {
      height: "100%",
      maxHeight: 550,
    },
  },
});

const NoResult = ({ value, setOpenDialog }: NoResultProps) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <FileRemoveOutline
        sx={{ mb: 2.5, fontSize: "5rem", color: "text.primary" }}
      />
      <Typography variant="h6" sx={{ mb: 11.5, wordWrap: "break-word" }}>
        {t("No results for")}
        {" "}
        <Typography
          variant="h6"
          component="span"
          sx={{ wordWrap: "break-word" }}
        >
          {`"${value}"`}
        </Typography>
      </Typography>

      <Typography variant="body2" sx={{ mb: 2.5, color: "text.disabled" }}>
        {t("Try searching for")}
      </Typography>
      <List sx={{ py: 0 }}>
        <ListItem
          sx={{ py: 2 }}
          disablePadding
          onClick={() => setOpenDialog(false)}
        >
          <a href="/dashboards/crm/">
            <Box
              component="a"
              sx={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                "&:hover > *": { color: "primary.main" },
              }}
            >
              <ChartDonut
                fontSize="small"
                sx={{ mr: 2.5, color: "text.primary" }}
              />
              <Typography variant="body2" sx={{ color: "text.primary" }}>
                {t("Orders")}
              </Typography>
            </Box>
          </a>
        </ListItem>
        <ListItem
          sx={{ py: 2 }}
          disablePadding
          onClick={() => setOpenDialog(false)}
        >
          <a href="/apps/user/view/2/">
            <Box
              component="a"
              sx={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                "&:hover > *": { color: "primary.main" },
              }}
            >
              <AccountOutline
                fontSize="small"
                sx={{ mr: 2.5, color: "text.primary" }}
              />
              <Typography variant="body2" sx={{ color: "text.primary" }}>
                {t("Customers")}
              </Typography>
            </Box>
          </a>
        </ListItem>
        <ListItem
          sx={{ py: 2 }}
          disablePadding
          onClick={() => setOpenDialog(false)}
        >
          <a href="/pages/account-settings/">
            <Box
              component="a"
              sx={{
                display: "flex",
                alignItems: "center",
                textDecoration: "none",
                "&:hover > *": { color: "primary.main" },
              }}
            >
              <CartArrowRight
                fontSize="small"
                sx={{ mr: 2.5, color: "text.primary" }}
              />
              <Typography variant="body2" sx={{ color: "text.primary" }}>
                {t("Products")}
              </Typography>
            </Box>
          </a>
        </ListItem>
      </List>
    </Box>
  );
};

const DefaultSuggestions = ({ setOpenDialog }: DefaultSuggestionsProps) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={6} sx={{ ml: 0 }}>
      {defaultSuggestionsData.map((item, index) => (
        <Grid item xs={12} sm={6} key={index}>
          <Typography
            component="p"
            variant="overline"
            sx={{ lineHeight: 1.25, color: "text.disabled" }}
          >
            {t(item.category)}
          </Typography>
          <List sx={{ py: 2.5 }}>
            {item.suggestions.map((suggestionItem, index2) => (
              <ListItem key={index2} sx={{ py: 2 }} disablePadding>
                <Link to={suggestionItem.link}>
                  <Box
                    component="a"
                    onClick={() => setOpenDialog(false)}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                      "&:hover > *": { color: "primary.main" },
                    }}
                  >
                    {suggestionItem.icon}
                    <Typography variant="body2" sx={{ color: "text.primary" }}>
                      {t(suggestionItem.suggestion)}
                    </Typography>
                  </Box>
                </Link>
              </ListItem>
            ))}
          </List>
        </Grid>
      ))}
    </Grid>
  );
};

const AppSearch = ({ hidden, settings }: Props) => {
  // ** States
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [options, setOptions] = useState<SearchDataType>([]);

  // ** Hooks & Vars
  const theme = useTheme();
  const navigate = useNavigate();
  const { layout } = settings;
  const wrapper = useRef<HTMLDivElement>(null);
  const fullScreenDialog = useMediaQuery(theme.breakpoints.down("sm"));
  const  { t } = useTranslation();

  const dispatch = useDispatch<AppDispatch>();
  const { data: searchData } = useSelector((state: RootState) => state.search);

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  // fetch all store values
  useEffect(() => {
    if (isMounted) {
      dispatch(fetchSearchData());
    }
  }, [dispatch, isMounted]);

  // set search options
  useEffect(() => {
    if (searchData.length) {
      setOptions(searchData);
    }
  }, [searchData]);

  // used unknown instead of SearchDataType because of MUI type assertion
  const filterOptions = (data: unknown[], { inputValue }: FilterOptionsState<{
    inputValue: string
  }>) => matchSorter(data, inputValue, {
    // keys are from SearchDataType
    // which is defined from redux search fetch
    keys: [
      "customer.name",
      "customer.email",
      "customer.phone",
      "orderNumber",
      "displayName",
      "email",
      "name",
      "phone",
      "status",
    ]
  })

  const urlRouter = (category: TempSearchDataKeyType, id: string) => {
    if (category === "users") return `/admin/view/${id}`;
    if (category === "customers") return `/customer/view/${id}`;
    if (category === "orders") return `/order/preview/${id}`;
    if (category === "products") return `/products/preview/${id}`;

    return "#";
  };

  const displayedOptionLabel = (
    option: SearchDatumType,
    category: TempSearchDataKeyType
  ) => {
    if (category === "users") return String(option.displayName);
    if (category === "customers") return String(option.name);
    if (category === "orders") return `${option.customer?.name} - #${option.orderNumber}`;
    if (category === "products") return String(option.name);

    return "";
  };

  // Handle click event on a list item in search result
  const handleOptionClick = (obj: SearchDatumType) => {
    const { category, _id } = obj;

    setSearchValue("");
    setOpenDialog(false);

    navigate(urlRouter(category, _id as string));
  };

  // Handle ESC & shortcut keys keydown events
  const handleKeydown = useCallback(
    (event: KeyboardEvent) => {
      // ** Shortcut keys to open searchbox (Ctrl + /)
      if (!openDialog && event.ctrlKey && event.which === 191) {
        setOpenDialog(true);
      }
    },
    [openDialog]
  );

  // Handle shortcut keys keyup events
  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      // ** ESC key to close searchbox
      if (openDialog && event.keyCode === 27) {
        setOpenDialog(false);
      }
    },
    [openDialog]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyUp, handleKeydown]);

  if (!isMounted) {
    return null;
  }

  return (
    <Box
      ref={wrapper}
      onClick={() => !openDialog && setOpenDialog(true)}
      sx={{ display: "flex", cursor: "pointer", alignItems: "center" }}
    >
      <IconButton
        color="inherit"
        sx={!hidden && layout === "vertical" ? { mr: 1, ml: -2.75 } : {}}
      >
        <Magnify />
      </IconButton>

      {!hidden && layout === "vertical" ? (
        <Typography sx={{ color: "text.disabled" }}>{t("Search")} (Ctrl+/)</Typography>
      ) : null}

      <Dialog
        fullWidth
        open={openDialog}
        fullScreen={fullScreenDialog}
        onClose={() => setOpenDialog(false)}
      >
        <Box sx={{ top: 0, width: "100%", position: "sticky" }}>
          <Autocomplete
            autoHighlight
            disablePortal
            options={options}
            filterOptions={filterOptions}
            id="appBar-search"
            isOptionEqualToValue={() => true}
            onInputChange={(event, value: string) => setSearchValue(value)}
            onChange={(event, obj) => handleOptionClick(obj as SearchDatumType)}
            noOptionsText={
              <NoResult value={searchValue} setOpenDialog={setOpenDialog} />
            }
            getOptionLabel={(option: SearchDatumType | unknown) => {
              const { category } = option as SearchDatumType;
              return displayedOptionLabel(option as SearchDatumType, category);
            }}
            groupBy={(option: SearchDatumType | unknown) =>
              searchValue.length
                ? t(categoryTitle[(option as SearchDatumType).category])
                : ""
            }
            sx={{
              "& + .MuiAutocomplete-popper": {
                ...(searchValue.length && {
                  overflow: "auto",
                  maxHeight: "calc(100vh - 69px)",
                  height: fullScreenDialog ? "calc(100vh - 69px)" : 481,
                }),
              },
            }}
            renderInput={(params: AutocompleteRenderInputParams) => {
              return (
                <TextField
                  {...params}
                  value={searchValue}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setSearchValue(event.target.value)
                  }
                  inputRef={(input) => {
                    if (input) {
                      if (openDialog) {
                        input.focus();
                      } else {
                        input.blur();
                      }
                    }
                  }}
                  InputProps={{
                    ...params.InputProps,
                    sx: { p: `${theme.spacing(3.75, 6)} !important` },
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        sx={{ color: "text.primary" }}
                      >
                        <Magnify />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() => setOpenDialog(false)}
                        sx={{
                          display: "flex",
                          cursor: "pointer",
                          alignItems: "center",
                        }}
                      >
                        {!hidden ? (
                          <Typography sx={{ mr: 2.5, color: "text.disabled" }}>
                            [esc]
                          </Typography>
                        ) : null}
                        <IconButton size="small" sx={{ p: 1 }}>
                          <Close fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              );
            }}
            renderOption={(props, option: SearchDatumType | unknown) => {
              const { category, _id } = option as SearchDatumType;
              const IconTag = themeConfig.navSubItemIcon;

              return searchValue.length && option ? (
                <ListItem
                  {...props}
                  key={(option as SearchDatumType)._id}
                  className={`suggestion ${props.className}`}
                  secondaryAction={
                    <SubdirectoryArrowLeft
                      fontSize="small"
                      sx={{ cursor: "pointer", color: "text.disabled" }}
                    />
                  }
                >
                  <ListItemButton
                    sx={{ py: 2.5, px: ` ${theme.spacing(6)} !important` }}
                    component={Link}
                    to={urlRouter(category, _id as string)}
                    onClick={() => {
                      setSearchValue("");
                      setOpenDialog(false);
                    }}
                  >
                    <UserIcon
                      icon={IconTag as any}
                      componentType="search"
                      iconProps={{
                        fontSize: "small",
                        sx: { mr: 2.5, color: "text.primary" },
                      }}
                    />
                    <Typography variant="body2" sx={{ color: "text.primary" }}>
                      {displayedOptionLabel(
                        option as SearchDatumType,
                        category
                      )}
                    </Typography>
                  </ListItemButton>
                </ListItem>
              ) : null;
            }}
          />
        </Box>

        {searchValue.length === 0 ? (
          <Box
            sx={{
              p: 10,
              display: "grid",
              overflow: "auto",
              alignItems: "center",
              justifyContent: "center",
              borderTop: `1px solid ${theme.palette.divider}`,
              height: fullScreenDialog ? "calc(100vh - 69px)" : "100%",
            }}
          >
            <DefaultSuggestions setOpenDialog={setOpenDialog} />
          </Box>
        ) : null}
      </Dialog>
    </Box>
  );
};

export default AppSearch;
