// ** React Imports
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

// ** MUI Imports
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import Box, { BoxProps } from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";

// ** Types
import { LineItemType, OrderLayoutProps, OrderType } from "../../types/orderTypes";

// ** Third Party Components
import format from "date-fns/format";

// ** Configs
import themeConfig from "../../configs/themeConfig";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { getOrder } from "../../store/apps/order";

// ** Layout Import
import BlankLayout from "../../comps/@core/layouts/BlankLayout";
import { Tooltip } from "@mui/material";
import { currencyFormatter } from "../../helpers/currency-formatter";
import { useSettings } from "../../hooks/useSettings";

const StyledLink = styled(Link)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "1rem",
  cursor: "pointer",
  textDecoration: "none",
  color: theme.palette.text.secondary,
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

const CalcWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "&:not(:last-of-type)": {
    marginBottom: theme.spacing(2),
  },
}));

// This page might be removed in the near future
// It is redundant
export const OrderPrintScreen = () => {
  const { id } = useParams<OrderLayoutProps>();
  const dispatch = useDispatch<AppDispatch>();
  const { settings } = useSettings();

  const { configuredCompany } = settings;
  const isVariobelOnlyField = configuredCompany === "variobel"

  // ** State
  const [error, setError] = useState<boolean>(false);
  const [data, setData] = useState<null | OrderType>(null);

  // ** Hooks
  const theme = useTheme();

  // order data fetch
  useEffect(() => {
    (async () => {
      dispatch(getOrder(id as string))
        .then((res) => {
          const { __v, ...temp } = res.payload.order;

          setData(temp);
          setError(false);
        })
        .catch((e) => {
          setData(null);
          setError(true);
        });
    })();
  }, [dispatch, id]);

  if (data) {
    return (
      <BlankLayout>
        <Box sx={{ p: 12, pb: 6 }}>
          {/* Order Heading Summary */}
          <Grid container>
            <Grid sm={6} xs={12} sx={{ mb: { sm: 0, xs: 4 } }}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ mb: 6, display: "flex", alignItems: "center" }}>
                  <svg
                    width={40}
                    fill="none"
                    height={22}
                    viewBox="0 0 268 150"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      rx="25.1443"
                      width="50.2886"
                      height="143.953"
                      fill={theme.palette.primary.main}
                      transform="matrix(-0.865206 0.501417 0.498585 0.866841 195.571 0)"
                    />
                    <rect
                      rx="25.1443"
                      width="50.2886"
                      height="143.953"
                      fillOpacity="0.4"
                      fill="url(#paint0_linear_7821_79167)"
                      transform="matrix(-0.865206 0.501417 0.498585 0.866841 196.084 0)"
                    />
                    <rect
                      rx="25.1443"
                      width="50.2886"
                      height="143.953"
                      fill={theme.palette.primary.main}
                      transform="matrix(0.865206 0.501417 -0.498585 0.866841 173.147 0)"
                    />
                    <rect
                      rx="25.1443"
                      width="50.2886"
                      height="143.953"
                      fill={theme.palette.primary.main}
                      transform="matrix(-0.865206 0.501417 0.498585 0.866841 94.1973 0)"
                    />
                    <rect
                      rx="25.1443"
                      width="50.2886"
                      height="143.953"
                      fillOpacity="0.4"
                      fill="url(#paint1_linear_7821_79167)"
                      transform="matrix(-0.865206 0.501417 0.498585 0.866841 94.1973 0)"
                    />
                    <rect
                      rx="25.1443"
                      width="50.2886"
                      height="143.953"
                      fill={theme.palette.primary.main}
                      transform="matrix(0.865206 0.501417 -0.498585 0.866841 71.7728 0)"
                    />
                    <defs>
                      <linearGradient
                        y1="0"
                        x1="25.1443"
                        x2="25.1443"
                        y2="143.953"
                        id="paint0_linear_7821_79167"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop />
                        <stop offset="1" stopOpacity="0" />
                      </linearGradient>
                      <linearGradient
                        y1="0"
                        x1="25.1443"
                        x2="25.1443"
                        y2="143.953"
                        id="paint1_linear_7821_79167"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop />
                        <stop offset="1" stopOpacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <Typography
                    variant="h6"
                    sx={{
                      ml: 2,
                      fontWeight: 700,
                      lineHeight: 1.2,
                      textTransform: "capitalize",
                    }}
                  >
                    {configuredCompany} {themeConfig.templateName}
                  </Typography>
                </Box>

                <Box>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Office 149, 450 South Brand Brooklyn
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    San Diego County, CA 91905, USA
                  </Typography>
                  <Typography variant="body2">
                    +1 (123) 456 7891, +44 (876) 543 2198
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item sm={6} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: { sm: "flex-end", xs: "flex-start" },
                }}
              >
                <Typography variant="h6" sx={{ mb: 2 }}>
                  {`Order #${data.orderNumber}`}
                </Typography>

                <Box sx={{ mb: 2, display: "flex" }}>
                  <Typography variant="body2" sx={{ mr: 3 }}>
                    Date issued:
                  </Typography>

                  <Typography variant="body2">
                    {format(new Date(data.lastModified), "MMM d, yyyy")}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex" }}>
                  <Typography variant="body2" sx={{ mr: 3 }}>
                    Date Paid:
                  </Typography>

                  <Typography variant="body2">
                    {/* data.date_paid ?? */ "Not paid yet"}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Divider sx={{ my: 6 }} />

          {/* Order Billing/Shipping Details */}
          <Grid container>
            <Grid item xs={12} sm={6} sx={{ mb: { lg: 0, xs: 4 } }}>
              <Typography
                variant="subtitle2"
                sx={{ mb: 3, color: "text.primary", letterSpacing: ".1px" }}
              >
                Billing address:
              </Typography>

              <Typography variant="body2" sx={{ mb: 2 }}>
                {`${data.customer.firstName} ${data.customer.lastName}`}
              </Typography>

              <Typography variant="body2" sx={{ mb: 2 }}>
                {data.customer.billingAddress[0].address}
              </Typography>

              <Typography variant="body2" sx={{ mb: 2 }}>
                {`${data.customer.billingAddress[0].postcode}, ${data.customer.billingAddress[0].city}`}
              </Typography>

              <Typography variant="body2" sx={{ mb: 2 }}>
                {data.customer.billingAddress[0].country?.label}
              </Typography>

              <Typography variant="body2" sx={{ mb: 2 }}>
                {data.customer.email}
              </Typography>

              <Typography variant="body2" sx={{ mb: 2 }}>
                {data.customer.phone}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              // sx={{
              //   display: "flex",
              //   justifyContent: ["flex-start", "flex-end"],
              // }}
            >
              <div>
                <Typography
                  variant="subtitle2"
                  sx={{ mb: 3, color: "text.primary", letterSpacing: ".1px" }}
                >
                  Shipping address:
                </Typography>

                {data.customer.shippingAddress[0] ? (
                  <>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      {`${data.customer.firstName} ${data.customer.lastName}`}
                    </Typography>

                    <Typography variant="body2" sx={{ mb: 2 }}>
                      {data.customer.shippingAddress[0].address}
                    </Typography>

                    <Typography variant="body2" sx={{ mb: 2 }}>
                      {`${data.customer.shippingAddress[0].postcode}, ${data.customer.shippingAddress[0].city}`}
                    </Typography>

                    <Typography variant="body2" sx={{ mb: 2 }}>
                      {data.customer.shippingAddress[0].country?.label}
                    </Typography>

                    <Typography variant="body2" sx={{ mb: 2 }}>
                      {data.customer.email}
                    </Typography>

                    <Typography variant="body2" sx={{ mb: 2 }}>
                      {data.customer.phone}
                    </Typography>
                  </>
                ) : (
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    Pickup Only
                  </Typography>
                )}
              </div>
            </Grid>
          </Grid>

          <Divider sx={{ mt: 6, mb: 0 }} />

          {/* Order Details Summary */}
          {data.lineItems.length && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell>QTY</TableCell>
                    <TableCell>Size</TableCell>
                    <TableCell>Price</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data.lineItems.map((lineItem) => {
                    if (!isVariobelOnlyField) return null;

                    const variobelLineItem = lineItem as LineItemType;

                    const {
                      print: { type, value },
                      product,
                    } = variobelLineItem;

                    const color = JSON.parse(value);
                    const image: string = product.images[0];

                    return (
                      <TableRow key={variobelLineItem._id}>
                        <TableCell>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            {type === "color" ? (
                              <>
                                <Tooltip title="ONYX">
                                  <Avatar
                                    variant="rounded"
                                    sx={{
                                      mr: 3,
                                      width: 34,
                                      height: 34,
                                      backgroundColor: color.onyxMatch.hex,
                                    }}
                                  >
                                    {" "}
                                  </Avatar>
                                </Tooltip>

                                <Tooltip title="HEX">
                                  <Avatar
                                    variant="rounded"
                                    sx={{
                                      mr: 3,
                                      width: 34,
                                      height: 34,
                                      backgroundColor: color.hex,
                                    }}
                                  >
                                    {" "}
                                  </Avatar>
                                </Tooltip>
                              </>
                            ) : (
                              <Avatar
                                variant="rounded"
                                alt={product.name}
                                src={type === "wallpaper" && (image as any)}
                                sx={{
                                  mr: 3,
                                  width: 34,
                                  height: 34,
                                }}
                              >
                                {""}{" "}
                              </Avatar>
                            )}
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column",
                              }}
                            >
                              <StyledLink
                                to={`/product/${variobelLineItem.product._id}`}
                              >
                                {variobelLineItem.name}
                              </StyledLink>

                              <Typography noWrap variant="caption">
                                {`${variobelLineItem.glassWidth} x ${variobelLineItem.glassHeight} mm`}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>

                        <TableCell>{variobelLineItem.qty}</TableCell>

                        <TableCell sx={{ whiteSpace: "nowrap" }}>
                          {(
                            (variobelLineItem.glassWidth *
                              variobelLineItem.glassHeight *
                              variobelLineItem.qty) /
                            Math.pow(10, 6)
                          ).toFixed(2)}{" "}
                          m<sup>2</sup>
                        </TableCell>
                        <TableCell>
                          {currencyFormatter(variobelLineItem.price)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {/* Order Pricing Summary */}
          <Grid container sx={{ pt: 8 }}>
            <Grid item xs={12} sm={7} lg={9} sx={{ order: { sm: 1, xs: 2 } }} />
            <Grid
              item
              xs={12}
              sm={5}
              lg={3}
              sx={{ mb: { sm: 0, xs: 4 }, order: { sm: 2, xs: 1 } }}
            >
              <CalcWrapper>
                <Typography variant="body2">Subtotal:</Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.primary",
                    letterSpacing: ".25px",
                    fontWeight: 600,
                  }}
                >
                  {currencyFormatter(data.basketPrice.subtotal)}
                </Typography>
              </CalcWrapper>

              {/* <CalcWrapper>
                  <Typography variant="body2">Discount:</Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "text.primary",
                      letterSpacing: ".25px",
                      fontWeight: 600,
                    }}
                  >
                    €0
                  </Typography>
                </CalcWrapper> */}

              {/* <CalcWrapper>
                  <Typography variant="body2">Delivery:</Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "text.primary",
                      letterSpacing: ".25px",
                      fontWeight: 600,
                    }}
                  >
                    €0
                  </Typography>
                </CalcWrapper> */}

              <CalcWrapper>
                <Typography variant="body2">Tax:</Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.primary",
                    letterSpacing: ".25px",
                    fontWeight: 600,
                  }}
                >
                  {`${data.basketPrice.taxRate}%`}
                </Typography>
              </CalcWrapper>
              <Divider sx={{ mt: 5, mb: 3 }} />
              <CalcWrapper>
                <Typography variant="body2">Total:</Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.primary",
                    letterSpacing: ".25px",
                    fontWeight: 600,
                  }}
                >
                  {currencyFormatter(data.basketPrice.price)}
                </Typography>
              </CalcWrapper>
            </Grid>
          </Grid>

          <Divider sx={{ my: 6 }} />
        </Box>
      </BlankLayout>
    );
  }

  if (error) {
    return (
      <BlankLayout>
        <Box sx={{ p: 5 }}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Alert severity="error">
                Order with the id: {id} does not exist. Please check the list of
                orders: <Link to="/order/list">Order List</Link>
              </Alert>
            </Grid>
          </Grid>
        </Box>
      </BlankLayout>
    );
  }

  return <BlankLayout />;
};
