import { useEffect, useState, SyntheticEvent } from "react";
import {
  Grid,
  Card,
  CardContent,
  Tab as MuiTab,
  Icon,
  Box,
  TabProps,
  styled,
  Typography,
  Button,
  CardHeader,
} from "@mui/material";

import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { getCompany } from "../../../store/apps/companies";
import { CompanyType } from "../../../types/companyTypes";
import { Avatar } from "../../../comps/avatar";
import DialogContainer from "../../../comps/dialogContainer";
import EditCompanyScreen from "./editCompany";
import { Chip } from "../../../comps/chip";
import { ThemeColor } from "../../../comps/@core/layouts/types";

const Tab = styled(MuiTab)<TabProps>(({ theme }) => ({
  minHeight: 48,
  flexDirection: "row",
  "& svg": {
    marginBottom: "0 !important",
    marginRight: theme.spacing(1),
  },
}));

const tabs = [
  {
    value: 'info',
    label: 'Info',
    icon: 'description'
  },
  {
    value: 'banking',
    label: 'Bank Details',
    icon: 'description'
  },
  {
    value: 'timeline',
    label: 'Timeline',
    icon: 'description'
  },
]

const PreviewCompanyScreen = () => {
  const { id } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const [company, setCompany] = useState<CompanyType>();
  const [tabValue, setTabValue] = useState<string>("info");
  const [editOpen, setEditOpen] = useState(false);
  const [numberOfBanks, setNumberOfBanks] = useState(1);

  const { t } = useTranslation();

  const handleChange = (event: SyntheticEvent, newTabValue: string) => {
    setTabValue(newTabValue);
  };

  const toggleEditDialog = () => setEditOpen((prev) => !prev);

  useEffect(() => {
    if (id) {
      (async () =>
        dispatch(getCompany(id)).then((res) => {
          const { company: _company } = res.payload;
          const bankNumber = (_company as CompanyType).bankDetails
          ?.length as number;
          setNumberOfBanks(bankNumber);


          setCompany(_company as CompanyType);
        }))();
    }
  }, [dispatch, id]);

  if (!company) return null;

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={3}>
        <Card
          sx={{
            mt: "calc(48px + 0.75rem)",
          }}
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                textDecoration: "underline",
                textAlign: "center",
              }}
            >
              {t("Details")}
            </Typography>

            <Box
              py={4}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ mr: 2, color: "text.primary" }}
                >
                  {`${t("Company Color")}:`}
                </Typography>

                <Chip
                  size="small"
                  color={company.colorCode as ThemeColor}
                  sx={{ width: 28, height: 28 }}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ mr: 2, color: "text.primary" }}
                >
                  {`${t("Tax Rate")}:`}
                </Typography>

                <Typography variant="body2">% {company.taxRate}</Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ mr: 2, color: "text.primary" }}
                >
                  {`${t("ICO")}:`}
                </Typography>

                <Typography variant="body2">{company.ico}</Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ mr: 2, color: "text.primary" }}
                >
                  {`${t("DIC")}:`}
                </Typography>

                <Typography variant="body2">{company.dic}</Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ mr: 2, color: "text.primary" }}
                >
                  {`${t("IČDPH")}:`}
                </Typography>

                <Typography variant="body2">{company.icdph}</Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ mr: 2, color: "text.primary" }}
                >
                  {`${t("Product Currency")}:`}
                </Typography>

                <Typography variant="body2">
                  {company.productCurrency}
                </Typography>
              </Box>
            </Box>

            <Button variant="contained" onClick={toggleEditDialog}>
              Edit
            </Button>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={9}>
        <TabContext value={tabValue}>
          <TabList
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleChange}
            aria-label="forced scroll tabs"
            sx={{
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            {tabs.map((tab, i) => (
              <Tab
                key={i}
                value={tab.value}
                label={t(tab.label)}
                icon={<Icon sx={{ fontSize: "18px" }}>{tab.icon}</Icon>}
                sx={{
                  "& .MuiTab-iconWrapper": {
                    mb: 0,
                    mr: "0.25rem",
                  },
                }}
              />
            ))}
          </TabList>

          <Box mt={3}>
            <TabPanel sx={{ p: 0 }} value="info">
              <Card>
                <CardContent
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 4,
                  }}
                >
                  <Avatar
                    skin="light"
                    variant="rounded"
                    src={company.logo}
                    sx={{
                      width: 250,
                      height: "auto",
                    }}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                    }}
                  >
                    <Typography variant="h6">{company.name}</Typography>

                    <Typography>{company.address}</Typography>
                  </Box>
                </CardContent>
              </Card>
            </TabPanel>

            <TabPanel sx={{ p: 0 }} value="banking">
              <Grid container xs={12} spacing={5}>
                {company.bankDetails &&
                  Array.from({ length: numberOfBanks }).map((_, i) => (
                    <Grid key={i} item xs={12}>
                      <Card>
                        <CardHeader title={`Bank Details ${i + 1}`} />
                        <CardContent
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 3,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              sx={{ mr: 2, color: "text.primary" }}
                            >
                              {`${t("Account Holder's Name")}:`}
                            </Typography>

                            <Typography variant="body2">
                              {company.bankDetails &&
                                company.bankDetails[i].name}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              sx={{ mr: 2, color: "text.primary" }}
                            >
                              {`${t("Account Number")}:`}
                            </Typography>

                            <Typography variant="body2">
                              {company.bankDetails &&
                                company.bankDetails[i].accountNumber}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              sx={{ mr: 2, color: "text.primary" }}
                            >
                              {`${t("Account IBAN")}:`}
                            </Typography>

                            <Typography variant="body2">
                              {company.bankDetails &&
                                company.bankDetails[i].iban}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="subtitle2"
                              sx={{ mr: 2, color: "text.primary" }}
                            >
                              {`${t("Account SWIFT")}:`}
                            </Typography>

                            <Typography variant="body2">
                              {company.bankDetails &&
                                company.bankDetails[i].swift}
                            </Typography>
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
              </Grid>
            </TabPanel>
          </Box>
        </TabContext>
      </Grid>

      <DialogContainer open={editOpen} handleClose={toggleEditDialog}>
        <EditCompanyScreen id={company._id} handleClose={toggleEditDialog} />
      </DialogContainer>
    </Grid>
  );
};

export default PreviewCompanyScreen;
