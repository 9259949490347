// ** MUI Imports
import MuiChip, { ChipProps as MuiChipProps } from "@mui/material/Chip";

// ** Hooks Imports
import useBgColor, { UseBgColorType } from "../hooks/useBgColor";

// ** Types
export type ChipProps = MuiChipProps & { skin?: "light" };

export const Chip = (props: ChipProps) => {
  // ** Props
  const { sx, skin, color } = props;

  // ** Hook
  const bgColors = useBgColor();

  const colors: UseBgColorType = {
    primary: { ...bgColors.primaryLight },
    secondary: { ...bgColors.secondaryLight },
    success: { ...bgColors.successLight },
    error: { ...bgColors.errorLight },
    warning: { ...bgColors.warningLight },
    info: { ...bgColors.infoLight },
  };

  return (
    <MuiChip
      {...props}
      variant="filled"
      {...(skin === "light" && { className: "MuiChip-light" })}
      sx={skin === "light" && color ? Object.assign(colors[color], sx) : sx}
    />
  );
};
