import * as XLSX from 'xlsx';

export const supportedTableExports = ['csv', 'txt', 'xlsx', 'xls'] as const;

export type TableExporterT = typeof supportedTableExports[number];

export const tableExporter = (type: TableExporterT, data: any, filename: string) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, filename);

  const file = filename + '.' + type
  XLSX.writeFile(workbook, file, { compression: true });
};
