// ** React Imports
import { useState, useEffect, useMemo, useCallback } from "react";

// ** MUI Imports
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { DataGrid, GridColumns } from "@mui/x-data-grid";

// ** Third Party Imports
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

// ** Store & Actions Imports
import { useDispatch, useSelector } from "react-redux";
import {
  addOrderStatus,
  deleteOrderStatus,
  editOrderStatus,
  fetchData,
} from "../../../store/apps/orderStatus";

// ** Custom Components Imports
import OrderStatusTableHeader from "../../../comps/order/status/order-status-table-header";
import OrderStatusFormDialog from "../../../comps/order/status/order-status-form-dialog";
import DeleteStatusDialog from "../../../comps/order/status/deleteStatusDialog";

// ** Types Imports
import { RootState, AppDispatch } from "../../../store";
import {
  OrderStatus,
  OrderStatusData,
  orderStatusSchema,
} from "../../../types/orderStatusTypes";
import { useAlertContext } from "../../../hooks/useAlertContext";
import getOrderStatusTableColumns from "../../../comps/order/status/order-status-table-columns";

export const defaultValues: OrderStatusData = {
  name: "",
  emailSubject: "",
  colorCode: "",
  template: "",
  notifyCustomer: false,
  notifyAdmin: false,
  isDefault: false,
  positionInKanban: 0
};

export const OrderStatusScreen = () => {
  // ** State
  const [pageSize, setPageSize] = useState<number>(10);
  const [deleteStatusOpen, setDeleteStatusOpen] = useState<boolean>(false);
  const [addOrderStatusOpen, setAddOrderStatusOpen] = useState<boolean>(false);
  const [editOrderStatusOpen, setEditOrderStatusOpen] =
    useState<boolean>(false);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState<OrderStatus>();
  const [isOrderStatusLoading, setIsOrderStatusLoading] =
    useState<boolean>(true);

  // ** Hooks
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const store = useSelector((state: RootState) => state.orderStatus);
  const addOrderStatusForm = useForm<OrderStatusData>({
    defaultValues,
    resolver: yupResolver(orderStatusSchema),
  });
  const editOrderStatusForm = useForm<OrderStatusData>({
    defaultValues,
    resolver: yupResolver(orderStatusSchema),
  });
  const { showErrorAlert, showSuccessAlert } = useAlertContext();

  useEffect(() => {
    (async () => {
      setIsOrderStatusLoading(true);
      await dispatch(fetchData());
      setIsOrderStatusLoading(false);
    })();
  }, [dispatch]);

  const toggleAddOrderStatusDialog = () =>
    setAddOrderStatusOpen(!addOrderStatusOpen);

  const toggleEditOrderStatusDialog = useCallback(() => {
    setEditOrderStatusOpen(!editOrderStatusOpen);
  }, [editOrderStatusOpen]);

  const toggleDeleteOrderStatusDialog = useCallback(() => {
    setDeleteStatusOpen(!deleteStatusOpen);
  }, [deleteStatusOpen]);

  const handleAddOrderStatus = async (data: OrderStatusData) => {
    if (store.data.some((orderStatus) => orderStatus.name === data.name)) {
      addOrderStatusForm.setError("name", {
        type: "manual",
        message: "Name must be unique",
      });
    } else {
      setIsOrderStatusLoading(true);

      await dispatch(addOrderStatus({ ...data }))
        .unwrap()
        .then(() => {
          showSuccessAlert(t("Status created"));
        })
        .catch(() => {
          showErrorAlert(t("Failed to create Status"));
        })
        .finally(() => {
          setIsOrderStatusLoading(false);
          toggleAddOrderStatusDialog();
          addOrderStatusForm.reset();
        });
    }
  };

  const handleEditOrderStatus = async (data: OrderStatusData) => {
    if (!selectedOrderStatus) return;

    if (
      store.data.some(
        (orderStatus) =>
          orderStatus.name !== selectedOrderStatus.name &&
          orderStatus.name === data.name
      )
    ) {
      editOrderStatusForm.setError("name", {
        type: "manual",
        message: "Name must be unique",
      });
    } else {
      setIsOrderStatusLoading(true);

      await dispatch(editOrderStatus({ id: selectedOrderStatus._id, data }))
        .unwrap()
        .then(() => {
          showSuccessAlert(t("Status updated"));
        })
        .catch(() => {
          showErrorAlert(t("Failed to update Status"));
        })
        .finally(() => {
          setIsOrderStatusLoading(false);
          toggleEditOrderStatusDialog();
        });
    }
  };

  const handleDeleteOrderStatus = async () => {
    if (!selectedOrderStatus) return;

    setIsOrderStatusLoading(true);

    await dispatch(deleteOrderStatus(selectedOrderStatus._id))
      .unwrap()
      .then(() => {
        showSuccessAlert(t("Status deleted"));
      })
      .catch(() => {
        showErrorAlert(t("Failed to delete Status"));
      })
      .finally(() => {
        setIsOrderStatusLoading(false);
        toggleDeleteOrderStatusDialog();
      });
  };

  const columns = useMemo<GridColumns<OrderStatus>>(
    () =>
      getOrderStatusTableColumns({
        t,
        toggleDeleteOrderStatusDialog,
        toggleEditOrderStatusDialog,
        setSelectedOrderStatus,
        editOrderStatusForm
      }),
    [
      editOrderStatusForm,
      t,
      toggleDeleteOrderStatusDialog,
      toggleEditOrderStatusDialog,
    ]
  );

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Card>
          <OrderStatusTableHeader toggle={toggleAddOrderStatusDialog} />

          <DataGrid
            autoHeight
            disableSelectionOnClick
            rows={store.data}
            getRowId={(row) => row._id}
            columns={columns}
            initialState={{
              sorting: {
                sortModel: [{ field: "name", sort: "asc" }],
              },
            }}
            loading={isOrderStatusLoading}
            pagination
            rowsPerPageOptions={[10, 25, 50]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
          />
        </Card>
      </Grid>

      <OrderStatusFormDialog
        title={t("Pridajte stav objednávky")}
        form={addOrderStatusForm}
        open={addOrderStatusOpen}
        toggle={toggleAddOrderStatusDialog}
        onSubmit={handleAddOrderStatus}
        type="add"
      />

      <OrderStatusFormDialog
        title={t("Update order status")}
        form={editOrderStatusForm}
        open={editOrderStatusOpen}
        toggle={toggleEditOrderStatusDialog}
        onSubmit={handleEditOrderStatus}
        type="edit"
      />

      {selectedOrderStatus && (
        <DeleteStatusDialog
          open={deleteStatusOpen}
          handleClose={toggleDeleteOrderStatusDialog}
          handleDelete={handleDeleteOrderStatus}
          status={selectedOrderStatus.name}
        />
      )}
    </Grid>
  );
};
