import React, { Dispatch, SetStateAction } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { BasketPriceTypeTemp, OrderType } from "../../../../../types/orderTypes";
import yup from "../../../../../helpers/customYup";
import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField, Select } from "formik-mui";
import { AppDispatch } from "../../../../../store";
import { useDispatch } from "react-redux";
import { updateOrder } from "../../../../../store/apps/order";
import { useAlertContext } from "../../../../../hooks/useAlertContext";
import { calculateDiscount } from "../../../../../helpers/calculateDiscount";

interface Props {
  data: OrderType;
  setData: Dispatch<SetStateAction<OrderType | null>>;
  handleClose: () => void;
}

const validationSchema = yup.object().shape({
  value: yup.number().required(),
  prefix: yup.string().required(),
});

interface FormType extends yup.InferType<typeof validationSchema> {}

const OrderDiscountCard = ({ data, setData, handleClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { showErrorAlert, showSuccessAlert } = useAlertContext();

  const value = data.basketPrice.appliedDiscounts
    ? data.basketPrice.appliedDiscounts.order.value
    : 0;
  const prefix = data.basketPrice.appliedDiscounts
    ? data.basketPrice.appliedDiscounts.order.prefix
    : "%";

  const initialValues = {
    value,
    prefix,
  };

  const onSubmit = async (
    values: FormType,
    formikHelpers: FormikHelpers<FormType>
  ) => {
    const { setSubmitting, resetForm } = formikHelpers;

    let appliedDiscounts: BasketPriceTypeTemp["appliedDiscounts"] = {
      onSale: [],

      customer: {
        value: 0,
        prefix: "%",
      },

      order: values,
    };

    if (data.basketPrice.appliedDiscounts) {
      appliedDiscounts = {
        ...data.basketPrice.appliedDiscounts,
        order: values,
      };
    }

    const _customerDiscount = calculateDiscount(
      data.basketPrice.subtotal,
      appliedDiscounts.customer
    );

    const onSaleDiscounts = appliedDiscounts.onSale;
    const _totalOnSaleDiscount = onSaleDiscounts.reduce((total, item) => {
      return Number(total + item.discount);
    }, 0);

    const _orderDiscount = calculateDiscount(
      data.basketPrice.subtotal,
      appliedDiscounts.order
    );

    const totalDiscount =
      _orderDiscount + _customerDiscount + _totalOnSaleDiscount;

    const newPrice = (data.basketPrice.subtotal + data.basketPrice.tax )- totalDiscount
    const orderUpdate = {
      basketPrice: {
        ...data.basketPrice,
        appliedDiscounts,
        price: newPrice,
      },
    };

    await dispatch(
      updateOrder({
        id: data._id,
        data: orderUpdate,
      })
    )
      .unwrap()
      .then((res) => {
        showSuccessAlert(t("Discount added"));
        handleClose();
        const orderData = res.order as OrderType;
        setData(orderData)
      })
      .catch(() => {
        showErrorAlert(t("Failed to add Discount"));
      })
      .finally(() => {
        setSubmitting(false);
        resetForm();
      });
  };

  return (
    <Card>
      <CardHeader title={`${t("Discount Order")}`} sx={{ mb: 1 }} />
      <CardContent>
        <Formik
          {...{
            initialValues,
            validationSchema,
            onSubmit,
          }}
        >
          {({ isSubmitting, submitForm }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Field
                    component={TextField}
                    name="discount"
                    autoComplete="off"
                    type="number"
                    label="Discount"
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    component={Select}
                    name="discountPrefix"
                    label="Discount prefix"
                    fullWidth
                    required
                  >
                    <MenuItem value={"%"}>%</MenuItem>
                    <MenuItem value={"€"}>€</MenuItem>
                  </Field>
                </Grid>

                {isSubmitting && (
                  <Grid item xs={12}>
                    <LinearProgress />
                  </Grid>
                )}

                <Grid item mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    {t("Update")}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default OrderDiscountCard;
