import { alpha, styled } from "@mui/material";
import TabSelector from "../../tab-selector";

export const StyledTabSelector = styled(TabSelector)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  minHeight: 0,
  marginBottom: theme.spacing(2),

  "& .MuiTab-root": {
    textTransform: "none",
    fontWeight: 600,
    fontSize: "0.85rem",
    padding: "8px 12px",
    minHeight: 0,

    "&.Mui-selected": {
      backgroundColor: alpha(theme.palette.secondary.light, 0.2),

    },
  },
})) as typeof TabSelector;
