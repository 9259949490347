// ** React Imports
import { ReactNode, ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// ** Hooks Import
import { useAuth } from "../../hooks/useAuth";

interface GuestGuardProps {
  children: ReactNode;
  fallback: ReactElement | null;
}

export const GuestGuard = (props: GuestGuardProps) => {
  const { children, fallback } = props;
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // if (window.localStorage.getItem("userData")) {
    //   navigate("/", { replace: true });
    // }
    if (auth.user) {
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (auth.loading /* || (!auth.loading && auth.user) */) {
    return fallback;
  }

  return <>{children}</>;
};
