import yup from "../helpers/customYup";

export interface PricingPrefixT {
  value: number;
  prefix: "%" | "€";
}

export interface CategoryMarginAndDiscountT {
  margin: CategoryMarginPrefixT;
  discount: CategoryDiscountPrefixT;
}

export interface CategoryMarginPrefixT {
  registeredCustomerMargin: PricingPrefixT;
  unregisteredCustomerMargin: PricingPrefixT;
  wholesaleCustomerMargin: PricingPrefixT;
}

export interface CategoryDiscountPrefixT {
  registeredCustomerDiscount: PricingPrefixT;
  unregisteredCustomerDiscount: PricingPrefixT;
  wholesaleCustomerDiscount: PricingPrefixT;
}

export interface BaseProductCategoryT {
  name: string;
  parentCategory: string | null; // _id of another product category
  discount: CategoryDiscountPrefixT;
  margin: CategoryMarginPrefixT;
  applyMarginToChildrenCategories: boolean;
}

const pricingPrefixYupT = yup
  .object()
  .shape({
    value: yup.number().required(),
    prefix: yup.string().oneOf(["%", "€"]).required(),
  })
  .required();

const marginPrefixYupT = yup.object().shape({
  registeredCustomerMargin: pricingPrefixYupT,
  unregisteredCustomerMargin: pricingPrefixYupT,
  wholesaleCustomerMargin: pricingPrefixYupT,
});

const discountPrefixYupT = yup.object().shape({
  registeredCustomerDiscount: pricingPrefixYupT,
  unregisteredCustomerDiscount: pricingPrefixYupT,
  wholesaleCustomerDiscount: pricingPrefixYupT,
});

export const BaseProductCategoryValidationSchema = yup.object().shape({
  name: yup.string().required(),
  parentCategory: yup.string().nullable(),
  discount: discountPrefixYupT,
  margin: marginPrefixYupT,
  applyMarginToChildrenCategories: yup.boolean().required(),
});

export interface ProductCategoryT extends BaseProductCategoryT {
  _id: string;
}
