import {
  EmailOutline,
  NoteOutline,
  NoteTextOutline,
  PhoneOutline,
} from "mdi-material-ui";

import yup from "../helpers/customYup";

export const timelineEntryType: {[key: string]: any} = {
  note: NoteOutline,
  phone: PhoneOutline,
  email: EmailOutline
}

export const productTimelineEntryType: {[key: string]: any} = {
  note: NoteOutline,
  update: NoteTextOutline
}

export interface UpdatedProductTimelineItemType {
  [key: string]: {
    previous: string[] | string,
    current: string[] | string
  }
}

export type TimelineEntryTypeKeyType = keyof typeof timelineEntryType;
export type ProductTimelineEntryTypeKeyType = keyof typeof productTimelineEntryType;

export const initialValues = {
  type: "note",
  text: "",
};

export const validationSchema = yup.object().shape({
  type: yup.string().required(),
  text: yup.string().required(),
});

export interface ValuesType extends yup.InferType<typeof validationSchema> {}

export interface TimelineEntriesType extends ValuesType {
  time: Date,
  admin: string,
  _id?: string;
}
