import { SyntheticEvent, useCallback, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";

import { Grid, Icon, Tab, Box } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import { AppDispatch, RootState } from "../../../store";
import {
  OrderStatus,
  OrderStatusData,
  orderStatusSchema,
} from "../../../types/orderStatusTypes";

import { useAlertContext } from "../../../hooks/useAlertContext";
import {
  editOrderStatus,
  getOrderStatus,
} from "../../../store/apps/orderStatus";
import ViewOrderStatusLeft from "./viewTabs/viewOrderStatusLeft";
import { defaultValues } from "./index";
import OrderStatusFormDialog from "../../../comps/order/status/order-status-form-dialog";
import { Spinner } from "../../../comps/utilities/spinner";
import ViewInfo from "./viewTabs/viewInfo";

const tabs = [
  {
    value: "info",
    label: "Info",
    icon: "description",
  },
  {
    value: "orders",
    label: "Orders",
    icon: "description",
  },
  {
    value: "timeline",
    label: "Timeline",
    icon: "description",
  },
];

const ViewOrderStatusScreen = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { data: orderStatusStore } = useSelector(
    (state: RootState) => state.orderStatus
  );
  const { showSuccessAlert, showErrorAlert } = useAlertContext();

  const [orderStatus, setOrderStatus] = useState<OrderStatus>();
  const [tabValue, setTabValue] = useState("info");
  const [editOrderStatusOpen, setEditOrderStatusOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleChange = (event: SyntheticEvent, newTabValue: string) => {
    setTabValue(newTabValue);
  };

  const editOrderStatusForm = useForm<OrderStatusData>({
    defaultValues,
    resolver: yupResolver(orderStatusSchema),
    values: orderStatus as OrderStatus,
  });

  const toggleEditDialog = () => setEditOrderStatusOpen((prev) => !prev);

  const fetchStatus = useCallback(() => {
    if (!id) return;

    setLoading(true);
    dispatch(getOrderStatus(id))
      .unwrap()
      .then((res) => {
        setOrderStatus(res.orderStatus);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, id]);

  const handleEditOrderStatus = async (data: OrderStatusData) => {
    if (!orderStatus) return;

    const statusNameExists = orderStatusStore.some(
      (status) => status.name !== orderStatus.name && status.name === data.name
    );

    if (statusNameExists) {
      editOrderStatusForm.setError("name", {
        type: "manual",
        message: "Name must be unique",
      });

      return;
    }

    setLoading(true);
    await dispatch(editOrderStatus({ id: orderStatus._id, data }))
      .unwrap()
      .then((res) => {
        setOrderStatus(res.orderStaus);
        showSuccessAlert(t("Status has been updated"));
        toggleEditDialog();
        fetchStatus();
      })
      .catch((e) => {
        Promise.reject(e);
        showErrorAlert(t("Status failed to updated"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchStatus();
  }, [fetchStatus]);

  if (loading) return <Spinner />;

  if (!orderStatus) return null;

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={3}>
        <ViewOrderStatusLeft {...{ orderStatus, toggleEditDialog }} />
      </Grid>

      <Grid item xs={12} md={9}>
        <TabContext value={tabValue}>
          <TabList
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleChange}
            aria-label="forced scroll tabs"
            sx={{
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            {tabs.map((tab, i) => (
              <Tab
                key={i}
                value={tab.value}
                label={t(tab.label)}
                disabled={tab.value === "orders" || tab.value === "timeline"}
                icon={<Icon sx={{ fontSize: "18px" }}>{tab.icon}</Icon>}
                sx={{
                  "& .MuiTab-iconWrapper": {
                    mb: 0,
                    mr: "0.25rem",
                  },
                }}
              />
            ))}
          </TabList>

          <Box mt={3}>
            <TabPanel sx={{ p: 0 }} value="info">
              <ViewInfo data={orderStatus} />
            </TabPanel>
          </Box>
        </TabContext>
      </Grid>

      <OrderStatusFormDialog
        title={t("Update order status")}
        form={editOrderStatusForm}
        open={editOrderStatusOpen}
        toggle={toggleEditDialog}
        onSubmit={handleEditOrderStatus}
        type="edit"
      />
    </Grid>
  );
};

export default ViewOrderStatusScreen;
