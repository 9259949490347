import { useEffect, MutableRefObject } from "react";

// Custom hook to control spread wallpaper styles

export function useSpreadWallpaperStyles(
  containerRef: MutableRefObject<HTMLDivElement | null>,
  isCropMode: boolean,
  spreadWallpaper: boolean,
  doorsToSpread: number,
  previewWallpaperSpread: boolean
) {
  useEffect(() => {
    const cropContainerElement = containerRef.current;

    if (isCropMode && cropContainerElement) {
      // This is run after a timeout to allow DOM fully update
      // Then it looks for the crop grid to be styled
      const runCropGridQuerySelect = () => {
        const cropperElement = cropContainerElement.querySelector(
          '[data-testid="cropper"]'
        );

        // FOR some reason its taking a while to get cropperElement
        // so appears as null when it is actually there
        if (cropperElement) {
          const currentCropperStyle = cropperElement.getAttribute("style");

          // Add spread wallpaper styles
          if (spreadWallpaper && previewWallpaperSpread) {
            const numberOfLines = doorsToSpread;
            const interval = 100 / numberOfLines;

            const BORDER_COLOR = "#F4A600";
            const BORDER_WIDTH = "3px";

            const newCropperStyle = `
              ${currentCropperStyle || ""}
              border-color: ${BORDER_COLOR};
              border-width: ${BORDER_WIDTH};
            `;

            cropperElement.setAttribute("style", newCropperStyle);

            // Remove any existing lines when the number of lines changes
            while (cropperElement.firstChild) {
              cropperElement.removeChild(cropperElement.firstChild);
            }

            for (let i = 1; i < numberOfLines; i++) {
              const spreadElement = document.createElement("div");

              const spreadElementStyle = `
                width: ${BORDER_WIDTH};
                background-color: ${BORDER_COLOR};
                height: 100%;
                position: absolute;
                top: 0;
                left: ${interval * i}%;
              `;
              spreadElement.setAttribute("style", spreadElementStyle);

              // Add the spreadElement to the cropperElement
              cropperElement.appendChild(spreadElement);
            }
          }

          // Remove spread wallpaper styles
          if (!spreadWallpaper || !previewWallpaperSpread) {
            const prevCropperStyle = `
              ${currentCropperStyle || ""}
              border-width: 1px;
              border-color: rgba(255, 255, 255, 0.5);
            `;

            cropperElement.setAttribute("style", prevCropperStyle);

            // Also, remove any existing lines
            while (cropperElement.firstChild) {
              cropperElement.removeChild(cropperElement.firstChild);
            }
          }
        }
      };

      setTimeout(runCropGridQuerySelect, 200);
    }
  }, [
    containerRef,
    isCropMode,
    spreadWallpaper,
    doorsToSpread,
    previewWallpaperSpread,
  ]);
}
