// ** React Imports
import { Fragment, useState } from "react";

// ** MUI Imports
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

// ** Icons Imports
import Close from "mdi-material-ui/Close";
import FileDocumentOutline from "mdi-material-ui/FileDocumentOutline";

// ** Third Party Components
import { useDropzone } from "react-dropzone";
import axios from "axios";

import { useAlertContext } from "../../../hooks/useAlertContext";

import { UseFormSetValue } from "react-hook-form";
import { Spinner } from "../../utilities/spinner";
import { ProductFormT } from "../../../types/productTypes";
import Translations from "../../layouts/components/Translations";
import { useTranslation } from "react-i18next";
import FileUploader from "../../utilities/file-uploader";
import { useSettings } from "../../../hooks/useSettings";

interface FileProp {
  name: string;
  type: string;
  size: number;
}

interface Props {
  setValue: UseFormSetValue<ProductFormT>;
}

const FileUploaderRestrictions = ({ setValue }: Props) => {
  // ** State
  const [files, setFiles] = useState<File[]>([]);
  const [uploading, setUploading] = useState(false);

  // ** Hooks
  const { t } = useTranslation();
  const { settings } = useSettings();
  const { configuredCompany } = settings;
  const isVariobelOnlyField = configuredCompany === "variobel";

  const { showSuccessAlert, showErrorAlert } = useAlertContext();
  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    maxSize: 2000000,

    accept: {
      "image/jpeg": [],
      "image/png": [],
    },

    onDrop: (acceptedFiles: File[]) => {
      setFiles(acceptedFiles.map((file: File) => Object.assign(file)));
    },

    onDropRejected: () => {
      showErrorAlert("You can only upload 1 file with a maximum size of 2 MB.");
    },
  });


  const handleRemoveFile = (file: FileProp) => {
    const uploadedFiles = files;
    const filtered = uploadedFiles.filter(
      (i: FileProp) => i.name !== file.name
    );
    setFiles([...filtered]);
  };

  const handleRemoveAllFiles = () => {
    setFiles([]);
  };

  const handleImageUpload = async () => {
    setUploading(true);

    const form = new FormData();
    let companyName = "variobel";

    if (!isVariobelOnlyField) companyName = "porezlamina"

    files.forEach((image) => {
      form.append("image", image);
      form.append("folder", "products");
      form.append("companyName", companyName);
    });

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_SERVER_HOST}/upload/single`,
      data: form,
      timeout: 180000,
    };

    axios(config)
      .then((res) => {
        const result = res.data;

        if (result.url) {
          if (isVariobelOnlyField) {
            setValue("images", [result.url]);
          } else {
            setValue("image", result.url)
          }

          showSuccessAlert("Images Uploaded!");
          setUploading(false);

          handleRemoveAllFiles();

        }
      })
      .catch((err) => {
        console.error(err);

        showErrorAlert("Error during Upload! Check console");
        setUploading(false);
      });
  };

  const renderFilePreview = (file: FileProp) => {
    if (file.type.startsWith("image")) {
      return (
        <img
          width={38}
          height={38}
          alt={file.name}
          src={URL.createObjectURL(file as any)}
        />
      );
    } else {
      return <FileDocumentOutline />;
    }
  };

  const renderFileList = files.map((file: FileProp) => (
    <ListItem key={file.name}>
      <div className="file-details">
        <div className="file-preview">{renderFilePreview(file)}</div>
        <div>
          <Typography className="file-name">{file.name}</Typography>
          <Typography className="file-size" variant="body2">
            {Math.round(file.size / 100) / 10 > 1000
              ? `${(Math.round(file.size / 100) / 10000).toFixed(1)} mb`
              : `${(Math.round(file.size / 100) / 10).toFixed(1)} kb`}
          </Typography>
        </div>
      </div>
      <IconButton onClick={() => handleRemoveFile(file)}>
        <Close fontSize="small" />
      </IconButton>
    </ListItem>
  ));

  if (uploading) return <Spinner />;

  return (
    <Fragment>
      <FileUploader
        {...{
          getRootProps,
          getInputProps,
          allowedFilesText: t("Allowed *.jpeg, *.jpg, *.png"),
          mainText: ("Drag and drop image or click to select image.") ,
          maxFileSizeText: t("One file with a max size of 2 MB."),
        }}
      />

      {files.length ? (
        <Fragment>
          <List>{renderFileList}</List>

          <div className="buttons">
            <Button
              color="error"
              variant="outlined"
              onClick={handleRemoveAllFiles}
            >
              <Translations text="Odstrániť všetko" />
            </Button>

            <Button variant="contained" onClick={handleImageUpload}>
              <Translations text="Upload súbory" />
            </Button>
          </div>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default FileUploaderRestrictions;
