import React, { MutableRefObject } from "react";
import { UseFormSetValue } from "react-hook-form";
import { TFunction } from "react-i18next";
import { Editor as EditorField } from "@tinymce/tinymce-react";

import insertedFields from "../../../utils/insertedStatusMailFields";

import { OrderStatusData } from "../../../types/orderStatusTypes";

const tinymcePlugins = [
  "advlist",
  "autolink",
  "lists",
  "link",
  "image",
  "charmap",
  "preview",
  "anchor",
  "searchreplace",
  "visualblocks",
  "fullscreen",
  "insertdatetime",
  "media",
  "table",
  "help",
  "wordcount",
];

const tinymceToolbar =
  "undo redo | casechange blocks fieldInsert | bold italic backcolor | alignleft aligncenter alignright alignjustify | image link code preview | " +
  "outdent indent | bullist numlist checklist | removeformat | a11ycheck table help |";

const tinymceContentStyle ="body { font-family: Helvetica, Arial, sans-serif; font-size:14px }";

interface Props {
  editorRef: MutableRefObject<any>;
  value: string;
  setValue: UseFormSetValue<OrderStatusData>;
  showInfoAlert:  (message: string, duration?: number | undefined) => void;
  t: TFunction<"translation", undefined>;
}

const EmailTemplateField = (props: Props) => {
  const { editorRef, value, setValue, showInfoAlert, t } = props;

  const handleImageSelect = (
    callback: (value: string, meta?: Record<string, any>) => void,
    value: string,
    meta: Record<string, any>
  ) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");

    input.addEventListener("change", (e) => {
      // @ts-ignore
      const file = (e.target as HTMLInputElement).files[0];

      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const id = "blobid" + new Date().getTime();
        const blobCache = editorRef.current.editorUpload.blobCache;
        if (reader.result) {
          const base64 = (reader.result as string).split(",")[1];
          const blobInfo = blobCache.create(id, file, base64);
          blobCache.add(blobInfo);

          /* call the callback and populate the Title field with the file name */
          callback(blobInfo.blobUri(), { title: file.name });
        }
      });
      reader.readAsDataURL(file);
    });

    input.click();
  };

  // editor type(Editor) found in client\node_modules\tinymce\tinymce.d.ts
  const addInsertedFieldsDropdown = (editor: any) => {
    editor.ui.registry.addSplitButton("fieldInsert", {
      text: "Insert",
      tooltip: "Insert Template Field",
      onAction: (_: any) => showInfoAlert("Please choose option from dropdown"),
      onItemAction: (buttonApi: any, value: any) => editor.insertContent(value),
      fetch: (callback: any) => {
        const items = insertedFields
          .filter((field) => field.field === "template")
          .map((field) => ({
            ...field,
            type: "choiceitem",
            text: t(field.text),
          }));

        callback(items as any);
      },
    });
  }

  return (
    <EditorField
      apiKey={process.env.REACT_APP_TINY_API_KEY}
      onInit={(evt, editor) => (editorRef.current = editor)}
      value={value}
      onEditorChange={(newValue, editor) => setValue("template", newValue)}
      init={{
        height: 500,
        menubar: false,
        plugins: tinymcePlugins,
        toolbar: tinymceToolbar,
        image_title: true,
        automatic_uploads: true,
        file_picker_types: "image",
        file_picker_callback: handleImageSelect,
        content_style: tinymceContentStyle,

        setup: (editor) => {
          addInsertedFieldsDropdown(editor)
        },
      }}
    />
  );
};

export default EmailTemplateField;
