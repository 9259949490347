import { TabList, TabListProps } from '@mui/lab';
import { alpha } from '@mui/material';
import React from 'react'

const TabSelector = (props: TabListProps) => {
  const { sx, ...rest } = props;

  return (
    <TabList
      sx={{
        border: (theme) => `1px solid ${theme.palette.primary.main}`,
        borderRadius: 2,

        "& .MuiTab-root": {
          textTransform: "none",
          fontWeight: 600,
          p: "8px 16px",

          "&.Mui-selected": {
            backgroundColor: (theme) => alpha(theme.palette.secondary.light, 0.2),
          }
        },

        "& .MuiTabs-indicator": {
          height: 0,
        },

        ...sx
      }}
      {...rest}
    />
  );
};

export default TabSelector;