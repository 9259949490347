// ** React Imports
import {
  Fragment,
  useState,
  SyntheticEvent,
  ReactNode /* , useEffect */,
  MouseEvent,
  ChangeEvent,
} from "react";

// ** MUI Imports
import {
  Box,
  List,
  Menu,
  Input,
  Avatar,
  Divider,
  Tooltip,
  Backdrop,
  MenuItem,
  IconButton,
  Typography,
  CircularProgress,
  InputAdornment,
  ListItem,
  ListItemProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// ** Icons Import
import MenuIcon from "mdi-material-ui/Menu";
import Circle from "mdi-material-ui/Circle";
import Reload from "mdi-material-ui/Reload";
import Magnify from "mdi-material-ui/Magnify";
import EmailOutline from "mdi-material-ui/EmailOutline";
import DotsVertical from "mdi-material-ui/DotsVertical";
import LabelOutline from "mdi-material-ui/LabelOutline";
import FolderOutline from "mdi-material-ui/FolderOutline";
import PencilOutline from "mdi-material-ui/PencilOutline";
import DeleteOutline from "mdi-material-ui/DeleteOutline";
// import EmailOpenOutline from 'mdi-material-ui/EmailOpenOutline'
import AlertCircleOutline from "mdi-material-ui/AlertCircleOutline";
import AlertOctagonOutline from "mdi-material-ui/AlertOctagonOutline";

// ** Third Party Imports
import PerfectScrollbar from "react-perfect-scrollbar";

import MailDetails from "./MailDetails";

// ** Types
import {
  MailLogType,
  MailFoldersArrType,
  MailFoldersObjType,
} from "../../../types/emailTypes";
import { RecentMessagesType } from "../../../types/mailAccountsType";
import { TablePagination } from "@mui/material";
import relativeTime from "../../../helpers/getRelativeTime";
import { useTranslation } from "react-i18next";

const MailItem = styled(ListItem)<ListItemProps>(({ theme }) => ({
  zIndex: 1,
  cursor: "pointer",
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("xs")]: {
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
  },
  [theme.breakpoints.up("sm")]: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
}));

const ScrollWrapper = ({
  children,
  hidden,
}: {
  children: ReactNode;
  hidden: boolean;
}) => {
  if (hidden) {
    return (
      <Box sx={{ height: "100%", overflowY: "auto", overflowX: "hidden" }}>
        {children}
      </Box>
    );
  } else {
    return (
      <PerfectScrollbar
        options={{ wheelPropagation: false, suppressScrollX: true }}
      >
        {children}
      </PerfectScrollbar>
    );
  }
};

const MailLog = (props: MailLogType) => {
  // ** Props
  const {
    setComposeInitialValues,
    toggleComposeOpen,
    isLoading,
    store,
    connectedImapAccounts,
    selectedEmails,
    query,
    setQuery,
    mailPage,
    setMailPage,
    mailsPerPage,
    setMailsPerPage,
    totalMessages,
    hidden,
    lgAbove,
    dispatch,
    labelColors,
    getCurrentMail,
    mailDetailsOpen,
    setMailDetailsOpen,
    handleLeftSidebarToggle,
  } = props;

  const { t } = useTranslation();

  // ** State
  const [labelAnchorEl, setLabelAnchorEl] = useState<null | HTMLElement>(null);
  const [folderAnchorEl, setFolderAnchorEl] = useState<null | HTMLElement>(
    null
  );

  // ** Vars
  const openLabelMenu = Boolean(labelAnchorEl);
  const openFolderMenu = Boolean(folderAnchorEl);

  const foldersConfig = {
    draft: {
      name: "draft",
      icon: <PencilOutline fontSize="small" sx={{ mr: 2 }} />,
    },
    spam: {
      name: "spam",
      icon: <AlertOctagonOutline fontSize="small" sx={{ mr: 2 }} />,
    },
    trash: {
      name: "trash",
      icon: <DeleteOutline fontSize="small" sx={{ mr: 2 }} />,
    },
    inbox: {
      name: "inbox",
      icon: <EmailOutline fontSize="small" sx={{ mr: 2 }} />,
    },
  };

  const foldersObj: MailFoldersObjType = {
    inbox: [foldersConfig.spam, foldersConfig.trash],
    sent: [foldersConfig.trash],
    draft: [foldersConfig.trash],
    spam: [foldersConfig.inbox, foldersConfig.trash],
    trash: [foldersConfig.inbox, foldersConfig.spam],
  };

  const handleLabelMenuClick = (event: SyntheticEvent) => {
    setLabelAnchorEl(event.currentTarget as HTMLElement);
  };
  const handleLabelMenuClose = () => {
    setLabelAnchorEl(null);
  };

  const handleFolderMenuClick = (event: SyntheticEvent) => {
    setFolderAnchorEl(event.currentTarget as HTMLElement);
  };
  const handleFolderMenuClose = () => {
    setFolderAnchorEl(null);
  };
  const handleRefreshMailsClick = () => {
    console.log("click refresh");
  };

  const renderLabelsMenu = () => {
    return Object.entries(labelColors).map(([key, value]: string[]) => {
      return (
        <MenuItem
          key={key}
          sx={{ display: "flex", alignItems: "center" }}
          onClick={() => {
            // handleLabelUpdate(connectedImapAccounts.selectedMails, key as MailLabelType)
            handleLabelMenuClose();
            // dispatch(handleSelectAllMail(false))
          }}
        >
          <Circle sx={{ mr: 2, fontSize: "0.75rem", color: `${value}.main` }} />
          <Typography sx={{ textTransform: "capitalize" }}>{key}</Typography>
        </MenuItem>
      );
    });
  };

  const renderFoldersMenu = () => {
    return foldersObj["inbox"].map((folder: MailFoldersArrType) => {
      return (
        <MenuItem
          key={folder.name}
          sx={{ display: "flex", alignItems: "center" }}
          onClick={() => {
            // handleFolderUpdate(connectedImapAccounts.selectedMails, folder.name)
            handleFolderMenuClose();
            // dispatch(handleSelectAllMail(false))
          }}
        >
          {folder.icon}
          <Typography sx={{ textTransform: "capitalize" }}>
            {folder.name}
          </Typography>
        </MenuItem>
      );
    });
  };

  const handleChangeMailPage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setMailPage(newPage);
  };

  const handleChangeMailsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setMailsPerPage(parseInt(event.target.value, 10));
    setMailPage(0);
  };

  const mailDetailsProps = {
    setComposeInitialValues,
    toggleComposeOpen,
    hidden,
    // folders,
    // dispatch,
    // direction,
    foldersObj,
    // updateMail,
    // routeParams,
    labelColors,
    // paginateMail,
    // handleStarMail,
    mailDetailsOpen,
    // handleLabelUpdate,
    // handleFolderUpdate,
    setMailDetailsOpen,
    mail: store && store.currentMail ? store.currentMail : null,
  };

  return (
    <Box
      sx={{
        width: "100%",
        overflow: "hidden",
        position: "relative",
        "& .ps__rail-y": { zIndex: 5 },
      }}
    >
      <Box sx={{ height: "100%", backgroundColor: "background.paper" }}>
        {/* SEARCH BAR */}
        <Box sx={{ px: 5, py: 3 }}>
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            {lgAbove ? null : (
              <IconButton
                onClick={handleLeftSidebarToggle}
                sx={{ mr: 1, ml: -2 }}
              >
                <MenuIcon fontSize="small" />
              </IconButton>
            )}
            <Input
              fullWidth
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder={`${t("Search")} email`}
              sx={{ width: "100%", "&:before, &:after": { display: "none" } }}
              startAdornment={
                <InputAdornment
                  position="start"
                  sx={{ color: "text.disabled" }}
                >
                  <Magnify sx={{ fontSize: "1.375rem" }} />
                </InputAdornment>
              }
            />
          </Box>
        </Box>

        <Divider sx={{ m: 0 }} />

        {/* TOP OPTIONS */}
        <Box sx={{ py: 1.75, px: { xs: 2.5, sm: 5 } }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {connectedImapAccounts ? (
                <Fragment>
                  <IconButton>
                    <DeleteOutline />
                  </IconButton>

                  <IconButton>
                    <EmailOutline />
                  </IconButton>

                  <IconButton onClick={handleFolderMenuClick}>
                    <FolderOutline />
                  </IconButton>

                  <IconButton onClick={handleLabelMenuClick}>
                    <LabelOutline />
                  </IconButton>

                  <Menu
                    open={openLabelMenu}
                    anchorEl={labelAnchorEl}
                    onClose={handleLabelMenuClose}
                    PaperProps={{ style: { minWidth: "9rem" } }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    {renderLabelsMenu()}
                  </Menu>

                  <Menu
                    open={openFolderMenu}
                    anchorEl={folderAnchorEl}
                    onClose={handleFolderMenuClose}
                    PaperProps={{ style: { minWidth: "9rem" } }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    {renderFoldersMenu()}
                  </Menu>
                </Fragment>
              ) : null}
            </Box>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton size="small" onClick={handleRefreshMailsClick}>
                <Reload sx={{ color: "text.disabled", fontSize: "1.375rem" }} />
              </IconButton>
              <IconButton size="small">
                <DotsVertical
                  sx={{ color: "text.disabled", fontSize: "1.375rem" }}
                />
              </IconButton>
            </Box>
          </Box>
        </Box>

        <Divider sx={{ m: 0 }} />

        {/* EMAIL LIST */}
        <Box
          sx={{
            p: 0,
            position: "relative",
            overflowX: "hidden",
            height: "calc(100% - 165px)",
          }}
        >
          <ScrollWrapper hidden={hidden}>
            {connectedImapAccounts && selectedEmails ? (
              <List sx={{ p: 0 }}>
                {connectedImapAccounts
                  .filter((acc, i) => selectedEmails.includes(acc.email))
                  .map((acc, accIndex, accArray) => {
                    if (
                      !acc.recentMessages ||
                      acc.recentMessages.length === 0
                    ) {
                      return (
                        <Box
                          key={accIndex}
                          sx={{
                            mt: 6,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <AlertCircleOutline fontSize="small" sx={{ mr: 2 }} />
                          <Typography>No Mails Found</Typography>
                        </Box>
                      );
                    }

                    return (
                      <>
                        {acc.recentMessages.map(
                          (
                            mail: RecentMessagesType,
                            index: number,
                            recentMessages
                          ) => {
                            const MailReadToggleIcon = EmailOutline;
                            // const MailReadToggleIcon = mail.isRead ? EmailOutline : EmailOpenOutline

                            return (
                              <Box
                                key={index}
                                sx={{
                                  transition: "all 0.15s ease-in-out",
                                  pt: 1,

                                  "&:hover": {
                                    zIndex: 2,
                                    boxShadow: "3",
                                    transform: "translateY(-2px)",
                                    "& .mail-info-right": {
                                      display: "none",
                                    },
                                    "& .mail-actions": {
                                      display: "flex",
                                    },
                                  },
                                }}
                              >
                                <MailItem
                                  sx={{
                                    pt: 1.75,
                                    pb: 2.75,
                                    backgroundColor:
                                      /* mail.isRead ? 'action.hover' : */ "background.paper",
                                  }}
                                  onClick={() => {
                                    setMailDetailsOpen(true);
                                    dispatch(
                                      getCurrentMail({
                                        id: mail.uid,
                                        selectedEmail: acc.email,
                                      })
                                    );
                                  }}
                                >
                                  <Box
                                    sx={{
                                      mr: 4,
                                      display: "flex",
                                      overflow: "hidden",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Avatar
                                      sx={{
                                        mr: 3.5,
                                        width: "2rem",
                                        height: "2rem",
                                      }}
                                    />
                                    <Box
                                      sx={{
                                        display: "flex",
                                        overflow: "hidden",
                                        flexDirection: {
                                          xs: "column",
                                          sm: "row",
                                        },
                                        alignItems: {
                                          xs: "flex-start",
                                          sm: "center",
                                        },
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          mr: 4,
                                          fontWeight: 600,
                                          whiteSpace: "nowrap",
                                          width: ["100%", "auto"],
                                          overflow: ["hidden", "unset"],
                                          textOverflow: ["ellipsis", "unset"],
                                        }}
                                      >
                                        {mail.from.split("<")[0]}
                                      </Typography>
                                      <Typography
                                        noWrap
                                        variant="body2"
                                        sx={{ width: "100%" }}
                                      >
                                        {mail.subject}
                                      </Typography>
                                    </Box>
                                  </Box>

                                  <Box
                                    className="mail-actions"
                                    sx={{
                                      display: "none",
                                      alignItems: "center",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <Tooltip
                                      placement="top"
                                      title="Delete Mail"
                                    >
                                      <IconButton>
                                        <DeleteOutline />
                                      </IconButton>
                                    </Tooltip>

                                    <Tooltip
                                      placement="top"
                                      title="Mark as Read/Unread"
                                    >
                                      <IconButton>
                                        <MailReadToggleIcon />
                                      </IconButton>
                                    </Tooltip>

                                    <Tooltip
                                      placement="top"
                                      title="Move to Spam"
                                    >
                                      <IconButton>
                                        <AlertOctagonOutline />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>

                                  <Box
                                    className="mail-info-right"
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <Typography
                                      variant="caption"
                                      sx={{
                                        minWidth: "50px",
                                        textAlign: "right",
                                        lineHeight: ".95rem",
                                        color: "text.disabled",
                                      }}
                                    >
                                      {`${relativeTime(
                                        new Date(),
                                        new Date(mail.date)
                                      )}`}
                                    </Typography>
                                  </Box>
                                </MailItem>

                                {recentMessages !== null &&
                                recentMessages.length - 1 > index ? (
                                  <Divider sx={{ my: 0, mx: -5 }} />
                                ) : accIndex !== accArray.length - 1 ? (
                                  <Divider sx={{ my: 0, mx: -5 }}>
                                    <Typography variant="body2" color="primary">
                                      END
                                    </Typography>
                                  </Divider>
                                ) : (
                                  <></>
                                )}
                              </Box>
                            );
                          }
                        )}
                      </>
                    );
                  })}
              </List>
            ) : (
              <Box
                sx={{
                  mt: 6,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <AlertCircleOutline fontSize="small" sx={{ mr: 2 }} />
                <Typography>No Account(s) Connected</Typography>
              </Box>
            )}
          </ScrollWrapper>

          <Backdrop
            open={isLoading}
            sx={{
              zIndex: 5,
              position: "absolute",
            }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>

        <Divider sx={{ m: 0 }} />

        {/* PAGINATION */}
        <Box>
          <TablePagination
            component="div"
            count={totalMessages}
            page={mailPage}
            rowsPerPage={mailsPerPage}
            onPageChange={handleChangeMailPage}
            onRowsPerPageChange={handleChangeMailsPerPage}
            labelRowsPerPage={`${t("Mails Per Page")}:`}
          />
        </Box>
      </Box>

      <MailDetails {...mailDetailsProps} />
    </Box>
  );
};

export default MailLog;
