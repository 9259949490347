import {
  Box,
  Alert,
  Tab,
} from "@mui/material";

import {
  useCallback,
  ReactNode,
} from "react";
import CropBox from "./crop-box";

import { TabSubmitButton } from "../../../tab-submit-button";
import WallpaperTransforms from "./wallpaper-transforms";
import ImageFileSelectWrapper from "./image-file-select-wrapper";
import DisplayNoImageSelected from "./display-no-image-selected";
import { WallpaperImages } from "./wallpaper-images";
import { TabContext, TabPanel } from "@mui/lab";
import TabSelector from "../../tab-selector";
import { WallpaperSelectBaseContentWrapper } from "./wallpaper-select-base-content-wrapper";
import { ICrop } from "../../../types";
import { AddOrderListLineItemType, LineItemType } from "../../../../../../../types/orderTypes";
import WallpaperSelectBaseOverlay from "./wallpaper-select-base-overlay";
import { customWallpaperStateI, localWallpaperStateI, updateCustomWallpaperState, updateLocalWallpaperState } from "../../../../../../../store/apps/print-wallpaper";
import { useDispatch } from "react-redux";
import useWallapaperSelectBase from "./wallpaper-select-base/useWallapaperSelectBase";

interface Props extends ICrop, Partial<localWallpaperStateI & customWallpaperStateI> {
  selectedLineItem: LineItemType | AddOrderListLineItemType;
  children: ReactNode;
  isRerouted?: boolean;
  isEmpty?:  boolean;
}

const WallpaperSelectBase = (props: Props) => {
  const {
    isRerouted,
    isEmpty, // used by custom wallpaper
    isCropMode,

    zoom,
    selectedLineItem,
    selectCroppedImage,
    preview,
    type = "custom",
    children,
    localWallpapers,
    wallpaper,
    rotation
  } = props;

  const dispatch = useDispatch();

  const handleWallpaperUpdate = useCallback((data: any) => {
    if (type === "manual") {
      dispatch(updateLocalWallpaperState(data));
    } else {
      dispatch(updateCustomWallpaperState(data));
    }
  }, [dispatch, type]);

  const baseProps = {
    type,
    rotation: rotation as number,

    // selectedID,
    selectedLineItem,

    selectCroppedImage,
    isCropMode: isCropMode as boolean,
    preview,
  };

  const {
    containerRef,
    tabs,
    tab,
    flip,
    isSelectedImageClear,
    isOrderItemDimensionsError,
    handleTabChange,
    handleOrderItemDimensionsChange,
    spreadWallpaper,
    handleSpreadWallaperChange,
    previewWallpaperSpread,
    handlePreviewWallpaperSpreadChange,
    orderItemDimensions,
    doorsToSpread,
    handleZoom,
    flipHorizontal,
    flipVertical,
    rotateRight,
    rotateLeft,
    handleDoorsSpreadSlider,
    handleCroppedImageSelect,
    onCropComplete,
  } = useWallapaperSelectBase(baseProps);

  const cropBoxProps = {
    file: preview,
    onCropComplete,
    flip,
    type,
    ...props,
  };

  const wallpaperTransformProps= {
    zoom,
    handleZoom,
    flipHorizontal,
    flipVertical,
    rotateLeft,
    rotateRight,
    spreadWallpaper,
    handleSpreadWallaperChange,
    previewWallpaperSpread,
    handlePreviewWallpaperSpreadChange,
    handleDoorsSpreadSlider,
    orderItemDimensions,
    isOrderItemDimensionsError,
    handleOrderItemDimensionsChange,
    doorsToSpread
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
          justifyContent: { xs: "flex-end", md: "flex-start" },
          gap: 1,
        }}
      >
        <Box
          sx={{
            flexBasis: "33%",
            position: "relative",
            width: "100%",
          }}
        >
          {!isCropMode && (
            <WallpaperSelectBaseOverlay {...{ handleWallpaperUpdate }} />
          )}

          <Box
            sx={{
              ...(!isCropMode && {
                pointerEvents: "none",
              }),
            }}
          >
            <TabContext value={tab}>
              <TabSelector
                onChange={handleTabChange}
                sx={{
                  width: "fit-content",
                  position: { md: "absolute" },
                  transform: { md: "translateY(calc(-100% - 24px))" },
                  mb: { xs: 1, md: 0 },
                }}
              >
                {tabs.map((tab) => (
                  <Tab
                    key={tab.label}
                    disableRipple
                    label={tab.label}
                    value={tab.value}
                  />
                ))}
              </TabSelector>

              <WallpaperSelectBaseContentWrapper>
                <TabPanel value="transform">
                  <WallpaperTransforms {...wallpaperTransformProps} />
                </TabPanel>

                <TabPanel value="images" sx={{ p: 0 }}>
                  {localWallpapers && (
                    <WallpaperImages {...{ wallpaper, localWallpapers }} />
                  )}
                </TabPanel>
              </WallpaperSelectBaseContentWrapper>
            </TabContext>
          </Box>
        </Box>

        <Box sx={{ flex: 1 }}>
          <WallpaperSelectBaseContentWrapper
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              gap: 1,
            }}
          >
            <Box
              ref={containerRef}
              sx={{
                minWidth: { xs: "100%", md: "600px" },
                height: "350px",
                position: "relative",
                display: "grid",
                placeItems: "center",
              }}
            >
              {/*BUG: Not working as a separate component */}
              {/* DisplayNoImageSelected onChange doesnt work */}

              {/* SOLN: this method works but is less clear */}
              {isEmpty ? (
                <DisplayNoImageSelected />
              ) : isRerouted || !isCropMode ? (
                children
              ) : (
                <CropBox {...cropBoxProps} />
              )}
            </Box>
          </WallpaperSelectBaseContentWrapper>

          {type === "custom" && !isSelectedImageClear && (
            <Alert
              severity="warning"
              sx={{ fontSize: "small", justifyContent: "center", mt: 2 }}
            >
              Minimálne požiadavky na veľkosť obrázka na tlač je:{" "}
              <b>5000px na výšku a 300DPI</b>
            </Alert>
          )}
        </Box>
      </Box>

      {isCropMode && (
        <Box display="flex" gap={1} mt={2} justifyContent="flex-end">
          {type === "custom" && (
            <ImageFileSelectWrapper>
              <TabSubmitButton variant="outlined" component="span">
                Nahradiť
                {/* replace image */}
              </TabSubmitButton>
            </ImageFileSelectWrapper>
          )}

          <TabSubmitButton
            variant="contained"
            onClick={handleCroppedImageSelect}
          >
            Ok
          </TabSubmitButton>
        </Box>
      )}
    </>
  );
};

export default WallpaperSelectBase;
