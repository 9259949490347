interface Props { src: string; }

const DisplayImageAfterCrop = (props: Props) => {
  const { src } = props;

  return (
    <img
      src={src}
      alt="selected wallpaper"
      style={{
        width: "100%",
        maxWidth: "600px",
        maxHeight: "350px",
        objectFit: "contain",
      }}
    />
  );
};

export default DisplayImageAfterCrop;
