import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, Grid } from "@mui/material";
import { DataGrid, GridColumns, GridRowId } from "@mui/x-data-grid";
import { ProductCategoryT } from "../../types/product-category-type";
import { CategoriesTableHeader } from "../../comps/products/categories/categories-table-header";
import { useTranslation } from "react-i18next";
import getCategoriesTableColumns from "../../comps/products/categories/categories-table-columns";
import { AppDispatch, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteProductCategory,
  fetchProductCategories,
  getProductsInCategory,
} from "../../store/apps/product-categories";
import ProductCategoriesForm from "../../comps/products/categories/categories-form";
import DeleteProductCategoryDialog from "../../comps/products/categories/delete-product-category-dialog";
import { useAlertContext } from "../../hooks/useAlertContext";
import { ProductType } from "../../types/productTypes";
import { useAbility } from "@casl/react";
import { AbilityContext } from "../../comps/layouts/components/acl/Can";

const ProductCategoriesScreen = () => {
  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [openForm, setOpenForm] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [mode, setMode] = useState<"add" | "edit">("add");
  const [selectedCategory, setSelectedCategory] =
    useState<ProductCategoryT | null>(null);
  const [selectedCategoryProducts, setSelectedCategoryProducts] = useState<
    ProductType[]
  >([]);

  const editableFormValues = useMemo(() => {
    if (!selectedCategory) return;
    const { _id, ...rest } = selectedCategory;

    return {
      ...rest,
      products: selectedCategoryProducts,
    };
  }, [selectedCategory, selectedCategoryProducts]);

  const { t } = useTranslation();
  const { showErrorAlert, showSuccessAlert } = useAlertContext();
  const dispatch = useDispatch<AppDispatch>();
  const store = useSelector((state: RootState) => state.productCategories);
  const ability = useAbility(AbilityContext);

  // get table data
  useEffect(() => {
    const getCategories = async () => {
      setIsLoading(true);
      await dispatch(fetchProductCategories());
      setIsLoading(false);
    };

    getCategories();
  }, [dispatch]);

  useEffect(() => {
    if (!selectedCategory) return;

    const getSelectedCategoryProducts = async () => {
      await dispatch(getProductsInCategory(selectedCategory._id))
        .unwrap()
        .then((res: any) => {
          const products = res.products as ProductType[];
          setSelectedCategoryProducts(products);
        });
    };

    getSelectedCategoryProducts();
  }, [dispatch, selectedCategory]);

  const toggleFormOpen = () => setOpenForm((prev) => !prev);
  const toggleDeleteOpen = () => setDeleteOpen((prev) => !prev);
  const toggleEditOpen = useCallback(() => {
    toggleFormOpen();
    setMode("edit");
  }, []);

  const handleDelete = async () => {
    if (!selectedCategory) return;

    setIsDeleting(true);
    await dispatch(deleteProductCategory(selectedCategory._id))
      .unwrap()
      .then(() => {
        showSuccessAlert(t("Category deleted"));
      })
      .catch(() => {
        showErrorAlert("Failed to delete category");
      })
      .finally(() => {
        setIsDeleting(false);
        toggleDeleteOpen();
      });
  };

  const columns = useMemo<GridColumns<ProductCategoryT>>(
    () =>
      getCategoriesTableColumns({
        t,
        setSelectedCategory,
        toggleDeleteOpen,
        toggleEditOpen,
        ability
      }),
    [t, toggleEditOpen, ability]
  );

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Card>
          <CategoriesTableHeader
            {...{ selectedRows, setSelectedRows, toggleFormOpen, setMode }}
          />

          <DataGrid
            autoHeight
            rows={store.data}
            getRowId={(row) => row._id}
            columns={columns}
            disableSelectionOnClick
            checkboxSelection
            selectionModel={selectedRows}
            onSelectionModelChange={(rows) => setSelectedRows(rows)}
            loading={isLoading}
            pagination
            rowsPerPageOptions={[10, 25, 50]}
            pageSize={Number(pageSize)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
          />
        </Card>
      </Grid>

      <ProductCategoriesForm
        open={openForm}
        handleClose={toggleFormOpen}
        type={mode}
        editID={selectedCategory ? selectedCategory._id : undefined}
        editableValues={selectedCategory ? editableFormValues : undefined}
      />

      {selectedCategory && (
        <>
          <DeleteProductCategoryDialog
            open={deleteOpen}
            handleClose={toggleDeleteOpen}
            handleDelete={handleDelete}
            item={selectedCategory.name}
            isDeleting={isDeleting}
          />
        </>
      )}
    </Grid>
  );
};

export default ProductCategoriesScreen;
