import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  MouseEvent,
  ChangeEvent,
} from "react";
import { useParams } from "react-router-dom";

// ** MUI Imports
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Backdrop,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  SelectChangeEvent,
  OutlinedInput,
  TablePagination,
} from "@mui/material";


import { sortByKey } from "../../../helpers/sortByKey";
import { getInitials } from "../../../helpers/get-initials";

import AddTimelineEntryCard from "../../../comps/AddTimelineEntryCard";
import { Avatar } from "../../../comps/avatar";
import CustomerEntryTimelineItem from "../../../comps/customers/TimelineItems/customerEntryTimelineItem";
import EmailTimelineItem from "../../../comps/customers/TimelineItems/emailTimelineItem";
import OrderRelatedTimelineItem from "../../../comps/customers/TimelineItems/orderRelatedTimelineItem";

import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../../store/apps/orderStatus";
import { fetchData as fetchAdminData } from "../../../store/apps/admin";
import { fetchImapConnections } from "../../../store/apps/email";
import { editCustomer, fetchCustomer } from "../../../store/apps/customer";

// Types
import {
  OrderType,
} from "../../../types/orderTypes";
import { AppDispatch, RootState } from "../../../store";
import {
  Customer,
  CustomerTimelineDataType,
  CustomerTimelineEmailsType,
  CustomerOrderHistoryistoryDataType,
  CustomerOrderCreateDataType,
  CustomerOrderManualEntriesDataType,
  CustomerTimelineStateType
} from "../../../types/customerTypes";
import {
  TimelineEntriesType,
  ValuesType,
} from "../../../types/customTimelineTypes";

import axios from "axios";
import { FormikHelpers } from "formik";

import { useAlertContext } from "../../../hooks/useAlertContext";
import { useAuth } from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";


interface Props {
  orderData: OrderType[];
  customerData: Customer;
  setCustomerData: Dispatch<SetStateAction<Customer | undefined>>;
}

const CustomerViewOrdersTimeline = (props: Props) => {
  const { orderData, customerData, setCustomerData } = props;
  const { timelineEntries } = customerData;

  const { id } = useParams();
  const { user } = useAuth();

  const [timelineEmailData, setTimelineEmailData] = useState<
    CustomerTimelineEmailsType[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredCategories, setFilteredCategories] = useState<string[]>([]);
  const [showEmails, setShowEmails] = useState(true);
  const [showOrders, setShowOrders] = useState(true);
  const [showManual, setShowManual] = useState(true);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(100);

  const [paginationStartIndex, setPaginationStartIndex] = useState(0);
  const [paginationEndIndex, setPaginationEndIndex] = useState(10); // must match init of rowsPerPage

  const [orderNumbers, setOrderNumbers] = useState<string[]>([]);
  const [selectedOrderNumber, setSelectedOrderNumber] = useState("");

  const [timelineData, setTimelineData] = useState<
  CustomerTimelineStateType[]
  >([]);

  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { showSuccessAlert, showErrorAlert } = useAlertContext();
  const {
    orderStatus,
    email,
    admin: admins,
  } = useSelector((state: RootState) => state);
  const { imapConnections } = email;

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSubmit = (
    values: ValuesType,
    formikHelpers: FormikHelpers<ValuesType>
  ) => {
    const { setSubmitting, resetForm } = formikHelpers;
    const now = new Date();

    const checkedTimelines = () => {
      if ( timelineEntries === null ) {
        return []
      }

      return timelineEntries
    }

    const entries = [
      ...checkedTimelines(),
      { ...values, time: now, admin: user!._id },
    ];

    dispatch(
      editCustomer({
        id: customerData._id,
        data: {
          timelineEntries: entries,
        },
      })
    )
      .unwrap()
      .then((res) => {
        showSuccessAlert(t("Order has been updated"));
        setSubmitting(false);
        resetForm();

        if (id) {
          dispatch(fetchCustomer(id))
            .unwrap()
            .then((res) => setCustomerData(res.customer));
        }
      })
      .catch((err) => {
        console.log(err);
        setSubmitting(false);
        showErrorAlert(t("Error during update! Check console"));
      });
  };

  const handleFilteredCategoriesChange = (
    event: SelectChangeEvent<typeof filteredCategories>
  ) => {
    const {
      target: { value },
    } = event;
    setFilteredCategories(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleOrderNumberChange = (event: SelectChangeEvent) => {
    setSelectedOrderNumber(event.target.value as string);
  };

  // fetch all admins
  useEffect(() => {
    dispatch(fetchAdminData({ search: "" }));
  }, [dispatch]);

  // fetch order statuses
  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);

  //fetch all imap connections
  useEffect(() => {
    if (customerData) {
      dispatch(fetchImapConnections());
    }
  }, [customerData, dispatch]);

  // fetch email communication
  useEffect(() => {
    const fetchTimelineCommunication = async () => {
      if (!customerData) return setIsLoading(false);
      if (imapConnections.length === 0) return setIsLoading(false);

      setIsLoading(true);
      const { connection, connected } = imapConnections[0];
      const mailboxes = ["INBOX", "Sent"];

      const mailboxFetch = async (box: string) => {
        const queries = {
          search: customerData.email,
          mailsPerPage: 100,
          page: 0,
          mailbox: box,
        };

        const url = `${
          process.env.REACT_APP_SERVER_HOST
        }/imap/get-mails?search=${queries.search}&mailsPerPage=${
          queries.mailsPerPage
        }&page=${queries.page + 1}&mailbox=${queries.mailbox}`;

        if (connection && connected === "connected") {
          const config = {
            method: "post",
            url,
            data: connection.details,
            withCredentials: true,
          };

          const _out = await axios(config).then((res) => {
            const { messages } = res.data;

            const item = messages.map((m: any) => ({
              ...m,
              box: box === "INBOX" ? "Received" : "Sent",
              color: box === "INBOX" ? "success" : "info",
            }));
            return item;
          });

          const closeConfig = {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_HOST}/imap/close-box`,
            data: {
              ...connection.details,
            },
            withCredentials: true,
          };

          await axios(closeConfig);

          return _out;
        }

        return [];
      };

      // Used over mapping the mailboxes bcos of an async bug where the results were the same as the other element
      // A cleaner solution is welcomed
      const m1 = await mailboxFetch(mailboxes[0]);
      const m2 = await mailboxFetch(mailboxes[1]);

      const output = Array(m1)
        .concat(Array(m2))
        .flat()
        .sort(sortByKey("date", false));

      setIsLoading(false);
      setTimelineEmailData(output as CustomerTimelineEmailsType[]);
    };

    (async () => fetchTimelineCommunication())();
  }, [customerData, imapConnections]);

  // handle filtering
  useEffect(() => {
    const allTrue = filteredCategories.length === 0;

    const showEmails = allTrue || filteredCategories.includes("email");
    const showOrders = allTrue || filteredCategories.includes("orders");
    const showManual = allTrue || filteredCategories.includes("manual");

    setShowEmails(showEmails);
    setShowOrders(showOrders);
    setShowManual(showManual);

    // clear selected
    if (
      !(filteredCategories.length === 1 && filteredCategories[0] === "orders")
    ) {
      setSelectedOrderNumber("");
    }
  }, [filteredCategories]);

  const statuses: any = orderStatus.data.reduce(
    (accumulator, status) => ({
      ...accumulator,
      [status._id]: { color: status.colorCode, name: status.name },
    }),
    {}
  );

  // creates timeline data
  useEffect(() => {
    let _timelineData: {
      time: Date;
      type: CustomerTimelineDataType;
      data:
        | TimelineEntriesType
        | CustomerOrderHistoryistoryDataType
        | CustomerOrderManualEntriesDataType
        | CustomerTimelineEmailsType
        | CustomerOrderCreateDataType;
    }[] = [];

    let tempOrderNumbers: string[] = [];

    orderData?.forEach((order) => {
      const {
        orderStatusHistory,
        timelineEntries: orderTimelineEntries,
        createdAt,
      } = order;

      tempOrderNumbers.push(order.orderNumber);

      // order creation
      const _data = {
        time: createdAt as unknown as Date,
        type: "orderCreated" as CustomerTimelineDataType,
        data: {
          id: order._id,
          orderNumber: order.orderNumber,
        },
      };

      _timelineData.push(_data);

      // order statuses
      orderStatusHistory.forEach((status) => {
        const _data = {
          time: status.modifiedOn,
          type: "status" as CustomerTimelineDataType,
          data: {
            id: order._id,
            orderNumber: order.orderNumber,
            data: status,
          },
        };

        _timelineData.push(_data);
      });

      // order manual timeline entries
      orderTimelineEntries.forEach((entry) => {
        const _data = {
          time: entry.time,
          type: "orderEntry" as CustomerTimelineDataType,
          data: {
            id: order._id,
            orderNumber: order.orderNumber,
            data: entry,
          },
        };

        _timelineData.push(_data);
      });
    });

    // imap/smtp email communication
    timelineEmailData?.forEach((email) => {
      const _data = {
        time: new Date(email.date).toISOString() as unknown as Date,
        type: "email" as CustomerTimelineDataType,
        data: email,
      };

      _timelineData.push(_data);
    });

    // customer manual timeline entries
    timelineEntries?.forEach((entry) => {
      const _data = {
        time: entry.time,
        type: "customerEntry" as CustomerTimelineDataType,
        data: entry,
      };

      _timelineData.push(_data);
    });

    const temp = _timelineData.sort(sortByKey("time", false));
    setTimelineData(temp);

    if (tempOrderNumbers.length) {
      setOrderNumbers([...new Set(tempOrderNumbers)]);
    }
  }, [orderData, timelineEmailData, timelineEntries]);

  // updates pages count
  useEffect(() => {
    if (timelineData.length) {
      const x = timelineData.filter((item) => {
        if (
          item.type === "orderCreated" &&
          showOrders &&
          (selectedOrderNumber ===
            (item.data as CustomerOrderCreateDataType).orderNumber ||
            selectedOrderNumber === "")
        )
          return item;
        if (
          item.type === "status" &&
          showOrders &&
          (selectedOrderNumber ===
            (item.data as CustomerOrderHistoryistoryDataType).orderNumber ||
            selectedOrderNumber === "")
        )
          return item;
        if (
          item.type === "orderEntry" &&
          showOrders &&
          (selectedOrderNumber ===
            (item.data as CustomerOrderManualEntriesDataType).orderNumber ||
            selectedOrderNumber === "")
        )
          return item;
        if (item.type === "customerEntry" && showManual) return item;
        if (item.type === "email" && showEmails) return item;

        return null;
      });

      setPageCount(x.length);
    }
  }, [selectedOrderNumber, showEmails, showManual, showOrders, timelineData]);

  // control pagination props
  useEffect(() => {
    const totalPages = pageCount;

    // + 1 due to first page needing to be 0 for mui component
    const currentPage = page + 1 > totalPages ? totalPages : page + 1;

    const _paginationStartIndex = (currentPage - 1) * rowsPerPage;
    const _paginationEndIndex = _paginationStartIndex + rowsPerPage;

    setPaginationStartIndex(_paginationStartIndex);
    setPaginationEndIndex(_paginationEndIndex);
  }, [page, pageCount, rowsPerPage]);

  if (!orderData.length) return null;

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card sx={{ position: "relative", minHeight: "200px" }}>
          <CardHeader
            title="Timeline Information"
            action={
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <FormControl>
                  <InputLabel
                    id="customer-timeline-category-checbox-label"
                    size="small"
                  >
                    Categories
                  </InputLabel>
                  <Select
                    labelId="customer-timeline-category-checbox-label"
                    id="customer-timeline-category-checbox"
                    multiple
                    size="small"
                    value={filteredCategories}
                    onChange={handleFilteredCategoriesChange}
                    input={<OutlinedInput label="Categories" />}
                    renderValue={(selected: string[]) => selected.join(", ")}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 48 * 4.5 + 8,
                          width: 200,
                        },
                      },
                    }}
                  >
                    {["email", "orders", "manual"].map((value) => (
                      <MenuItem key={value} value={value}>
                        <Checkbox
                          checked={filteredCategories.indexOf(value) > -1}
                        />
                        <ListItemText
                          primary={value}
                          sx={{ textTransform: "capitalize" }}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {filteredCategories.length === 1 &&
                  filteredCategories[0] === "orders" && (
                    <FormControl>
                      <InputLabel
                        id="customer-timeline-order-number-checbox-label"
                        size="small"
                      >
                        Order
                      </InputLabel>
                      <Select
                        labelId="customer-timeline-order-number-checbox-label"
                        id="customer-timeline-order-number-checbox"
                        size="small"
                        value={selectedOrderNumber}
                        onChange={handleOrderNumberChange}
                        input={<OutlinedInput label="Order" />}
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 48 * 4.5 + 8,
                              width: 200,
                            },
                          },
                        }}
                      >
                        {["", ...orderNumbers].map((value) => (
                          <MenuItem
                            key={value}
                            value={value}
                            sx={{ minHeight: "24px !important" }}
                          >
                            <ListItemText
                              primary={value === "" ? value : `#${value}`}
                            />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
              </Box>
            }
            sx={{ mb: 4 }}
          />

          <CardContent>
            <Timeline
              sx={{
                my: 0,
                py: 0,
                paddingLeft: 0,
                paddingRight: 0,
                "& .MuiTimelineItem-root": {
                  width: "100%",
                  "&:before": {
                    display: "none",
                  },
                },
              }}
            >
              <TimelineItem sx={{ minHeight: 150 }}>
                <TimelineSeparator sx={{ ml: "-10px" }}>
                  <Avatar
                    skin="light"
                    sx={{
                      width: 40,
                      height: 40,
                      fontWeight: 600,
                      fontSize: "1rem",
                    }}
                  >
                    {getInitials(
                      `${customerData.firstName} ${customerData.lastName}`
                    )}
                  </Avatar>
                  <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent
                  sx={{
                    mt: 0,
                    mb: (theme) => `${theme.spacing(3)} !important`,
                  }}
                >
                  <Card sx={{ borderRadius: 0 }}>
                    <AddTimelineEntryCard {...{ onSubmit }} />
                  </Card>
                </TimelineContent>
              </TimelineItem>

              {timelineData
                .filter((item) => {
                  const itemType = item.type;
                  const orderData = item.data as
                    | CustomerOrderCreateDataType
                    | CustomerOrderHistoryistoryDataType
                    | CustomerOrderManualEntriesDataType;

                  if (
                    showOrders &&
                    (itemType === "orderCreated" ||
                      itemType === "status" ||
                      itemType === "orderEntry") &&
                    (selectedOrderNumber === orderData.orderNumber ||
                      selectedOrderNumber === "")
                  ) {
                    return item;
                  }
                  if (itemType === "customerEntry" && showManual) return item;
                  if (itemType === "email" && showEmails) return item;

                  return null;
                })
                .slice(paginationStartIndex, paginationEndIndex)
                .map((data, i) => {
                  const dataType = data.type;
                  const orderDataType =
                    dataType === "orderCreated" ||
                    dataType === "status" ||
                    dataType === "orderEntry";

                  if (orderDataType && showOrders) {
                    const getOrderNumber = () => {
                      const orderData = data.data as
                        | CustomerOrderCreateDataType
                        | CustomerOrderHistoryistoryDataType
                        | CustomerOrderManualEntriesDataType;
                      return orderData.orderNumber;
                    };

                    const orderNumber = getOrderNumber();

                    const handleFilter = () => {
                      setSelectedOrderNumber(orderNumber);
                      setFilteredCategories(["orders"]);
                    };

                    return (
                      <OrderRelatedTimelineItem
                        {...{ data, admins, statuses, handleFilter }}
                      />
                    );
                  }

                  if (data.type === "customerEntry" && showManual) {
                    return (
                      <CustomerEntryTimelineItem
                        key={i}
                        {...{ data, admins }}
                      />
                    );
                  }

                  if (dataType === "email" && showEmails) {
                    return <EmailTimelineItem key={i} {...{ data }} />;
                  }

                  return null;
                })}
            </Timeline>
          </CardContent>

          <Backdrop
            open={isLoading}
            sx={{
              zIndex: 5,
              position: "absolute",
            }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          <TablePagination
            component="div"
            count={pageCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default CustomerViewOrdersTimeline;
