import { CardDataType } from "../types";

export const customerCardData: CardDataType[] = [
  {
    avatars: ["1.png", "2.png", "3.png", "4.png"],
    title: "Customer",
    totalUsers: 4,
  },
  {
    avatars: ["5.png", "6.png", "7.png", "8.png", "1.png", "2.png", "3.png"],
    title: "User",
    totalUsers: 7,
  },
  {
    avatars: ["4.png", "5.png", "6.png", "7.png", "8.png"],
    title: "Visitors",
    totalUsers: 5,
  },
  { totalUsers: 3, title: "Support", avatars: ["1.png", "2.png", "3.png"] },
  { totalUsers: 2, title: "Restricted User", avatars: ["4.png", "5.png"] },
];

export const rolesArr: string[] = [
  "User Management",
  "Content Management",
  "Disputes Management",
  "Database Management",
  "Financial Management",
  "Reporting",
  "API Control",
  "Repository Management",
  "Payroll",
];
