// ** MUI Imports
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";

// ** Third Party Imports
import { useTranslation } from "react-i18next";

interface Props {
  toggle: () => void;
}

const OrderStatusTableHeader = (props: Props) => {
  // ** Hooks
  const { t } = useTranslation();

  // ** Props
  const { toggle } = props;

  return (
    <Box
      sx={{
        p: 5,
        pb: 3,
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Button
        sx={{ mr: 4, mb: 2 }}
        color="secondary"
        variant="outlined"
        startIcon={<Icon>ios_share</Icon>}
      >
        {t("Export")}
      </Button>

      <Button sx={{ mb: 2 }} onClick={toggle} variant="contained">
        {`${t("Add")} ${t("Status").toLocaleLowerCase()}`}
      </Button>
    </Box>
  );
};

export default OrderStatusTableHeader;
