// ** React Imports
import {
  Dispatch,
  SetStateAction,
  useState,
} from "react";

// ** MUI Imports
import {
  Card,
  Divider,
  Typography,
  Box,
  CardContent,
} from "@mui/material";

// ** Third Party Imports
import format from "date-fns/format";
import { useTranslation } from "react-i18next";

// ** Custom Component Imports
import { Chip } from "../../../chip";
import OrderDetailsSummary from "../../orderDetailsSummary";

// ** Type Imports
import { OrderType } from "../../../../types/orderTypes";
import { ThemeColor } from "../../../@core/layouts/types";

// ** Hook Imports
import useCompanies from "../../../../hooks/useCompanies";

import { Actions } from "./previewCardActions";
import { OrderStatusChip } from "./previewCardOrderStatusChip";
import { EditOrderDialog } from "../editOrderDialog";

interface Props {
  data: OrderType;
  setData: Dispatch<SetStateAction<OrderType | null>>;
}

export const OrderPreviewCardScreen = ({
  data,
  setData,
}: Props) => {
  const { lineItems, basketPrice } = data;

  const [editOrderOpen, setEditOrderOpen] = useState<boolean>(false);

  const { t } = useTranslation();
  const companies = useCompanies();

  const company = companies.find((c) => c._id === data.company);

  const handleEditOrderClick = () => {
    setEditOrderOpen(!editOrderOpen);
  };

  if (!data) {
    return null;
  }

  return (
    <Box sx={{ gap: 2, display: "flex", flexDirection: "column" }}>
      <Card>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex" }}>
              <Typography variant="body1" mr={1}>
                {t("Objednávka")}
              </Typography>
              <Typography variant="body1">{` #${data.orderNumber}`}</Typography>
            </Box>

            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              {company && (
                <Chip
                  skin="light"
                  size="medium"
                  label={company.name}
                  color={company.colorCode as ThemeColor}
                  sx={{
                    textTransform: "capitalize",
                    "& .MuiChip-label": { lineHeight: "18px" },
                  }}
                />
              )}

              <OrderStatusChip
                data={data}
                orderID={data._id}
                setData={setData}
              />

              <Actions
                data={data}
                setData={setData}
                handleEditOrderClick={handleEditOrderClick}
              />
            </Box>
          </Box>

          <Divider />

          <Box>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Typography variant="body2">{t("Date issued")}:</Typography>

              {!data.createdAt && <Typography variant="body2">NA</Typography>}

              {data.createdAt && (
                <Typography variant="body2">
                  {format(new Date(data.createdAt), "MMM d, yyyy")}
                </Typography>
              )}
            </Box>

            <Box sx={{ display: "flex", gap: 1 }}>
              <Typography variant="body2">{t("Date paid")}:</Typography>
              <Typography variant="body2">{t("Not paid")}</Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>

      <Card>
        <OrderDetailsSummary {...{ lineItems, basketPrice }} />
      </Card>

      <EditOrderDialog
        open={editOrderOpen}
        setOpen={setEditOrderOpen}
        order={data}
        setOrder={setData}
      />
    </Box>
  );
};
