import React, { Dispatch, SetStateAction } from "react";
import { GridActionsCellItem, GridColumns } from "@mui/x-data-grid";
import { Icon, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import StyledLink from "../../table/StyledLink";
import { Admin } from "../../../types/adminTypes";

interface CellType {
  row: Admin;
}

interface Props {
  t: any;
  toggleDeleteAdminDialog: () => void;
  setSelectedAdmin: Dispatch<SetStateAction<Admin | undefined>>
}

const getAdminListTableColumns = (props: Props): GridColumns<Admin> => {
  const {
    t,
    setSelectedAdmin,
    toggleDeleteAdminDialog,
  } = props;

  const columns = [
    {
      flex: 0.12,
      minWidth: 200,
      field: "displayName",
      headerName: t("View Meno"),
      renderCell: ({ row }: CellType) => (
        <StyledLink to={`/admin/view/${row._id}`}>
          {row.displayName}
        </StyledLink>
      ),
      valueGetter: ({ row }: CellType) => row.displayName,
    },
    {
      flex: 0.12,
      minWidth: 200,
      field: "email",
      headerName: t("Email"),
      renderCell: ({ row }: CellType) => (
        <Typography noWrap variant="body2">
          {row.email}
        </Typography>
      ),
      valueGetter: ({ row }: CellType) => row.email,
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "status",
      headerName: t("Role"),
      type: "string",
      valueGetter: ({ row }: CellType) => row.role,
    },

    {
      flex: 0.1,
      minWidth: 180,
      field: "isRegistered",
      headerName: t("Registered"),
      type: "boolean",
    },

    {
      flex: 0.1,
      minWidth: 100,
      sortable: false,
      field: "actions",
      headerName: t("Actions"),
      type: "actions",
      getActions: ({ row }: CellType) => [
        <GridActionsCellItem
          showInMenu
          sx={{ "& .MuiListItemIcon-root": { minWidth: 0 } }}
          icon={<Icon fontSize="small">visibility</Icon>}
          label={t("View")}
          // @ts-expect-error
          component={Link}
          to={`/admin/view/${row._id}`}
        />,
        <GridActionsCellItem
          showInMenu
          sx={{ "& .MuiListItemIcon-root": { minWidth: 0 } }}
          icon={<Icon fontSize="small">edit</Icon>}
          label={t("Edit")}
          // @ts-expect-error
          component={Link}
          to={`/admin/edit/${row._id}`}
        />,
        <GridActionsCellItem
          showInMenu
          sx={{ "& .MuiListItemIcon-root": { minWidth: 0 } }}
          icon={<Icon fontSize="small">delete</Icon>}
          label={t("Delete")}
          onClick={() => {
            setSelectedAdmin(row);
            toggleDeleteAdminDialog();
          }}
        />,
      ],
    },
  ];

  return columns;
};

export default getAdminListTableColumns;
