// ** React Imports
import { useState, useEffect, useMemo } from "react";

// ** Redux Imports
import { useDispatch, useSelector } from "react-redux";

// ** MUI Imports
import {
  Avatar,
  AvatarGroup,
  Box,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import debounce from "lodash.debounce";

// ** Hooks
import { useSettings } from "../../hooks/useSettings";

// ** Types
import { RootState, AppDispatch } from "../../store";
import {
  MailLayoutType,
  MailLabelColors, /* , MailFolderType, MailLabelType */
  supportedMailboxes,
} from "../../types/emailTypes";

// ** Email App Component Imports
import MailLog from "./components/MailLog";
import SidebarLeft from "./components/SidebarLeft";
import ComposePopup from "./components/ComposePopup";
import { fetchAccounts, getCurrentMail } from "../../store/apps/email";
import { getInitials } from "../../helpers/get-initials";
import { ImapAccountType, MailFormDataType, SmtpAccountType } from "../../types/mailAccountsType";
import { useTranslation } from "react-i18next";

// ** Variables
const labelColors: MailLabelColors = {
  private: "error",
  personal: "success",
  company: "primary",
  important: "warning",
};

const MailBoxScreen = ({ folder, label }: MailLayoutType) => {
  // ** States
  const [composeOpen, setComposeOpen] = useState<boolean>(false);
  const [mailDetailsOpen, setMailDetailsOpen] = useState<boolean>(false);
  const [leftSidebarOpen, setLeftSidebarOpen] = useState<boolean>(false);
  const [query, setQuery] = useState('');
  const [mailPage, setMailPage] = useState(0)
  const [mailsPerPage, setMailsPerPage] = useState(10)
  const [totalMessages, setTotalMessages] = useState(0);
  const [mailbox, setMailbox] = useState<supportedMailboxes>('INBOX');

  const [isLoading, setIsLoading] = useState(true);
  const [composeInitialValues, setComposeInitialValues] = useState<MailFormDataType>({
    from: "",
    to: "",
    subject: "",
    html: "",
  });

  // ** Hooks
  const { t } = useTranslation();
  const theme = useTheme();
  const { settings } = useSettings();
  const dispatch = useDispatch<AppDispatch>();
  const lgAbove = useMediaQuery(theme.breakpoints.up("lg"));

  const mdAbove = useMediaQuery(theme.breakpoints.up("md"));
  const smAbove = useMediaQuery(theme.breakpoints.up("sm"));

  const hidden = useMediaQuery(theme.breakpoints.down("lg"));
  const store = useSelector((state: RootState) => state.email);

  const composePopupWidth = mdAbove ? 754 : smAbove ? 520 : "100%";
  const { skin, appBar, footer, layout, navHidden /* , direction */ } =
    settings;

    const debounceFetchAccounts = useMemo(
      () =>
        debounce(async (val: string, mailsPerPage, mailPage, mailbox) => {
          setIsLoading(true)
          await dispatch(
            fetchAccounts({
              search: val,
              mailsPerPage,
              page: mailPage,
              mailbox
            })
          );
          setIsLoading(false)
        }, 1000),
      [dispatch]
    );

    useEffect(() => {
      debounceFetchAccounts(query, mailsPerPage, mailPage, mailbox);
    }, [debounceFetchAccounts, mailPage, mailsPerPage, query, mailbox]);


  const toggleComposeOpen = () => setComposeOpen(!composeOpen);
  const handleLeftSidebarToggle = () => setLeftSidebarOpen(!leftSidebarOpen);

  const calculateAppHeight = () => {
    return `(${
      (appBar === "hidden" ? 0 : (theme.mixins.toolbar.minHeight as number)) *
        (layout === "horizontal" && !navHidden ? 2 : 1) +
      (footer === "hidden" ? 0 : 56)
    }px + ${theme.spacing(6)} * 2)`;
  };

  // ** Vars
  const leftSidebarWidth = 260;

  // Email controls
  const [connectedImapAccounts, setConnectedImapAccounts] = useState<ImapAccountType[]>(
    []
  );
  const [connectedSmtpAccounts, setConnectedSmtpAccounts] = useState<SmtpAccountType[]>(
    []
  );

  const [selectedEmails, setSelectedEmails] = useState<string[]>([]);

  const handleSelectedEmails = (
    event: SelectChangeEvent<typeof selectedEmails>
  ) => {
    const {
      target: { value },
    } = event;

    setSelectedEmails(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // TODO: figure out totalMessages when multiple accounts are connected
  //imap connected
  useEffect(() => {
    const _connectedImapAccounts = store.accounts
      .filter((acc) => acc.imap.connected === "connected")
      .map((acc, _, arr) => acc.imap) as ImapAccountType[];

    setConnectedImapAccounts(_connectedImapAccounts);

    setSelectedEmails([_connectedImapAccounts[0]?.email]);
  }, [setConnectedImapAccounts, store.accounts]);


  // handle totalMessages depending on selected emails
  useEffect(() => {
    if(selectedEmails.length > 0 && connectedImapAccounts.length > 0) {
      if (selectedEmails.length === 1) setTotalMessages(connectedImapAccounts[0].totalMessages)

      if (selectedEmails.length > 1) {
        let _totalMessages = 0;

        // needed when we have multiselect so the one with most messages is the totalMessages
        connectedImapAccounts.forEach((acc) => {
          _totalMessages = acc.totalMessages > _totalMessages ? acc.totalMessages : _totalMessages
        })

        setTotalMessages(_totalMessages)
      }
    }
  }, [selectedEmails, connectedImapAccounts])

  //smtp connected
  useEffect(() => {
    const _connectedSmtpAccounts = store.accounts
      .filter((acc) => acc.smtp?.connected === "connected")
      .map((acc) => acc.smtp) as SmtpAccountType[];

    setConnectedSmtpAccounts(_connectedSmtpAccounts);
  }, [setConnectedSmtpAccounts, store.accounts]);

  // compose email initialValues control
    useEffect(() => {
    const _values = {
      from: connectedSmtpAccounts[0] ? connectedSmtpAccounts[0].user : '',
      to: '',
      subject: '',
      html: '',
    };

    setComposeInitialValues(_values)
  }, [connectedSmtpAccounts])

  return (
    <Box>
      <Box sx={{ mb: 3, display: "flex", gap: 3, alignItems: "center" }}>
        <Typography>
          {connectedImapAccounts.length === 0
            ? t("Connected to 0 accounts")
            : connectedImapAccounts.length > 1
            ? t("Connected to X accounts", {val: connectedImapAccounts.length})
            : t("Connected to 1 account")}
        </Typography>

        <AvatarGroup
          max={4}
          sx={{
            "& .MuiAvatarGroup-avatar": { fontSize: ".875rem" },
            "& .MuiAvatar-root, & .MuiAvatarGroup-avatar": {
              width: 40,
              height: 40,
            },
          }}
        >
          {connectedImapAccounts.map((acc, index: number) => (
            <Avatar key={index} alt={acc.email}>
              {getInitials(acc.email).toUpperCase()}
            </Avatar>
          ))}
        </AvatarGroup>

        {connectedImapAccounts.length > 0 && (
          <FormControl>
            <InputLabel id="multiple-email-accounts-select">
              {t("Selected Emails")}
            </InputLabel>

            <Select
              labelId="multiple-email-accounts-select"
              multiple
              value={selectedEmails}
              onChange={handleSelectedEmails}
              input={<OutlinedInput label="Selected Emails" />}
              renderValue={(selected) => selected.join(", ")}
            >
              {connectedImapAccounts.map((acc, index: number) => (
                <MenuItem key={index} value={acc.email}>
                  <Checkbox checked={selectedEmails.indexOf(acc.email) > -1} />
                  {acc.email}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          borderRadius: 1,
          overflow: "hidden",
          position: "relative",
          boxShadow: skin === "bordered" ? 0 : 6,
          height: `calc(100vh - ${calculateAppHeight()})`,
          ...(skin === "bordered" && {
            border: `1px solid ${theme.palette.divider}`,
          }),
        }}
      >
        <SidebarLeft
          //@ts-ignore
          store={store}
          hidden={hidden}
          lgAbove={lgAbove}
          dispatch={dispatch}
          mailDetailsOpen={mailDetailsOpen}
          leftSidebarOpen={leftSidebarOpen}
          leftSidebarWidth={leftSidebarWidth}
          toggleComposeOpen={toggleComposeOpen}
          setMailDetailsOpen={setMailDetailsOpen}
          handleLeftSidebarToggle={handleLeftSidebarToggle}
          connectedSmtpAccounts={connectedSmtpAccounts}
          mailbox={mailbox}
          setMailbox={setMailbox}
        />

        <MailLog
          //@ts-ignore
          store={store}
          isLoading={isLoading}
          setComposeInitialValues={setComposeInitialValues}
          toggleComposeOpen={toggleComposeOpen}
          query={query}
          setQuery={setQuery}
          mailPage={mailPage}
          setMailPage={setMailPage}
          mailsPerPage={mailsPerPage}
          setMailsPerPage={setMailsPerPage}
          totalMessages={totalMessages}
          connectedImapAccounts={connectedImapAccounts}
          selectedEmails={selectedEmails}
          hidden={hidden}
          lgAbove={lgAbove}
          dispatch={dispatch}
          labelColors={labelColors}
          getCurrentMail={getCurrentMail}
          mailDetailsOpen={mailDetailsOpen}
          setMailDetailsOpen={setMailDetailsOpen}
          handleLeftSidebarToggle={handleLeftSidebarToggle}
        />

        <ComposePopup
          mdAbove={mdAbove}
          composeOpen={composeOpen}
          composePopupWidth={composePopupWidth}
          toggleComposeOpen={toggleComposeOpen}
          connectedSmtpAccounts={connectedSmtpAccounts}
          dispatch={dispatch}
          composeInitialValues={composeInitialValues}
        />
      </Box>
    </Box>
  );
};

export default MailBoxScreen;
