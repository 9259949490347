// ** React Imports
import { useRef, useState, useEffect } from "react";

// ** MUI Imports
import {
  Box,
  Grid,
  Icon,
  Button,
  Popover,
  Dialog as MuiDialog,
  Checkbox,
  TextField,
  IconButton,
  FormControl,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormHelperText,
  Autocomplete,
  InputAdornment,
  FormControlLabel,
  FormLabel,
  LinearProgress,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

// ** Third Party Imports
import { Controller, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

// ** Types Imports
import { OrderStatusData } from "../../../types/orderStatusTypes";
import { ThemeColor } from "../../@core/layouts/types";

import useOrderStatuses, { useOrderStatusPosition } from "../../../hooks/useOrderStatuses";
import { useAlertContext } from "../../../hooks/useAlertContext";

import { Chip } from "../../chip";
import EmailTemplateField from "./emailTemplateField";
import EmailSubjectField from "./emailSubjectField";

const colors: ThemeColor[] = [
  "primary",
  "secondary",
  "error",
  "warning",
  "info",
  "success",
];

interface Props {
  title: string;
  form: UseFormReturn<OrderStatusData, any>;
  open: boolean;
  toggle: () => void;
  onSubmit: (data: OrderStatusData) => void;
  type: "add" | "edit";
}

const OrderStatusFormDialog = (props: Props) => {
  // ** Props
  const { title, form, open, toggle, onSubmit, type } = props;

  // ** State
  const colorPickerAnchorEl = useRef<HTMLDivElement | null>(null);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  // ** Hooks
  const { showInfoAlert } = useAlertContext();
  const orderStatusesObj = useOrderStatuses();
  const orderStatusesPositionObj = useOrderStatusPosition();
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
  } = form;

  const editorRef = useRef<any>(null);

  // ** Handlers
  const handleClose = () => {
    toggle();
  };

  // auto-increment order-status position in kanban board
  useEffect(() => {
    if (type !== "add") return;
    const currentNumberOfStatuses = Object.keys(orderStatusesObj).length
    const newStatusOrderInKanban = currentNumberOfStatuses;

    setValue("positionInKanban", newStatusOrderInKanban);
  }, [orderStatusesObj, type, setValue])

  return (
    <MuiDialog
      disableEnforceFocus // to allow internal dialogs focus
      fullWidth
      maxWidth="md"
      scroll="body"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        {title}
        <IconButton
          size="small"
          onClick={handleClose}
          sx={{ position: "absolute", right: "1rem", top: "1rem" }}
        >
          <Icon>close</Icon>
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ pt: (theme) => `${theme.spacing(2)} !important` }}>
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    if (type === "add") {
                      return (
                        <TextField
                          label={`${t("Name")}`}
                          required
                          value={value}
                          onChange={onChange}
                          error={Boolean(errors.name)}
                        />
                      );
                    }

                    return (
                      <Autocomplete
                        freeSolo
                        disableClearable
                        value={value}
                        options={Object.keys(orderStatusesObj)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`${t("Name")}`}
                            required
                            onChange={onChange}
                            error={Boolean(errors.name)}
                            InputProps={{
                              ...params.InputProps,
                            }}
                          />
                        )}
                      />
                    );
                  }}
                />
                {errors.name && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {errors.name.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Controller
                  name="colorCode"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      <TextField
                        ref={colorPickerAnchorEl}
                        required
                        InputProps={{
                          readOnly: true,
                          ...(value && {
                            startAdornment: (
                              <InputAdornment position="start">
                                <Chip
                                  size="small"
                                  color={value as ThemeColor}
                                  sx={{ width: 28, height: 28 }}
                                />
                              </InputAdornment>
                            ),
                          }),
                        }}
                        label={t("Kód farby")}
                        onClick={() => {
                          setDisplayColorPicker(true);
                        }}
                        error={Boolean(errors.colorCode)}
                      />
                      <Popover
                        open={displayColorPicker}
                        anchorEl={colorPickerAnchorEl.current}
                        PaperProps={{
                          sx: {
                            minWidth: colorPickerAnchorEl.current?.offsetWidth,
                            maxWidth: colorPickerAnchorEl.current?.offsetWidth,
                            padding: 5,
                          },
                        }}
                        onClose={() => {
                          setDisplayColorPicker(false);
                        }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        disableAutoFocus
                        disableEnforceFocus
                      >
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 3 }}>
                          {colors.map((color) => (
                            <Chip
                              size="small"
                              color={color}
                              sx={{ width: 28, height: 28 }}
                              clickable
                              onClick={() => {
                                onChange(color);
                                setDisplayColorPicker(false);
                              }}
                            />
                          ))}
                        </Box>
                      </Popover>
                    </>
                  )}
                />
                {errors.colorCode && errors.colorCode.message && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {t(errors.colorCode.message)}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Controller
                  name="positionInKanban"
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    if (type === "add") {
                      return (
                        <TextField
                          label={`${t("Position in kanban")}`}
                          disabled
                          required
                          value={value}
                          onChange={onChange}
                          error={Boolean(errors.positionInKanban)}
                        />
                      );
                    }

                    return (
                      <>
                        <InputLabel id="kanban-position-label">
                          {t("Position in kanban")}
                        </InputLabel>
                        <Select
                          labelId="kanban-position-label"
                          value={value}
                          label={t("Position in kanban")}
                          onChange={onChange}
                        >
                          {Object.values(orderStatusesPositionObj).map(
                            (value) => (
                              <MenuItem key={value} value={value}>
                                {value}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </>
                    );
                  }}
                />

                {errors.positionInKanban && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {errors.positionInKanban.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Controller
                  name="emailSubject"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <EmailSubjectField
                      {...{
                        value,
                        setValue,
                        t,
                        onChange,
                        error: errors.emailSubject,
                      }}
                    />
                  )}
                />

                {errors.emailSubject && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {errors.emailSubject.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormLabel id="sk-email-template-area" sx={{ mb: 2 }}>
                  Email Template
                </FormLabel>
                <Controller
                  aria-labelledby="sk-email-template-area"
                  name="template"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <EmailTemplateField
                      {...{ editorRef, value, setValue, showInfoAlert, t }}
                    />
                  )}
                />

                {errors.template && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {errors.template.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            {/* checkboxes */}
            <Grid container item>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Controller
                    name="isDefault"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <FormControlLabel
                        label="Default (only one status can be default)"
                        sx={errors.isDefault ? { color: "error.main" } : null}
                        control={
                          <Checkbox
                            required
                            checked={value}
                            onChange={onChange}
                            sx={
                              errors.isDefault ? { color: "error.main" } : null
                            }
                          />
                        }
                      />
                    )}
                  />
                  {errors.isDefault && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.isDefault.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Controller
                    name="notifyCustomer"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <FormControlLabel
                        label="Notify Customer"
                        sx={
                          errors.notifyCustomer ? { color: "error.main" } : null
                        }
                        control={
                          <Checkbox
                            required
                            checked={value}
                            onChange={onChange}
                            sx={
                              errors.notifyCustomer
                                ? { color: "error.main" }
                                : null
                            }
                          />
                        }
                      />
                    )}
                  />
                  {errors.notifyCustomer && errors.notifyCustomer.message && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.notifyCustomer.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Controller
                    name="notifyAdmin"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <FormControlLabel
                        label="Notify Admin"
                        sx={errors.notifyAdmin ? { color: "error.main" } : null}
                        control={
                          <Checkbox
                            required
                            checked={value}
                            onChange={onChange}
                            sx={
                              errors.notifyAdmin
                                ? { color: "error.main" }
                                : null
                            }
                          />
                        }
                      />
                    )}
                  />
                  {errors.notifyAdmin && errors.notifyAdmin.message && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.notifyAdmin.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </form>

        {isSubmitting && <LinearProgress sx={{ mt: 4 }} />}
      </DialogContent>

      <DialogActions>
        <Button
          size="large"
          type="submit"
          sx={{ mr: 2 }}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          {t("Save")}
        </Button>
        <Button
          size="large"
          color="secondary"
          variant="outlined"
          onClick={handleClose}
        >
          {t("Cancel")}
        </Button>
      </DialogActions>
    </MuiDialog>
  );
};

export default OrderStatusFormDialog;
