import { IconButton, Input } from "@mui/material";
import React, { ReactNode, ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import { updateCustomWallpaperState } from "../../../../../../../store/apps/print-wallpaper";

interface Props {
  children: ReactNode;
}

const ImageFileSelectWrapper = (props: Props) => {
  const { children } = props;

  const dispatch = useDispatch();

  const onSelectFile = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    dispatch(updateCustomWallpaperState({
      preview: file,
      isCropMode: true
    }))
  };

  return (
    <label htmlFor={"inputId"}>
      <Input
        type="file"
        id={"inputId"}
        inputProps={{
          accept: "image/*",
          onChange: onSelectFile,
        }}
        sx={{ display: "none" }}
      />
      <IconButton
        disableTouchRipple
        color="primary"
        aria-label="upload picture"
        component="span"
        sx={{ ":hover": { background: "none" }, borderRadius: "10px", p: 0}}
      >
        {children}
      </IconButton>
    </label>
  );
};

export default ImageFileSelectWrapper;
