import {
  Grid,
  TextField
  } from "@mui/material";
import { ChangeEvent, FocusEvent } from "react";


interface Props {
  value: number;
  valueWithTax: number;
  onChange: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onChangeWithTax: (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  onBlur: (
    event: FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>
  ) => void;
  onBlurWithTax: (
    event: FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>
  ) => void;
  t: any;
}

// NOTE: These fields are solely for display, nothing sent as form data
const ProductTaxFields = ({
  value,
  valueWithTax,
  onChange,
  onChangeWithTax,
  onBlur,
  onBlurWithTax,
  t,
}: Props) => {
  return (
    <Grid item container xs={12} spacing={5}>
      <Grid item xs={12} sm={6}>
        <TextField
          type="number"
          label={t("Without Tax")}
          fullWidth
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          type="number"
          label={t("With Tax")}
          fullWidth
          value={valueWithTax}
          onChange={onChangeWithTax}
          onBlur={onBlurWithTax}
        />
      </Grid>
    </Grid>
  );
};

export default ProductTaxFields;
