import { Grid } from '@mui/material'
import ProductForm from '../../comps/products/form';

export const NewProductScreen = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <ProductForm />
      </Grid>
    </Grid>
  )
};
