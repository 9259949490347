export function compareArrays(arr1: any[], arr2: any[]): Partial<any[]> {
  const diff: Partial<any[]> = [];

  for (let i = 0; i < Math.max(arr1.length, arr2.length); i++) {
    if (i < arr1.length && i < arr2.length) {
      // Compare elements at the same index
      const elementDiff = compareObjects(arr1[i], arr2[i]);
      if (Object.keys(elementDiff).length > 0) {
        diff[i] = elementDiff;
      }
    } else if (i < arr1.length) {
      // If arr2 is shorter, add the remaining elements from arr1
      diff[i] = arr1[i];
    } else if (i < arr2.length) {
      // If arr1 is shorter, add the remaining elements from arr2
      diff[i] = arr2[i];
    }
  }

  return diff;
}

/**
 * Compare two `flat` arrays for equality
 * @param arr1 1st Array
 * @param arr2 2nd Array
 * @returns boolean
 * @author arndom
 */
export function arraysEqual(arr1: any[], arr2: any[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}

export function compareObjects<T>(obj1: T, obj2: T): Partial<T> {
  const diff: Partial<T> = {};

  for (const key in obj1) {
    const val1 = obj1[key];
    const val2 = obj2[key];

    if (Array.isArray(val1) && Array.isArray(val2)) {
      if (!arraysEqual(val1, val2)) {
        diff[key] = val2;
      }
    } else if (typeof val1 === "object" && typeof val2 === "object") {
      const objectDiff = compareObjects(val1, val2);
      if (Object.keys(objectDiff).length > 0) {
        // @ts-ignore
        diff[key] = objectDiff;
      }
    } else if (val1 !== val2) {
      diff[key] = val2;
    }
  }

  return diff;
}
