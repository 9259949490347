// ** React Imports
import { ReactNode } from 'react'

// ** MUI Imports
import { deepmerge } from '@mui/utils'
import { Theme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import GlobalStyles from '@mui/material/GlobalStyles'
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles'

// ** Type Imports
import { Settings } from '../../../context/settingsContext'

// ** Theme Config
import themeConfig from '../../../configs/themeConfig'

// ** Direction component for LTR or RTL
import Direction from '../../layouts/components/Direction'

// ** Theme Override Imports
import overrides from './overrides'
import typography from './typography'

// ** Theme
import themeOptions from './ThemeOptions'
import UserThemeOptions from '../../layouts/UserThemeOptions'

// ** Global Styles
import GlobalStyling from './globalStyles'

// **Locales
import * as dataGridLocales from "@mui/x-data-grid/locales";
import * as locales from '@mui/material/locale';
import { useTranslation } from 'react-i18next'

// type SupportedLocales = keyof typeof locales;

interface Props {
  settings: Settings
  children: ReactNode
}

const ThemeComponent = (props: Props) => {
  // ** Props
  const { settings, children } = props

  // ** Merged ThemeOptions of Core and User
  const coreThemeConfig = themeOptions(settings)

  // ** Pass ThemeOptions to CreateTheme Function to create partial theme without component overrides
  let theme = createTheme(coreThemeConfig)

  // ** Deep Merge Component overrides of core and user
  const mergeComponentOverrides = (theme: Theme, settings: Settings) =>
    deepmerge({ ...overrides(theme, settings) }, UserThemeOptions()?.components)

  // ** Deep Merge Typography of core and user
  const mergeTypography = (theme: Theme) => deepmerge(typography(theme), UserThemeOptions()?.typography)


  // ** Localization check
  const { i18n } = useTranslation();
  // const [locale, setLocale] = useState<SupportedLocales>('enUS');

  // useEffect(() => {
  //   const lang = i18n.language;

  //   if (lang) {
  //     const getLocale = () => {
  //         if (lang === 'en') return  'enUS';
  //         if (lang === 'cs') return  'csCZ';
  //         return lang + lang.toUpperCase();
  //     };

  //     const _locale = getLocale() as SupportedLocales;
  //     setLocale(_locale);
  //   }
  // },[i18n.language]);

  const getLocale = () => {
    switch (i18n.language) {
      case "en":
        return "enUS";
      case "cs":
        return "csCZ";
      case "sk":
        return "skSK";
      default:
        return i18n.language + i18n.language?.toUpperCase();
    }
  };

  // ** Continue theme creation and pass merged component overrides to CreateTheme function
  theme = createTheme(
    theme,
    // locales[locale],
    locales[getLocale() as keyof typeof locales],
    dataGridLocales[getLocale() as keyof typeof dataGridLocales],
    {
      components: { ...mergeComponentOverrides(theme, settings) },
      typography: { ...mergeTypography(theme) }
    }
  )

  // ** Set responsive font sizes to true
  if (themeConfig.responsiveFontSizes) {
    theme = responsiveFontSizes(theme)
  }

  return (
    <ThemeProvider theme={theme}>
      <Direction direction={settings.direction}>
        <CssBaseline />
        <GlobalStyles styles={() => GlobalStyling(theme, settings) as any} />
        {children}
      </Direction>
    </ThemeProvider>
  )
}

export default ThemeComponent
