// ** MUI Components
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Box, { BoxProps } from '@mui/material/Box'


import { Link, useNavigate } from 'react-router-dom'
import BlankLayout from '../comps/@core/layouts/BlankLayout'
import FooterIllustrations from '../comps/FooterIllustrations'

// ** Styled Components
const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    width: '90vw'
  }
}))

const Img = styled('img')(({ theme }) => ({
  marginTop: theme.spacing(15),
  marginBottom: theme.spacing(15),
  [theme.breakpoints.down('lg')]: {
    height: 450,
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10)
  },
  [theme.breakpoints.down('md')]: {
    height: 400
  }
}))

const Error404 = () => {
  const navigate = useNavigate();

  return (
    <BlankLayout>
      <Box className='content-center'>
        <Box sx={{ p: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
          <BoxWrapper>
            <Typography variant='h1' sx={{ mb: 2.5 }}>
              404
            </Typography>
            <Typography variant='h5' sx={{ mb: 2.5, letterSpacing: '0.18px', fontSize: '1.5rem !important' }}>
              Page Not Found ⚠️
            </Typography>
            <Typography variant='body2'>We couldn&prime;t find the page you are looking for.</Typography>
          </BoxWrapper>

          <Img alt='error-illustration' src='/images/pages/404.png' />

          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button variant='contained' onClick={() => navigate(-1)} sx={{ px: 5.5 }}>
              Go back
            </Button>

            <Button component={Link} to='/' variant='outlined' sx={{ px: 5.5 }}>
              Home
            </Button>
          </Box>
        </Box>

        <FooterIllustrations image='/images/pages/misc-404-object.png' />
      </Box>
    </BlankLayout>
  )
}


export default Error404
