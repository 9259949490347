import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Grid } from "@mui/material";
import { Control, FieldErrors, UseFormSetValue } from "react-hook-form";
import { ProductFormInputs } from "../../../../types/productTypes";
import { updatePriceValueWith } from "../utils/updatePriceValueWith";
import PriceAccordion from "../PriceAccordion";
import { MarginInput, MarginPrefixInput } from "../MarginFields";
import {
  CallbackCalculateTotalWithTax,
  RoundToDecimal,
} from "../utils/helpers";
import ProductTaxFields from "../ProductTaxFields";
import { PricingDivider } from "../SectionDivider";

interface Props {
  control: Control<ProductFormInputs, any>;
  t: any;
  errors: FieldErrors<ProductFormInputs>;

  setValue: UseFormSetValue<ProductFormInputs>;

  watchCustomerDiscount: ProductFormInputs["customerDiscount"];
  watchRetailCustomerMargin: ProductFormInputs["retailCustomerMargin"];

  watchPrice: number;
  tax: number;
}
// Component for rendering a section with wholesale and retail discount fields
export const RegisteredRetailCustomersOnSaleFields = ({
  control,
  t,
  errors,

  watchPrice,

  watchCustomerDiscount,
  watchRetailCustomerMargin,

  tax,
  setValue,
}: Props) => {
  const [
    totalPriceWithRetailCustomerMargin,
    setTotalPriceWithRetailCustomerMargin,
  ] = useState(0);

  const [totalPriceWithCustomerDiscount, setTotalPriceWithCustomerDiscount] =
    useState(0);

  const [
    totalPriceWithCustomerDiscountWithTax,
    setTotalPriceWithCustomerDiscountWithTax,
  ] = useState(0);

  const calculateTotalWithTax = useCallback(
    (
      priceWithMargin: number,
      setTotalPriceWithTax: Dispatch<SetStateAction<number>>,
      tax: number
    ) =>
      CallbackCalculateTotalWithTax(priceWithMargin, setTotalPriceWithTax, tax),
    []
  );

  // TODO: abstract as a function outside component
  // used by similar wholesale componet
  const handleTaxFieldsOnChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    setState: Dispatch<SetStateAction<number>>
  ) => {
    setState(Number(event.currentTarget.value));
  };

  // same above TODO
  const handleTaxFieldsOnBlur = (
    totalDisountPrice: number,
    totalPrice: number,
    setTotalDiscountPrice: Dispatch<SetStateAction<number>>,
    marginPrefix: string,
    formValue: string
  ) => {
    if (totalDisountPrice < totalPrice) {
      setTotalDiscountPrice(totalDisountPrice);

      let newDiscount = totalPrice - totalDisountPrice;

      if (marginPrefix === "%") {
        newDiscount = (newDiscount * 100) / totalPrice;
      }

      newDiscount = RoundToDecimal(newDiscount, 2);
      setValue(formValue, newDiscount);
    } else {
      setTotalDiscountPrice(totalPrice);
      setValue(formValue, 0);
    }
  };

  // same above TODO
  const handleTaxFieldsOnBlurWithTax = (
    totalDisountPriceWithTax: number,
    totalPrice: number,
    setTotalDiscountPrice: Dispatch<SetStateAction<number>>,
    setTotalDiscountPriceWithTax: Dispatch<SetStateAction<number>>,
    marginPrefix: string,
    formValue: string
  ) => {
    const _tax = totalPrice * (tax / 100);
    let totalPriceWithTax = _tax + totalPrice;
    totalPriceWithTax = RoundToDecimal(totalPriceWithTax, 2);

    if (totalDisountPriceWithTax <= totalPriceWithTax) {
      setTotalDiscountPriceWithTax(totalDisountPriceWithTax);

      let totalDiscountPrice = totalDisountPriceWithTax / ((100 + tax) / 100);
      totalDiscountPrice = RoundToDecimal(totalDiscountPrice, 2);

      setTotalDiscountPrice(totalDiscountPrice);
      let newDiscount = totalPrice - totalDiscountPrice;

      if (marginPrefix === "%") {
        newDiscount = (newDiscount * 100) / totalPrice;
      }

      newDiscount = RoundToDecimal(newDiscount, 2);
      setValue(formValue, newDiscount);
    } else {
      setTotalDiscountPriceWithTax(totalPriceWithTax);
      setValue(formValue, 0);
    }
  };

  // update total Price With Retail CustomerMargin
  useEffect(() => {
    const margin = updatePriceValueWith(
      watchPrice,
      watchRetailCustomerMargin.value,
      watchRetailCustomerMargin.prefix
    );
    let total = watchPrice + margin;
    total = RoundToDecimal(total, 2);

    setTotalPriceWithRetailCustomerMargin(total);
  }, [watchPrice, watchRetailCustomerMargin]);

  // update total Price With customer discount
  useEffect(() => {
    const discount = updatePriceValueWith(
      totalPriceWithRetailCustomerMargin,
      watchCustomerDiscount.value,
      watchCustomerDiscount.prefix
    );
    let total = totalPriceWithRetailCustomerMargin - discount;
    total = RoundToDecimal(total, 2);

    setTotalPriceWithCustomerDiscount(total);
  }, [totalPriceWithRetailCustomerMargin, watchCustomerDiscount]);

  // Below  calculates total prices with tax
  useEffect(() => {
    calculateTotalWithTax(
      totalPriceWithCustomerDiscount,
      setTotalPriceWithCustomerDiscountWithTax,
      tax
    );
  }, [tax, totalPriceWithCustomerDiscount, calculateTotalWithTax]);

  return (
    <Grid item container xs={12} sm={6} spacing={5}>
      <PricingDivider caption={t("Discount")} />

      {/* Retail Discount */}
      <Grid item container xs={12} spacing={5}>
        <ProductTaxFields
          t={t}
          value={totalPriceWithCustomerDiscount}
          valueWithTax={totalPriceWithCustomerDiscountWithTax}
          onChange={(event) =>
            handleTaxFieldsOnChange(event, setTotalPriceWithCustomerDiscount)
          }
          onChangeWithTax={(event) =>
            handleTaxFieldsOnChange(
              event,
              setTotalPriceWithCustomerDiscountWithTax
            )
          }
          onBlur={(event) => {
            handleTaxFieldsOnBlur(
              totalPriceWithCustomerDiscount,
              totalPriceWithRetailCustomerMargin,
              setTotalPriceWithCustomerDiscount,
              watchCustomerDiscount.prefix,
              "customerDiscount.value"
            );
          }}
          onBlurWithTax={(event) => {
            handleTaxFieldsOnBlurWithTax(
              totalPriceWithCustomerDiscountWithTax,
              totalPriceWithRetailCustomerMargin,
              setTotalPriceWithCustomerDiscount,
              setTotalPriceWithCustomerDiscountWithTax,
              watchCustomerDiscount.prefix,
              "customerDiscount.value"
            );
          }}
        />
        <Grid item xs={12}>
          <PriceAccordion>
            <>
              <MarginPrefixInput
                label={t("Discount type")}
                namePrefix="customerDiscount.prefix"
                {...{ errors, control, t }}
              />

              <MarginInput
                label={t("Retail discount")}
                name="customerDiscount.value"
                prefix={watchCustomerDiscount.prefix}
                {...{ errors, control, t }}
              />
            </>
          </PriceAccordion>
        </Grid>
      </Grid>
    </Grid>
  );
};
