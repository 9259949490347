import {
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";

import {
  Box,
  Typography,
  Button,
} from "@mui/material";

import { Link } from "react-router-dom";

import { CustomerOrderHistoryistoryDataType, CustomerTimelineItemType } from "../../../../types/customerTypes";
import { OrderStatusHistoryType } from "../../../../types/orderTypes";
import { ThemeColor } from "../../../@core/layouts/types";

import relativeTime from "../../../../helpers/getRelativeTime";

import { Chip } from "../../../chip";

interface Props extends CustomerTimelineItemType {
  handleFilter: () => void;
  statuses: any
}

const OrderStatusTimelineItem = (props: Props) => {
  const { data, statuses, handleFilter  } = props;

  const {
    id,
    orderNumber,
    data: historyData,
  } = data.data as CustomerOrderHistoryistoryDataType;
  const { modifiedOn, status } =
    historyData as OrderStatusHistoryType;

  const chipColor = statuses[status as unknown as string]
    ? statuses[status as unknown as string].color
    : ("grey" as ThemeColor);

  return (
    <TimelineItem>
      <TimelineSeparator sx={{ px: "4px" }}>
        <TimelineDot color={chipColor} />
        <TimelineConnector />
      </TimelineSeparator>

      <TimelineContent
        sx={{
          mt: 0,
          mb: (theme) => `${theme.spacing(3)} !important`,

          "&:hover": {
            cursor: "pointer",
            "& .order-filter-button": {
              display: "flex",
              transition: "all 0.3s ease-in-out",
              fontSize: "0.55rem",
              fontWeight: 700,
              minWidth: 0
            },
          },
        }}
      >
        <Box
          sx={{
            mb: 3,
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography
              sx={{
                mr: 2,
                fontWeight: 600,
                a: {
                  ":hover": {
                    textDecoration: "underline",
                  },
                },
              }}
            >
              Order{" "}
              <Link to={`/order/preview/${id}`}>
                #{orderNumber}
              </Link>{" "}
              <i>Updated</i>
            </Typography>
            <Button
              variant="outlined"
              size="small"
              className="order-filter-button"
              sx={{ display: "none" }}
              onClick={handleFilter}
            >
              Filter
            </Button>
          </Box>

          {!modifiedOn && (
            <Typography
              variant="caption"
              sx={{ color: "text.disabled" }}
            >
              NA
            </Typography>
          )}

          {modifiedOn && (
            <Typography
              variant="caption"
              sx={{ color: "text.disabled" }}
            >
              {relativeTime(new Date(), new Date(modifiedOn))}
            </Typography>
          )}
        </Box>

        {statuses[status as unknown as string] && (
          <Chip
            skin="light"
            size="small"
            label={statuses[status as unknown as string].name}
            color={chipColor}
            sx={{
              textTransform: "capitalize",
              "& .MuiChip-label": {
                lineHeight: "18px",
              },
            }}
          />
        )}
      </TimelineContent>
    </TimelineItem>
  );
}

export default OrderStatusTimelineItem