import { GridActionsCellItem, GridColumns } from "@mui/x-data-grid";
import { OrderType } from "../../../types/orderTypes";
import { Typography, Icon } from "@mui/material";
import relativeTime from "../../../helpers/getRelativeTime";
import StyledLink from "../../table/StyledLink";
import DropdownOrderStatusChip from "../status/dropdownOrderStatusChip";
import { OrderStatus } from "../../../types/orderStatusTypes";
import { ICompany } from "../../../hooks/useCompanies";
import { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { currencyFormatter } from "../../../helpers/currency-formatter";
import { AnyAbility } from "@casl/ability";

interface CellType {
  row: OrderType;
}

interface Props {
  t: any;
  companies: ICompany[];
  orderStatus: OrderStatus[];
  toggleDeleteOrderDialog: () => void;
  setSelectedOrder: Dispatch<SetStateAction<OrderType | undefined>>;
  ability: AnyAbility;
}

const getOrderListTableColumns = (props: Props): GridColumns<OrderType> => {
  const { t, companies, orderStatus, setSelectedOrder, toggleDeleteOrderDialog, ability } = props;

  const canDelete = ability.can('delete', 'order');

  const columns = [
    {
      flex: 0.3,
      minWidth: 240,
      field: "order",
      headerName: t("Orders"),
      renderCell: ({ row }: CellType) => {
        const num = row.orderNumber ? row.orderNumber : "NA";

        if (!row.customer) {
          return (
            <StyledLink
              to={`/order/preview/${row._id}`}
            >{`Anonymous - #${num}`}</StyledLink>
          );
        }

        return (
          <StyledLink
            to={`/order/preview/${row._id}`}
          >{`${row.customer.firstName} ${row.customer.lastName} - #${num}`}</StyledLink>
        );
      },
    },
    {
      flex: 0.15,
      minWidth: 125,
      field: "createdAt",
      headerName: t("Date"),
      renderCell: ({ row }: CellType) => {
        const current = new Date();
        const previous = new Date(row.createdAt);
        const time = relativeTime(current, previous);

        return <Typography variant="body2">{time}</Typography>;
      },
    },
    {
      flex: 0.15,
      minWidth: 150,
      field: "isRegistered",
      headerName: t("Registered"),
      type: "boolean",
      valueGetter: ({ row }: CellType) =>
        row.customer ? row.customer.isRegistered : false,
    },
    {
      flex: 0.15,
      minWidth: 150,
      field: "company",
      headerName: t("Company"),
      renderCell: ({ row }: CellType) => {
        const company = companies.filter((c) => c.id === row.company)[0];
        return (
          <Typography variant="body2">
            {company ? company.name : "Not Specified"}
          </Typography>
        );
      },
    },
    {
      flex: 0.15,
      minWidth: 110,
      field: "status",
      headerName: t("Status"),
      renderCell: ({ row }: CellType) => (
        <DropdownOrderStatusChip row={row} orderStatuses={orderStatus} />
      ),
    },
    {
      flex: 0.15,
      minWidth: 90,
      field: "total",
      headerName: t("Total"),
      renderCell: ({ row }: CellType) => (
        //0 is shipping fee
        <Typography variant="body2">
          {currencyFormatter(row.basketPrice.price + 0)}
        </Typography>
      ),
    },
    {
      flex: 0.1,
      minWidth: 130,
      sortable: false,
      field: "actions",
      headerName: t("Actions"),
      type: "actions",
      getActions: ({ row }: CellType) => [
        <GridActionsCellItem
          showInMenu
          sx={{ "& .MuiListItemIcon-root": { minWidth: 0 } }}
          icon={<Icon fontSize="small">visibility</Icon>}
          label={t("View")}
          // @ts-expect-error
          component={Link}
          to={`/order/preview/${row._id}`}
        />,
        <GridActionsCellItem
          showInMenu
          sx={{ "& .MuiListItemIcon-root": { minWidth: 0 } }}
          icon={<Icon fontSize="small">delete</Icon>}
          label={t("Delete")}
          disabled={!canDelete}
          onClick={() => {
            setSelectedOrder(row);
            toggleDeleteOrderDialog();
          }}
        />,
      ],
    },
  ];

  return columns;
};

export default getOrderListTableColumns;
