import {
  Grid,
  TextField,
  FormControl,
  FormHelperText,
  InputAdornment,
} from "@mui/material";
import {
  Control,
  FieldErrors,
  Controller,
  UseFormSetValue,
  useFormContext,
} from "react-hook-form";
import { PorezProductFormInputsT, ProductFormInputs } from "../../../types/productTypes";

import ProductPricingAdjusters from "./product-pricing-adjusters";
import { CategoryMarginAndDiscountT } from "../../../types/product-category-type";

interface Props {
  control: Control<ProductFormInputs | PorezProductFormInputsT, any>;
  t: any;
  errors: FieldErrors<ProductFormInputs | PorezProductFormInputsT>;
  setValue: UseFormSetValue<ProductFormInputs | PorezProductFormInputsT>;
  tax: number;
  isVariobelOnlyField: boolean;
  categoryMarginAndDiscount?: CategoryMarginAndDiscountT;
}

const ProductPricingFields = ({
  control,
  t,
  errors,
  setValue,
  tax,
  isVariobelOnlyField,
  categoryMarginAndDiscount,
}: Props) => {
  const { watch } = useFormContext<ProductFormInputs>();

  const watchPrice = Number(watch("price"));
  const taxOnPrice = watchPrice * (tax / 100);
  const priceWithTax = watchPrice + taxOnPrice;

  return (
    <Grid item container spacing={5}>
      {/* Price */}
      <Grid item xs={12} sm={3}>
        <FormControl fullWidth>
          <Controller
            name="price"
            control={control}
            rules={{
              required: true,
              validate: (value) => value > 0,
            }}
            render={({ field: { value, onChange } }) => (
              <TextField
                value={value}
                label={`${t("Purchase price")}`}
                type="number"
                onChange={onChange}
                placeholder=""
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
                error={Boolean(errors.price)}
              />
            )}
          />
          {errors.price && (
            <FormHelperText sx={{ color: "error.main" }}>
              {t("Must be greater than 0")}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={3}>
        <TextField
          disabled
          type="number"
          label={t("Purchase price with VAT")}
          fullWidth
          value={priceWithTax}
        />
      </Grid>

      {isVariobelOnlyField && (
        <ProductPricingAdjusters
          {...{
            t,
            control: control as unknown as Control<ProductFormInputs, any>,
            errors: errors as FieldErrors<ProductFormInputs>,
            setValue: setValue as UseFormSetValue<ProductFormInputs>,
            tax,
            isVariobelOnlyField,
            categoryMarginAndDiscount,
          }}
        />
      )}
    </Grid>
  );
};

export default ProductPricingFields;
