import {
  Grid,
  TextField,
  FormControl,
  FormHelperText,
  InputAdornment
} from "@mui/material";
import { Control, FieldErrors, Controller } from 'react-hook-form';
import { ProductFormInputs } from '../../../types/productTypes';

interface Props {
  label: string;
  name: string;
  control: Control<ProductFormInputs, any>;
  t: any;
  errors: FieldErrors<ProductFormInputs>;
}

export const NumericInput = ({ label, name, control, errors, t }: Props) => (
  <Grid item xs={12} sm={6}>
    <FormControl fullWidth>
      <Controller
        name={name}
        control={control}
        rules={{
          required: true ,
          validate: (value) => {
            if (name === "width" || name === "height") return true;
            return value > 0
          },
        }}
        render={({ field: { value, onChange } }) => (
          <TextField
            value={value}
            label={label}
            type="number"
            onChange={onChange}
            placeholder=""
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">mm</InputAdornment>
              ),
            }}
            error={Boolean(errors[name])} />
        )} />
      {errors[name] && (
        <FormHelperText sx={{ color: "error.main" }}>
          {t("Must be greater than 0")}
        </FormHelperText>
      )}
    </FormControl>
  </Grid>
);
