import {
  Grid, FormControl,
  FormHelperText
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import ProductImageFileUploader from '../product-image-file-uploader';

import { Control, FieldErrors, UseFormSetValue } from 'react-hook-form';
import { ProductFormInputs, ProductFormT } from '../../../types/productTypes';

interface Props {
  control: Control<ProductFormInputs, any>;
  t: any;
  errors: FieldErrors<ProductFormInputs>;
  setValue:  UseFormSetValue<ProductFormT>;
}

const ImageUploaderFields = ({ control, t, errors, setValue }: Props) => {
  const { watch } = useFormContext();
  const watchImages = watch("images") as string[];

  return (
  <Grid item container spacing={5}>
    <Grid item container xs={12} spacing={2} alignItems="center">
      {watchImages.map((image, index) => (
        <Grid item xs={12} key={index}>
          <img
            src={image}
            width={400}
            height="100%"
            style={{
              objectFit: "contain",
            }}
            alt={`product-${index}`} />
        </Grid>
      ))}
    </Grid>

    <Grid item xs={12}>
        <ProductImageFileUploader setValue={setValue} />
    </Grid>

    <Grid item xs={12}>
      <FormControl fullWidth>
        <Controller
          name="images"
          control={control}
          rules={{
            required: true,
            validate: (value) => value.length > 0,
          }}
          render={() => <></>} />
        {errors.images && (
          <FormHelperText sx={{ color: "error.main" }}>
            {t("This field cannot be empty")}
          </FormHelperText>
        )}
      </FormControl>
    </Grid>
  </Grid>
)};

export default ImageUploaderFields
