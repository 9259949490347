import { Dispatch, SetStateAction } from "react";

export const RoundToDecimal = (num: number, dp: number) => {
  const formatter = new Intl.NumberFormat('en', { minimumFractionDigits: dp, maximumFractionDigits: dp });
  return parseFloat(formatter.format(num));
}
export   const CallbackCalculateTotalWithTax = (
  priceWithMargin: number,
  setTotalPriceWithTax: Dispatch<SetStateAction<number>>,
  tax: number
) => {
  if (priceWithMargin > 0) {
    const taxValue = priceWithMargin * (tax / 100);
    let newValue = taxValue + priceWithMargin;

    newValue = RoundToDecimal(newValue, 2);
    setTotalPriceWithTax(newValue);
  }
};
