import React from "react";
import { StyledTextField } from "./styled-textfield";
import { StyledTabContentContainer } from "./styled-tab-content-container";
import { Typography, InputAdornment } from "@mui/material";

interface Props {
  colorHexField: string;
  handleHexColorChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  showLabel?: boolean;
}

const HexColorTab = (props: Props) => {
  const { colorHexField, handleHexColorChange, showLabel = false } = props;

  return (
    <StyledTabContentContainer>
      {showLabel && (
        <Typography variant="caption" sx={{ fontWeight: 700 }}>
          HEX
        </Typography>
      )}

      <StyledTextField
        variant="outlined"
        size="small"
        type="text"
        InputProps={{
          startAdornment: <InputAdornment position="start">#</InputAdornment>,
        }}
        fullWidth
        sx={{ "& .MuiOutlinedInput-root": { width: "unset" } }}
        inputProps={{ maxLength: 6 }}
        value={colorHexField}
        onChange={handleHexColorChange}
      />
    </StyledTabContentContainer>
  );
};

export default HexColorTab;
