// ** React Imports
import { createContext, useEffect, useState, ReactNode } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// ** Axios
import axios from "axios";

// ** Config
import authConfig from "../configs/auth";

// ** Types
import {
  AuthValuesType,
  RegisterParams,
  LoginParams,
  ErrCallbackType,
  UserDataType,
} from "./types";

// ** Defaults
const defaultProvider: AuthValuesType = {
  user: null,
  loading: true,
  setUser: () => null,
  setLoading: () => Boolean,
  isInitialized: false,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  setIsInitialized: () => Boolean,
  register: () => Promise.resolve(),
};

const AuthContext = createContext(defaultProvider);

type Props = {
  children: ReactNode;
};

const AuthProvider = ({ children }: Props) => {
  // ** States
  const [user, setUser] = useState<UserDataType | null>(defaultProvider.user);
  const [loading, setLoading] = useState<boolean>(defaultProvider.loading);
  const [isInitialized, setIsInitialized] = useState<boolean>(
    defaultProvider.isInitialized
  );

  // ** Hooks
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const initAuth = async (): Promise<void> => {
      setLoading(true);
      await axios
        .get(authConfig.authEndpoint, { withCredentials: true })
        .then(async (response) => {
          setLoading(false);
          setUser({ ...response.data.userData });
        })
        .catch(() => {
          setUser(null);
          setLoading(false);
        });
      setIsInitialized(true);
    };
    initAuth();
  }, [isInitialized]);

  const handleLogin = (
    params: LoginParams,
    errorCallback?: ErrCallbackType
  ) => {
    axios
      .post(authConfig.loginEndpoint, params, { withCredentials: true })
      .then((res) => {
        const returnUrl = searchParams.get("returnUrl");

        setUser({ ...res.data.userData });
        // window.localStorage.setItem(
        //   "userData",
        //   JSON.stringify(res.data.userData)
        // );

        const redirectURL = returnUrl && returnUrl !== "/" ? returnUrl : "/";

        navigate(redirectURL, { replace: true });
      })
      .catch((err) => {
        if (errorCallback) errorCallback(err.response.data.error);
      });
  };

  const handleLogout = () => {
    axios
      .post(authConfig.logoutEndpoint, {}, { withCredentials: true })
      .then(() => {
        setUser(null);
        setIsInitialized(false);
        // window.localStorage.removeItem("userData");
        navigate("/login");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRegister = (
    params: RegisterParams,
    errorCallback?: ErrCallbackType
  ) => {
    axios
      .post(authConfig.registerEndpoint, params)
      .then((res) => {
        if (res.data.error) {
          if (errorCallback) errorCallback(res.data.error);
        } else {
          handleLogin({ email: params.email, password: params.password });
        }
      })
      .catch((err: { [key: string]: string }) =>
        errorCallback ? errorCallback(err) : null
      );
  };

  const values = {
    user,
    loading,
    setUser,
    setLoading,
    isInitialized,
    setIsInitialized,
    login: handleLogin,
    logout: handleLogout,
    register: handleRegister,
  };

  return (
    <AuthContext.Provider value={values}>
      {isInitialized && children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
