import {
  createContext,
  ReactNode,
  useReducer,
} from "react";

import {
  Alert,
  Slide,
  SlideProps,
  Snackbar,
  Typography,
  Box,
} from "@mui/material";

import { useTranslation } from "react-i18next";

import { AlertProviderValuesType, AlertStateType } from "./types";
import { alertReducer } from "./reducers";


type TransitionProps = Omit<SlideProps, "direction">;

interface AlertWrapperProps {
  children: ReactNode;
}

const initialState: AlertStateType = {
  duration: 3000,
  message: "This is an info message ",
  open: false,
  severity: "info", //'success' | 'info' | 'warning' | 'error'
};

const defaultProvider: AlertProviderValuesType = {
  closeAlert: () => null,
  showErrorAlert: () => null,
  showWarningAlert: () => null,
  showInfoAlert: () => null,
  showSuccessAlert: () => null,
}

const AlertContext = createContext(defaultProvider);

const transition = (props: TransitionProps) => (
  <Slide {...props} direction="right" />
);

const AlertProvider = ({ children }: AlertWrapperProps) => {
  const [state, dispatch] = useReducer(alertReducer, initialState);
  const { t } = useTranslation();

  const handleClose = () => {
    dispatch({
      type: "CLOSE_ALERT",
    });
  };

  const closeAlert = () => {
    dispatch({
      type: "CLOSE_ALERT",
    });
  }

  const showErrorAlert = (message: string, duration?: number) => {
    const time = duration ?? 3000

    dispatch({
      type: "SHOW_ERROR",
      payload: {
        ...state,
        message,
        duration: time
      }
    });
  }

  const showWarningAlert = (message: string, duration?: number) => {
    const time = duration ?? 3000

    dispatch({
      type: "SHOW_WARNING",
      payload: {
        ...state,
        message,
        duration: time

      }
    });
  }

  const showInfoAlert = (message: string, duration?: number) => {
    const time = duration ?? 3000

    dispatch({
      type: "SHOW_INFO",
      payload: {
        ...state,
        message,
        duration: time
      }
    });
  }

  const showSuccessAlert = (message: string, duration?: number) => {
    const time = duration ?? 3000

    dispatch({
      type: "SHOW_SUCCESS",
      payload: {
        ...state,
        message,
        duration: time
      }
    });
  }

  const values = {
    closeAlert,
    showErrorAlert,
    showWarningAlert,
    showInfoAlert,
    showSuccessAlert
  }

  return (
    <AlertContext.Provider value={values}>
      <Box>
        <Snackbar
          open={state.open}
          autoHideDuration={state.duration}
          onClose={handleClose}
          TransitionComponent={transition}
        >
          <Alert onClose={handleClose} severity={state.severity}>
            <Typography
              variant="body1"
              sx={{
                maxWidth: "300px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
            >
              {t(state.message)}
            </Typography>
          </Alert>
        </Snackbar>

        {children}
      </Box>
    </AlertContext.Provider>
  );
};

export { AlertContext, AlertProvider }
