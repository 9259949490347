import chroma from "chroma-js";
import { findClosestOnyx } from "../../../helpers/onyx-matcher";
import {
  AddOrderLineItemT,
  AddOrderListLineItemType,
  BasketPriceTypeTemp,
  LineItemType,
  OrderLineItemT,
} from "../../../types/orderTypes";
import { Dispatch, SetStateAction } from "react";
import { Customer } from "../../../types/customerTypes";
import {
  calculateDiscount,
  calculateOnSaleDiscountAmount,
  calculateOnSaleDiscountValue,
} from "../../../helpers/calculateDiscount";
import { ProductType } from "../../../types/productTypes";

export const calculatePPU = (
  glassWidth: number,
  glassHeight: number,
  ppm2: number
) => {
  if (ppm2 === 0) {
    return 0;
  }

  return ((glassWidth * glassHeight) / 1000000) * ppm2;
};

export const subtotal = (
  items: (AddOrderLineItemT | OrderLineItemT)[]
) => {
    return items
      .map((item) => {
        if (!isVariobelLineItem(item)) {
          return item.price * item.qty;
        }

        return item.ppu * item.qty;
      })
      .reduce((sum, i) => sum + i, 0);
};

const BASE_PRODUCT_ITEM = {
  id: 0,
  product: null,
  price: 0,
  qty: 1
};

const VARIOBEL_INIT_PRODUCT_ITEM = {
  name: "",
  print: {
    type: "color",
    value: JSON.stringify({
      hex: "#f00",
      onyxMatch: findClosestOnyx(chroma("#F00").rgb()),
    }),
  },
  glassWidth: 1000,
  glassHeight: 1000,
  description: "",
  ppu: 0,
  ppm2: 0,
}

const POREZ_INIT_PRODUCT_ITEM = {
  width: 0,
  height: 0
}

export const INITIAL_ADD_VARIOBEL_PRODUCT_ITEM = {
  ...BASE_PRODUCT_ITEM,
  ...VARIOBEL_INIT_PRODUCT_ITEM
}

export const INITIAL_ADD_POREZ_PRODUCT_ITEM = {
  ...BASE_PRODUCT_ITEM,
  ...POREZ_INIT_PRODUCT_ITEM
};

export const INITIAL_ADD_PRODUCT_BASKET_PRICE = {
  price: 0,
  tax: 0,
  subtotal: 0,
  taxRate: 0,
  qtyTotal: 0,
  appliedDiscounts: {
    onSale: [],
    customer: {
      value: 0,
      prefix: "%",
    },
    order: {
      value: 0,
      prefix: "%",
    },
  },
} as BasketPriceTypeTemp;

export const calculateBaseBasketPrice = (
  itemList: (OrderLineItemT | AddOrderLineItemT)[],
  tax: number,
  basketPrice: BasketPriceTypeTemp
) => {
  const invoiceSubtotal = subtotal(itemList);
  const invoiceTaxes = (tax / 100) * invoiceSubtotal;
  const invoiceTotal = invoiceTaxes + invoiceSubtotal;

  const qtyTotal = itemList.reduce((total, row) => {
    return total + row.qty;
  }, 0);

  const _basketPrice: BasketPriceTypeTemp = {
    ...basketPrice,
    price: invoiceTotal,
    tax: invoiceTaxes,
    subtotal: invoiceSubtotal,
    taxRate: tax,
    qtyTotal,
  };

  return _basketPrice;
};

export const updateBasketPriceWithDiscounts = (
  customer: Customer,
  itemList: OrderLineItemT[] | AddOrderLineItemT[],
  basketPrice: BasketPriceTypeTemp
) => {
  let totalOnSale = 0;

  const onSaleDiscounts =
    [] as BasketPriceTypeTemp["appliedDiscounts"]["onSale"];
  const { discount } = customer;
  const { value, prefix } = discount;

  const customerDiscountable = {
    value: value as number,
    prefix: prefix as string,
  };

  itemList.forEach((item) => {
    if(!isVariobelLineItem(item)) return;

    const { product, qty } = item;
    if (!product) return;


    if (product.onSale) {
      const discount = calculateOnSaleDiscountValue(product, customer);
      const amount = calculateOnSaleDiscountAmount(product, customer);

      onSaleDiscounts.push({
        discount: discount * qty,
        amount,
        productID: product._id,
        qty,
      });
    }
  });

  const customerDiscount = calculateDiscount(
    basketPrice.subtotal,
    customerDiscountable
  );

  if (onSaleDiscounts.length) {
    totalOnSale = onSaleDiscounts.reduce((total, item) => {
      return total + item.discount;
    }, 0);
  }

  const totalPrice = basketPrice.price - totalOnSale - customerDiscount;

  const _basketPrice: BasketPriceTypeTemp = {
    ...basketPrice,
    price: totalPrice,
    appliedDiscounts: {
      ...basketPrice.appliedDiscounts,
      onSale: onSaleDiscounts,
      customer: customerDiscountable,
    },
  };

  return _basketPrice;
};

export const handleAddProductLineItemUpdate = (
  lineItem: AddOrderLineItemT,
  itemList: AddOrderLineItemT[],
  setItemList: Dispatch<SetStateAction<AddOrderLineItemT[]>>,
  tax: number,
  basketPrice: BasketPriceTypeTemp,
  setBasketPrice: Dispatch<SetStateAction<BasketPriceTypeTemp>>
) => {
  const _itemList = itemList.map((item) => {
    if (item.id === lineItem.id) {
      return lineItem;
    }

    return item;
  });

  const _basketPrice = calculateBaseBasketPrice(_itemList, tax, basketPrice);

  setBasketPrice(_basketPrice);
  setItemList(_itemList);
};

export const updateItemPricing = (
  item: LineItemType | AddOrderListLineItemType
) => {
  const ppm2 = item.price;
  const ppu = calculatePPU(item.glassWidth, item.glassHeight, item.ppm2);
  const price = item.ppu * item.qty;

  return { ppm2, ppu, price }
}


export const updateProdcutPricingWithMargins = (
  product: ProductType,
  customer: Customer | null,
) => {
  const {
    price,
    retailMargin,
    // retailMarginPrefix,
    retailCustomerMargin,
    // retailCustomerMarginPrefix,
    wholesaleCustomerMargin,
    // wholesaleCustomerMarginPrefix,
  } = product;

  let updatedPrice = price; // unregistered customer
  let margin = retailMargin;

  if (customer && customer.isRegistered) {
    margin = retailCustomerMargin; // retail registered customer

    if (customer && customer.isWholesaleCustomer) {
      margin = wholesaleCustomerMargin;
    }
  }

  if (margin.prefix === "%") {
    const _margin = price * (margin.value/100);
    updatedPrice += _margin
  } else {
    updatedPrice += margin.value
  }

  return { ...product, price: updatedPrice };
};

// A runtime type check to distingiush porez or variobel order lineitems
export const isVariobelLineItem = (obj: any): obj is LineItemType => {
  return "glassWidth" in obj;
}
