import { Box, Typography, TypographyProps, styled } from '@mui/material';
import React from 'react'
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import Translations from '../layouts/components/Translations';
import DropzoneWrapper from '../@core/styles/libs/react-dropzone';

interface Props {
  getRootProps: <T extends DropzoneRootProps>(props?: T) => T;
  getInputProps: <T extends DropzoneInputProps>(props?: T) => T;
  mainText: string;
  allowedFilesText: string;
  maxFileSizeText: string;
}

const Img = styled("img")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    marginRight: theme.spacing(10),
  },
  [theme.breakpoints.down("md")]: {
    marginBottom: theme.spacing(4),
  },
  [theme.breakpoints.down("sm")]: {
    width: 250,
  },
}));

// Styled component for the heading inside the dropzone area
const HeadingTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(4),
  },
}));

const FileUploader = (props: Props) => {
  const {
    getRootProps,
    getInputProps,
    allowedFilesText,
    mainText,
    maxFileSizeText,
  } = props;

  return (
    <DropzoneWrapper>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />

        <Box
          sx={{
            display: "flex",
            flexDirection: ["column", "column", "row"],
            alignItems: "center",
          }}
        >
          <Img width={300} alt="Upload img" src="/images/misc/upload.png" />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: ["center", "center", "inherit"],
            }}
          >
            <HeadingTypography variant="h5">
              <Translations
                text={mainText}
              />
            </HeadingTypography>
            <Typography color="textSecondary">
              <Translations text={allowedFilesText} />
            </Typography>
            <Typography color="textSecondary">
              <Translations text={maxFileSizeText} />
            </Typography>
          </Box>
        </Box>
      </div>
    </DropzoneWrapper>
  );
}

export default FileUploader