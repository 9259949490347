import React from 'react';
import Circle, { CircleProps as CircleSwatchProps } from '@uiw/react-color-circle'
import { Box, Typography } from "@mui/material"


const ColorSwatches = (props: CircleSwatchProps) => {
  return (
    <Box
      sx={{
        mt: { xs: 1, md: 3 },
        py: { xs: 0.5, md: 0 },
        gap: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: { xs: "flex-start", md: "center" },
        overflowX: { xs: "auto", md: "unset" },

        "& .w-color-swatch": {
          justifyContent: "center",
          minHeight: 44,
          flexWrap: { xs: "nowrap !important", sm: "wrap !important" },

          "& > div": {
            width: { xs: "32px !important", md: "42px !important" },
            height: { xs: "32px !important", md: "42px !important" },
          },
        },
      }}
    >
      <Typography
        variant="caption"
        sx={{
          fontWeight: 700,
          display: { xs: "none", md: "block" },
          fontSize: "0.85rem",
        }}
      >
        Populárne Farby
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Circle {...props} />
      </Box>
    </Box>
  );
}

export default ColorSwatches