// ** React Imports
import { Dispatch, SetStateAction } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";

// ** Third Party Imports
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { useTranslation } from "react-i18next";

// ** Custom Components
import { Avatar } from "../../comps/avatar";

// ** Types
import { Customer } from "../../types/customerTypes";

// ** Utils Import
import { getInitials } from "../../helpers/get-initials";

interface Props {
  customerData: Customer;
  setCustomerData: Dispatch<SetStateAction<Customer | undefined>>;
  toggleEditCustomerDialog: () => void;
}


export const CustomerViewLeftScreen = ({
  customerData,
  setCustomerData,
  toggleEditCustomerDialog,
}: Props) => {
  const { t } = useTranslation();

  if (!customerData) {
    return null;
  }

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Card>
          <CardContent
            sx={{
              pt: 15,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Avatar
              skin="light"
              variant="rounded"
              sx={{
                width: 120,
                height: 120,
                fontWeight: 600,
                mb: 4,
                fontSize: "3rem",
              }}
            >
              {getInitials(
                `${customerData.firstName} ${customerData.lastName}`
              )}
            </Avatar>
            <Typography variant="h6" sx={{ mb: 4 }}>
              {customerData.firstName} {customerData.lastName}
            </Typography>
          </CardContent>

          <CardContent>
            <Typography variant="h6">{t("Details")}</Typography>
            <Divider sx={{ mt: 4 }} />
            <Box sx={{ pt: 2, pb: 1 }}>
              <Box sx={{ display: "flex", mb: 2.7 }}>
                <Typography
                  variant="subtitle2"
                  sx={{ mr: 2, color: "text.primary" }}
                >
                  {`${t("Full name")}:`}
                </Typography>
                <Typography variant="body2">
                  {customerData.firstName} {customerData.lastName}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", mb: 2.7 }}>
                <Typography
                  variant="subtitle2"
                  sx={{ mr: 2, color: "text.primary" }}
                >
                  {`${t("Email")}:`}
                </Typography>
                <Typography variant="body2">{customerData.email}</Typography>
              </Box>
              <Box sx={{ display: "flex", mb: 2.7 }}>
                <Typography
                  sx={{ mr: 2, fontWeight: 500, fontSize: "0.875rem" }}
                >
                  {t("Role")}:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ textTransform: "capitalize" }}
                >
                  Customer
                </Typography>
              </Box>
              {customerData.phone && (
                <Box sx={{ display: "flex", mb: 2.7 }}>
                  <Typography
                    sx={{ mr: 2, fontWeight: 500, fontSize: "0.875rem" }}
                  >
                    {`${t("Phone")}:`}
                  </Typography>
                  <Typography variant="body2">{customerData.phone}</Typography>
                </Box>
              )}
              <Box sx={{ display: "flex", alignItems: "center", mb: 2.7 }}>
                <Typography
                  sx={{ mr: 2, fontWeight: 500, fontSize: "0.875rem" }}
                >
                  {`${t("GDPR consent date")}:`}
                </Typography>
                <Icon
                  sx={{
                    fontWeight: "body2.fontWeight",
                    fontSize: "body2.fontSize",
                    color: "text.secondary",
                  }}
                >
                  {customerData.gdprConsent
                    ? "check_box"
                    : "check_box_outline_blank"}
                </Icon>
              </Box>
              <Box sx={{ display: "flex", mb: 2.7 }}>
                <Typography
                  sx={{ mr: 2, fontWeight: 500, fontSize: "0.875rem" }}
                >
                  {`${t("GDPR date")}:`}
                </Typography>
                <Typography variant="body2">
                  {format(
                    parseISO(customerData.gdprConsentDate),
                    "MMM d, yyyy"
                  )}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2.7 }}>
                <Typography
                  sx={{ mr: 2, fontWeight: 500, fontSize: "0.875rem" }}
                >
                  {`${t("Marketingový súhlas")}:`}
                </Typography>
                <Icon
                  sx={{
                    fontWeight: "body2.fontWeight",
                    fontSize: "body2.fontSize",
                    color: "text.secondary",
                  }}
                >
                  {customerData.marketingConsent
                    ? "check_box"
                    : "check_box_outline_blank"}
                </Icon>
              </Box>
              {customerData.marketingConsentDate && (
                <Box sx={{ display: "flex", mb: 2.7 }}>
                  <Typography
                    sx={{ mr: 2, fontWeight: 500, fontSize: "0.875rem" }}
                  >
                    {`${t("Marketing consent date")}:`}
                  </Typography>
                  <Typography variant="body2">
                    {format(
                      parseISO(customerData.marketingConsentDate),
                      "MMM d, yyyy"
                    )}
                  </Typography>
                </Box>
              )}
              <Box sx={{ display: "flex", alignItems: "center", mb: 2.7 }}>
                <Typography
                  sx={{ mr: 2, fontWeight: 500, fontSize: "0.875rem" }}
                >
                  {`${t("Registered")}:`}
                </Typography>
                <Icon
                  sx={{
                    fontWeight: "body2.fontWeight",
                    fontSize: "body2.fontSize",
                    color: "text.secondary",
                  }}
                >
                  {customerData.isRegistered
                    ? "check_box"
                    : "check_box_outline_blank"}
                </Icon>
              </Box>

              {customerData.registerDate && (
                <Box sx={{ display: "flex", mb: 2.7 }}>
                  <Typography
                    sx={{ mr: 2, fontWeight: 500, fontSize: "0.875rem" }}
                  >
                    {`${t("Registration date")}:`}
                  </Typography>
                  <Typography variant="body2">
                    {format(parseISO(customerData.registerDate), "MMM d, yyyy")}
                  </Typography>
                </Box>
              )}

              <Box sx={{ display: "flex" }}>
                <Typography
                  sx={{ mr: 2, fontWeight: 500, fontSize: "0.875rem" }}
                >
                  {`${t("Discount")}:`}
                </Typography>
                <Typography variant="body2">
                  {customerData.discount.value}{customerData.discount.prefix}
                </Typography>
              </Box>
            </Box>
          </CardContent>

          <CardActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              sx={{ mr: 2 }}
              onClick={toggleEditCustomerDialog}
            >
              {t("Edit customer")}
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};
