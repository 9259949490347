import { Dispatch, SetStateAction } from "react";
import {
  Dialog,
  DialogTitle,
  Icon,
  IconButton,
  DialogContent,
  Grid,
  Button,
  LinearProgress,
} from "@mui/material";

import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField } from "formik-mui";


import { useTranslation } from "react-i18next";
import { addToLocales, updateLocales } from "../../../../store/apps/locales";

import { AppDispatch } from "../../../../store";
import { FormValuesType, TranslationType, validationSchema } from "../../../../types/translationTypes";
import { useAlertContext } from "../../../../hooks/useAlertContext";

const initialValues = {
  cs: "",
  en: "",
  sk: "",
};

interface Props {
  open: boolean;
  handleClose: () => void;
  type?: "edit" | "add";
  editID?: number;
  editableValues?: FormValuesType;
  translations: TranslationType[];
  dispatch: AppDispatch;
  setIsLocaleLoading: Dispatch<SetStateAction<boolean>>;
}

const TransalationDialog = (props: Props) => {
  const {
    open,
    handleClose,
    type = "add",
    editID,
    editableValues,
    translations,
    dispatch,
    setIsLocaleLoading,
  } = props;
  const { t } = useTranslation();
  const { showErrorAlert, showSuccessAlert } = useAlertContext()

  const onSubmit = async (
    values: FormValuesType,
    formikHelpers: FormikHelpers<FormValuesType>
  ) => {
    const { setSubmitting, resetForm } = formikHelpers;
    const { cs, en, sk } = values;

    if (type === "add") {
      const data = { id: translations.length, cs, en, sk };

      setIsLocaleLoading(true);

      await dispatch(addToLocales(data))
        .unwrap()
        .then(() => {
          showSuccessAlert(t("Translation added"));
        })
        .catch(() => {
          showErrorAlert(t("Failed to add Translation"));
        })
        .finally(() => {
          setIsLocaleLoading(false);

          setSubmitting(false);
          handleClose();
          resetForm();
        });
    }

    if (type === "edit" && editID) {
      const data = { id: editID, cs, en, sk };

      setIsLocaleLoading(true);

      await dispatch(updateLocales({ id: editID, data }))
        .unwrap()
        .then(() => {
          showSuccessAlert(t("Translation added"));
        })
        .catch(() => {
          showErrorAlert(t("Failed to add Translation"));
        })
        .finally(() => {
          setIsLocaleLoading(false);

          setSubmitting(false);
          handleClose();
          resetForm();
        });
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      scroll="body"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        {type === "add" ? t("Add") : t("Edit")} {t("translation").toLowerCase()}
        <IconButton
          size="small"
          onClick={handleClose}
          sx={{ position: "absolute", right: "1rem", top: "1rem" }}
        >
          <Icon>close</Icon>
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ pt: (theme) => `${theme.spacing(2)} !important` }}>
        <Formik
          {...{
            initialValues:
              type === "add"
                ? initialValues
                : (editableValues as FormValuesType),
                validationSchema,
            onSubmit,
          }}
        >
          {({ isSubmitting, submitForm }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Field
                    component={TextField}
                    name="cs"
                    autoComplete="off"
                    type="text"
                    label="Czech (cs)"
                    placeholder="text"
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    component={TextField}
                    name="en"
                    disabled={type === "edit"}
                    autoComplete="off"
                    type="text"
                    label="English (en)"
                    placeholder="text"
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    component={TextField}
                    name="sk"
                    autoComplete="off"
                    type="text"
                    label="Slovak (sk)"
                    placeholder="text"
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item xs={12}>
                  {isSubmitting && <LinearProgress />}
                </Grid>

                <Grid item mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                      {type === "add" ? t("Add") : t("Edit")} {t("translation").toLowerCase()}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default TransalationDialog;
