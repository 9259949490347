import {
  Accordion,
  AccordionSummary,
  Icon,
  AccordionDetails,
  Grid
} from "@mui/material";
import { ReactNode } from "react";

interface Props {
  children: ReactNode
}

const PriceAccordion = ({ children }: Props) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<Icon color="secondary">expand_circle_down</Icon>}
        sx={{
          flexDirection: "row-reverse",
          padding: "0rem 0.5rem",

          "& .MuiAccordionSummary-expandIconWrapper": {
            transform: "none !important",
          },
        }}
      />
      <AccordionDetails>
        <Grid item container spacing={5}>
          {children}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );};

export default PriceAccordion;
