import Box from "@mui/material/Box";
import { ownWallpaperThumb } from "./select-print-dialog/helpers";
import {
  AddOrderListLineItemType,
  LineItemType,
  PrintTypeTemp,
} from "../../../types/orderTypes";
import { PrintThumbImage } from "../orderDetailsSummary/printThumbImage";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SelectPrintDialog } from "./select-print-dialog";
import { useAlertContext } from "../../../hooks/useAlertContext";
import { useTranslation } from "react-i18next";

interface Props<T> {
  lineItem: T;
  itemList: T[];
  setItemList: Dispatch<SetStateAction<T[]>>;
}

export const PrintCell = <
  T extends LineItemType | AddOrderListLineItemType
>(
  props: Props<T>
) => {
  const { lineItem, itemList, setItemList } = props;
  const { showWarningAlert } = useAlertContext();
  const { t } = useTranslation();
  const [openSelectPrintDialog, setOpenSelectPrintDialog] = useState(false);

  const [tempLineItem, setTempLineItem] = useState<LineItemType | AddOrderListLineItemType>(lineItem);

  useEffect(() => {
    if (lineItem) {
      setTempLineItem(lineItem)
    }
  }, [lineItem])

  const handleSelectPrintDialogClose = () => {
    setOpenSelectPrintDialog(false);
  };

  const handleSelectPrintOpen = () => {
    if (!tempLineItem.product) {
      return showWarningAlert(t("Please select a product"))
    }

    return setOpenSelectPrintDialog(true);
  };

  const handleLineItemPrintChange = (newPrint: PrintTypeTemp) => {
    const _lineItem = {
      ...tempLineItem,
      print: newPrint,
    };

    const _orderList = itemList.map((item) => {
      if (
        ("id" in item &&
          item.id === (_lineItem as AddOrderListLineItemType).id) ||
        ("_id" in item && item._id === (_lineItem as LineItemType)._id)
      ) {
        return _lineItem;
      }

      return item;
    });

    setItemList(_orderList as T[]);
    handleSelectPrintDialogClose();
  };

  return (
    <>
      <Box
        onClick={handleSelectPrintOpen}
        sx={{
          display: "flex",
          "&:hover": {
            cursor: "pointer",
            opacity: 0.9,
          },
        }}
      >
        <PrintThumbImage
          lineItem={tempLineItem}
          isWallpaperSpread={false}
          src={ownWallpaperThumb(tempLineItem)}
        />
      </Box>

      <SelectPrintDialog
        selectedLineItem={tempLineItem}
        setSelectedLineItem={setTempLineItem}
        handleSelectPrint={handleLineItemPrintChange}
        open={openSelectPrintDialog}
        onClose={handleSelectPrintDialogClose}
      />
    </>
  );
};
