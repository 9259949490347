import { Box, Grid, TextField } from "@mui/material";
import {
  Control,
  FieldErrors,
  UseFormSetValue,
  useFormContext,
} from "react-hook-form";
import { ProductFormInputs } from "../../../types/productTypes";

import { MarginPrefixInput, MarginInput } from "./MarginFields";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { updatePriceValueWith } from "./utils/updatePriceValueWith";
import PriceAccordion from "./PriceAccordion";
import { PricingDivider, SectionDivider } from "./SectionDivider";
import ProductTaxFields from "./ProductTaxFields";
import { CallbackCalculateTotalWithTax, RoundToDecimal } from "./utils/helpers";
import { UnRegisteredCustomersOnSaleFields } from "./onSale/un-registered-customers-on-sale-fields";
import { RegisteredRetailCustomersOnSaleFields } from "./onSale/registered-retail-customers-on-sale-fields";
import { RegisteredWholesaleCustomersOnSaleFields } from "./onSale/registered-wholesale-customers-on-sale-fields";
import { CheckboxField as OnSaleCheckbox } from "./CheckboxField";
import { DatePickerField as OnSaleDatePicker } from "./DatePickerField";
import { CategoryMarginAndDiscountT } from "../../../types/product-category-type";

interface Props {
  control: Control<ProductFormInputs, any>;
  t: any;
  errors: FieldErrors<ProductFormInputs>;
  setValue: UseFormSetValue<ProductFormInputs>;
  tax: number;
  isVariobelOnlyField: boolean;
  categoryMarginAndDiscount?: CategoryMarginAndDiscountT;
}

const ProductPricingAdjusters = ({
  control,
  t,
  errors,
  setValue,
  tax,
  isVariobelOnlyField,
  categoryMarginAndDiscount,
}: Props) => {
  const { watch } = useFormContext<ProductFormInputs>();

  const watchOnSale = watch("onSale");

  const watchSalesDiscount = watch("saleDiscount");
  const watchCustomerDiscount = watch("customerDiscount");
  const watchWholesaleDiscount = watch("wholesaleDiscount");

  const watchRetailMargin = watch("retailMargin");
  const watchRetailCustomerMargin = watch("retailCustomerMargin");
  const watchWholesaleCustomerMargin = watch("wholesaleCustomerMargin");

  const watchPrice = Number(watch("price"));

  const [
    totalPriceWithUnregisteredCustomerMargin,
    setTotalPriceWithUnregisteredCustomerMargin,
  ] = useState(0);

  const [
    totalPriceWithUnregisteredCustomerMarginWithTax,
    setTotalPriceWithUnregisteredCustomerMarginWithTax,
  ] = useState(0);

  const [
    totalPriceWithUnregisteredCustomerDiscount,
    setTotalPriceWithUnregisteredCustomerDiscount,
  ] = useState(0);

  const [
    totalPriceWithUnregisteredCustomerDiscountWithTax,
    setTotalPriceWithUnregisteredCustomerDiscountWithTax,
  ] = useState(0);

  const [
    totalPriceWithRegisteredCustomerMargin,
    setTotalPriceWithRegisteredCustomerMargin,
  ] = useState(0);

  const [
    totalPriceWithRegisteredCustomerMarginWithTax,
    setTotalPriceWithRegisteredCustomerMarginWithTax,
  ] = useState(0);

  const [
    totalPriceWithRegisteredCustomerDiscount,
    setTotalPriceWithRegisteredCustomerDiscount,
  ] = useState(0);

  const [
    totalPriceWithRegisteredCustomerDiscountWithTax,
    setTotalPriceWithRegisteredCustomerDiscountWithTax,
  ] = useState(0);

  const [
    categoryTotalPriceWithWholesaleCustomerMargin,
    setCategoryTotalPriceWithWholesaleCustomerMargin,
  ] = useState(0);

  const [
    categoryTotalPriceWithWholesaleCustomerMarginWithTax,
    setCategoryTotalPriceWithWholesaleCustomerMarginWithTax,
  ] = useState(0);

  const [
    categoryTotalPriceWithWholesaleCustomerDiscount,
    setCategoryTotalPriceWithWholesaleCustomerDiscount,
  ] = useState(0);

  const [
    categoryTotalPriceWithWholesaleCustomerDiscountWithTax,
    setCategoryTotalPriceWithWholesaleCustomerDiscountWithTax,
  ] = useState(0);

  const [totalPriceWithRetailMargin, setTotalPriceWithRetailMargin] =
    useState(0);
  const [
    totalPriceWithRetailMarginWithTax,
    setTotalPriceWithRetailMarginWithTax,
  ] = useState(0);

  const [
    totalPriceWithRetailCustomerMargin,
    setTotalPriceWithRetailCustomerMargin,
  ] = useState(0);
  const [
    totalPriceWithRetailCustomerMarginWithTax,
    setTotalPriceWithRetailCustomerMarginWithTax,
  ] = useState(0);

  const [
    totalPriceWithWholesaleCustomerMargin,
    setTotalPriceWithWholesaleCustomerMargin,
  ] = useState(0);
  const [
    totalPriceWithWholesaleCustomerMarginWithTax,
    setTotalPriceWithWholesaleCustomerMarginWithTax,
  ] = useState(0);

  const handleTaxFieldsOnChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    setState: Dispatch<SetStateAction<number>>
  ) => {
    setState(Number(event.currentTarget.value));
  };

  const calculateTotalWithTax = useCallback(
    (
      priceWithMargin: number,
      setTotalPriceWithTax: Dispatch<SetStateAction<number>>,
      tax: number
    ) =>
      CallbackCalculateTotalWithTax(priceWithMargin, setTotalPriceWithTax, tax),
    []
  );

  const handleTaxFieldsOnBlur = (
    _: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    totalPrice: number,
    setTotalPrice: Dispatch<SetStateAction<number>>,
    marginPrefix: string,
    formValue: string
  ) => {
    if (totalPrice < watchPrice) {
      setTotalPrice(watchPrice);
    } else {
      let newMargin = totalPrice - watchPrice;
      if (marginPrefix === "%") {
        newMargin = (newMargin * 100) / watchPrice;
      }

      newMargin = RoundToDecimal(newMargin, 2);
      setValue(formValue, newMargin);
    }
  };

  const handleTaxFieldsOnBlurWithTax = (
    _: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    totalPrice: number,
    setTotalPrice: Dispatch<SetStateAction<number>>,
    totalPriceWithTax: number,
    setTotalPriceWithTax: Dispatch<SetStateAction<number>>,
    marginPrefix: string,
    formValue: string
  ) => {
    if (totalPriceWithTax <= watchPrice) {
      const _tax = totalPrice * (tax / 100);
      let newValue = _tax + totalPrice;

      newValue = RoundToDecimal(newValue, 2);
      setTotalPriceWithTax(newValue);
    } else {
      let newValue = totalPriceWithTax / ((100 + tax) / 100);

      newValue = RoundToDecimal(newValue, 2);
      setTotalPrice(newValue);

      let newMargin = newValue - watchPrice;
      if (marginPrefix === "%") {
        newMargin = (newMargin * 100) / watchPrice;
      }

      newMargin = RoundToDecimal(newMargin, 2);
      setValue(formValue, newMargin);
    }
  };

  // update Price with totalPriceWithUnregisteredCustomerMargin
  useEffect(() => {
    if (categoryMarginAndDiscount) {
      const margin = updatePriceValueWith(
        watchPrice,
        categoryMarginAndDiscount?.margin.unregisteredCustomerMargin.value,
        categoryMarginAndDiscount?.margin.unregisteredCustomerMargin.prefix
      );

      let total = watchPrice + margin;
      total = RoundToDecimal(total, 2);
      setTotalPriceWithUnregisteredCustomerMargin(total);
    }
  }, [categoryMarginAndDiscount, watchPrice]);

  // update Price with totalPriceWithUnregisteredCustomerMarginWithTax

  useEffect(() => {
    calculateTotalWithTax(
      totalPriceWithUnregisteredCustomerMargin,
      setTotalPriceWithUnregisteredCustomerMarginWithTax,
      tax
    );
  }, [calculateTotalWithTax, tax, totalPriceWithUnregisteredCustomerMargin]);

  // Update price with totalPriceWithUnregisteredCustomerDiscount

  useEffect(() => {
    if (categoryMarginAndDiscount) {
      const discount = updatePriceValueWith(
        watchPrice,
        categoryMarginAndDiscount.discount.unregisteredCustomerDiscount.value,
        categoryMarginAndDiscount.discount.unregisteredCustomerDiscount.prefix
      );

      let total = watchPrice - discount;
      total = RoundToDecimal(total, 2);
      setTotalPriceWithUnregisteredCustomerDiscount(total);
    }
  }, [categoryMarginAndDiscount, watchPrice]);

  // update price with totalPriceWithUnregisteredCustomerDiscountWithTax

  useEffect(() => {
    calculateTotalWithTax(
      totalPriceWithUnregisteredCustomerDiscount,
      setTotalPriceWithUnregisteredCustomerDiscountWithTax,
      tax
    );
  }, [calculateTotalWithTax, tax, totalPriceWithUnregisteredCustomerDiscount]);

  // update price for totalPriceWithRegisteredCustomerMargin

  useEffect(() => {
    if (categoryMarginAndDiscount) {
      const margin = updatePriceValueWith(
        watchPrice,
        categoryMarginAndDiscount.margin.registeredCustomerMargin.value,
        categoryMarginAndDiscount.margin.registeredCustomerMargin.prefix
      );

      let total = watchPrice + margin;
      total = RoundToDecimal(total, 2);
      setTotalPriceWithRegisteredCustomerMargin(total);
    }
  }, [categoryMarginAndDiscount, watchPrice]);

  // update price for totalPriceWithRegisteredCustomerMarginWithTax

  useEffect(() => {
    calculateTotalWithTax(
      totalPriceWithRegisteredCustomerMargin,
      setTotalPriceWithRegisteredCustomerMarginWithTax,
      tax
    );
  }, [calculateTotalWithTax, tax, totalPriceWithRegisteredCustomerMargin]);

  // update price for totalPriceWithRegisteredCustomerDiscount

  useEffect(() => {
    if (categoryMarginAndDiscount) {
      const discount = updatePriceValueWith(
        watchPrice,
        categoryMarginAndDiscount.discount.registeredCustomerDiscount.value,
        categoryMarginAndDiscount.discount.registeredCustomerDiscount.prefix
      );

      let total = watchPrice - discount;
      total = RoundToDecimal(total, 2);
      setTotalPriceWithRegisteredCustomerDiscount(total);
    }
  }, [categoryMarginAndDiscount, watchPrice]);

  // update price for totalPriceWithRegisteredCustomerDiscountWithTax

  useEffect(() => {
    calculateTotalWithTax(
      totalPriceWithRegisteredCustomerDiscount,
      setTotalPriceWithRegisteredCustomerDiscountWithTax,
      tax
    );
  }, [calculateTotalWithTax, tax, totalPriceWithRegisteredCustomerDiscount]);

  // Update price for categoryTotalPriceWithWholesaleCustomerMargin

  useEffect(() => {
    if (categoryMarginAndDiscount) {
      const margin = updatePriceValueWith(
        watchPrice,
        categoryMarginAndDiscount.margin.wholesaleCustomerMargin.value,
        categoryMarginAndDiscount.margin.wholesaleCustomerMargin.prefix
      );

      let total = watchPrice + margin;
      total = RoundToDecimal(total, 2);
      setCategoryTotalPriceWithWholesaleCustomerMargin(total);
    }
  }, [categoryMarginAndDiscount, watchPrice]);

  // Update price for categoryTotalPriceWithWholesaleCustomerMarginWithTax

  useEffect(() => {
    calculateTotalWithTax(
      categoryTotalPriceWithWholesaleCustomerMargin,
      setCategoryTotalPriceWithWholesaleCustomerMarginWithTax,
      tax
    );
  }, [
    calculateTotalWithTax,
    categoryTotalPriceWithWholesaleCustomerMargin,
    tax,
  ]);

  // Update price for categoryTotalPriceWithWholesaleCustomerDiscount

  useEffect(() => {
    if (categoryMarginAndDiscount) {
      const discount = updatePriceValueWith(
        watchPrice,
        categoryMarginAndDiscount.discount.wholesaleCustomerDiscount.value,
        categoryMarginAndDiscount.discount.wholesaleCustomerDiscount.prefix
      );

      let total = watchPrice - discount;
      total = RoundToDecimal(total, 2);
      setCategoryTotalPriceWithWholesaleCustomerDiscount(total);
    }
  }, [categoryMarginAndDiscount, watchPrice]);

  // Update price for categoryTotalPriceWithWholesaleCustomerDiscountWithTax

  useEffect(() => {
    calculateTotalWithTax(
      categoryTotalPriceWithWholesaleCustomerDiscount,
      setCategoryTotalPriceWithWholesaleCustomerDiscountWithTax,
      tax
    );
  }, [
    calculateTotalWithTax,
    categoryTotalPriceWithWholesaleCustomerDiscount,
    tax,
  ]);

  // update total Price With Retail margin
  useEffect(() => {
    if (!isVariobelOnlyField) return;

    const margin = updatePriceValueWith(
      watchPrice,
      watchRetailMargin.value,
      watchRetailMargin.prefix
    );
    let total = watchPrice + margin;
    total = RoundToDecimal(total, 2);

    setTotalPriceWithRetailMargin(total);
  }, [watchPrice, watchRetailMargin, isVariobelOnlyField]);

  // update total Price With Retail CustomerMargin
  useEffect(() => {
    if (!isVariobelOnlyField) return;

    const margin = updatePriceValueWith(
      watchPrice,
      watchRetailCustomerMargin.value,
      watchRetailCustomerMargin.prefix
    );
    let total = watchPrice + margin;
    total = RoundToDecimal(total, 2);

    setTotalPriceWithRetailCustomerMargin(total);
  }, [watchPrice, watchRetailCustomerMargin, isVariobelOnlyField]);

  // update total Price With Wholesale Customer Margin
  useEffect(() => {
    if (!isVariobelOnlyField) return;

    const margin = updatePriceValueWith(
      watchPrice,
      watchWholesaleCustomerMargin.value,
      watchWholesaleCustomerMargin.prefix
    );
    let total = watchPrice + margin;
    total = RoundToDecimal(total, 2);

    setTotalPriceWithWholesaleCustomerMargin(total);
  }, [watchPrice, watchWholesaleCustomerMargin, isVariobelOnlyField]);

  // Below  calculates total prices with tax
  //-------------------START---------------//
  useEffect(() => {
    calculateTotalWithTax(
      totalPriceWithRetailMargin,
      setTotalPriceWithRetailMarginWithTax,
      tax
    );
  }, [tax, totalPriceWithRetailMargin, calculateTotalWithTax]);

  useEffect(() => {
    calculateTotalWithTax(
      totalPriceWithRetailCustomerMargin,
      setTotalPriceWithRetailCustomerMarginWithTax,
      tax
    );
  }, [tax, totalPriceWithRetailCustomerMargin, calculateTotalWithTax]);

  useEffect(() => {
    calculateTotalWithTax(
      totalPriceWithWholesaleCustomerMargin,
      setTotalPriceWithWholesaleCustomerMarginWithTax,
      tax
    );
  }, [tax, totalPriceWithWholesaleCustomerMargin, calculateTotalWithTax]);
  //--------------------END---------------//

  const watchOnSaleBreakpointSM = watchOnSale ? 6 : 12;
  return (
    <>
      {/* On Sale */}
      <OnSaleCheckbox
        name="onSale"
        control={control}
        label={`${t("On sale")}`}
        errors={errors}
      />

      {/* Sale ends */}
      <OnSaleDatePicker
        name="saleEnds"
        control={control}
        label={`${t("Sale ends")}`}
        errors={errors}
        watchOnSale={watchOnSale}
      />

      <SectionDivider caption={t("Unregistered customer")} />

      {/* Unregistered Customer Retail margin  */}
      <Grid item container xs={12} spacing={5}>
        <Grid item container xs={12} sm={watchOnSaleBreakpointSM} spacing={5}>
          <PricingDivider caption={t("Margin")} />

          <ProductTaxFields
            t={t}
            value={totalPriceWithRetailMargin}
            valueWithTax={totalPriceWithRetailMarginWithTax}
            onChange={(event) =>
              handleTaxFieldsOnChange(event, setTotalPriceWithRetailMargin)
            }
            onChangeWithTax={(event) =>
              handleTaxFieldsOnChange(
                event,
                setTotalPriceWithRetailMarginWithTax
              )
            }
            onBlur={(event) =>
              handleTaxFieldsOnBlur(
                event,
                totalPriceWithRetailMargin,
                setTotalPriceWithRetailMargin,
                watchRetailMargin.prefix,
                "retailMargin.value"
              )
            }
            onBlurWithTax={(event) =>
              handleTaxFieldsOnBlurWithTax(
                event,
                totalPriceWithRetailMargin,
                setTotalPriceWithRetailMargin,
                totalPriceWithRetailMarginWithTax,
                setTotalPriceWithRetailMarginWithTax,
                watchRetailMargin.prefix,
                "retailMargin.value"
              )
            }
          />
          <Grid item xs={12}>
            <PriceAccordion>
              <>
                <MarginPrefixInput
                  label={t("Margin type")}
                  namePrefix="retailMargin.prefix"
                  {...{ errors, control, t }}
                />
                <MarginInput
                  label={t("Retail margin")}
                  name="retailMargin.value"
                  prefix={watchRetailMargin.prefix}
                  {...{ errors, control, t }}
                />
              </>
            </PriceAccordion>
          </Grid>
        </Grid>

        {watchOnSale && (
          <UnRegisteredCustomersOnSaleFields
            {...{
              control,
              t,
              errors,
              setValue,
              watchSalesDiscount,
              watchPrice,
              watchRetailMargin,
              tax,
            }}
          />
        )}
      </Grid>

      {/* Unregistered Customer Prices With Category */}
      {categoryMarginAndDiscount && (
        <>
          <Box mt={2} ml={5} width={"100%"}>
            <PricingDivider caption={t("With Category")} />
          </Box>

          <Grid item container xs={12} spacing={5}>
            <Grid
              item
              container
              xs={12}
              sm={watchOnSaleBreakpointSM}
              spacing={5}
            >
              <PricingDivider caption={t("Margin")} />

              <Grid item container xs={12} spacing={5}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="number"
                    label={t("Without Tax")}
                    fullWidth
                    value={totalPriceWithUnregisteredCustomerMargin}
                    // onChange={onChange}
                    // onBlur={onBlur}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    type="number"
                    label={t("With Tax")}
                    fullWidth
                    value={totalPriceWithUnregisteredCustomerMarginWithTax}
                    // onChange={onChangeWithTax}
                    // onBlur={onBlurWithTax}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={watchOnSaleBreakpointSM}
              spacing={5}
            >
              <PricingDivider caption={t("Discount")} />

              <Grid item container xs={12} spacing={5}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="number"
                    label={t("Without Tax")}
                    fullWidth
                    value={totalPriceWithUnregisteredCustomerDiscount}
                    // onChange={onChange}
                    // onBlur={onBlur}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    type="number"
                    label={t("With Tax")}
                    fullWidth
                    value={totalPriceWithUnregisteredCustomerDiscountWithTax}
                    // onChange={onChangeWithTax}
                    // onBlur={onBlurWithTax}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {/*Registered Customer Retail margin  */}

      <SectionDivider caption={t("Registered retail customer")} />

      <Grid item container xs={12} spacing={5}>
        <Grid item container xs={12} sm={watchOnSaleBreakpointSM} spacing={5}>
          <PricingDivider caption={t("Margin")} />

          <ProductTaxFields
            t={t}
            value={totalPriceWithRetailCustomerMargin}
            valueWithTax={totalPriceWithRetailCustomerMarginWithTax}
            onChange={(event) =>
              handleTaxFieldsOnChange(
                event,
                setTotalPriceWithRetailCustomerMargin
              )
            }
            onChangeWithTax={(event) =>
              handleTaxFieldsOnChange(
                event,
                setTotalPriceWithRetailCustomerMarginWithTax
              )
            }
            onBlur={(event) =>
              handleTaxFieldsOnBlur(
                event,
                totalPriceWithRetailCustomerMargin,
                setTotalPriceWithRetailCustomerMargin,
                watchRetailCustomerMargin.prefix,
                "retailCustomerMargin.value"
              )
            }
            onBlurWithTax={(event) =>
              handleTaxFieldsOnBlurWithTax(
                event,
                totalPriceWithRetailCustomerMargin,
                setTotalPriceWithRetailCustomerMargin,
                totalPriceWithRetailCustomerMarginWithTax,
                setTotalPriceWithRetailCustomerMarginWithTax,
                watchRetailCustomerMargin.prefix,
                "retailCustomerMargin.value"
              )
            }
          />
          <Grid item xs={12}>
            <PriceAccordion>
              <>
                <MarginPrefixInput
                  label={t("Margin type")}
                  namePrefix="retailCustomerMargin.prefix"
                  {...{ errors, control, t }}
                />
                <MarginInput
                  label={t("Retail margin")}
                  name="retailCustomerMargin.value"
                  prefix={watchRetailCustomerMargin.prefix}
                  {...{ errors, control, t }}
                />
              </>
            </PriceAccordion>
          </Grid>
        </Grid>

        {watchOnSale && (
          <RegisteredRetailCustomersOnSaleFields
            {...{
              control,
              t,
              errors,
              watchPrice,

              watchCustomerDiscount,
              watchRetailCustomerMargin,

              tax,
              setValue,
            }}
          />
        )}
      </Grid>

      {/* Registered Customer Prices With Category */}
      {categoryMarginAndDiscount && (
        <>
          <Box mt={2} ml={5} width={"100%"}>
            <PricingDivider caption={t("With Category")} />
          </Box>

          <Grid item container xs={12} spacing={5}>
            <Grid
              item
              container
              xs={12}
              sm={watchOnSaleBreakpointSM}
              spacing={5}
            >
              <PricingDivider caption={t("Margin")} />

              <Grid item container xs={12} spacing={5}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="number"
                    label={t("Without Tax")}
                    fullWidth
                    value={totalPriceWithRegisteredCustomerMargin}
                    // onChange={onChange}
                    // onBlur={onBlur}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    type="number"
                    label={t("With Tax")}
                    fullWidth
                    value={totalPriceWithRegisteredCustomerMarginWithTax}
                    // onChange={onChangeWithTax}
                    // onBlur={onBlurWithTax}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={watchOnSaleBreakpointSM}
              spacing={5}
            >
              <PricingDivider caption={t("Discount")} />

              <Grid item container xs={12} spacing={5}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="number"
                    label={t("Without Tax")}
                    fullWidth
                    value={totalPriceWithRegisteredCustomerDiscount}
                    // onChange={onChange}
                    // onBlur={onBlur}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    type="number"
                    label={t("With Tax")}
                    fullWidth
                    value={totalPriceWithRegisteredCustomerDiscountWithTax}
                    // onChange={onChangeWithTax}
                    // onBlur={onBlurWithTax}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      <SectionDivider caption={t("Registered wholesale customer")} />

      {/*Registered Customer wholesale Margin  */}
      <Grid item container xs={12} spacing={5}>
        <Grid item container xs={12} sm={watchOnSaleBreakpointSM} spacing={5}>
          <PricingDivider caption={t("Margin")} />

          <ProductTaxFields
            t={t}
            value={totalPriceWithWholesaleCustomerMargin}
            valueWithTax={totalPriceWithWholesaleCustomerMarginWithTax}
            onChange={(event) =>
              handleTaxFieldsOnChange(
                event,
                setTotalPriceWithWholesaleCustomerMargin
              )
            }
            onChangeWithTax={(event) =>
              handleTaxFieldsOnChange(
                event,
                setTotalPriceWithWholesaleCustomerMarginWithTax
              )
            }
            onBlur={(event) =>
              handleTaxFieldsOnBlur(
                event,
                totalPriceWithWholesaleCustomerMargin,
                setTotalPriceWithWholesaleCustomerMargin,
                watchWholesaleCustomerMargin.prefix,
                "wholesaleCustomerMargin.value"
              )
            }
            onBlurWithTax={(event) =>
              handleTaxFieldsOnBlurWithTax(
                event,
                totalPriceWithWholesaleCustomerMargin,
                setTotalPriceWithWholesaleCustomerMargin,
                totalPriceWithWholesaleCustomerMarginWithTax,
                setTotalPriceWithWholesaleCustomerMarginWithTax,
                watchWholesaleCustomerMargin.prefix,
                "wholesaleCustomerMargin.value"
              )
            }
          />
          <Grid item xs={12}>
            <PriceAccordion>
              <>
                <MarginPrefixInput
                  label={t("Margin type")}
                  namePrefix="wholesaleCustomerMargin.prefix"
                  {...{ errors, control, t }}
                />
                <MarginInput
                  label={t("Wholesale margin")}
                  name="wholesaleCustomerMargin.value"
                  prefix={watchWholesaleCustomerMargin.prefix}
                  {...{ errors, control, t }}
                />
              </>
            </PriceAccordion>
          </Grid>
        </Grid>

        {watchOnSale && (
          <RegisteredWholesaleCustomersOnSaleFields
            {...{
              control,
              t,
              errors,
              watchPrice,

              watchWholesaleDiscount,
              watchWholesaleCustomerMargin,

              tax,
              setValue,
            }}
          />
        )}
      </Grid>

      {/* Wholesale Customer Prices With Category */}
      {categoryMarginAndDiscount && (
        <>
          <Box mt={2} ml={5} width={"100%"}>
            <PricingDivider caption={t("With Category")} />
          </Box>

          <Grid item container xs={12} spacing={5}>
            <Grid
              item
              container
              xs={12}
              sm={watchOnSaleBreakpointSM}
              spacing={5}
            >
              <PricingDivider caption={t("Margin")} />

              <Grid item container xs={12} spacing={5}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="number"
                    label={t("Without Tax")}
                    fullWidth
                    value={categoryTotalPriceWithWholesaleCustomerMargin}
                    // onChange={onChange}
                    // onBlur={onBlur}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    type="number"
                    label={t("With Tax")}
                    fullWidth
                    value={categoryTotalPriceWithWholesaleCustomerMarginWithTax}
                    // onChange={onChangeWithTax}
                    // onBlur={onBlurWithTax}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={watchOnSaleBreakpointSM}
              spacing={5}
            >
              <PricingDivider caption={t("Discount")} />

              <Grid item container xs={12} spacing={5}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    type="number"
                    label={t("Without Tax")}
                    fullWidth
                    value={categoryTotalPriceWithWholesaleCustomerDiscount}
                    // onChange={onChange}
                    // onBlur={onBlur}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField
                    type="number"
                    label={t("With Tax")}
                    fullWidth
                    value={
                      categoryTotalPriceWithWholesaleCustomerDiscountWithTax
                    }
                    // onChange={onChangeWithTax}
                    // onBlur={onBlurWithTax}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default ProductPricingAdjusters;
