import React from 'react'
import { Card, CardContent, Typography } from '@mui/material';

import { Admin } from '../../../../types/adminTypes';

interface Props {
  t: any;
  admin: Admin
}

const ViewInfo = (props: Props) => {
  const { t } = props;

  return (
    <Card>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography variant="h6">{t("Admin Info")}</Typography>

      </CardContent>
    </Card>
  )
}

export default ViewInfo