import { Config } from "imap";
import yup from "../helpers/customYup";

export interface ImapCreationType {
  email: string;
  password: string;
  host: string;
  port: number;
  tls: boolean;
}
export interface SmtpCreationType {
  user: string;
  pass: string;
  host: string;
  port: number;
  service: string;
}

export interface RecentMessagesType {
  date: string,
  from: string,
  subject: string,
  to: string,
  body: string,
  uid: string,
}

export interface ImapConnectionType extends ImapCreationType {
  autoConnect: boolean;
  connected: 'connected' | 'disconnected';

  connection: {
    details: {
      state: string;
      _config: Config;
    }
  } | null;

  ownedBy: string;

  __v: number;
  _id: string
}

export interface ImapAccountType extends ImapConnectionType {
  recentMessages: RecentMessagesType[] | null;
  totalMessages: number;
}

export interface SmtpAccountType extends SmtpCreationType {
  autoConnect: boolean;
  connected: 'connected' | 'disconnected';
  ownedBy: string;
  __v: number;
  _id: string
}

export interface MailAccountCreationType {
  imap: ImapCreationType;
  smtp: SmtpCreationType;
}

export interface MailAccountsType {
  id: number;
  email: string;
  ownedBy: string;
  imap: ImapAccountType;
  smtp: SmtpAccountType | null;
}

export interface CurrentMailInputsType {
  id: number | string;
  selectedEmail: string
}

export const mailFormValidationSchema = yup.object().shape({
  from: yup.string().email().required(),
  to: yup.string().email().required(),
  subject: yup.string().required(),
  html: yup.string().required(),
});

export type MailFormDataType = yup.InferType<typeof mailFormValidationSchema>;
