import { Grid } from "@mui/material";

import CompanyForm from "./components/companyForm";

interface Props {
  id: string;
  handleClose: () => void;
}

const EditCompanyScreen = (props: Props) => {
  const { id, handleClose } = props;
  return (
    <Grid container>
      <Grid item xs={12}>
        <CompanyForm type="edit" id={id} handleClose={handleClose} />
      </Grid>
    </Grid>
  );
};

export default EditCompanyScreen;
