import {
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";

import { Box, Typography } from "@mui/material";

import { Avatar } from "../../avatar";

import { TimelineEntriesType, TimelineEntryTypeKeyType, timelineEntryType } from "../../../types/customTimelineTypes";
import { Admin } from "../../../types/adminTypes";
import { RootState } from "../../../store";
import { CustomerTimelineItemType } from "../../../types/customerTypes";

import { getInitials } from "../../../helpers/get-initials";
import relativeTime from "../../../helpers/getRelativeTime";

interface Props extends CustomerTimelineItemType {
  admins: RootState['admin'];
}

const CustomerEntryTimelineItem = (props: Props) => {
  const { data, admins } = props;

  const { type, text, time, admin } = data.data as TimelineEntriesType;

  const Icon = timelineEntryType[type as TimelineEntryTypeKeyType];

  const currentAdmin = (admins.data as Admin[]).find(
    (_admin) => _admin._id === admin
  );

  return (
    <TimelineItem>
      <TimelineSeparator>
        <Icon color="primary" fontSize="small" />
        <TimelineConnector />
      </TimelineSeparator>

      <TimelineContent
        sx={{
          mt: 0,
          pt: 0,
          mb: (theme) => `${theme.spacing(3)} !important`,

          "&:hover": {
            cursor: "pointer",
            "& .entry-user": {
              display: "contents",
            },
          },
        }}
      >
        <Box
          sx={{
            mb: 3,
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              mr: 2,
              fontWeight: 600,
              textTransform: "capitalize",
            }}
          >
            Customer <i>{type}</i> Entry
          </Typography>

          {!time && (
            <Typography variant="caption" sx={{ color: "text.disabled" }}>
              NA
            </Typography>
          )}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            {admin && currentAdmin && (
              <Box sx={{ display: "none" }} className="entry-user">
                {currentAdmin.avatar ? (
                  <Avatar
                    alt="Profile Pic"
                    src={currentAdmin.avatar}
                    skin="light"
                    sx={{
                      width: 24,
                      height: 24,
                      fontWeight: 600,
                      fontSize: "0.75rem",
                      textTransform: "uppercase",
                    }}
                  />
                ) : (
                  <Avatar
                    skin="light"
                    sx={{
                      width: 24,
                      height: 24,
                      fontWeight: 600,
                      fontSize: "0.75rem",
                      textTransform: "uppercase",
                    }}
                  >
                    {getInitials(currentAdmin.displayName)}
                  </Avatar>
                )}
                <Typography variant="caption" sx={{ color: "text.disabled" }}>
                  {currentAdmin.displayName} created this{" "}
                </Typography>
              </Box>
            )}
            {time && (
              <Typography variant="caption" sx={{ color: "text.disabled" }}>
                {relativeTime(new Date(), new Date(time))}
              </Typography>
            )}
          </Box>
        </Box>

        <Typography variant="body2" sx={{ mb: 2, textTransform: "capitalize" }}>
          Text: {text}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
};

export default CustomerEntryTimelineItem;
