import DisplayImageAfterCrop from "./components/display-image-after-crop";
import WallpaperSelectBase from "./components/wallpaper-select-base";
import { ICrop } from "../../types";
import { AddOrderListLineItemType, LineItemType } from "../../../../../../types/orderTypes";
import { customWallpaperStateI } from "../../../../../../store/apps/print-wallpaper";

interface Props extends ICrop, customWallpaperStateI {
  selectedLineItem: LineItemType | AddOrderListLineItemType
}

const CustomWallpaperTab = (props: Props) => {
  const {
    preview,
    wallpaper,
    isCropMode,
    croppedImage,
  } = props;

  const isFileImage = preview && preview.type.startsWith("image");

  // no file selected,
  // no wallpaper as well, wallpaper === nulll
  // nothing to display
  const isEmpty = !isFileImage && !wallpaper;

  // no file selected,
  // but wallpaper is there because it was uploaded,
  // used when routes change
  const isRerouted = !isFileImage && wallpaper;

  const displayCropBoxProps = {
    isRerouted,
    isEmpty,
    ...props,
  };

  const Content = () => {
    if (isRerouted) {
      return (
        <DisplayImageAfterCrop src={wallpaper.data} />
      );
    }

    // After Crop
    // Show Cropped Image
    if (!isCropMode) {
      return <DisplayImageAfterCrop src={croppedImage} />;
    }

    return null;
  }

  return (
    <WallpaperSelectBase {...displayCropBoxProps}>
      <Content />
    </WallpaperSelectBase>
  );
};

export default CustomWallpaperTab;
