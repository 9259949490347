import { SetStateAction, Dispatch, useMemo } from "react";

// ** Type Imports
import { AddOrderListLineItemType, Color } from "../../../../types/orderTypes";
import { PrintTypeTemp } from "../../../../types/orderTypes";
import { LineItemType } from "../../../../types/orderTypes";

// ** Util Imports
import PrintDialog from "../print-dialog";
import { IFile, PrintTabSelectorType } from "../print-dialog/types";
import { SnackbarProvider } from "notistack";

interface SelectPrintDialogProps {
  selectedLineItem: LineItemType | AddOrderListLineItemType;
  setSelectedLineItem: Dispatch<SetStateAction<LineItemType | AddOrderListLineItemType>>
  handleSelectPrint: (
    newPrint: PrintTypeTemp
  ) => void;
  open: boolean;
  onClose: () => void;
}

export const SelectPrintDialog = (props: SelectPrintDialogProps) => {
  const {
    selectedLineItem: _selectedLineItem,
    setSelectedLineItem,
    handleSelectPrint,
    open,
    onClose,
  } = props;

  const selectedLineItem = _selectedLineItem as LineItemType | AddOrderListLineItemType;
  const selectedTab = useMemo(() => {
    const value = selectedLineItem.print.type as PrintTabSelectorType
    return value;
  }, [selectedLineItem.print.type]);

  const getSelectedValue = () => {
    if (selectedTab === "color") {
      return JSON.parse(selectedLineItem.print.value)
    }

    if (selectedTab === "ownWallpaper") {
      const obj = JSON.parse(selectedLineItem.print.value);
      return obj.original;
    }

    return JSON.parse(selectedLineItem.print.value).file
  }

  const selectedValue = useMemo(getSelectedValue, [selectedLineItem.print.value, selectedTab]);

  const handleSelect = (value: Color | string | IFile, tab: string) => {
    onClose(); // handle dialog close alone

    // copied from eshop ecomm-checkout
    // TODO: models needs to be updated so obejcts are used in shop and admin
    // to avoid stringify nonsense
    const newPrintValue = () => {
      if (tab === "color") {
        return JSON.stringify(value as Color);
      }

      // updated print value to have a string
      // needs to be object in other places except here
      if (tab === "ownWallpaper") {
        return String((value as IFile).details);
      }

      // tab === "wallpaper"
      const _value = value as IFile
      const data = {
        file: (_value as IFile).name,
        ...JSON.parse(_value.details as string),
      };

      return JSON.stringify(data);
    }

    const newPrint = {
      type: tab,
      value: newPrintValue()
    }

    handleSelectPrint(newPrint)
  }

  return (
    <SnackbarProvider maxSnack={10}>
      <PrintDialog
        selectedLineItem = {selectedLineItem}
        setSelectedLineItem={setSelectedLineItem}
        selectedValue={selectedValue}
        selectedTab={selectedTab}
        open={open}
        closeDialog={onClose}
        handleSelect={handleSelect}
        />
    </SnackbarProvider>

  );
}