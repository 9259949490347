import {
  Box,
  Button,
  Card,
  Grid,
  Icon,
  Typography
} from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColumns } from "@mui/x-data-grid";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store";
import { deleteCompany, fetchCompanies } from "../../../store/apps/companies";

import { CompanyType } from "../../../types/companyTypes";

import Translations from "../../../comps/layouts/components/Translations";
import DeleteCompanyDialog from "./components/deleteCompanyDialog";
import { Chip } from "../../../comps/chip";
import { ThemeColor } from "../../../comps/@core/layouts/types";
import DialogContainer from "../../../comps/dialogContainer";
import EditCompanyScreen from "./editCompany";
import { useAlertContext } from "../../../hooks/useAlertContext";

interface CellType {
  row: CompanyType
}

const CompanyListScreen = () => {
  const [companies, setCompanies] = useState<CompanyType[]>([])
  const [pageSize, setPageSize] = useState(50);

  const [selectedCompany, setSelectedCompany] = useState<CompanyType>();
  const [deleteCompanyOpen, setDeleteCompanyOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const store = useSelector((state: RootState) => state.companies);
  const { showErrorAlert, showSuccessAlert }  = useAlertContext();

  const toggleEditDialog = () => setEditOpen((prev) => !prev);
  const toggleDeleteCompanyDialog = () => setDeleteCompanyOpen((prev) => !prev);

  const handleDelete = () => {
    if (!selectedCompany) return;

    dispatch(deleteCompany(selectedCompany._id))
      .unwrap()
      .then(() => {
        showSuccessAlert(t("Company deleted"))
      })
      .catch(() => {
        showErrorAlert(t("Failed to delete Company"))
      })
      .finally(() => {
        toggleDeleteCompanyDialog()
      })
  }

  // fetch data on init
  useEffect(() => {
    dispatch(fetchCompanies())
  }, [dispatch])

  // set display data
  useEffect(() => {
    if (store.companies.length > 0){
      setCompanies(store.companies)
    }
  }, [store.companies])

  const columns: GridColumns<CompanyType> = [
    {
      flex: 0.25,
      minWidth: 100,
      field: "name",
      headerName: t("Name"),
      renderCell: ({ row }: CellType) => (
        <Typography
          variant="body2"
          component={Link}
          to={`/company/preview/${row._id}`}
        >
          {row.name}
        </Typography>
      ),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "isDefault",
      headerName: t("Default"),
      type: "boolean",
    },
    {
      flex: 0.25,
      minWidth: 100,
      field: "logo",
      headerName: t("Logo"),
      renderCell: ({ row }: CellType) => (
        <Box width="100%" height="100%" display="flex">
          <img
            alt="preview"
            src={row.logo}
            style={{
              objectFit: "contain",
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
      ),
    },

    {
      flex: 0.25,
      minWidth: 100,
      field: "colorCode",
      headerName: t("Company color"),
      renderCell: ({ row }: CellType) => (
        <Chip
          size="small"
          color={row.colorCode as ThemeColor}
          sx={{ width: 28, height: 28 }}
        />
      ),
    },

    {
      flex: 0.1,
      minWidth: 80,
      field: "actions",
      headerName: t("Actions"),
      // renderCell: ({ row }: CellType) => (
      //   <RowOptions
      //     {...{
      //       row,
      //       companies,
      //       setCompanies,
      //       dispatch,
      //     }}
      //   />
      // ),
      type: "actions",
      getActions: ({ row }: CellType) => [
        <GridActionsCellItem
          showInMenu
          sx={{
            "& .MuiListItemIcon-root": { minWidth: 0 },
            textTransform: "none",
          }}
          icon={<Icon fontSize="small">edit</Icon>}
          label={t("Edit")}
          // @ts-expect-error
          component={Button}
          onClick={() => {
            setSelectedCompany(row)
            toggleEditDialog()
          }}
        />,
        <GridActionsCellItem
          showInMenu
          sx={{ "& .MuiListItemIcon-root": { minWidth: 0 } }}
          icon={<Icon fontSize="small">delete</Icon>}
          label={t("Delete")}
          onClick={() => {
            setSelectedCompany(row)
            toggleDeleteCompanyDialog()
          }}
        />,
      ],
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          <Box
            sx={{
              p: 5,
              pt: 7,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6" fontWeight={600}>
                <Translations text="Companies" />
              </Typography>

              <Button
                variant="contained"
                component={Link}
                to="/company/new"
                startIcon={<Icon>add</Icon>}
              >
                <Translations text="Add company" />
              </Button>
            </Box>
          </Box>

          <DataGrid
            autoHeight
            pagination
            rows={companies}
            getRowId={(row) => row._id}
            columns={columns}
            disableSelectionOnClick
            pageSize={Number(pageSize)}
            rowsPerPageOptions={[50, 100, 200]}
            sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          />
        </Card>
      </Grid>

      {selectedCompany && (
        <>
          <DeleteCompanyDialog
            open={deleteCompanyOpen}
            handleClose={toggleDeleteCompanyDialog}
            handleDelete={handleDelete}
            company={selectedCompany.name}
          />

          <DialogContainer open={editOpen} handleClose={toggleEditDialog}>
            <EditCompanyScreen
              id={selectedCompany._id}
              handleClose={toggleEditDialog}
            />
          </DialogContainer>
        </>
      )}
    </Grid>
  );
};

export default CompanyListScreen;
