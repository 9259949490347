import React from 'react';
import {
  Grid,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText
} from '@mui/material';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { ProductFormInputs } from '../../../types/productTypes';

interface Props { name: string; control: Control<ProductFormInputs, any>; label: string; errors: FieldErrors<ProductFormInputs> }

// Component for rendering a checkbox field
export const CheckboxField = ({ name, control, label, errors }: Props) => (
  <Grid item xs={4} sm={2} sx={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }}>
    <FormControl>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <FormControlLabel
            label={label}
            sx={errors[name] ? { color: "error.main" } : null}
            control={<Checkbox
              checked={value}
              onChange={onChange}
              name={`validation-basic-checkbox-${name}`}
              sx={errors[name] ? { color: "error.main" } : null} />} />
        )} />
      {errors[name] && (
        <FormHelperText
          sx={{ color: "error.main" }}
          id={`validation-basic-checkbox-${name}`}
        >
          {`This field cannot be empty`}
        </FormHelperText>
      )}
    </FormControl>
  </Grid>
);
