import React from "react";
import { Customer } from "../../../../types/customerTypes";
import DialogContainer from "../../../dialogContainer";
import {
  Grid,
  Box,
  Typography,
  Divider,
  CardContent,
  LinearProgress,
  Button,
} from "@mui/material";
import OrderDetailsSummary from "../../orderDetailsSummary";
import { AddOrderLineItemT, BasketPriceTypeTemp } from "../../../../types/orderTypes";

interface Props {
  customer: Customer;
  open: boolean;
  handleClose: () => void;
  itemList: AddOrderLineItemT[];
  basketPrice: BasketPriceTypeTemp;
  isOrderBeingCreated: boolean;
  handleOrderCreate: () => void
}

const AddOrderSummary = (props: Props) => {
  const {
    customer,
    open,
    handleClose,
    itemList,
    basketPrice,
    isOrderBeingCreated,
    handleOrderCreate,
  } = props;

  return (
    <DialogContainer open={open} handleClose={handleClose}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography pl={4} pt={4} mb={4} variant="h6">
            Order Summary
          </Typography>
          <Divider sx={{ mb: 0 }} />

          <OrderDetailsSummary
            {...{
              lineItems: itemList,
              basketPrice,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
              }}
            >
              <Typography
                variant="body2"
                sx={{ textTransform: "capitalize", fontSize: "1.15rem" }}
              >
                {`${customer.firstName} ${customer.lastName}`}
              </Typography>

              <Typography variant="body2">
                {customer.billingAddress[0]
                  ? customer.billingAddress[0].address
                  : ""}
              </Typography>

              <Typography variant="body2">
                {`${
                  customer.billingAddress[0]
                    ? customer.billingAddress[0].postcode
                    : ""
                }, ${
                  customer.billingAddress[0]
                    ? customer.billingAddress[0].city
                    : ""
                }`}
              </Typography>

              <Typography variant="body2">
                {customer.billingAddress[0]
                  ? customer.billingAddress[0].country?.label
                  : ""}
              </Typography>

              <Typography variant="body2">{customer.email}</Typography>

              <Typography variant="body2">{customer.phone}</Typography>
            </Box>
          </CardContent>
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ my: 0 }} />

          {isOrderBeingCreated && (
            <Box mt={2}>
              <LinearProgress />
            </Box>
          )}

          <Box
            sx={{
              p: 2,
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              onClick={handleOrderCreate}
              disabled={isOrderBeingCreated}
            >
              Create Order
            </Button>
          </Box>
        </Grid>
      </Grid>
    </DialogContainer>
  );
};

export default AddOrderSummary;
