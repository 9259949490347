// ** Third Party Import
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next'

interface Props {
  text: string
}

const Translations = ({ text }: Props) => {
  // ** Hook
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const initLang = localStorage.getItem('lang');

    if (initLang) {
      i18n.changeLanguage(initLang);
    }
  },[i18n]);

  return <>{`${t(text)}`}</>
}

export default Translations
