import React from "react";
import { GridActionsCellItem, GridAlignment, GridColumns } from "@mui/x-data-grid";
import { Icon, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Customer, CustomerData } from "../../../types/customerTypes";
import { AnyAbility } from "@casl/ability";
import StyledLink from "../../table/StyledLink";
import { UseFormReturn } from "react-hook-form";

interface CellType {
  row: Customer;
}

interface Props {
  t: any;
  setSelectedCustomer: React.Dispatch<
    React.SetStateAction<Customer | undefined>
  >;
  editCustomerForm: UseFormReturn<CustomerData, any>
  toggleEditCustomerDialog: () => void;
  toggleDeleteCustomerDialog: () => void;
  ability: AnyAbility;
}

const getCustomerListTableColumns = (props: Props): GridColumns<Customer> => {
  const {
    t,
    setSelectedCustomer,
    editCustomerForm,
    toggleEditCustomerDialog,
    toggleDeleteCustomerDialog,
    ability
  } = props;

  const canUpdate = ability.can('update', 'product');
  const canDelete = ability.can('delete', 'product');

  const columns = [
    {
      flex: 0.12,
      minWidth: 200,
      field: "fullName",
      headerName: t("Customer"),
      renderCell: ({ row }: CellType) => (
        <StyledLink to={`/customer/view/${row._id}`}>
          {`${row.firstName} ${row.lastName}`}
        </StyledLink>
      ),
      valueGetter: ({ row }: CellType) => `${row.firstName} ${row.lastName}`,
    },
    {
      flex: 1,
      minWidth: 200,
      field: "email",
      headerName: t("Email"),
      renderCell: ({ row }: CellType) => {
        return (
          <Typography noWrap variant="body2">
            {row.email}
          </Typography>
        );
      },
      valueGetter: ({ row }: CellType) => row.email,
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "role",
      headerName: t("Role"),
      type: "string",
      valueGetter: () => "Customer",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "gdprConsent",
      headerName: t("Collection of personal data"),
      type: "boolean",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "marketingConsent",
      headerName: t("Marketing consent"),
      type: "boolean",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "isRegistered",
      headerName: t("Registered"),
      type: "boolean",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "isVerified",
      headerName: t("Verified"),
      type: "boolean",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "isWholesaleCustomer",
      headerName: t("Wholesale Customer"),
      type: "boolean",
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "discount",
      headerName: t("Discount"),
      type: "number",
      align: "center" as GridAlignment,
      valueGetter: ({ row }: CellType) =>
        `${row.discount.value ?? 0}${row.discount.prefix}`,
    },
    {
      flex: 0.1,
      minWidth: 100,
      sortable: false,
      field: "actions",
      headerName: t("Actions"),
      type: "actions",
      getActions: ({ row }: CellType) => [
        <GridActionsCellItem
          showInMenu
          sx={{ "& .MuiListItemIcon-root": { minWidth: 0 } }}
          icon={<Icon fontSize="small">visibility</Icon>}
          label={t("View")}
          // @ts-expect-error
          component={Link}
          to={`/customer/view/${row._id}`}
        />,
        <GridActionsCellItem
          showInMenu
          sx={{ "& .MuiListItemIcon-root": { minWidth: 0 } }}
          icon={<Icon fontSize="small">edit</Icon>}
          label={t("Edit")}
          disabled={!canUpdate}
          onClick={() => {
            editCustomerForm.reset({ ...row, password: "" });
            setSelectedCustomer(row);
            toggleEditCustomerDialog();
          }}
        />,
        <GridActionsCellItem
          showInMenu
          sx={{ "& .MuiListItemIcon-root": { minWidth: 0 } }}
          icon={<Icon fontSize="small">delete</Icon>}
          label={t("Delete")}
          disabled={!canDelete}
          onClick={() => {
            setSelectedCustomer(row);
            toggleDeleteCustomerDialog();
          }}
        />,
      ],
    },
  ];

  return columns;
};

export default getCustomerListTableColumns;
