import { useEffect } from "react";

// Custom hook to control update of debounced orderItemDimensions

export function useDebouncedOrderItemDimensions(
  orderItemDimensions: {
    width: number;
    height: number;
  },
  handleWallpaperDetailsUpdate: (data: any) => void
) {
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleWallpaperDetailsUpdate({
        debouncedOrderItemDimensions: orderItemDimensions,
      });
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [orderItemDimensions, handleWallpaperDetailsUpdate]);
}
