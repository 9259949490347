// ** React Imports
import { ReactNode } from 'react'

// ** Component Imports
import { AbilityContext } from './Can'

// ** Types
import { NavSectionTitle } from '../../../@core/layouts/types'
import { defaultACLObj } from '../../../../configs/acl'
import { useAbility } from '@casl/react'

interface Props {
  children: ReactNode
  navTitle?: NavSectionTitle
}

const CanViewNavSectionTitle = (props: Props) => {
  // ** Props
  const { children, navTitle } = props

  const ability = useAbility(AbilityContext)
  const aclAbilities = navTitle?.acl ?? defaultACLObj;

  return ability && ability.can(aclAbilities.action, aclAbilities.subject) ? <>{children}</> : null
}

export default CanViewNavSectionTitle
