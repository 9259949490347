// ** React Imports
import { ReactElement, useState, SyntheticEvent } from "react";

// ** MUI Imports
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { styled, Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MuiTabList, { TabListProps } from "@mui/lab/TabList";

// ** Third Party Imports
import { useTranslation } from "react-i18next";

// ** Demo Tabs Imports
import { TabAccountScreen } from "./tabAccount";
// import TabBilling from "src/views/pages/account-settings/TabBilling";
// import TabSecurity from "src/views/pages/account-settings/TabSecurity";
// import TabConnections from "src/views/pages/account-settings/TabConnections";
// import TabNotifications from "src/views/pages/account-settings/TabNotifications";

const TabList = styled(MuiTabList)<TabListProps>(({ theme }) => ({
  "& .MuiTabs-indicator": {
    display: "none",
  },
  "& .Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    color: `${theme.palette.common.white} !important`,
  },
  "& .MuiTab-root": {
    minWidth: 65,
    minHeight: 38,
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.up("md")]: {
      minWidth: 130,
    },
  },
}));

export const AccountSettingsScreen = () => {
  // ** State
  const [activeTab, setActiveTab] = useState<string>("account");

  // ** Hooks
  const { t } = useTranslation();
  const hideText = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const handleChange = (event: SyntheticEvent, value: string) => {
    setActiveTab(value);
  };

  const tabContentList: { [key: string]: ReactElement } = {
    account: <TabAccountScreen />,
    // security: <TabSecurity />,
    // connections: <TabConnections />,
    // notifications: <TabNotifications />,
    // billing: <TabBilling apiPricingPlanData={apiPricingPlanData} />,
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <TabContext value={activeTab}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <TabList
                variant="scrollable"
                scrollButtons="auto"
                onChange={handleChange}
                aria-label="customized tabs example"
              >
                <Tab
                  value="account"
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Icon
                        sx={{ fontSize: "1.5rem", ...(!hideText && { mr: 2 }) }}
                      >
                        person
                      </Icon>
                      {!hideText && t("Account")}
                    </Box>
                  }
                />
                <Tab
                  value="security"
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Icon
                        sx={{ fontSize: "1.5rem", ...(!hideText && { mr: 2 }) }}
                      >
                        lock_open
                      </Icon>
                      {!hideText && t("Security")}
                    </Box>
                  }
                />
                <Tab
                  value="billing"
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Icon
                        sx={{ fontSize: "1.5rem", ...(!hideText && { mr: 2 }) }}
                      >
                        bookmark
                      </Icon>
                      {!hideText && t("Billing")}
                    </Box>
                  }
                />
                <Tab
                  value="notifications"
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Icon
                        sx={{ fontSize: "1.5rem", ...(!hideText && { mr: 2 }) }}
                      >
                        notifications
                      </Icon>
                      {!hideText && t("Notifications")}
                    </Box>
                  }
                />
                <Tab
                  value="connections"
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Icon
                        sx={{ fontSize: "1.5rem", ...(!hideText && { mr: 2 }) }}
                      >
                        link
                      </Icon>
                      {!hideText && t("Connections")}
                    </Box>
                  }
                />
              </TabList>
            </Grid>
            <Grid item xs={12}>
              <TabPanel sx={{ p: 0 }} value={activeTab}>
                {tabContentList[activeTab]}
              </TabPanel>
            </Grid>
          </Grid>
        </TabContext>
      </Grid>
    </Grid>
  );
};
