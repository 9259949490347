import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { OrderStatus } from "../../../../types/orderStatusTypes";

interface Props {
  data: OrderStatus;
}

const ViewInfo = (props: Props) => {
  const { data } = props;

  return (
    <Card>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography variant="h6">Template Preview</Typography>

        <div dangerouslySetInnerHTML={{ __html: data.template }} />
      </CardContent>
    </Card>
  );
};

export default ViewInfo;
