export const updatePriceValueWith = (
  price: number,
  discountOrMarginValue: number,
  prefix: string
) => {
  let calculatedDiscountOrMargin = Number(discountOrMarginValue);

  if (prefix === "%") {
    calculatedDiscountOrMargin = price * (Number(discountOrMarginValue) / 100);
  }

  return calculatedDiscountOrMargin;
};
