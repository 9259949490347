import React from "react";
import { GridColumns } from "@mui/x-data-grid";
import { Typography } from "@mui/material";
import { currencyFormatter } from "../../../helpers/currency-formatter";
import { OrderType } from "../../../types/orderTypes";
import StyledLink from "../../table/StyledLink";
import DropdownOrderStatusChip from "../../order/status/dropdownOrderStatusChip";
import { format } from "date-fns";
import { OrderStatus } from "../../../types/orderStatusTypes";
import { isVariobelLineItem } from "../../order/add-edit/helpers";

interface CellType {
  row: OrderType;
}

interface Props {
  t: any;
  handleCallbackOnStatusChange: () => void;
  orderStatusesStore: OrderStatus[];
}

const getProductOrderListTableColumns = (props: Props): GridColumns<OrderType> => {
  const {
    t,
    handleCallbackOnStatusChange,
    orderStatusesStore
  } = props;


  const columns = [
    {
      flex: 0.15,
      minWidth: 100,
      field: "order",
      headerName: t("Orders"),
      renderCell: ({ row }: CellType) => {
        const num = row.orderNumber ? row.orderNumber : "NA";

        if (!row.customer) {
          return (
            <StyledLink
              to={`/order/preview/${row._id}`}
            >{`Anonymous - #${num}`}</StyledLink>
          );
        }

        return (
          <StyledLink to={`/order/preview/${row._id}`}>
            {/* {`${row.customer.firstName}. ${row.customer.lastName} - #${num}`} */}
            {`#${num}`}
          </StyledLink>
        );
      },
    },
    {
      flex: 0.15,
      minWidth: 100,
      field: "lastModified",
      headerName: t("Updated"),
      renderCell: ({ row }: CellType) => (
        <Typography variant="body2">
          {format(new Date(row.lastModified), "MMM d, yyyy")}
        </Typography>
      ),
    },
    {
      flex: 0.1,
      minWidth: 80,
      field: "price",
      headerName: t("Price"),
      renderCell: ({ row }: CellType) => (
        //0 is shipping fee
        <Typography variant="body2">
          {currencyFormatter(row.basketPrice.price + 0)}
        </Typography>
      ),
    },
    {
      flex: 0.1,
      minWidth: 80,
      field: "area",
      headerName: t("Area"),
      renderCell: ({ row }: CellType) => {
        const lineItem = row.lineItems[0];

        if(!isVariobelLineItem(lineItem)) return null;

        return (
          <Typography variant="body2">
            {(
              (lineItem.glassWidth * lineItem.glassHeight * lineItem.qty) /
              Math.pow(10, 6)
            ).toFixed(2)}{" "}
            m<sup>2</sup>
          </Typography>
        );
      },
    },
    {
      flex: 0.2,
      minWidth: 200,
      field: "status",
      headerName: t("Status"),
      renderCell: ({ row }: CellType) => {
       return (
          <DropdownOrderStatusChip
            row={row}
            orderStatuses={orderStatusesStore}
            onStatusChangeCallback={handleCallbackOnStatusChange}
          />
        );
      },
    },
  ];


  return columns;
};

export default getProductOrderListTableColumns;
