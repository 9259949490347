import axios from "axios";
import { Area } from "react-easy-crop/types";

interface BlobData {
  blob: Blob;
  objectURL: string;
}

const TO_RADIANS = Math.PI / 180;

const createImage = (url: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });
};

// Returns the new bounding area of a rotated rectangle.
const rotateSize = (width: number, height: number, rotation: number) => {
  const rotRad = rotation * TO_RADIANS;

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
};

// This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
export const getCroppedImg = async (
  imageSrc: string,
  pixelCrop: Area,
  rotation: number = 0,
  flip = { horizontal: false, vertical: false }
) => {
  const image: HTMLImageElement = await createImage(imageSrc);
  const canvas: HTMLCanvasElement = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const rotRad = rotation * TO_RADIANS;

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating
  // and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  const croppedCanvas = document.createElement("canvas");

  const croppedCtx = croppedCanvas.getContext("2d");

  if (!croppedCtx) return null;

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width;
  croppedCanvas.height = pixelCrop.height;

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // As a blob and OBJ-URL
  return new Promise<BlobData>((resolve, reject) => {
    croppedCanvas.toBlob((blob: Blob | null) => {
      if (blob) {
        const objectURL: string = URL.createObjectURL(blob);
        resolve({ blob, objectURL });
      } else {
        reject("Error creating blob");
      }
    }, "image/jpeg");
  });
};

// Calculate the ideal resolution for a custom image selected for a print wallpaper
export function calculatePrintImageResolution(printWidthMm: number, printHeightMm: number ) {
  const desiredDPI = 300 ?? process.env.REACT_APP_PRINT_DPI;

  // Convert millimeters to inches (1 inch ≈ 25.4 mm)
  const printWidthInches = printWidthMm / 25.4;
  const printHeightInches = printHeightMm / 25.4;

  // Calculate image resolution in pixels
  const resolutionWidth = Math.round(printWidthInches * desiredDPI);
  const resolutionHeight = Math.round(printHeightInches * desiredDPI);

  return { width: resolutionWidth, height: resolutionHeight };
}

function convertImageToBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      if (event.target) {
        const base64Image = event.target.result as string;
        resolve(base64Image);
      }
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}

export async function getImageDimensions(file: File) {
  const base64Image = await convertImageToBase64(file);
  let DPI = 0;

  const config = {
    method: "post",
    url: `${process.env.REACT_APP_SERVER_HOST}/helpers/base64-metadata`,
    data: { b64: base64Image },
  };

  await axios(config)
    .then((res) => {
      const { metadata } = res.data;
      const { density } = metadata;

      if (density) {
        DPI = density;
      }
    })
    .catch((err) => Promise.reject(err));

  const imageSrc = URL.createObjectURL(file)
  const image: HTMLImageElement = await createImage(imageSrc);
  const { width, height } = image;


  return { width, height, DPI }
}