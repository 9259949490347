export const currencyFormatter = (value: number) => {
  const formatter = new Intl.NumberFormat("sk-SK", {
    style: "currency",
    currency: "EUR"
  });

  const amount = formatter.format(value);

  return amount;
};
