import { Box, Drawer, Icon, IconButton, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { Link } from "react-router-dom";
import { Avatar } from "../../avatar";
import { getInitials } from "../../../helpers/get-initials";
import OrderDetailsSummary from "../orderDetailsSummary";
import OrderPreviewTimeline from "../preview/order-preview-timeline";
import { OrderType } from "../../../types/orderTypes";

interface Props {
  open: boolean;
  onClose: () => void;
  order: OrderType | null;
  setOrder: Dispatch<SetStateAction<OrderType | null>>;
}

const KanbanItemPreview = (props: Props) => {
  const { open, onClose, order, setOrder } = props;

  if (!order) return null;

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box
        role="presentation"
        sx={{
          width: { xs: "100vw", lg: "50vw" },
          background: (theme) => theme.palette.background.default,
        }}
      >
        <Box
          sx={{
            p: "24px 32px",
            background: (theme) => theme.palette.background.paper,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Box
              component={Link}
              to={`/customer/view/${order.customer._id}`}
              target="_blank"
            >
              <Avatar
                skin="light"
                variant="rounded"
                sx={{
                  width: 60,
                  height: 60,
                  fontWeight: 600,
                  fontSize: "1.45rem",
                  textTransform: "uppercase",
                  mr: 2,
                }}
              >
                {getInitials(
                  `${order.customer.firstName} ${order.customer.lastName}`
                )}
              </Avatar>
            </Box>
            <Box>
              <Typography
                component={Link}
                to={`/order/preview/${order._id}`}
                sx={{
                  fontWeight: 500,
                  fontStyle: "italic",
                  "&:hover": { textDecoration: "underline" },
                }}
              >
                Order #{order.orderNumber}
              </Typography>

              <Typography
                sx={{
                  textTransform: "capitalize",
                  maxWidth: "150px",
                  textOverflow: "ellipsis",
                  textWrap: "nowrap",
                  overflow: "hidden",
                }}
              >
                {order.customer.firstName} {order.customer.lastName}
              </Typography>
            </Box>

            <IconButton
              component={Link}
              to={`/order/preview/${order._id}`}
              target="_blank"
            >
              <Icon>open_in_new</Icon>
            </IconButton>
          </Box>
          <IconButton onClick={onClose}>
            <Icon>close</Icon>
          </IconButton>
        </Box>

        <Box
          sx={{
            p: "32px",
            display: "grid",

            gap: "32px",
          }}
        >
          <OrderDetailsSummary
            {...{
              lineItems: order.lineItems,
              basketPrice: order.basketPrice,
            }}
          />

          <OrderPreviewTimeline {...{ data: order, setData: setOrder }} />
        </Box>
      </Box>
    </Drawer>
  );
};

export default KanbanItemPreview;
