import React, {
  useState,
  MouseEvent,
  ChangeEvent,
  useEffect,
  SetStateAction,
  Dispatch,
} from "react";

import {
  Card,
  CardHeader,
  TablePagination,
  CardContent,
  useTheme,
} from "@mui/material";

import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";

import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { FormikHelpers } from "formik";

import { ProductType } from "../../../../types/productTypes";
import { ValuesType } from "../../../../types/customTimelineTypes";
import { AppDispatch } from "../../../../store";
import { Admin } from "../../../../types/adminTypes";

import AddTimelineEntryCard from "../../../../comps/AddTimelineEntryCard";
import ViewProductTimelineItems from "./ViewProductTimelineItems";
import { Avatar } from "../../../../comps/avatar";

import { updateProduct, getProduct } from "../../../../store/apps/product";
import { fetchData as fetchAdminData } from "../../../../store/apps/admin";

import { useAuth } from "../../../../hooks/useAuth";
import { useAlertContext } from "../../../../hooks/useAlertContext";

import { getInitials } from "../../../../helpers/get-initials";
import { sortByKey } from "../../../../helpers/sortByKey";

interface Props {
  id: string;
  product: ProductType;
  setProduct: Dispatch<SetStateAction<ProductType | undefined>>;
}

const ViewProductTimeline = (props: Props) => {
  const { product, setProduct, id } = props;
  const { timelineEntries } = product;

  const theme = useTheme();
  const { user } = useAuth();
  const { showSuccessAlert, showErrorAlert } = useAlertContext();
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(100);
  const [admins, setAdmins] = useState<Admin[]>([]);

  // Fetch Admin Data
  useEffect(() => {
    dispatch(fetchAdminData({ search: "" }))
      .unwrap()
      .then((res) => {
        const _admins = res.admins;
        setAdmins(_admins);
      });
  }, [dispatch]);

  // Fetch product
  // This might seem redundant since there is a fetch already in the ViewProduct parent
  // But it is done to help when a product possibly updates, sort of a "refetch"
  // Hence no loader, so user barely notices
  useEffect(() => {
    (async () =>
      dispatch(getProduct(id)).then((res) => {
        const _product = res.payload.product;
        _product.saleEnds = _product.saleEnds
          ? new Date(_product.saleEnds)
          : null;

        setProduct(_product as ProductType);
      }))();
  }, [dispatch, id, setProduct]);

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSubmit = (
    values: ValuesType,
    formikHelpers: FormikHelpers<ValuesType>
  ) => {
    const { setSubmitting, resetForm } = formikHelpers;
    const now = new Date();

    const entries = [
      ...timelineEntries,
      { ...values, time: now, admin: user!._id },
    ];

    const setUpdatedProduct = () => {
      dispatch(getProduct(product._id))
        .unwrap()
        .then((res) => {
          const _product = res.product;
          _product.saleEnds = _product.saleEnds
            ? new Date(_product.saleEnds)
            : null;

          setProduct(_product as ProductType);
        });
    };

    dispatch(
      updateProduct({
        id: product._id,
        data: {
          timelineEntries: entries,
        },
      })
    )
      .unwrap()
      .then((res) => {
        showSuccessAlert(t("Product updated"));
        setSubmitting(false);
        resetForm();

        setUpdatedProduct();
      })
      .catch((err) => {
        Promise.reject(err);
        setSubmitting(false);
        showErrorAlert(t("Failed to update Product"));
      });
  };

  useEffect(() => {
    if (timelineEntries.length) {
      setPageCount(timelineEntries.length);
    }
  }, [timelineEntries.length]);

  const totalPages = timelineEntries.length;

  // + 1 due to first page needing to be 0 for mui component
  const currentPage = page + 1 > totalPages ? totalPages : page + 1;
  const paginationStartIndex = (currentPage - 1) * rowsPerPage;
  const paginationEndIndex = paginationStartIndex + rowsPerPage;

  if (!user) return null;

  return (
    <Card>
      <CardHeader title="Timeline" />

      <CardContent sx={{ pt: `${theme.spacing(2.5)} !important` }}>
        <Timeline
          sx={{
            my: 0,
            py: 0,
            paddingLeft: 0,
            paddingRight: 0,
            "& .MuiTimelineItem-root": {
              width: "100%",
              "&:before": {
                display: "none",
              },
            },
          }}
        >
          <TimelineItem sx={{ minHeight: 150 }}>
            <TimelineSeparator sx={{ ml: "-10px" }}>
              <Avatar
                skin="light"
                sx={{
                  width: 40,
                  height: 40,
                  fontWeight: 600,
                  fontSize: "1rem",
                }}
              >
                {getInitials(`${user?.displayName}`)}
              </Avatar>
              <TimelineConnector />
            </TimelineSeparator>

            <TimelineContent
              sx={{
                mt: 0,
                mb: (theme) => `${theme.spacing(3)} !important`,
              }}
            >
              <Card sx={{ borderRadius: 0 }}>
                <AddTimelineEntryCard isNoteOnly {...{ onSubmit }} />
              </Card>
            </TimelineContent>
          </TimelineItem>

          {timelineEntries
            .sort(sortByKey("time", false))
            .slice(paginationStartIndex, paginationEndIndex)
            .map((entry) => (
              <ViewProductTimelineItems {...{ entry, admins }} />
            ))}
        </Timeline>
      </CardContent>

      <TablePagination
        component="div"
        count={pageCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
};

export default ViewProductTimeline;
