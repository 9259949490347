import yup from "../helpers/customYup";

import { Admin } from "./adminTypes";
import { ThemeColor } from "../comps/@core/layouts/types";
import { OrderType } from "./orderTypes";

export const orderStatusSchema = yup.object().shape({
  name: yup.string().required(),
  emailSubject: yup.string().required(),
  colorCode: yup
    .mixed<ThemeColor | "">()
    .transform((value) => (value ? value : undefined))
    .required(),
  template: yup.string().required(),
  notifyCustomer: yup.boolean().defined(),
  notifyAdmin: yup.boolean().defined(),
  isDefault: yup.boolean().defined(),
  positionInKanban: yup.number().required()
});

export interface OrderStatusData
  extends yup.InferType<typeof orderStatusSchema> {}

export interface OrderStatus extends OrderStatusData {
  _id: string;
  createdAt: string;
  createdBy: Admin;
  lastModified: string;
  lastModifiedBy: Admin;
}

export type KanbanColumnT = Pick<
OrderStatus,
"_id" | "name" | "colorCode" | "positionInKanban"
>;

export type KanbanBoardColumnT = OrderType[];
export type KanbanBoardStateT = KanbanBoardColumnT[]