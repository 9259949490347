// ** React Imports
import { ElementType, ReactNode, useState } from "react";

// ** MUI Imports
import { styled } from "@mui/material/styles";
import {
  Box,
  List,
  Button,
  Drawer,
  ListItemIcon,
  ListItemText,
  ListItem,
  ListItemButtonProps,
} from "@mui/material";

// ** Icons Imports
import SendOutline from "mdi-material-ui/SendOutline";
import EmailOutline from "mdi-material-ui/EmailOutline";
// import DeleteOutline from "mdi-material-ui/DeleteOutline";

// ** Third Party Imports
import PerfectScrollbar from "react-perfect-scrollbar";

// ** Custom Components Imports
// import CustomBadge from "../../../comps/@core/components/mui/badge";

// ** Types
// import { CustomBadgeProps } from "../../../comps/@core/components/mui/badge/types";
import { MailSidebarType, supportedMailboxes } from "../../../types/emailTypes";
import { useTranslation } from "react-i18next";

// ** Styled Components
const ListItemStyled = styled(ListItem)<
  ListItemButtonProps & { component?: ElementType; to?: string }
>(({ theme }) => ({
  borderLeftWidth: "3px",
  borderLeftStyle: "solid",
  padding: theme.spacing(0, 5),
  marginBottom: theme.spacing(2),
}));

// const ListBadge = styled(CustomBadge)<CustomBadgeProps>(() => ({
//   "& .MuiBadge-badge": {
//     height: "18px",
//     minWidth: "18px",
//     transform: "none",
//     position: "relative",
//     transformOrigin: "none",
//   },
// }));

const SidebarLeft = (props: MailSidebarType) => {
  // ** Props
  const {
    // store,
    hidden,
    lgAbove,
    // dispatch,
    leftSidebarOpen,
    leftSidebarWidth,
    toggleComposeOpen,
    setMailDetailsOpen,
    // handleSelectAllMail,
    handleLeftSidebarToggle,
    connectedSmtpAccounts,
    setMailbox
  } = props;

  const { t } = useTranslation();

  const RenderBadge = () => {
    // return (
    //   <ListBadge skin="light" color={color} sx={{ ml: 2 }} badgeContent={1} />
    // );

    return null;
  };

  const ScrollWrapper = ({ children }: { children: ReactNode }) => {
    if (hidden) {
      return (
        <Box sx={{ height: "100%", overflowY: "auto", overflowX: "hidden" }}>
          {children}
        </Box>
      );
    } else {
      return (
        <PerfectScrollbar options={{ wheelPropagation: false }}>
          {children}
        </PerfectScrollbar>
      );
    }
  };

  const mailBoxes = [
    {
      title: "Inbox",
      mailbox: "INBOX",
      children: ["Seen", "Unseen"],
      notificationColor: "error",
      icon: <EmailOutline sx={{ fontSize: "1.25rem" }} />,
      isSelected: true,
    },

    {
      title: "Sent",
      mailbox: "Sent",
      children: null,
      notificationColor: null,
      icon: <SendOutline sx={{ fontSize: "1.25rem" }} />,
      isSelected: false,
    },

    // TODO: figure out why the Trash/Junk folders arent working
    // Error Message: Error in IMAP command FETCH: Invalid messageset

    // {
    //   title: "Trash",
    //   mailbox: "Junk",
    //   children: null,
    //   notificationColor: null,
    //   icon: <DeleteOutline sx={{ fontSize: "1.25rem" }} />,
    //   isSelected: false,
    // },
  ];

  const [mailboxes, setMailBoxes] = useState(mailBoxes);

  const handleListItemClick = (mailbox: supportedMailboxes, title: string) => {
    const alteredMailboxes = mailboxes.map((m) =>  {
      m.isSelected = false;

      if (m.title === title) {
        m.isSelected = true;
      }

      return m;
    });

    setMailbox(mailbox);
    setMailBoxes(alteredMailboxes)
    setMailDetailsOpen(false);
    handleLeftSidebarToggle();
  };

  return (
    <Drawer
      open={leftSidebarOpen}
      onClose={handleLeftSidebarToggle}
      variant={lgAbove ? "permanent" : "temporary"}
      ModalProps={{
        disablePortal: true,
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        zIndex: 9,
        display: "block",
        position: lgAbove ? "static" : "absolute",
        "& .MuiDrawer-paper": {
          boxShadow: "none",
          width: leftSidebarWidth,
          zIndex: lgAbove ? 2 : "drawer",
          position: lgAbove ? "static" : "absolute",
        },
        "& .MuiBackdrop-root": {
          position: "absolute",
        },
      }}
    >
      <Box sx={{ p: 5, overflowY: "hidden" }}>
        <Button
          fullWidth
          variant="contained"
          disabled={connectedSmtpAccounts.length === 0}
          onClick={toggleComposeOpen}
        >
          {t("Compose")}
        </Button>
      </Box>

      <ScrollWrapper>
        <Box sx={{ pt: 1.25, overflowY: "hidden" }}>
          <List component="div">
            {mailboxes.map((box) => (
              <ListItemStyled
                key={box.title}
                disabled={!box.isSelected}
                onClick={() => handleListItemClick(box.mailbox as supportedMailboxes, box.title)}
                sx={{
                  pt: 0.5,
                  cursor: 'pointer',
                  borderLeftColor: (theme) =>
                    box.isSelected ? theme.palette.primary.main : "transparent",
                }}
              >
                <ListItemIcon
                  sx={{
                    color: box.isSelected ? "primary.main" : "text.secondary",
                  }}
                >
                  {box.icon}
                </ListItemIcon>

                <ListItemText
                  primary={t(box.title)}
                  primaryTypographyProps={{
                    noWrap: true,
                    sx: {
                      color: (theme) =>
                        box.isSelected ? theme.palette.primary.main : "",
                    },
                  }}
                />
                {RenderBadge()}
              </ListItemStyled>
            ))}
          </List>
        </Box>
      </ScrollWrapper>
    </Drawer>
  );
};

export default SidebarLeft;
