import { Customer } from "../types/customerTypes";
import { ProductType } from "../types/productTypes";

interface Discountable {
  value: number;
  prefix: string;
}

export const calculateDiscount = (
  total: number,
  entity: Discountable,
) => {
  if (!entity || !entity.value) return 0;

  let calculatedDiscount = entity.value;

  if (entity.prefix === "%") {
    calculatedDiscount = total * (entity.value / 100);
  }

  return calculatedDiscount;
};


export const calculateOnSaleDiscountValue = (
  product: ProductType,
  customer: Customer,
) => {
  let discountedPrice = 0;
  if (!product.onSale) return discountedPrice;

  let discountAmount = product.saleDiscount; // unregistered customer discount

  if (customer.isRegistered) {
    discountAmount = product.customerDiscount; // retail registered customer discount;

    if (customer.isWholesaleCustomer) {
      discountAmount = product.wholesaleDiscount; // wholesale registered customer discount
    }
  }

  const originalPrice = product.price;

  const { value, prefix } = discountAmount
  if (prefix === "%") {
    const _discountAmount = originalPrice * (value / 100);
    return _discountAmount
  }

  return value;
};

// used in order summary
export const calculateOnSaleDiscountAmount = (
  product: ProductType,
  customer: Customer,
) => {
  if (!product.onSale) return "0%";

  let discountAmount = product.salesPrice; // unregistered customer discount

  if (customer.isRegistered) {
    discountAmount = product.customerDiscount; // retail registered customer discount;

    if (customer.isWholesaleCustomer) {
      discountAmount = product.wholesaleDiscount; // wholesale registered customer discount
    }
  }

  const { value, prefix } = discountAmount
  return `${value}${prefix}`;
};