import {
  Card,
  Box,
  CardContent,
  Typography,
  Button,
  Icon,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { Chip } from "../../../../comps/chip";

import { OrderStatus } from "../../../../types/orderStatusTypes";
import { ThemeColor } from "../../../../comps/@core/layouts/types";

interface Props {
  orderStatus: OrderStatus;
  toggleEditDialog: () => void;
}

const ViewOrderStatusLeft = (props: Props) => {
  const { orderStatus, toggleEditDialog } = props;
  const { t } = useTranslation();

  return (
    <Card>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            textDecoration: "underline",
            textAlign: "center",
          }}
        >
          {t("Details")}
        </Typography>

        <Box
          py={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ mr: 2, color: "text.primary" }}
            >
              {`${t("Name")}:`}
            </Typography>

            <Typography variant="body2">{orderStatus.name}</Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ mr: 2, color: "text.primary" }}
            >
              {`${t("Subject")}:`}
            </Typography>

            <Typography variant="body2">{orderStatus.emailSubject}</Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ mr: 2, color: "text.primary" }}
            >
              {`${t("Color")}:`}
            </Typography>

            <Chip
              size="small"
              color={orderStatus.colorCode as ThemeColor}
              sx={{ width: 28, height: 28 }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ mr: 2, color: "text.primary" }}
            >
              {`${t("Notify Customer")}:`}
            </Typography>

            <Icon
              sx={{
                fontWeight: "body2.fontWeight",
                fontSize: "body2.fontSize",
                color: "text.secondary",
              }}
            >
              {orderStatus.notifyCustomer
                ? "check_box"
                : "check_box_outline_blank"}
            </Icon>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ mr: 2, color: "text.primary" }}
            >
              {`${t("Notify Admin")}:`}
            </Typography>

            <Icon
              sx={{
                fontWeight: "body2.fontWeight",
                fontSize: "body2.fontSize",
                color: "text.secondary",
              }}
            >
              {orderStatus.notifyAdmin
                ? "check_box"
                : "check_box_outline_blank"}
            </Icon>
          </Box>
        </Box>

        <Button variant="contained" onClick={toggleEditDialog}>
          Edit
        </Button>
      </CardContent>
    </Card>
  );
};

export default ViewOrderStatusLeft;
