import React from 'react';
import {
  Grid,
  Typography,
  Divider,
} from '@mui/material';

interface Props {
  caption: string;
}

export const SectionDivider = ({ caption }: Props) => (
  <Grid item xs={12}>
    <Divider
      textAlign="left"
      sx={{
        borderColor: (theme) => theme.palette.primary.main,
        "&::before, ::after": {
          borderColor: "inherit",
        },
      }}
    >
      <Typography
        variant="overline"
        color="primary"
        sx={{
          fontWeight: 600,
          textTransform: "uppercase",
        }}
      >
        {caption}
      </Typography>
    </Divider>
  </Grid>
);


export const PricingDivider = ({ caption }: Props) => (
  <Grid item xs={12} sm={6} sx={{ pt: "0px !important" }}>
    <Typography
      variant="overline"
      color="secondary"
      sx={{
        fontWeight: 600,
        textTransform: "uppercase",
      }}
    >
      {caption}
    </Typography>
  </Grid>
);
