import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  SelectChangeEvent,
} from "@mui/material";
import { ICompany } from "../../../hooks/useCompanies";

interface Props {
  t: (key: string) => string;
  companies: ICompany[];
  productStatuses: string[];
  companyValue: string;
  searchValue: string;
  statusValue: string;
  handleSourceValue: (event: SelectChangeEvent) => void;
  handleSearchValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleStatusValue: (e: SelectChangeEvent) => void;
}

const ProductListFilters = (props: Props) => {
  const {
    t,
    companies,
    productStatuses,
    companyValue,
    searchValue,
    statusValue,
    handleSourceValue,
    handleSearchValue,
    handleStatusValue,
  } = props;

  return (
    <Card>
      <CardHeader title={t("Filter")} />
      <CardContent>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="product-source-select">{t("Source")}</InputLabel>
              <Select
                fullWidth
                value={companyValue}
                label={t("Source")}
                onChange={handleSourceValue}
                labelId="product-source-select"
              >
                <MenuItem value="">None</MenuItem>
                {companies.map((company, i) => (
                  <MenuItem key={i} value={company.id}>
                    {company.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              value={searchValue}
              placeholder={t("Search product")}
              onChange={handleSearchValue}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="product-status-select">{t("Status")}</InputLabel>
              <Select
                fullWidth
                value={statusValue}
                label={t("Status")}
                onChange={handleStatusValue}
                labelId="product-status-select"
              >
                <MenuItem value="">None</MenuItem>
                {productStatuses.map((product, i) => (
                  <MenuItem key={i} value={product}>
                    {product}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProductListFilters;
