// ** Redux Imports
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

// ** Types Imports
import { KanbanColumnT, OrderStatus, OrderStatusData } from "../../../types/orderStatusTypes";
import { Dispatch } from "redux";

interface Redux {
  getState: any;
  dispatch: Dispatch<any>;
}

type StateT = {
  data: OrderStatus[];
  kanbanColumns: KanbanColumnT[];
};

const initialState: StateT = {
  data: [],
  kanbanColumns: []
};

// ** Fetch Order Statuses
export const fetchData = createAsyncThunk("orderStatus/fetchData", async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_HOST}/order-status/get-all`,
    {
      withCredentials: true,
    }
  );

  return response.data;
});

// ** Add Order Status
export const addOrderStatus = createAsyncThunk(
  "orderStatus/addOrderStatus",
  async (data: OrderStatusData, { dispatch }: Redux) => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_HOST}/order-status/create`,
      data,
      { withCredentials: true }
    );

    dispatch(fetchData());
    return response.data;
  }
);

// ** Delete Order Status
export const deleteOrderStatus = createAsyncThunk(
  "orderStatus/deleteOrderStatus",
  async (id: string, { dispatch }: Redux) => {
    const response = await axios.delete(
      `${process.env.REACT_APP_SERVER_HOST}/order-status/delete/${id}`,
      {
        withCredentials: true,
      }
    );

    dispatch(fetchData());
    return response.data;
  }
);

// ** Edit Order Status
export const editOrderStatus = createAsyncThunk(
  "orderStatus/editOrderStatus",
  async (
    { id, data }: { id: string; data: OrderStatusData },
    { dispatch }: Redux
  ) => {
    const response = await axios.patch(
      `${process.env.REACT_APP_SERVER_HOST}/order-status/update/${id}`,
      data,
      {
        withCredentials: true,
      }
    );

    dispatch(fetchData());
    return response.data;
  }
);

// ** Send Email
export const sendOrderStatusEmail = createAsyncThunk(
  "orderStatus/sendEmail",
  async ({ orderID, recipient }: { orderID: string; recipient: string }) => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_HOST}/order-status/send-email`,
      { orderID, recipient },
      { withCredentials: true }
    );

    return response.data;
  }
);

export const getOrderStatus = createAsyncThunk(
  "orderStatus/get",

  async (id: number | string, { getState, dispatch }: Redux) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_HOST}/order-status/get/${id}`,
      { withCredentials: true }
    );

    return response.data;
  }
);

export const appOrderStatusesSlice = createSlice({
  name: "appOrderStatus",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchData.fulfilled,
        (state, action: PayloadAction<{ orderStatuses: OrderStatus[] }>) => {
          state.data = action.payload.orderStatuses;

          const kanbanColumns = action.payload.orderStatuses.map(({ _id, name, colorCode, positionInKanban }) => ({
            _id, name, colorCode, positionInKanban
          }))
          state.kanbanColumns = kanbanColumns;
        }
      )
      .addCase(
        addOrderStatus.fulfilled,
        (state, action: PayloadAction<{ orderStatus: OrderStatus }>) => {
          state.data.push(action.payload.orderStatus);

          const { _id, name, colorCode, positionInKanban } = action.payload.orderStatus;
          const kanbanColumn = { _id, name, colorCode, positionInKanban };
          state.kanbanColumns.push(kanbanColumn);
        }
      )
      .addCase(
        deleteOrderStatus.fulfilled,
        (state, action: PayloadAction<{ orderStatus: OrderStatus }>) => {
          state.data = state.data.filter(
            (orderStatus) => orderStatus._id !== action.payload.orderStatus._id
          );

          state.kanbanColumns = state.kanbanColumns.filter(
            (orderStatus) => orderStatus._id !== action.payload.orderStatus._id
          );
        }
      )
      .addCase(
        editOrderStatus.fulfilled,
        (state, action: PayloadAction<{ orderStatus: OrderStatus }>) => {
          const newOrderStatuses = state.data.filter(
            (orderStatus) => orderStatus._id !== action.payload.orderStatus._id
          );
          newOrderStatuses.push(action.payload.orderStatus);
          state.data = newOrderStatuses;

          const kanbanColumns = newOrderStatuses.map(({ _id, name, colorCode, positionInKanban }) => ({
            _id, name, colorCode, positionInKanban
          }))
          state.kanbanColumns = kanbanColumns;
        }
      );
  },
});

export default appOrderStatusesSlice.reducer;
