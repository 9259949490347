import React, { useEffect } from "react";

import WallpaperSelectBase from "./custom-wallpaper-tab/components/wallpaper-select-base";
import DisplayImageAfterCrop from "./custom-wallpaper-tab/components/display-image-after-crop";
import { ICrop } from "../types";
import { AddOrderListLineItemType, LineItemType } from "../../../../../types/orderTypes";
import { localWallpaperStateI, updateLocalWallpaperState } from "../../../../../store/apps/print-wallpaper";
import { useDispatch } from "react-redux";

interface Props extends ICrop, localWallpaperStateI {
  selectedLineItem: LineItemType | AddOrderListLineItemType
}

const WallpaperTab = (props: Props) => {
  const {
    wallpaper,
    isCropMode,
    croppedImage,
    ...rest
  } = props;

  const displayCropBoxProps = {
    ...rest,
    wallpaper,
    isCropMode,
    type: "manual" as "custom" | "manual",
  };

  const dispatch = useDispatch();

  // Set img src
  // imgSrc prop is used by cropBox component
  useEffect(() => {
    if (wallpaper.name){
      dispatch(updateLocalWallpaperState({
        imgSrc: wallpaper.name
      }))
    }
  }, [dispatch, wallpaper]);

  const Content = () => {
    if (!isCropMode) {
      return <DisplayImageAfterCrop src={croppedImage} />;
    }

    return null;
  };

  return (
    <WallpaperSelectBase {...displayCropBoxProps}>
      <Content />
    </WallpaperSelectBase>
  );
};

export default WallpaperTab;
