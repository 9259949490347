import React, { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import {
  Grid,
} from "@mui/material";
import {
  Control,
  FieldErrors,
  UseFormSetValue,
} from "react-hook-form";
import { ProductFormInputs } from "../../../../types/productTypes";

import { updatePriceValueWith } from "../utils/updatePriceValueWith";
import PriceAccordion from "../PriceAccordion";
import { MarginInput, MarginPrefixInput } from "../MarginFields";
import ProductTaxFields from "../ProductTaxFields";
import {
  CallbackCalculateTotalWithTax,
  RoundToDecimal,
} from "../utils/helpers";
import { PricingDivider } from "../SectionDivider";

interface Props {
  control: Control<ProductFormInputs, any>;
  t: any;
  errors: FieldErrors<ProductFormInputs>;
  setValue: UseFormSetValue<ProductFormInputs>;

  watchSalesDiscount: ProductFormInputs["saleDiscount"];
  watchRetailMargin: ProductFormInputs["retailMargin"];

  tax: number;
  watchPrice: number;
}
// Component for rendering a section with sale price fields
export const UnRegisteredCustomersOnSaleFields = ({
  control,
  t,
  errors,
  setValue,

  watchSalesDiscount,
  watchRetailMargin,

  tax,
  watchPrice,
}: Props) => {
  const [totalPriceWithRetailMargin, setTotalPriceWithRetailMargin] =
    useState(0);

  const [totalPriceWithSalesPrice, setTotalPriceWithSalesPrice] = useState(0);
  const [totalPriceWithSalesPriceWithTax, setTotalPriceWithSalesPriceWithTax] =
    useState(0);

  const handleTaxFieldsOnChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    setState: Dispatch<SetStateAction<number>>
  ) => {
    setState(Number(event.currentTarget.value));
  };

  const handleTaxFieldsOnBlur = () => {
    if (totalPriceWithSalesPrice < totalPriceWithRetailMargin) {
      setTotalPriceWithSalesPrice(totalPriceWithSalesPrice); // dont necessarily need this but helps understanding

      let newDiscount = totalPriceWithRetailMargin - totalPriceWithSalesPrice;

      if (watchSalesDiscount.prefix === "%") {
        newDiscount = (newDiscount * 100) / totalPriceWithRetailMargin;
      }
      newDiscount = RoundToDecimal(newDiscount, 2);

      setValue("saleDiscount.value", newDiscount);
    } else {
      setTotalPriceWithSalesPrice(totalPriceWithRetailMargin);
      setValue("saleDiscount.value", 0);
    }
  };

  const handleTaxFieldsOnBlurWithTax = () => {
    const _tax = totalPriceWithRetailMargin * (tax / 100);
    let totalPriceWithTax = _tax + totalPriceWithRetailMargin;
    totalPriceWithTax = RoundToDecimal(totalPriceWithTax, 2);

    if (totalPriceWithSalesPriceWithTax <= totalPriceWithTax) {
      setTotalPriceWithSalesPriceWithTax(totalPriceWithSalesPriceWithTax);

      let totalPrice = totalPriceWithSalesPriceWithTax / ((100 + tax) / 100);
      totalPrice = RoundToDecimal(totalPrice, 2);

      setTotalPriceWithSalesPrice(totalPrice);
      let newDiscount = totalPriceWithRetailMargin - totalPrice;

      if (watchSalesDiscount.prefix === "%") {
        newDiscount = (newDiscount * 100) / totalPriceWithRetailMargin;
      }
      newDiscount = RoundToDecimal(newDiscount, 2);

      setValue("saleDiscount.value", newDiscount);
    } else {
      setTotalPriceWithSalesPriceWithTax(totalPriceWithTax);
      setValue("saleDiscount.value", 0);
    }
  };

  const calculateTotalWithTax = useCallback(
    (
      priceWithMargin: number,
      setTotalPriceWithTax: Dispatch<SetStateAction<number>>,
      tax: number
    ) =>
      CallbackCalculateTotalWithTax(priceWithMargin, setTotalPriceWithTax, tax),
    []
  );

  // update total Price With Retail Margin
  useEffect(() => {
    const margin = updatePriceValueWith(
      watchPrice,
      watchRetailMargin.value,
      watchRetailMargin.prefix
    );
    let total = watchPrice + margin;
    total = RoundToDecimal(total, 2);

    setTotalPriceWithRetailMargin(total);
  }, [watchPrice, watchRetailMargin]);

  // update total Price With sales price
  useEffect(() => {
    const discount = updatePriceValueWith(
      totalPriceWithRetailMargin,
      watchSalesDiscount.value,
      watchSalesDiscount.prefix
    );
    let total = totalPriceWithRetailMargin - discount;
    total = RoundToDecimal(total, 2);

    setTotalPriceWithSalesPrice(total);
  }, [totalPriceWithRetailMargin, watchSalesDiscount]);

  // Below  calculates total price with tax
  useEffect(() => {
    calculateTotalWithTax(
      totalPriceWithSalesPrice,
      setTotalPriceWithSalesPriceWithTax,
      tax
    );
  }, [tax, totalPriceWithSalesPrice, calculateTotalWithTax]);

  return (
    <Grid item container xs={12} sm={6} spacing={5}>
      <PricingDivider caption={t("Discount")} />

      <Grid item container spacing={5} xs={12}>
        <ProductTaxFields
          t={t}
          value={totalPriceWithSalesPrice}
          valueWithTax={totalPriceWithSalesPriceWithTax}
          onChange={(event) =>
            handleTaxFieldsOnChange(event, setTotalPriceWithSalesPrice)
          }
          onChangeWithTax={(event) =>
            handleTaxFieldsOnChange(event, setTotalPriceWithSalesPriceWithTax)
          }
          onBlur={(event) => handleTaxFieldsOnBlur()}
          onBlurWithTax={(event) => handleTaxFieldsOnBlurWithTax()}
        />
        <Grid item xs={12}>
          <PriceAccordion>
            <>
              <MarginPrefixInput
                label={t("Discount type")}
                namePrefix="saleDiscount.prefix"
                {...{ errors, control, t }}
              />

              <MarginInput
                label={t("Discount")}
                name="saleDiscount.value"
                prefix={watchSalesDiscount.prefix}
                {...{ errors, control, t }}
              />
            </>
          </PriceAccordion>
        </Grid>
      </Grid>
    </Grid>
  );
};
