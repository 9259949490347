// ** React Imports
import { Fragment, useState } from "react";

// ** MUI Imports
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import { LinearProgress, MenuItem, Select } from "@mui/material";

// ** Third Party Imports
import { useTranslation } from "react-i18next";
import { Controller, UseFormReturn } from "react-hook-form";

// ** Types Imports
import { CustomerData } from "../../types/customerTypes";
import CustomerAddressArray from "./customerAddressArray";

const pricingPrefixList = ["%", "€"];
const roles = ["Admin", "User"];

interface Props {
  title: string;
  form: UseFormReturn<CustomerData, any>;
  open: boolean;
  toggle: () => void;
  onSubmit: (data: CustomerData) => void;
}

export const CustomerFormDialog = (props: Props) => {
  // ** Props
  const { title, form, open, toggle, onSubmit } = props;

  // ** States
  const [showPassword, setShowPassword] = useState<boolean>(false);

  // ** Hooks
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
  } = form;

  const watchDiscount = watch("discount");

  // ** Handlers
  const handleClose = () => {
    toggle();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      scroll="body"
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>
        {title}
        <IconButton
          size="small"
          onClick={handleClose}
          sx={{ position: "absolute", right: "1rem", top: "1rem" }}
        >
          <Icon>close</Icon>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ pt: (theme) => `${theme.spacing(2)} !important` }}>
        <form onSubmit={(e) => e.preventDefault()}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                {`1. ${t("Account details")}`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      required
                      value={value}
                      label={t("First name")}
                      onChange={onChange}
                      error={Boolean(errors.firstName)}
                    />
                  )}
                />
                {errors.firstName && errors.firstName.message && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {t(errors.firstName.message)}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      required
                      value={value}
                      label={t("Last name")}
                      onChange={onChange}
                      error={Boolean(errors.lastName)}
                    />
                  )}
                />
                {errors.lastName && errors.lastName.message && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {t(errors.lastName.message)}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      required
                      type="email"
                      autoComplete="add"
                      value={value}
                      label={t("Email")}
                      onChange={onChange}
                      error={Boolean(errors.email)}
                    />
                  )}
                />
                {errors.email && errors.email.message && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {t(errors.email.message)}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel
                  htmlFor="customer-password"
                  required
                  error={Boolean(errors.password)}
                >
                  {t("Password")}
                </InputLabel>
                <Controller
                  name="password"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <OutlinedInput
                      required
                      value={value}
                      autoComplete="new-password"
                      label={t("Password")}
                      onChange={onChange}
                      id="customer-password"
                      error={Boolean(errors.password)}
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? (
                              <Icon>visibility</Icon>
                            ) : (
                              <Icon>visibility_off</Icon>
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  )}
                />
                {errors.password && errors.password.message && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {t(errors.password.message)}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      value={value}
                      label={t("Phone")}
                      onChange={onChange}
                      error={Boolean(errors.phone)}
                    />
                  )}
                />
                {errors.phone && errors.phone.message && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {t(errors.phone.message)}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Controller
                  name="role"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormControl>
                      <InputLabel>{`${t("Role")}`}</InputLabel>
                      <Select
                        label={`${t("Role")}`}
                        // value={value}
                        // onChange={onChange}
                        // error={Boolean(errors.discountPrefix)}
                      >
                        {roles.map((role, index) => (
                          <MenuItem key={index} value={role}>
                            {role}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
                {/* {errors.discountPrefix && errors.discountPrefix.message && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {t(errors.discountPrefix.message)}
                  </FormHelperText>
                )} */}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item container spacing={5}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Controller
                    name="discount.prefix"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <FormControl>
                        <InputLabel>{`${t("Discount type")}`}</InputLabel>
                        <Select
                          label={`${t("Discount type")}`}
                          value={value}
                          onChange={onChange}
                          error={Boolean(errors.discount?.prefix)}
                        >
                          {pricingPrefixList.map((prefix, index) => (
                            <MenuItem key={index} value={prefix}>
                              {prefix}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                  {errors.discount?.prefix && errors.discount?.prefix.message && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {t(errors.discount?.prefix.message)}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Controller
                    name="discount.value"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value}
                        type="number"
                        label={t("Discount")}
                        onChange={onChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {watchDiscount.prefix}
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{ min: 0 }}
                        error={Boolean(errors.discount)}
                      />
                    )}
                  />
                  {errors.discount?.value && errors.discount?.value.message && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {t(errors.discount?.value.message)}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <Controller
                  name="isWholesaleCustomer"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      label={t("Wholesale Customer")}
                      sx={
                        errors.isWholesaleCustomer
                          ? { color: "error.main" }
                          : null
                      }
                      control={
                        <Checkbox
                          required
                          checked={value}
                          onChange={onChange}
                          sx={
                            errors.isWholesaleCustomer
                              ? { color: "error.main" }
                              : null
                          }
                        />
                      }
                    />
                  )}
                />
                {errors.isWholesaleCustomer &&
                  errors.isWholesaleCustomer.message && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {t(errors.isWholesaleCustomer.message)}
                    </FormHelperText>
                  )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <Controller
                  name="isRegistered"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      label={t("Registered")}
                      sx={errors.isRegistered ? { color: "error.main" } : null}
                      control={
                        <Checkbox
                          required
                          checked={value}
                          onChange={onChange}
                          sx={
                            errors.isRegistered ? { color: "error.main" } : null
                          }
                        />
                      }
                    />
                  )}
                />
                {errors.isRegistered && errors.isRegistered.message && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {t(errors.isRegistered.message)}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <Controller
                  name="isVerified"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      label={t("Verified")}
                      sx={errors.isVerified ? { color: "error.main" } : null}
                      control={
                        <Checkbox
                          required
                          checked={value}
                          onChange={onChange}
                          sx={
                            errors.isVerified ? { color: "error.main" } : null
                          }
                        />
                      }
                    />
                  )}
                />
                {errors.isVerified && errors.isVerified.message && (
                  <FormHelperText sx={{ color: "error.main" }}>
                    {t(errors.isVerified.message)}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ mb: 0 }} />
            </Grid>

            <CustomerAddressArray
              field="billingAddress"
              nested={false}
              preTitle="2."
              title="Billing address"
              required={true}
              control={control}
              errors={errors.billingAddress}
            />

            <Grid item xs={12}>
              <Divider sx={{ mb: 0 }} />
            </Grid>

            <CustomerAddressArray
              field="shippingAddress"
              nested={false}
              preTitle="3."
              title="Shipping address"
              required={false}
              control={control}
              errors={errors.shippingAddress}
            />

            <Grid item xs={12}>
              <Divider sx={{ mb: 0 }} />
            </Grid>

            <Grid container item alignItems="center" xs={12}>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                4. {t("Company information")}
              </Typography>
            </Grid>

            <Fragment>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Controller
                    name={`company.name`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value}
                        label={t("Name")}
                        onChange={onChange}
                        error={Boolean(errors.company?.name)}
                      />
                    )}
                  />
                  {errors.company?.name && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {t(errors.company?.name?.message as string)}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Controller
                    name={`company.ico`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value}
                        label={t("Company identification number")}
                        onChange={onChange}
                        error={Boolean(errors.company?.ico)}
                      />
                    )}
                  />
                  {errors.company?.ico && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {t(errors.company?.ico.message as string)}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Controller
                    name={`company.dic`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value}
                        label={t("DIC")}
                        onChange={onChange}
                        error={Boolean(errors.company?.dic)}
                      />
                    )}
                  />
                  {errors.company?.dic && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {t(errors.company?.dic.message as string)}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <Controller
                    name={`company.icdph`}
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <TextField
                        value={value}
                        label={t("ICDPH")}
                        onChange={onChange}
                        error={Boolean(errors.company?.icdph)}
                      />
                    )}
                  />
                  {errors.company?.icdph && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {t(errors.company?.icdph.message as string)}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Fragment>

            <Grid item xs={12}>
              <Divider sx={{ mb: 0 }} />
            </Grid>

            <Grid container item>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Controller
                    name="gdprConsent"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <FormControlLabel
                        label={`${t("Collection of personal data")} *`}
                        sx={errors.gdprConsent ? { color: "error.main" } : null}
                        control={
                          <Checkbox
                            required
                            checked={value}
                            onChange={onChange}
                            sx={
                              errors.gdprConsent
                                ? { color: "error.main" }
                                : null
                            }
                          />
                        }
                      />
                    )}
                  />
                  {errors.gdprConsent && errors.gdprConsent.message && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {t(errors.gdprConsent.message)}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Controller
                    name="marketingConsent"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <FormControlLabel
                        label={t("Marketingový súhlas")}
                        sx={
                          errors.marketingConsent
                            ? { color: "error.main" }
                            : null
                        }
                        control={
                          <Checkbox
                            required
                            checked={value}
                            onChange={onChange}
                            sx={
                              errors.marketingConsent
                                ? { color: "error.main" }
                                : null
                            }
                          />
                        }
                      />
                    )}
                  />
                  {errors.marketingConsent &&
                    errors.marketingConsent.message && (
                      <FormHelperText sx={{ color: "error.main" }}>
                        {t(errors.marketingConsent.message)}
                      </FormHelperText>
                    )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              {isSubmitting && <LinearProgress />}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isSubmitting}
          size="large"
          type="submit"
          sx={{ mr: 2 }}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          {t("Save")}
        </Button>
        <Button
          disabled={isSubmitting}
          size="large"
          color="secondary"
          variant="outlined"
          onClick={handleClose}
        >
          {t("Cancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
