import axios from "axios";
import { useEffect, useState } from "react";

export const useStatusGuard = () => {
  const [loading, setLoading] = useState(true);
  const [isServerOk, setIsServerOk] = useState<boolean>(false);

  useEffect(() => {
    const checkServerStatus = () => {
      axios
        .get(`${process.env.REACT_APP_SERVER_HOST}/status`)
        .then(() => {
          setLoading(false);
          setIsServerOk(true);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setIsServerOk(false);
        })
    };

    checkServerStatus();
  }, []);

  return { loading, isServerOk };
};
