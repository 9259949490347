import {
  LinearProgress,
  Button,
  FormControl,
  Box,
  FormControlLabel,
  FormHelperText,
  Tooltip
} from "@mui/material";

import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField, RadioGroup } from "formik-mui";

import { useTranslation } from "react-i18next";

import CustomRadio from "./CustomRadio";

import {
  ValuesType,
  validationSchema,
  initialValues,
  timelineEntryType,
} from "../types/customTimelineTypes";

interface Props {
  open: boolean;
  handleClose: () => void;
  onSubmit: (
    values: ValuesType,
    formikHelpers: FormikHelpers<ValuesType>
  ) => void;
  isNoteOnly: boolean
}

const AddTimelineEntryCard = (props: Partial<Props>) => {
  const { onSubmit, isNoteOnly } = props;
  const { t } = useTranslation();

  const getTimelineEntryTypes = () => {
    let types = Object.keys(timelineEntryType);

    if (isNoteOnly) {
      types = types.filter((type) => type === 'note');
    }

    return types
  }

  const timelineEntryTypes = getTimelineEntryTypes()

  if (!onSubmit) return null;

  return (
    <>
      <Formik
        {...{
          initialValues,
          validationSchema,
          onSubmit,
        }}
      >
        {({ isSubmitting, submitForm, errors, touched, handleBlur }) => (
          <Form>
            <Field
              component={TextField}
              name="text"
              onBlur={handleBlur}
              autoComplete="off"
              type="text"
              fullWidth
              required
              placeholder="Leave a note..."
              variant="standard"
              sx={{
                "& .MuiInputBase-input": {
                  padding: "12px 14px",
                },

                "& .MuiFormHelperText-root ": {
                  margin: "4px 14px",
                },
              }}
            />

            {isSubmitting && (
              <Box mt={"8px"}>
                <LinearProgress />
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "8px 10px",
                gap: 2,
              }}
            >
              <FormControl
                error={Boolean(errors.type) && Boolean(touched.type)}
                sx={{ flexDirection: "column" }}
              >
                <Field
                  component={RadioGroup}
                  id="customer-timeline-note-radiogroup"
                  size="small"
                  name="type"
                  onBlur={handleBlur}
                  sx={{ flexDirection: "row" }}
                >
                  {timelineEntryTypes.map((type) => {
                    const EntryTypeIcon = timelineEntryType[type];

                    return (
                      <Tooltip
                        key={type}
                        title={t(`Select ${type.toUpperCase()}`)}
                      >
                        <FormControlLabel
                          sx={{ m: 0 }}
                          value={type}
                          control={
                            <CustomRadio sx={{ p: "4px 9px" }}>
                              <EntryTypeIcon />
                            </CustomRadio>
                          }
                          label=""
                        />
                      </Tooltip>
                    );
                  })}
                </Field>
                {Boolean(errors.type) && Boolean(touched.type) && (
                  <FormHelperText>{errors.type}</FormHelperText>
                )}
              </FormControl>

              <Button
                size="small"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={submitForm}
              >
                {t("Submit")}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddTimelineEntryCard;
