// ** React Imports
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

// ** MUI Imports
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";

// ** Types
import { OrderType, OrderLayoutProps } from "../../types/orderTypes";
import { Customer, CustomerData, customerSchema } from "../../types/customerTypes";

// ** Redux
import { AppDispatch } from "../../store";
import { getOrder } from "../../store/apps/order";

// ** Components Imports
import { OrderPreviewCardScreen } from "../../comps/order/preview/previewCard";
import { CustomerViewLeftScreen } from "../customer/viewLeft";
import OrderPreviewTimeline from "../../comps/order/preview/order-preview-timeline";
import { CustomerFormDialog } from "../../comps/customers/customerDialog";

import { clearSpace } from "../../helpers/clearSpace";

import axios from "axios";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAlertContext } from "../../hooks/useAlertContext";

export const OrderPreviewScreen = () => {
  const { id } = useParams<OrderLayoutProps>();
  const dispatch = useDispatch<AppDispatch>();

  // ** State
  const [error, setError] = useState<boolean>(false);
  const [data, setData] = useState<null | OrderType>(null);
  const [customerData, setCustomerData] = useState<Customer>();
  const [editCustomerOpen, setEditCustomerOpen] = useState<boolean>(false);

  const { t } = useTranslation();
  const editCustomerForm = useForm<CustomerData>({
    values: customerData ? { ...customerData, password: "" } : undefined,
    resolver: yupResolver(customerSchema),
    context: {
      editMode: true
    }
  });
  const { showErrorAlert, showSuccessAlert }  = useAlertContext();

  const toggleEditCustomerDialog = () => setEditCustomerOpen((prev) => !prev);

  const handleEditCustomer = (data: CustomerData) => {
    if(!customerData) return;

    const isEmpty = clearSpace(String(data.password)).length === 0

    // prevents empty string from being sent for update
    if (isEmpty || !data.password) {
      delete data.password
    }

    axios
      .patch(
        `${process.env.REACT_APP_SERVER_HOST}/customer/update/${customerData._id}`,
        data,
        { withCredentials: true }
      )
      .then((response) => {
        setCustomerData(response.data.customer);
        showSuccessAlert(t("Customer updated"))
        toggleEditCustomerDialog();
      })
      .catch(() => {
        setCustomerData(undefined);
        showErrorAlert(t("Failed to delete Customer"))
      });
  };

  useEffect(() => {
    (async () => {
      dispatch(getOrder(id as string))
        .then((res) => {
          const { __v, ...temp } = res.payload.order;

          setData(temp);
          setError(false);
        })
        .catch((e) => {
          setData(null);
          setError(true);
        });
    })();


  }, [dispatch, id]);

  useEffect(() => {
    if (data) setCustomerData(data.customer);
  }, [data]);

  if (data) {
    return (
      <Grid
        container
        spacing={6}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Grid item xs={12} md={5} lg={4}>
          <CustomerViewLeftScreen
            customerData={customerData as Customer}
            setCustomerData={setCustomerData}
            toggleEditCustomerDialog={toggleEditCustomerDialog}
          />
        </Grid>

        <Grid item xs={12} md={7} lg={8} container spacing={4}>
          <Grid item xs={12}>
            <OrderPreviewCardScreen data={data} setData={setData} />
          </Grid>

          <Grid item xs={12}>
            <OrderPreviewTimeline {...{ data, setData }} />
          </Grid>

          <CustomerFormDialog
            title={t("Edit customer")}
            form={editCustomerForm}
            open={editCustomerOpen}
            toggle={toggleEditCustomerDialog}
            onSubmit={handleEditCustomer}
          />
        </Grid>
      </Grid>
    );
  }

  if (error) {
    return (
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Alert severity="error">
            Order with the id: {id} does not exist. Please check the list of
            orders: <Link to="/order/list">Order List</Link>
          </Alert>
        </Grid>
      </Grid>
    );
  }

  return null;
};
