import chroma from "chroma-js";
import * as values from "../data/onyxTable.json";

type RGB = [number, number, number];

function calculateRGBDistance(rgb1: RGB, rgb2: RGB): number {
  const distance = Math.sqrt(
    Math.pow(rgb1[0] - rgb2[0], 2) +
      Math.pow(rgb1[1] - rgb2[1], 2) +
      Math.pow(rgb1[2] - rgb2[2], 2)
  );

  return distance;
}

export function findClosestOnyx(rgb: RGB): { hex: string; onyx: string } {
  let closestKey = "";
  let closestDistance = Number.MAX_VALUE;
  // let closestRGB: RGB | undefined;
  let closestHEX: string = "";

  for (const [key, value] of Object.entries(values)) {
    const distance = calculateRGBDistance(rgb, value as RGB);
    const chromaValue = chroma(value[0], value[1], value[2]);

    if (distance === 0) {
      // Exact match found, return the key
      return { hex: chromaValue.hex(), onyx: key };
    }

    if (distance < closestDistance) {
      closestKey = key;
      closestDistance = distance;
      closestHEX = chromaValue.hex();
    }
  }

  return { hex: closestHEX, onyx: closestKey };
}
