import React from "react";
import { StyledTabContentContainer } from "./styled-tab-content-container";
import { StyledTextField } from "./styled-textfield";
import { Typography, Box } from "@mui/material";

interface Props {
  colorCmykField: number[];
  handleCmykColorChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    i: number
  ) => void;
  showLabel?: boolean;
}

const CmykColorTab = (props: Props) => {
  const { colorCmykField, handleCmykColorChange, showLabel = false } = props;

  return (
    <StyledTabContentContainer>
      {showLabel && (
        <Typography variant="caption" sx={{ fontWeight: 700 }}>
          CMYK
        </Typography>
      )}

      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
        {colorCmykField.map((value: number, i: number) => (
          <StyledTextField
            variant="outlined"
            size="small"
            key={i}
            inputProps={{ step: 1, min: 0, max: 100, maxLength: 3 }}
            onChange={(e) => handleCmykColorChange(e, i)}
            type="number"
            value={(value * 100).toFixed(0)}
            sx={{
              "& .MuiOutlinedInput-root": {
                width: { xs: 100, md: 80 },
              },
            }}
          />
        ))}
      </Box>
    </StyledTabContentContainer>
  );
};

export default CmykColorTab;
