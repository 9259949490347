import {
  Grid,
  TextField,
  FormControl,
  FormHelperText, MenuItem,
  Select,
  InputLabel
} from "@mui/material";
import { Control, FieldErrors, Controller } from 'react-hook-form';
import { PorezProductFormInputsT, ProductFormInputs } from '../../../types/productTypes';
import { ICompany } from "../../../hooks/useCompanies";
import { ProductCategoryT } from "../../../types/product-category-type";
import FormFieldError from "./form-field-error";

interface Props {
  control: Control<ProductFormInputs | PorezProductFormInputsT, any>;
  t: any;
  errors: FieldErrors<ProductFormInputs | PorezProductFormInputsT>;
  companies: ICompany[];
  categories: ProductCategoryT[];
  isVariobelOnlyField: boolean;
}

const ProductInfoFields = ({
  control,
  t,
  errors,
  companies,
  categories,
  isVariobelOnlyField
}: Props) => (
  <Grid item container spacing={5}>
    <Grid item xs={12} sm={6}>
      <FormControl fullWidth>
        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <TextField
              value={value}
              label={`${t("Product name")}`}
              onChange={onChange}
              placeholder=""
              error={Boolean(errors.name)}
            />
          )}
        />
        {errors.name && <FormFieldError />}
      </FormControl>
    </Grid>

    <Grid item xs={12} sm={6}>
      <FormControl fullWidth>
        <Controller
          name="company"
          control={control}
          rules={{ required: true }}
          render={({ field: { value, onChange } }) => (
            <FormControl>
              <InputLabel>{`${t("Company")}`}</InputLabel>
              <Select
                label={`${t("Company")}`}
                value={value}
                onChange={onChange}
              >
                <MenuItem value="">None</MenuItem>
                {companies.map((c, index) => (
                  <MenuItem key={index} value={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        {errors.company && <FormFieldError />}
      </FormControl>
    </Grid>

    {isVariobelOnlyField && (
      <Grid item xs={12}>
        <FormControl fullWidth>
          <Controller
            name="description"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextField
                rows={4}
                multiline
                value={value}
                label={`${t("Product description")}`}
                onChange={onChange}
                placeholder=""
                error={Boolean(errors.description)}
              />
            )}
          />
          {errors.description && (
            <FormHelperText sx={{ color: "error.main" }}>
              {t("This field cannot be empty")}
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
    )}

    <Grid item xs={12} sm={6}>
      <FormControl fullWidth>
        <Controller
          name="category"
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormControl>
              <InputLabel shrink={value !== null ? true : false}>{`${t(
                "Category"
              )}`}</InputLabel>
              <Select
                label={`${t("Category")}`}
                value={value}
                onChange={onChange}
              >
                {categories.map((c, index) => (
                  <MenuItem key={index} value={c._id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
        {errors.category && <FormFieldError />}
      </FormControl>
    </Grid>
  </Grid>
);

export default ProductInfoFields;
