// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios, { AxiosResponse } from "axios";

// ** Types
import { Dispatch } from "redux";
import {
  ImapAccountType,
  CurrentMailInputsType,
  RecentMessagesType,
  MailAccountCreationType,
  SmtpAccountType,
  MailAccountsType,
  MailFormDataType,
  ImapConnectionType,
} from "../../../types/mailAccountsType";
import { supportedMailboxes } from "../../../types/emailTypes";

interface DataQueries {
  search: string;
  mailsPerPage: number;
  page: number;
  mailbox: supportedMailboxes
}

interface ReduxType {
  getState: any;
  dispatch: Dispatch<any>;
}

// fetch & connect all imap accounts
export const fetchImapConnections = createAsyncThunk(
  "mail/getImapConnections",
  async () => {
    const _accounts = await axios.get(
      `${process.env.REACT_APP_SERVER_HOST}/imap/get-all`,
      { withCredentials: true }
    );

    let { accounts } = _accounts.data;

    accounts = await Promise.all(
      accounts.map(async (account: ImapAccountType) => {
        const { email: user, password, host, port, tls } = account;

        // fallbacks
        account.connected = "disconnected";
        account.connection = null;

        if (account.autoConnect) {
          const connectConfig = {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_HOST}/imap/connect`,
            data: {
              user,
              host,
              password,
              port,
              tls,
            },
            withCredentials: true,
          };

          await axios(connectConfig)
            .then((res) => {
              const { details } = res.data;

              account.connected =
                details.state === "authenticated"
                  ? "connected"
                  : "disconnected";
              account.connection = res.data;

              return details;
            })
            .catch(() => console.log("error, check console"));

          return account;
        }

        return account;
      })
    );

    return accounts;
  }
)

// fetch & connect all smtp accounts
export const fetchSmtpConnections = createAsyncThunk(
  "mail/getSmtpConnections",
  async () => {
    const _accounts = await axios.get(
      `${process.env.REACT_APP_SERVER_HOST}/smtp/get-all`,
      { withCredentials: true }
    );

    let { accounts } = _accounts.data;

    accounts = await Promise.all(
      accounts.map(async (account: SmtpAccountType) => {
        const { user, pass, host, port, service } = account;

        // fallbacks
        account.connected = "disconnected";

        if (account.autoConnect) {
          const config = {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_HOST}/smtp/connect`,
            data: {
              user,
              pass,
              host,
              port,
              service,
            },
            withCredentials: true,
          };

          await axios(config)
            .then((res) => {
              const { connected } = res.data as {
                connected: boolean;
                message: string;
              };

              if (connected) account.connected = "connected";
            })
            .catch(() => console.log("error, check console"));

          return account;
        }

        return account;
      })
    );

    return accounts;
  }
)

// ** Fetch & connect all imap, smtp accounts
export const fetchAccounts = createAsyncThunk(
  "mail/getAllAccounts",
  async (queries: DataQueries) => {
    const imapResponse = await axios.get(
      `${process.env.REACT_APP_SERVER_HOST}/imap/get-all`,
      { withCredentials: true }
    );

    const smtpResponse = await axios.get(
      `${process.env.REACT_APP_SERVER_HOST}/smtp/get-all`,
      { withCredentials: true }
    );

    let { accounts: imapAccounts } = imapResponse.data;
    let { accounts: smtpAccounts } = smtpResponse.data;

    imapAccounts = await Promise.all(
      imapAccounts.map(async (account: ImapAccountType) => {
        const { email: user, password, host, port, tls } = account;

        // fallbacks
        account.connected = "disconnected";
        account.connection = null;
        account.recentMessages = null;
        account.totalMessages = 0

        if (account.autoConnect) {
          const connectConfig = {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_HOST}/imap/connect`,
            data: {
              user,
              host,
              password,
              port,
              tls,
            },
            withCredentials: true,
          };

          const connection = await axios(connectConfig)
            .then((res) => {
              const { details } = res.data;

              account.connected =
                details.state === "authenticated"
                  ? "connected"
                  : "disconnected";
              account.connection = res.data;

              return details;
            })
            .catch(() => console.log("error, check console"));

          // + 1 to queries.page as mui pagination init has to be 0
          const url = `${process.env.REACT_APP_SERVER_HOST}/imap/get-mails?search=${queries.search}&mailsPerPage=${queries.mailsPerPage}&page=${queries.page + 1}&mailbox=${queries.mailbox}`
          const mailConfig = {
            method: "post",
            url,
            data: connection,
            withCredentials: true,
          };

          //@ts-ignore
          if (account.connected === "connected") {
            await axios(mailConfig)
              .then((res:AxiosResponse<{ messages: RecentMessagesType[], totalMessages: number }>) => {
                const { messages, totalMessages } = res.data;
                account.recentMessages = messages;
                account.totalMessages =  totalMessages;

                return res.data;
              })
              .catch(() => console.log("error, check console"));
          }

          return account;
        }

        return account;
      })
    );

    smtpAccounts = await Promise.all(
      smtpAccounts.map(async (account: SmtpAccountType) => {
        const { user, pass, host, port, service } = account;

        // fallbacks
        account.connected = "disconnected";

        if (account.autoConnect) {
          const config = {
            method: "post",
            url: `${process.env.REACT_APP_SERVER_HOST}/smtp/connect`,
            data: {
              user,
              pass,
              host,
              port,
              service,
            },
            withCredentials: true,
          };

          await axios(config)
            .then((res) => {
              const { connected } = res.data as {
                connected: boolean;
                message: string;
              };

              if (connected) account.connected = "connected";
            })
            .catch(() => console.log("error, check console"));

          return account;
        }

        return account;
      })
    );


    const accounts = imapAccounts.map(
      (acc: ImapAccountType, index: number, arr: any) => {
        const smtp = smtpAccounts.find(
          (acc1: SmtpAccountType) =>
            acc.email === acc1.user && acc.ownedBy && acc1.ownedBy
        );

        if (smtp) {
          return {
            id: index,
            email: acc.email,
            ownedBy: acc.ownedBy,
            imap: acc,
            smtp,
          };
        }

        return {
          id: index,
          email: acc.email,
          ownedBy: acc.ownedBy,
          imap: acc,
          smtp: null,
        };
      }
    );

    return { accounts };
  }
);

// turns on autoconnect for imap account
export const connectImap = createAsyncThunk(
  "mail/connectImap",
  async (params: { id: string }, { getState, dispatch }: ReduxType) => {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_SERVER_HOST}/imap/update/${params.id}`,
      data: {
        autoConnect: true,
      },
      withCredentials: true,
    };

    const response = await axios(config);

    dispatch(fetchAccounts(getState().email.queries));
    return response.data;
  }
);

// disconnect imap account
export const disconnectImap = createAsyncThunk(
  "mail/disconnectImap",
  async (params: { id: string; user: string }, { getState, dispatch }: ReduxType) => {
    const { user } = params;

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_SERVER_HOST}/imap/disconnect/${params.id}`,
      data: {
        user,
      },
      withCredentials: true,
    };

    const response = await axios(config);

    dispatch(fetchAccounts(getState().email.queries));
    return response.data;
  }
);

// connect smtp account
export const connectSmtp = createAsyncThunk(
  "mail/connectSmtp",

  async (data: SmtpAccountType, { getState, dispatch }: ReduxType) => {
    const { user, pass, host, port, service } = data;

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_SERVER_HOST}/smtp/connect`,
      data: {
        user,
        pass,
        host,
        port,
        service,
      },
      withCredentials: true,
    };

    const response = await axios(config);

    dispatch(fetchAccounts(getState().email.queries));
    return response.data;
  }
);

// turn on autoConnect for smtp
export const autoConnectSmtp = createAsyncThunk(
  "mail/autoconnectSmtp",
  async (params: { id: string }, { getState, dispatch }: ReduxType) => {
    const config = {
      method: "patch",
      url: `${process.env.REACT_APP_SERVER_HOST}/smtp/update/${params.id}`,
      data: {
        autoConnect: true,
      },
      withCredentials: true,
    };

    const response = await axios(config);

    dispatch(fetchAccounts(getState().email.queries));
    return response.data;
  }
);

// disconnect smtp account
export const disconnectSmtp = createAsyncThunk(
  "mail/disconnectSmtp",
  async (params: { id: string; user: string }, { getState, dispatch }: ReduxType) => {
    const { user } = params;

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_SERVER_HOST}/smtp/disconnect/${params.id}`,
      data: {
        user,
      },
      withCredentials: true,
    };

    const response = await axios(config);

    dispatch(fetchAccounts(getState().email.queries));
    return response.data;
  }
);

export const sendSmtpMail = createAsyncThunk(
  "mail/sendSmtp",
  async (data: MailFormDataType, { dispatch, getState }: ReduxType) => {

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_SERVER_HOST}/smtp/send`,
      data,
      withCredentials: true,
    };

    const response = await axios(config);
    dispatch(fetchAccounts(getState().email.queries));
    return response;
  }
);

// create and then connect account(imap & smtp)
export const createAccount = createAsyncThunk(
  "mail/createAccount",

  async (params: MailAccountCreationType, { dispatch }: ReduxType) => {
    const { imap, smtp } = params;

    const imapConnectiononConfig = {
      method: "post",
      url: `${process.env.REACT_APP_SERVER_HOST}/imap/create`,
      data: {
        ...imap,
      },
      withCredentials: true,
    };

    const smtpConnectiononConfig = {
      method: "post",
      url: `${process.env.REACT_APP_SERVER_HOST}/smtp/create`,
      data: {
        ...smtp,
      },
      withCredentials: true,
    };

    const imapResponse = await axios(imapConnectiononConfig);
    const smtpResponse = await axios(smtpConnectiononConfig);

    let imapData = imapResponse.data;
    imapData = {
      ...imapData.account,
    };

    let smtpData = smtpResponse.data;
    smtpData = {
      ...smtpData.account,
    };

    await dispatch(connectImap({ id: imapData._id }));
    await dispatch(connectSmtp(smtpData));

    return null;
  }
);

// update account(imap & smtp)
export const updateAccount = createAsyncThunk(
  "mail/updateAccount",

  async (
    params: {
      ids: { imapId: string; smtpId: string };
      data: MailAccountCreationType;
    },
    { getState, dispatch }: ReduxType
  ) => {
    const { imap, smtp } = params.data;
    const { imapId, smtpId } = params.ids;

    const imapResponse = await axios.patch(
      `${process.env.REACT_APP_SERVER_HOST}/imap/update/${imapId}`,
      {
        ...imap,
      },
      { withCredentials: true }
    );

    const smtpCreateConfig = {
      method: "post",
      url: `${process.env.REACT_APP_SERVER_HOST}/smtp/create`,
      data: {
        ...smtp,
      },
      withCredentials: true,
    };

    const smtpUpdateConfig = {
      method: "patch",
      url: `${process.env.REACT_APP_SERVER_HOST}/smtp/update/${smtpId}`,
      data: {
        ...smtp,
      },
      withCredentials: true,
    };

    const smtpConfig = smtpId === '' ? smtpCreateConfig : smtpUpdateConfig

    const smtpResponse = await axios(smtpConfig);

    dispatch(fetchAccounts(getState().email.queries));
    return { imap: imapResponse.data, smtp: smtpResponse.data };
  }
);

// delete account(imap & smtp) from db
export const deleteAccount = createAsyncThunk(
  "mail/deleteAccount",
  async (params: {imapId: string, smtpId: string}, { getState }: ReduxType) => {
    const { imapId, smtpId } = params;

    await axios.delete(
      `${process.env.REACT_APP_SERVER_HOST}/imap/delete/${imapId}`,
      {
        withCredentials: true,
      }
    );

    await axios.delete(
      `${process.env.REACT_APP_SERVER_HOST}/smtp/delete/${smtpId}`,
      {
        withCredentials: true,
      }
    );

    const _accounts = getState().email.accounts as MailAccountsType[]
    const accounts = _accounts.filter((account) => (account.imap._id !== imapId && account.smtp?._id !== smtpId))

    return { accounts };
  }
);

// ** Get Current Mail
export const getCurrentMail = createAsyncThunk(
  "appEmail/selectMail",
  async (inputs: CurrentMailInputsType, { getState }: ReduxType) => {
    const { id, selectedEmail } = inputs;

    const accounts = getState().email.accounts as MailAccountsType[];

    const mails = accounts.filter(
      (account) => account.email === selectedEmail
    )[0].imap.recentMessages;
    const currentMail = mails && mails.filter((mail) => mail.uid === id)[0];

    return currentMail;
  }
);

export const appEmailSlice = createSlice({
  name: "appEmail",
  initialState: {
    imapConnections: [] as ImapConnectionType[],
    smtpConnections: [] as SmtpAccountType[],
    accounts: [] as MailAccountsType[],
    queries: {
      search: '',
      mailsPerPage: 10,
      page: 1,
      mailbox: 'INBOX'
    } as DataQueries,
    currentMail: null as null | RecentMessagesType,
    loading: false,
  },

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchImapConnections.fulfilled, (state, action) => {
      state.imapConnections = action.payload;
    });

    /* -------------------------------------------- */

    builder.addCase(fetchSmtpConnections.fulfilled, (state, action) => {
      state.smtpConnections = action.payload;
    });

    /* -------------------------------------------- */

    builder.addCase(fetchAccounts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAccounts.fulfilled, (state, action) => {
      const { accounts } = action.payload;

      state.accounts = accounts;
      state.loading = false;
    });
    builder.addCase(fetchAccounts.rejected, (state) => {
      state.loading = false;
    });

    /* -------------------------------------------- */

    builder.addCase(deleteAccount.fulfilled, (state, action) => {
      const { accounts } = action.payload;

      state.accounts = accounts;
      state.loading = false;
    });

    /* -------------------------------------------- */

    builder.addCase(createAccount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createAccount.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createAccount.rejected, (state) => {
      state.loading = false;
    });

    /* -------------------------------------------- */

    builder.addCase(getCurrentMail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCurrentMail.fulfilled, (state, action) => {
      state.currentMail = action.payload;
      state.loading = false;
    });
    builder.addCase(getCurrentMail.rejected, (state) => {
      state.loading = false;
    });

    /* -------------------------------------------- */

    builder.addCase(connectImap.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(connectImap.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(connectImap.rejected, (state) => {
      state.loading = false;
    });

    /* -------------------------------------------- */

    builder.addCase(disconnectImap.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(disconnectImap.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(disconnectImap.rejected, (state) => {
      state.loading = false;
    });

    /* -------------------------------------------- */

    builder.addCase(disconnectSmtp.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(disconnectSmtp.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(disconnectSmtp.rejected, (state) => {
      state.loading = false;
    });

    /* -------------------------------------------- */

    builder.addCase(connectSmtp.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(connectSmtp.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(connectSmtp.rejected, (state) => {
      state.loading = false;
    });

    /* -------------------------------------------- */

    builder.addCase(autoConnectSmtp.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(autoConnectSmtp.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(autoConnectSmtp.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default appEmailSlice.reducer;
