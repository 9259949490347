// ** MUI Components
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box, { BoxProps } from "@mui/material/Box";
import BlankLayout from "./@core/layouts/BlankLayout";

// ** Styled Components
const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "90vw",
  },
}));

const NotAuthorized = () => {
  return (
    <BlankLayout>
      <Box className="content-center">
        <Box
          sx={{
            p: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <BoxWrapper>
            <Typography variant="h1" sx={{ mb: 2.5 }}>
              401
            </Typography>
            <Typography
              variant="h5"
              sx={{ mb: 2.5, fontSize: "1.5rem !important" }}
            >
              You are not authorized! 🔐
            </Typography>
            <Typography variant="body2">
              You don&prime;t have permission to access this page. Go Home!
            </Typography>
          </BoxWrapper>

          <img alt="error-illustration" src="/images/pages/401.png" />

          <Button component="a" href="/" variant="contained" sx={{ px: 5.5 }}>
            Back to Home
          </Button>
        </Box>
      </Box>
    </BlankLayout>
  );
};

export default NotAuthorized;
