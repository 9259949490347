// ** MUI Imports
import { Grid } from "@mui/material";

// ** Custom Components Imports
import AdminForm from "../../../comps/admins/adminForm";

export const AdminEditScreen = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <AdminForm type="edit" />
      </Grid>
    </Grid>
  );
};
