// Copied from server/src/utils/statusMailHelpers/insertedFields.ts

const insertedFields = [
  {
    text: "Insert First Name",
    value: "{firstName}",
    field:  'template'
  },

  {
    text: "Insert Last Name",
    value: "{lastName}",
    field:  'template'
  },

  {
    text: "Insert Order Details",
    value: "{orderDetails}",
    field:  'template'
  },
  {
    text: "Insert Order Status",
    value: "{orderStatus}",
    field:  'template'
  },
  {
    text: "Insert Order Number",
    value: "{orderNumber}",
    field:  'subject'
  },
];

export default insertedFields;
