import { ITabs } from "./types";

export const getTabs = (tabList: ITabs[], type: "manual" | "custom") => {
  const tabs = tabList.filter((tab) => {
    if (type === "custom" && !tab.shared) {
      return false;
    }

    return true;
  });

  return tabs;
};

export const tabList: ITabs[] = [
  {
    label: "Obrázky",
    value: "images",
    shared: false,
  },
  {
    label: "Upraviť",
    value: "transform",
    shared: true,
  },
];