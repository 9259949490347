// ** React Imports
import {
  Dispatch,
  memo,
  MouseEvent,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { Link } from "react-router-dom";

// ** MUI Imports
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DataGrid, GridActionsCellItem, GridColumns } from "@mui/x-data-grid";

// ** Third Party Imports
import axios from "axios";
import format from "date-fns/format";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

// ** Styled Components
import DatePickerWrapper from "../../../comps/@core/styles/libs/react-datepicker";

// ** Custom Component Imports
import { Chip } from "../../../comps/chip";
import DeleteOrderDialog from "../../../comps/order/deleteOrderDialog";

// ** Custom Hook Imports
import useOrderStatuses from "../../../hooks/useOrderStatuses";

// ** Type Imports
import { Customer } from "../../../types/customerTypes";
import { OrderType } from "../../../types/orderTypes";
import { DateType } from "../../../types/reactDatepickerTypes";
import { ThemeColor } from "../../../comps/@core/layouts/types";
import { useAlertContext } from "../../../hooks/useAlertContext";
import DatePickerInput from "../../../comps/utilities/date-picker-input";
import { currencyFormatter } from "../../../helpers/currency-formatter";

interface CustomerViewOrdersScreenProps {
  customerData: Customer;
  orderData: OrderType[];
  setOrderData: Dispatch<SetStateAction<OrderType[]>>;
  isOrderLoading: boolean;
  setIsOrderLoading: Dispatch<SetStateAction<boolean>>;
  statusFilter: string;
  setStatusFilter: Dispatch<SetStateAction<string>>;
  startDateFilter: DateType;
  setStartDateFilter: Dispatch<SetStateAction<DateType>>;
  endDateFilter: DateType;
  setEndDateFilter: Dispatch<SetStateAction<DateType>>;
  datesFilter: Date[];
  setDatesFilter: Dispatch<SetStateAction<Date[]>>;
  tablePageSize: number;
  setTablePageSize: Dispatch<SetStateAction<number>>;
}

interface CellType {
  row: OrderType;
}

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.primary.main,
}));

export const CustomerViewOrdersScreen = memo(
  ({
    customerData,
    orderData,
    setOrderData,
    isOrderLoading,
    setIsOrderLoading,
    statusFilter,
    setStatusFilter,
    startDateFilter,
    setStartDateFilter,
    endDateFilter,
    setEndDateFilter,
    datesFilter,
    setDatesFilter,
    tablePageSize,
    setTablePageSize,
  }: CustomerViewOrdersScreenProps) => {
    // ** State
    const [deleteOrderOpen, setDeleteOrderOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<OrderType>();
    const [exportMenuAnchorEl, setExportMenuAnchorEl] =
      useState<null | HTMLElement>(null);

    // ** Hooks
    const { t } = useTranslation();
    const orderStatusesObj = useOrderStatuses();
    const { showErrorAlert, showSuccessAlert } = useAlertContext();

    const handleStatusFilter = (e: SelectChangeEvent) => {
      setStatusFilter(e.target.value);

      setIsOrderLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_SERVER_HOST}/order/get-all/customer/${customerData._id}`,
          {
            params: {
              ...(startDateFilter && { after: startDateFilter.toISOString() }),
              ...(endDateFilter && { before: endDateFilter.toISOString() }),
              ...(e.target.value && { status: e.target.value }),
            },
            withCredentials: true,
          }
        )
        .then((response) => {
          setOrderData(response.data.orders);
        })
        .catch(() => {
          setOrderData([]);
        })
        .finally(() => {
          setIsOrderLoading(false);
        });
    };

    const handleOnChangeRange = (dates: any) => {
      const [start, end] = dates;
      if (start !== null && end !== null) {
        setDatesFilter(dates);
      }
      setStartDateFilter(start);
      setEndDateFilter(end);

      setIsOrderLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_SERVER_HOST}/order/get-all/customer/${customerData._id}`,
          {
            params: {
              ...(start && { after: start.toISOString() }),
              ...(end && { before: end.toISOString() }),
              ...(statusFilter && { status: statusFilter }),
            },
            withCredentials: true,
          }
        )
        .then((response) => {
          setOrderData(response.data.orders);
        })
        .catch(() => {
          setOrderData([]);
        })
        .finally(() => {
          setIsOrderLoading(false);
        });
    };

    const handleExportMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
      setExportMenuAnchorEl(event.currentTarget);
    };

    const handleExportMenuClose = () => {
      setExportMenuAnchorEl(null);
    };

    const toggleDeleteOrderDialog = useCallback(() => {
      setDeleteOrderOpen(!deleteOrderOpen);
    }, [deleteOrderOpen]);

    const handleDeleteOrder = async () => {
      if (selectedOrder) {
        await axios
          .delete(
            `${process.env.REACT_APP_SERVER_HOST}/order/delete/${selectedOrder._id}`,
            {
              withCredentials: true,
            }
          )
          .then(() => {
            showSuccessAlert(t("Order deleted"));
          })
          .catch(() => {
            showErrorAlert(t("Failed to delete Order"));
          });

        setIsOrderLoading(true);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_SERVER_HOST}/order/get-all/customer/${customerData._id}`,
            { withCredentials: true }
          );
          setOrderData(response.data.orders);
          toggleDeleteOrderDialog();
        } catch (err) {
          console.log(err);
          setOrderData([]);
        } finally {
          setIsOrderLoading(false);
        }
      }
    };

    const columns = useMemo<GridColumns<OrderType>>(
      () => [
        {
          flex: 0.15,
          minWidth: 150,
          field: "orderNumber",
          headerName: t("Objednávka"),
          renderCell: ({ row }: CellType) => (
            <StyledLink to={`/order/preview/${row._id}`}>
              {`#${row.orderNumber}`}
            </StyledLink>
          ),
          valueGetter: ({ row }: CellType) => `#${row.orderNumber}`,
        },
        {
          flex: 0.15,
          minWidth: 150,
          field: "lastModified",
          headerName: t("Dátum"),
          renderCell: ({ row }: CellType) => (
            <Typography variant="body2">
              {format(new Date(row.lastModified), "MMM d, yyyy")}
            </Typography>
          ),
          valueGetter: ({ row }: CellType) =>
            format(new Date(row.lastModified), "MMM d, yyyy"),
        },
        {
          flex: 0.15,
          minWidth: 150,
          field: "status",
          headerName: t("Status"),
          renderCell: ({ row }: CellType) => {
            return (
              <Chip
                skin="light"
                size="small"
                label={
                  row.orderStatus ? t(row.orderStatus.name) : t("Nedefinované")
                }
                color={
                  (row.orderStatus && row.orderStatus.colorCode
                    ? row.orderStatus.colorCode
                    : "error") as ThemeColor
                }
                sx={{
                  textTransform: "capitalize",
                  "& .MuiChip-label": { lineHeight: "18px" },
                }}
              />
            );
          },
          valueGetter: ({ row }: CellType) =>
            row.orderStatus ? t(row.orderStatus.name) : t("Nedefinované"),
        },
        {
          flex: 0.15,
          minWidth: 150,
          field: "total",
          headerName: t("Celková cena"),
          renderCell: ({ row }: CellType) => (
            //0 is shipping fee
            <Typography variant="body2">
              {currencyFormatter(row.basketPrice.price + 0)}
            </Typography>
          ),
          valueGetter: ({ row }: CellType) => {
            currencyFormatter(row.basketPrice.price + 0);
          },
        },
        {
          flex: 0.1,
          minWidth: 100,
          sortable: false,
          field: "actions",
          headerName: t("Actions"),
          type: "actions",
          getActions: ({ row }: CellType) => [
            <GridActionsCellItem
              showInMenu
              sx={{ "& .MuiListItemIcon-root": { minWidth: 0 } }}
              icon={<Icon fontSize="small">visibility</Icon>}
              label={t("View")}
              // @ts-expect-error
              component={Link}
              to={`/order/preview/${row._id}`}
            />,
            <GridActionsCellItem
              showInMenu
              sx={{ "& .MuiListItemIcon-root": { minWidth: 0 } }}
              icon={<Icon fontSize="small">delete</Icon>}
              label={t("Delete")}
              onClick={() => {
                setSelectedOrder(row);
                toggleDeleteOrderDialog();
              }}
            />,
          ],
        },
      ],
      [t, toggleDeleteOrderDialog]
    );

    return (
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <CardHeader title={t("Filtre")} />
            <CardContent>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="order-status-select">
                      {t("Order state")}
                    </InputLabel>

                    <Select
                      fullWidth
                      value={statusFilter}
                      sx={{ mr: 4, mb: 2 }}
                      label={t("Order state")}
                      onChange={handleStatusFilter}
                      labelId="order-status-select"
                    >
                      <MenuItem value="">{t("Žiadne")}</MenuItem>
                      {Object.keys(orderStatusesObj).map((orderStatus, i) => (
                        <MenuItem
                          key={i}
                          value={orderStatus}
                          sx={{ textTransform: "capitalize" }}
                        >
                          {t(orderStatus)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <DatePickerWrapper>
                    <DatePicker
                      isClearable
                      selectsRange
                      monthsShown={2}
                      endDate={endDateFilter}
                      selected={startDateFilter}
                      startDate={startDateFilter}
                      shouldCloseOnSelect={false}
                      // id="date-range-picker-months"
                      onChange={handleOnChangeRange}
                      customInput={
                        <DatePickerInput
                          dates={datesFilter}
                          setDates={setDatesFilter}
                          label={t("Dátum objednávky (rozsah)")}
                          end={endDateFilter as number | Date}
                          start={startDateFilter as number | Date}
                        />
                      }
                    />
                  </DatePickerWrapper>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title={t("Zoznam objednávok")}
              sx={{ "& .MuiCardHeader-action": { m: 0 } }}
              titleTypographyProps={{
                variant: "h6",
                sx: {
                  lineHeight: "32px !important",
                  letterSpacing: "0.15px !important",
                },
              }}
              action={
                <>
                  <Button
                    variant="contained"
                    aria-haspopup="true"
                    onClick={handleExportMenuClick}
                    endIcon={<Icon>expand_more</Icon>}
                    aria-expanded={
                      Boolean(exportMenuAnchorEl) ? "true" : undefined
                    }
                    aria-controls={
                      Boolean(exportMenuAnchorEl)
                        ? "customer-view-overview-export"
                        : undefined
                    }
                    disabled
                  >
                    {t("Export")}
                  </Button>
                  <Menu
                    open={Boolean(exportMenuAnchorEl)}
                    anchorEl={exportMenuAnchorEl}
                    onClose={handleExportMenuClose}
                    id="customer-view-overview-export"
                  >
                    <MenuItem onClick={handleExportMenuClose}>PDF</MenuItem>
                    <MenuItem onClick={handleExportMenuClose}>XLSX</MenuItem>
                    <MenuItem onClick={handleExportMenuClose}>CSV</MenuItem>
                  </Menu>
                </>
              }
            />
            <DataGrid
              autoHeight
              disableSelectionOnClick
              // checkboxSelection
              columns={columns}
              rows={orderData}
              getRowId={(row) => row._id}
              loading={isOrderLoading}
              rowsPerPageOptions={[7, 10, 25, 50]}
              pageSize={tablePageSize}
              onPageSizeChange={(newPageSize) => setTablePageSize(newPageSize)}
              sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
            />
            {selectedOrder && (
              <DeleteOrderDialog
                open={deleteOrderOpen}
                handleClose={toggleDeleteOrderDialog}
                handleDelete={handleDeleteOrder}
                orders={[`#${selectedOrder._id}`]}
              />
            )}
          </Card>
        </Grid>
      </Grid>
    );
  }
);
