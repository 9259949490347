import { Grid } from "@mui/material";

import CompanyForm from "./components/companyForm";

const AddCompanyScreen = () => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <CompanyForm type="create" />
      </Grid>
    </Grid>
  );
};

export default AddCompanyScreen;
