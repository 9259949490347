// ** React Imports
import { Dispatch, SetStateAction, SyntheticEvent, useState } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { styled } from "@mui/material/styles";
import MuiTab, { TabProps } from "@mui/material/Tab";

// ** Third Party Imports
import { useTranslation } from "react-i18next";

// ** Demo Components Imports
import { CustomerViewOrdersScreen } from "./viewTabs/viewOrders";
import { CustomerViewAddressScreen } from "./viewTabs/viewAddress";
import { CustomerViewCompaniesScreen } from "./viewTabs/viewCompanies";
import CustomerViewTimeline from "./viewTabs/viewTimeline";

// ** Types
import { Address, Customer } from "../../types/customerTypes";
import { OrderType } from "../../types/orderTypes";
import { DateType } from "../../types/reactDatepickerTypes";

interface Props {
  customerData: Customer;
  setCustomerData: Dispatch<SetStateAction<Customer | undefined>>;
  orderData: OrderType[];
  setOrderData: Dispatch<SetStateAction<OrderType[]>>;
  isOrderLoading: boolean;
  setIsOrderLoading: Dispatch<SetStateAction<boolean>>;
  toggleEditCustomerDialog: () => void;
}

// ** Styled Tab component
const Tab = styled(MuiTab)<TabProps>(({ theme }) => ({
  minHeight: 48,
  flexDirection: "row",
  "& svg": {
    marginBottom: "0 !important",
    marginRight: theme.spacing(1),
  },
}));

const tabs = [
  {
    value: "orders",
    label: "Orders",
    icon: "description",
  },
  {
    value: "timeline",
    label: "Timeline",
    icon: "description",
  },
  {
    value: "billing",
    label: "Billing",
    icon: "bookmark",
  },
  {
    value: "companies",
    label: "Spoločnosti",
    icon: "apartment",
  },
];

export const CustomerViewRightScreen = ({
  customerData,
  setCustomerData,
  orderData,
  setOrderData,
  isOrderLoading,
  setIsOrderLoading,
  toggleEditCustomerDialog,
}: Props) => {
  // ** States
  const [tabValue, setTabValue] = useState<string>("orders");
  const [orderDatesFilter, setOrderDatesFilter] = useState<Date[]>([]);
  const [orderStatusFilter, setOrderStatusFilter] = useState<string>("");
  const [orderTablePageSize, setOrderTablePageSize] = useState<number>(7);
  const [orderEndDateFilter, setOrderEndDateFilter] = useState<DateType>(null);
  const [orderStartDateFilter, setOrderStartDateFilter] =
    useState<DateType>(null);
  const [addressData, setAddressData] = useState<Address[]>(
    customerData.billingAddress
  );

  // ** Hooks
  const { t } = useTranslation();

  const handleChange = (event: SyntheticEvent, newTabValue: string) => {
    setTabValue(newTabValue);
  };

  return (
    <TabContext value={tabValue}>
      <TabList
        variant="scrollable"
        scrollButtons="auto"
        onChange={handleChange}
        aria-label="forced scroll tabs"
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        {tabs.map((tab, i) => (
          <Tab
            key={i}
            value={tab.value}
            label={t(tab.label)}
            icon={<Icon sx={{ fontSize: "18px" }}>{tab.icon}</Icon>}
            sx={{
              "& .MuiTab-iconWrapper": {
                mb: 0,
                mr: "0.25rem",
              },
            }}
          />
        ))}
      </TabList>

      <Box sx={{ mt: 3 }}>
        <TabPanel sx={{ p: 0 }} value="orders">
          <CustomerViewOrdersScreen
            customerData={customerData}
            orderData={orderData}
            setOrderData={setOrderData}
            isOrderLoading={isOrderLoading}
            setIsOrderLoading={setIsOrderLoading}
            statusFilter={orderStatusFilter}
            setStatusFilter={setOrderStatusFilter}
            startDateFilter={orderStartDateFilter}
            setStartDateFilter={setOrderStartDateFilter}
            endDateFilter={orderEndDateFilter}
            setEndDateFilter={setOrderEndDateFilter}
            datesFilter={orderDatesFilter}
            setDatesFilter={setOrderDatesFilter}
            tablePageSize={orderTablePageSize}
            setTablePageSize={setOrderTablePageSize}
          />
        </TabPanel>

        <TabPanel sx={{ p: 0 }} value="timeline">
          <CustomerViewTimeline
            {...{ orderData, customerData, setCustomerData }}
          />
        </TabPanel>

        <TabPanel sx={{ p: 0 }} value="billing">
          <CustomerViewAddressScreen
            customerID={customerData._id}
            addressData={addressData}
            setAddressData={setAddressData}
            toggleEditCustomerDialog={toggleEditCustomerDialog}
          />
        </TabPanel>

        <TabPanel sx={{ p: 0 }} value="companies">
          {customerData.company && (
            <CustomerViewCompaniesScreen companyData={customerData.company} />
          )}

          {!customerData.company && (
            <Typography sx={{ textAlign: "center" }}>
              No Company Data
            </Typography>
          )}
        </TabPanel>
      </Box>
    </TabContext>
  );
};
