// ** React Imports
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Printer from "mdi-material-ui/Printer";
import IconButton from "@mui/material/IconButton";

// ** MUI Imports
import {
  Card,
  Button,
  Box,
  CardContent,
  Backdrop,
  CircularProgress,
} from "@mui/material";

// ** Third Party Imports
import JsPdf from "jspdf";
import html2canvas from "html2canvas";
import axios from "axios";

// ** Types
import { OrderType } from "../../../../../types/orderTypes";

import useCompanies from "../../../../../hooks/useCompanies";

interface Props {
  data: OrderType;
}

export const OrderInvoiceCard = ({ data }: Props) => {
  // ** Hook
  const companies = useCompanies();
  const company = companies.filter((c) => c._id === data.company)[0];

  const [isLoading, setIsLoading] = useState(true);
  const [invoiceData, setInvoiceData] = useState("");

  // ** Ref
  const PreviewRef = useRef<HTMLDivElement>(null);
  const handleDownloadPdf = () => {
    if (PreviewRef.current) {
      const ref = PreviewRef.current as any;

      html2canvas(ref, {
        logging: false,
        useCORS: true,
        scale: 0.8,
      })
        .then((canvas) => {
          const imgData = canvas.toDataURL();
          const pdf = new JsPdf();
          pdf.addImage(imgData, "JPEG", 0, 0, 0, 0);
          pdf.save(`order-${data._id}.pdf`);
        })
        .catch((err) => console.log(err));
    }
  };

  // handle invoice generation
  useEffect(() => {
    if (data && company) {
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_SERVER_HOST}/order/get-invoice`,
        data: {
          data,
          company,
        },
        withCredentials: true,
      };

      (async () => {
        await axios(config)
          .then((res) => setInvoiceData(res.data))
          .catch((err) => console.log("error generating invoice", err));
      })();
    }
  }, [company, data]);

  // handle loading
  useEffect(() => {
    if (invoiceData.replace(/\s/g, "").length) {
      setIsLoading(false);
    }
  }, [invoiceData]);

  if (data) {
    return (
      <Card>
        {isLoading && (
          <Box
            sx={{
              minHeight: "200px",
            }}
          >
            <Backdrop open={isLoading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        )}

        {/* <ArchivedOrderInvoice {...{data}} /> */}
        {!isLoading && (
          <div
            ref={PreviewRef}
            dangerouslySetInnerHTML={{ __html: invoiceData }}
          />
        )}

        {/* Bottom Actions */}
        <CardContent>
          <Box
            display={"flex"}
            justifyContent={{ xs: "flex-start", md: "flex-end" }}
            alignItems={"center"}
            ml={{ xs: 1, md: 0 }}
            width={{ xs: 200, md: "100%" }}
          >
            <Button
              variant="outlined"
              sx={{
                mr: 4,
                fontSize: { xs: 12, md: 14 },
              }}
              onClick={handleDownloadPdf}
            >
              Download
            </Button>
            <Button
              variant="outlined"
              target="_blank"
              component={Link}
              to={`/order/print/${data._id}`}
              sx={{
                fontSize: { xs: 12, md: 14 },
              }}
            >
              <IconButton
                sx={{ marginRight: 2, width: 20, height: 20, color: "#666CFF" }}
              >
                <Printer />
              </IconButton>
              Print
            </Button>
          </Box>
        </CardContent>
      </Card>
    );
  }

  return null;
};
