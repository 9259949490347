import { Dialog, DialogContent } from "@mui/material";
import React, { ReactNode } from "react";

interface Props {
  open: boolean;
  handleClose: () => void;
  children: ReactNode;
}

const DialogContainer = (props: Props) => {
  const  { open, handleClose, children } = props;

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      scroll="body"
      open={open}
      onClose={handleClose}
    >
      <DialogContent sx={{ p: 0 }}>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default DialogContainer;
