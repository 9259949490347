import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { fetchCompanies } from "../store/apps/companies";
import { CompanyType } from "../types/companyTypes";

export interface ICompany extends CompanyType {
  id: string;
}

const useCompanies = () => {
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const dispatch = useDispatch<AppDispatch>();
  const store = useSelector((state: RootState) => state.companies);

  useEffect(() => {
    dispatch(fetchCompanies());
  }, [dispatch]);

  useEffect(() => {
    if (store.companies) {
      const _companies = store.companies.map((c: CompanyType) => ({
        id: c._id,
        ...c
      }))

      setCompanies(_companies)
    }

  }, [store.companies])

  return companies;
};

export default useCompanies;
