import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  BoxProps,
  CardContent,
  Divider,
  Grid,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TypographyProps,
  styled,
} from "@mui/material";

import { useTranslation } from "react-i18next";

import {
  OrderLineItemT,
  AddOrderLineItemT,
  BasketPriceTypeTemp,
} from "../../../types/orderTypes";

import { LineItemRow } from "./lineItemRow";
import { useEffect, useState } from "react";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import { ProductType } from "../../../types/productTypes";
import { calculateDiscount } from "../../../helpers/calculateDiscount";
import { currencyFormatter } from "../../../helpers/currency-formatter";
import { useSettings } from "../../../hooks/useSettings";

interface Props {
  lineItems: (OrderLineItemT | AddOrderLineItemT)[];
  basketPrice: BasketPriceTypeTemp;
}

const CalcWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "&:not(:last-of-type)": {
    marginBottom: theme.spacing(2),
  },
}));

const StyledTypography = styled(Typography)<TypographyProps>(() => ({
  color: "text.primary",
  letterSpacing: ".25px",
  fontWeight: 600,
}));

const boxStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
};

const OrderDetailsSummary = (props: Props) => {
  const { lineItems, basketPrice } = props;

  const { t } = useTranslation();
  const productStore = useSelector((state: RootState) => state.product);

  const [totalOnSaleDiscount, setTotalOnSaleDiscount] = useState(0);
  const [customerDiscount, setCustomerDiscount] = useState(0);
  const [orderDiscount, setOrderDiscount] = useState(0);

  const { settings } = useSettings();
  const { configuredCompany } = settings;

  const isVariobelOnlyField = configuredCompany === "variobel"

  // Calculate total on-sale discount
  useEffect(() => {
    if (!basketPrice.appliedDiscounts) return;

    const onSaleDiscounts = basketPrice.appliedDiscounts.onSale;

    if (!onSaleDiscounts.length) {
      setTotalOnSaleDiscount(0);
      return;
    }

    const totalDiscount = onSaleDiscounts.reduce((total, item) => {
      return total + Number(item.discount);
    }, 0);

    setTotalOnSaleDiscount(Number(totalDiscount));
  }, [basketPrice]);

  // Calculate customer discount
  useEffect(() => {
    if (!basketPrice.appliedDiscounts) return;

    const _customerDiscount = calculateDiscount(
      basketPrice.subtotal,
      basketPrice.appliedDiscounts.customer
    );

    setCustomerDiscount(_customerDiscount);
  }, [basketPrice.appliedDiscounts, basketPrice.subtotal]);

  // Calculate order discount
  useEffect(() => {
    if (!basketPrice.appliedDiscounts) return;

    const _orderDiscount = calculateDiscount(
      basketPrice.subtotal,
      basketPrice.appliedDiscounts.order
    );

    setOrderDiscount(_orderDiscount);
  }, [basketPrice.appliedDiscounts, basketPrice.subtotal]);

  return (
    <>
      {lineItems.length && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell id="col-product">{t("Product")}</TableCell>
                <TableCell id="col-qty">{t("QTY")}</TableCell>
                {!isVariobelOnlyField && (
                  <>
                    <TableCell id="col-qty">{t("Width")}</TableCell>
                    <TableCell id="col-qty">{t("Height")}</TableCell>
                  </>
                )}
                {isVariobelOnlyField && <TableCell>{t("Area")}</TableCell>}
                <TableCell>{t("Total price")}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {lineItems.map((lineItem) => (
                <LineItemRow
                  key={(lineItem as OrderLineItemT)._id}
                  {...{
                    isVariobelOnlyField,
                    lineItem: lineItem as OrderLineItemT,
                  }}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <CardContent sx={{ pt: 8 }}>
        <Grid container>
          <Grid item xs={12} sm={6} lg={8} />

          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            sx={{ mb: { sm: 0, xs: 4 }, order: { sm: 2, xs: 1 } }}
          >
            <CalcWrapper>
              <Typography variant="body2">{t("Subtotal")}:</Typography>
              <StyledTypography variant="body2">
                {currencyFormatter(basketPrice.subtotal)}
              </StyledTypography>
            </CalcWrapper>

            <CalcWrapper>
              <Typography variant="body2">
                {t("With-Tax")} ({`${basketPrice.taxRate}%`}):
              </Typography>
              <StyledTypography variant="body2">
                {currencyFormatter(basketPrice.subtotal + basketPrice.tax)}
              </StyledTypography>
            </CalcWrapper>

            {basketPrice.appliedDiscounts &&
              (basketPrice.appliedDiscounts.onSale.length > 0 ||
                basketPrice.appliedDiscounts.customer.value > 0 ||
                basketPrice.appliedDiscounts.order.value > 0) && (
                <CalcWrapper sx={{ mb: "0px !important" }}>
                  <Accordion
                    disableGutters
                    sx={{
                      boxShadow: "none !important",
                      width: "100%",
                      background: "transparent",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        <Icon color="secondary">expand_circle_down</Icon>
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      sx={{
                        p: 0,
                        minHeight: 0,
                        mb: 1,

                        "& .MuiAccordionSummary-content": {
                          ...boxStyle,
                          mr: 1.5,
                          my: 1.5,
                        },
                      }}
                    >
                      <StyledTypography variant="body2">
                        {t("Discount")}
                      </StyledTypography>

                      <StyledTypography variant="body2" color="error">
                        -{" "}
                        {currencyFormatter(
                          Number(
                            totalOnSaleDiscount +
                              customerDiscount +
                              orderDiscount
                          )
                        )}
                      </StyledTypography>
                    </AccordionSummary>

                    <AccordionDetails sx={{ p: 0 }}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1.75,
                        }}
                      >
                        {basketPrice.appliedDiscounts.onSale.length > 0 && (
                          <>
                            {basketPrice.appliedDiscounts.onSale.map(
                              (item, ind) => {
                                const product = (
                                  productStore.data as ProductType[]
                                ).find(
                                  (_product) => _product._id === item.productID
                                );

                                // TODO: figure out why the find is failing
                                // if (!product) return null;

                                return (
                                  <Box
                                    key={ind}
                                    sx={{
                                      ...boxStyle,
                                      // py: 1,
                                    }}
                                  >
                                    <StyledTypography variant="body2">
                                      {product
                                        ? product.name
                                        : t("Product discount")}{" "}
                                      <b>x {item.qty}</b>
                                    </StyledTypography>

                                    <StyledTypography
                                      variant="body2"
                                      color="error"
                                    >
                                      -{" "}
                                      {currencyFormatter(Number(item.discount))}
                                    </StyledTypography>
                                  </Box>
                                );
                              }
                            )}
                          </>
                        )}

                        {basketPrice.appliedDiscounts.customer.value > 0 && (
                          <Box
                            sx={{
                              ...boxStyle,
                            }}
                          >
                            <StyledTypography variant="body2">
                              {t("Customer discount")}{" "}
                              <b>
                                (
                                {`${basketPrice.appliedDiscounts.customer.value} ${basketPrice.appliedDiscounts.customer.prefix}`}
                                )
                              </b>
                            </StyledTypography>

                            <StyledTypography variant="body2" color="error">
                              - {currencyFormatter(Number(customerDiscount))}
                            </StyledTypography>
                          </Box>
                        )}

                        {basketPrice.appliedDiscounts.order.value > 0 && (
                          <Box
                            sx={{
                              ...boxStyle,
                            }}
                          >
                            <StyledTypography variant="body2">
                              {t("Order discount")}{" "}
                              <b>
                                (
                                {`${basketPrice.appliedDiscounts.order.value} ${basketPrice.appliedDiscounts.order.prefix}`}
                                )
                              </b>
                            </StyledTypography>

                            <StyledTypography variant="body2" color="error">
                              - {currencyFormatter(Number(orderDiscount))}
                            </StyledTypography>
                          </Box>
                        )}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </CalcWrapper>
              )}

            <Divider sx={{ my: 3 }} />

            <CalcWrapper>
              <Typography variant="body2">{t("Total")}:</Typography>
              <StyledTypography variant="body2">
                {currencyFormatter(basketPrice.price)}
              </StyledTypography>
            </CalcWrapper>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};

export default OrderDetailsSummary;
