import { AlertDispatchType, AlertStateType } from "./types";

export const alertReducer = (
  state: AlertStateType,
  action: AlertDispatchType
): AlertStateType => {
  switch (action.type) {
    case "CLOSE_ALERT": {
      return { ...state, open: false };
    }

    case "SHOW_ERROR": {
      return { ...state, ...action.payload, severity: "error", open: true };
    }

    case "SHOW_WARNING": {
      return { ...state, ...action.payload, severity: "warning", open: true };
    }

    case "SHOW_INFO": {
      return { ...state, ...action.payload, severity: "info", open: true };
    }

    case "SHOW_SUCCESS": {
      return { ...state, ...action.payload, severity: "success", open: true };
    }

    default:
      return state;
  }
};
