import {
  Dispatch,
  SetStateAction,
  useEffect
} from "react";
import { useDispatch, useSelector } from "react-redux";
import DropdownOrderStatusChip from "../../status/dropdownOrderStatusChip";
import { OrderType } from "../../../../types/orderTypes";
import { AppDispatch, RootState } from "../../../../store";
import { getOrder } from "../../../../store/apps/order";
import { fetchData as fetchOrderStatus } from "../../../../store/apps/orderStatus";

interface OrderStatusChipProps {
  data: OrderType;
  orderID: string;
  setData: Dispatch<SetStateAction<OrderType | null>>;
}
export const OrderStatusChip = ({
  data, orderID, setData,
}: OrderStatusChipProps) => {
  const orderStatusesStore = useSelector(
    (state: RootState) => state.orderStatus.data
  );
  const dispatch = useDispatch<AppDispatch>();

  // fetch order statuses
  useEffect(() => {
    dispatch(fetchOrderStatus());
  }, [dispatch]);

  const handleCallbackOnStatusChange = () => {
    dispatch(getOrder(orderID))
      .unwrap()
      .then((res) => {
        const { __v, ...temp } = res.order;

        setData(temp);
      });
  };

  return (
    <DropdownOrderStatusChip
      row={data}
      orderStatuses={orderStatusesStore}
      onStatusChangeCallback={handleCallbackOnStatusChange} />
  );
};
