// ** React Imports
import { useState } from "react";

// ** MUI Components
import {
  Link,
  Button,
  Checkbox,
  TextField,
  InputLabel,
  IconButton,
  Box,
  BoxProps,
  FormControl,
  useMediaQuery,
  OutlinedInput,
  FormHelperText,
  InputAdornment,
  Typography,
  TypographyProps,
  FormControlLabel,
  FormControlLabelProps,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

// ** Icons Imports
import {
  EyeOutline,
  EyeOffOutline,
} from "mdi-material-ui";

// ** Third Party Imports
import yup from "../helpers/customYup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// ** Hooks
import { useAuth } from "../hooks/useAuth";
// import useBgColor from "../hooks/useBgColor";
import { useSettings } from "../hooks/useSettings";

// ** Configs
import themeConfig from "../configs/themeConfig";
import BlankLayout from "../comps/@core/layouts/BlankLayout";
import capitalize from "../helpers/capitalize";

// ** Demo Imports
// import FooterIllustrationsV2 from 'src/views/pages/auth/FooterIllustrationsV2'

// ** Styled Components
const LoginIllustrationWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  padding: theme.spacing(20),
  paddingRight: "0 !important",
  [theme.breakpoints.down("lg")]: {
    padding: theme.spacing(10),
  },
}));

const LoginIllustration = styled("img")(({ theme }) => ({
  maxWidth: "48rem",
  [theme.breakpoints.down("xl")]: {
    maxWidth: "38rem",
  },
  [theme.breakpoints.down("lg")]: {
    maxWidth: "30rem",
  },
}));

const RightWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.up("md")]: {
    maxWidth: 400,
  },
  [theme.breakpoints.up("lg")]: {
    maxWidth: 450,
  },
}));

const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.down("md")]: {
    maxWidth: 400,
  },
}));

const TypographyStyled = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 600,
  letterSpacing: "0.18px",
  marginBottom: theme.spacing(1.5),
  [theme.breakpoints.down("md")]: { marginTop: theme.spacing(8) },
}));

const FormControlLabelStyled = styled(FormControlLabel)<FormControlLabelProps>(
  ({ theme }) => ({
    "& .MuiFormControlLabel-label": {
      fontSize: "0.875rem",
      color: theme.palette.text.secondary,
    },
  })
);

const loginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(5).required(),
});

interface LoginData extends yup.InferType<typeof loginSchema> {}

const defaultValues = {
  password: "",
  email: "",
};

export const LoginScreen = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  // ** Hooks
  const auth = useAuth();
  const theme = useTheme();
  // const bgClasses = useBgColor();
  const { settings } = useSettings();
  const hidden = useMediaQuery(theme.breakpoints.down("md"));

  // ** Vars
  const { skin, configuredCompany } = settings;

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = (data: LoginData) => {
    const { email, password } = data;
    auth.login({ email, password }, (err) => {
      if (typeof err === "string") {
        setError("email", {
          type: "manual",
          message: err,
        });
      } else {
        setError("email", {
          type: "manual",
          message: "Email or Password is invalid",
        });
      }
    });
  };

  const imageSource =
    skin === "bordered"
      ? "auth-v2-login-illustration-bordered"
      : "auth-v2-login-illustration";

  return (
    <BlankLayout>
      <Box className="content-right">
        {!hidden ? (
          <Box
            sx={{
              flex: 1,
              display: "flex",
              position: "relative",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoginIllustrationWrapper>
              <LoginIllustration
                alt="login-illustration"
                src={`/images/pages/${imageSource}-${theme.palette.mode}.png`}
              />
            </LoginIllustrationWrapper>
            {/* <FooterIllustrationsV2 /> */}
          </Box>
        ) : null}
        <RightWrapper
          sx={
            skin === "bordered" && !hidden
              ? { borderLeft: `1px solid ${theme.palette.divider}` }
              : {}
          }
        >
          <Box
            sx={{
              p: 7,
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "background.paper",
            }}
          >
            <BoxWrapper>
              <Box
                sx={{
                  top: 30,
                  left: 40,
                  display: "flex",
                  position: "absolute",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <svg
                  width={47}
                  fill="none"
                  height={26}
                  viewBox="0 0 268 150"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    rx="25.1443"
                    width="50.2886"
                    height="143.953"
                    fill={theme.palette.primary.main}
                    transform="matrix(-0.865206 0.501417 0.498585 0.866841 195.571 0)"
                  />
                  <rect
                    rx="25.1443"
                    width="50.2886"
                    height="143.953"
                    fillOpacity="0.4"
                    fill="url(#paint0_linear_7821_79167)"
                    transform="matrix(-0.865206 0.501417 0.498585 0.866841 196.084 0)"
                  />
                  <rect
                    rx="25.1443"
                    width="50.2886"
                    height="143.953"
                    fill={theme.palette.primary.main}
                    transform="matrix(0.865206 0.501417 -0.498585 0.866841 173.147 0)"
                  />
                  <rect
                    rx="25.1443"
                    width="50.2886"
                    height="143.953"
                    fill={theme.palette.primary.main}
                    transform="matrix(-0.865206 0.501417 0.498585 0.866841 94.1973 0)"
                  />
                  <rect
                    rx="25.1443"
                    width="50.2886"
                    height="143.953"
                    fillOpacity="0.4"
                    fill="url(#paint1_linear_7821_79167)"
                    transform="matrix(-0.865206 0.501417 0.498585 0.866841 94.1973 0)"
                  />
                  <rect
                    rx="25.1443"
                    width="50.2886"
                    height="143.953"
                    fill={theme.palette.primary.main}
                    transform="matrix(0.865206 0.501417 -0.498585 0.866841 71.7728 0)"
                  />
                  <defs>
                    <linearGradient
                      y1="0"
                      x1="25.1443"
                      x2="25.1443"
                      y2="143.953"
                      id="paint0_linear_7821_79167"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop />
                      <stop offset="1" stopOpacity="0" />
                    </linearGradient>
                    <linearGradient
                      y1="0"
                      x1="25.1443"
                      x2="25.1443"
                      y2="143.953"
                      id="paint1_linear_7821_79167"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop />
                      <stop offset="1" stopOpacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
                <Typography
                  variant="h6"
                  sx={{
                    ml: 2,
                    lineHeight: 1,
                    fontWeight: 700,
                    fontSize: "1.5rem !important",
                    textTransform: "capitalize"
                  }}
                >
                  {configuredCompany} {themeConfig.templateName}
                </Typography>
              </Box>
              <Box sx={{ mb: 6 }}>
                <TypographyStyled variant="h5">{`Welcome to ${capitalize(configuredCompany)} ${themeConfig.templateName}! 👋🏻`}</TypographyStyled>
                <Typography variant="body2">
                  Please sign-in to your account and start the adventure
                </Typography>
              </Box>
              {/* <Alert
                icon={false}
                sx={{
                  py: 3,
                  mb: 6,
                  ...bgClasses.primaryLight,
                  "& .MuiAlert-message": { p: 0 },
                }}
              >
                <Typography
                  variant="caption"
                  sx={{ mb: 2, display: "block", color: "primary.main" }}
                >
                  Admin: <strong>admin@variobel.sk</strong> / Pass:{" "}
                  <strong>admin</strong>
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ display: "block", color: "primary.main" }}
                >
                  Client: <strong>client@variobel.sk</strong> / Pass:{" "}
                  <strong>client</strong>
                </Typography>
              </Alert> */}
              <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
              >
                <FormControl fullWidth sx={{ mb: 4 }}>
                  <Controller
                    name="email"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <TextField
                        autoFocus
                        label="Email"
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        error={Boolean(errors.email)}
                        placeholder="Email"
                      />
                    )}
                  />
                  {errors.email && (
                    <FormHelperText sx={{ color: "error.main" }}>
                      {errors.email.message}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor="auth-login-v2-password"
                    error={Boolean(errors.password)}
                  >
                    Password
                  </InputLabel>
                  <Controller
                    name="password"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <OutlinedInput
                        value={value}
                        onBlur={onBlur}
                        label="Password"
                        onChange={onChange}
                        id="auth-login-v2-password"
                        error={Boolean(errors.password)}
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onMouseDown={(e) => e.preventDefault()}
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <EyeOutline />
                              ) : (
                                <EyeOffOutline />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    )}
                  />
                  {errors.password && (
                    <FormHelperText sx={{ color: "error.main" }} id="">
                      {errors.password.message}
                    </FormHelperText>
                  )}
                </FormControl>
                <Box
                  sx={{
                    mb: 4,
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControlLabelStyled
                    label="Remember Me"
                    control={<Checkbox />}
                    sx={{
                      "& .MuiFormControlLabel-label": { color: "text.primary" },
                    }}
                  />
                  <a href="/forgot-password">
                    <Typography
                      component={Link}
                      variant="body2"
                      sx={{ color: "primary.main" }}
                    >
                      Forgot Password?
                    </Typography>
                  </a>
                </Box>
                <Button
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{ mb: 7 }}
                >
                  Login
                </Button>
                {/* <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <Typography sx={{ mr: 2, color: "text.secondary" }}>
                    New on our platform?
                  </Typography>
                  <Typography>
                    <a href="/register">
                      <Typography
                        component={Link}
                        sx={{ color: "primary.main" }}
                      >
                        Create an account
                      </Typography>
                    </a>
                  </Typography>
                </Box>
                <Divider
                  sx={{ mt: 5, mb: 7.5, "& .MuiDivider-wrapper": { px: 4 } }}
                >
                  or
                </Divider>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <a href="/">
                    <IconButton
                      component="a"
                      onClick={(e: MouseEvent<HTMLElement>) =>
                        e.preventDefault()
                      }
                    >
                      <Facebook sx={{ color: "#497ce2" }} />
                    </IconButton>
                  </a>
                  <a href="/">
                    <IconButton
                      component="a"
                      onClick={(e: MouseEvent<HTMLElement>) =>
                        e.preventDefault()
                      }
                    >
                      <Twitter sx={{ color: "#1da1f2" }} />
                    </IconButton>
                  </a>
                  <a href="/">
                    <IconButton
                      component="a"
                      onClick={(e: MouseEvent<HTMLElement>) =>
                        e.preventDefault()
                      }
                    >
                      <Github
                        sx={{
                          color: (theme) =>
                            theme.palette.mode === "light"
                              ? "#272727"
                              : theme.palette.grey[300],
                        }}
                      />
                    </IconButton>
                  </a>
                  <a href="/">
                    <IconButton
                      component="a"
                      onClick={(e: MouseEvent<HTMLElement>) =>
                        e.preventDefault()
                      }
                    >
                      <Google sx={{ color: "#db4437" }} />
                    </IconButton>
                  </a>
                </Box> */}
              </form>
            </BoxWrapper>
          </Box>
        </RightWrapper>
      </Box>
    </BlankLayout>
  );
};
