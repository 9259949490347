// ** React Imports
import { SyntheticEvent, useState } from "react";

// ** MUI Imports
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";

import DialogContainer from "../dialogContainer";
import RolePermissionForm from "./role-permission-form";

// ** Icon Imports
import { ContentCopy } from "mdi-material-ui";

// ** Image Import
import addNewRoleIllustration from "../../assets/images/add-new-role-illustration.png";

// ** Types Import
import { CardDataType } from "../@fake-db/types";

// ** Fake Data Import
import { customerCardData } from "../@fake-db/roles/static-data";

const RolesCards = () => {
  // ** States
  const [dialogTitle, setDialogTitle] = useState<"Add" | "Edit">("Add");
  const [openAddDialog, setOpenAddDialog] = useState(false);

  const toggleAddDialog = () => setOpenAddDialog((prev) => !prev);

  const renderCards = () =>
    customerCardData.map((item: CardDataType, index: number) => (
      <Grid item xs={12} sm={6} lg={4} key={index}>
        <Card>
          <CardContent>
            <Box
              sx={{
                mb: 3,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="body2">{`Total ${item.totalUsers} users`}</Typography>
              <AvatarGroup
                max={4}
                sx={{
                  "& .MuiAvatar-root": {
                    width: 40,
                    height: 40,
                    fontSize: "0.875rem",
                  },
                }}
              >
                {item.avatars.map((img, index: number) => (
                  <Avatar
                    key={index}
                    alt={item.title}
                    src={`/images/avatars/${img}`}
                  />
                ))}
              </AvatarGroup>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="h6">{item.title}</Typography>
                <Typography
                  href="/"
                  variant="body2"
                  component={Link}
                  sx={{ color: "primary.main" }}
                  onClick={(e: SyntheticEvent) => {
                    e.preventDefault();
                    setDialogTitle("Edit");
                    toggleAddDialog();
                  }}
                >
                  Edit Role
                </Typography>
              </Box>
              <IconButton sx={{ color: "text.secondary" }}>
                <ContentCopy fontSize="small" />
              </IconButton>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    ));

  return (
    <Grid container spacing={6} className="match-height">
      {renderCards()}
      <Grid item xs={12} sm={6} lg={4}>
        <Card>
          <Grid container sx={{ height: "100%" }}>
            <Grid item xs={5}>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "center",
                }}
              >
                <img
                  width={65}
                  height={130}
                  alt="add-role"
                  src={addNewRoleIllustration}
                />
              </Box>
            </Grid>
            <Grid item xs={7}>
              <CardContent>
                <Box sx={{ textAlign: "right" }}>
                  <Button
                    variant="contained"
                    sx={{ mb: 2.5, whiteSpace: "nowrap" }}
                    onClick={(e: SyntheticEvent) => {
                      e.preventDefault();
                      setDialogTitle("Add");
                      toggleAddDialog();
                    }}
                  >
                    Add Role
                  </Button>
                  <Typography variant="body2">
                    Add role, if it doesn't exist.
                  </Typography>
                </Box>
              </CardContent>
            </Grid>
          </Grid>
        </Card>
      </Grid>

      <DialogContainer handleClose={toggleAddDialog} open={openAddDialog}>
        <RolePermissionForm
          dialogTitle={dialogTitle}
          toggleAddDialog={toggleAddDialog}
        />
      </DialogContainer>
    </Grid>
  );
};

export default RolesCards;
