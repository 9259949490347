// ** React Imports
import { Dispatch, Fragment, MouseEvent, SetStateAction, useState } from "react";

// ** MUI Imports
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import TableContainer from "@mui/material/TableContainer";
import { Box, IconButton, Icon, Menu, MenuItem, CircularProgress } from "@mui/material";

// ** Third Party Imports
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

// ** Types
import { Address } from "../../../types/customerTypes";
import { AppDispatch } from "../../../store";

import { editCustomer } from "../../../store/apps/customer";

import { useAlertContext } from "../../../hooks/useAlertContext";

interface Props {
  customerID: string;
  addressData: Address[];
  setAddressData: Dispatch<SetStateAction<Address[]>>;
  toggleEditCustomerDialog: () => void;
}
interface IActions {
  customerID: string;
  id: string;
  addressData: Address[];
  setAddressData: Dispatch<SetStateAction<Address[]>>;
  toggleEditCustomerDialog: () => void;
}

const Actions = (props: IActions) => {
  const { customerID, id, addressData, setAddressData, toggleEditCustomerDialog } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleting, setDeleting] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { showSuccessAlert, showErrorAlert } = useAlertContext();

  const handleRowOptionsClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRowOptionsClose = () => {
    setAnchorEl(null);
  };

  const handleAddressDelete = () => {
    setDeleting(true);
    const _addressData = addressData.filter((address) => address._id !== id);

    dispatch(
      editCustomer({
        id: customerID,
        data: {
          billingAddress: _addressData,
        },
      })
    )
      .unwrap()
      .then(() => {
        showSuccessAlert(t("Customer has been updated"));
      })
      .catch((err) => {
        console.log(err);
        showErrorAlert(t("Error during update! Check console"));
      })
      .finally(() => {
        setDeleting(false);
        handleRowOptionsClose();
        setAddressData(_addressData);
      });
  };

  return (
    <>
      <IconButton size="small" onClick={handleRowOptionsClick}>
        <Icon>more_vert</Icon>
      </IconButton>

      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleRowOptionsClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={toggleEditCustomerDialog}>{t("Edit")}</MenuItem>
        <MenuItem onClick={handleAddressDelete}>
          {!deleting && t("Delete")}

          {deleting && (
            <>
              <CircularProgress size={16} sx={{ mr: 2 }} />
              {t("Deleting...")}
            </>
          )}
        </MenuItem>
      </Menu>
    </>
  );
};

export const CustomerViewAddressScreen = ({
  customerID,
  addressData,
  setAddressData,
  toggleEditCustomerDialog
}: Props) => {
  const { t } = useTranslation();

  if (!addressData.length) {
    return null
  }

  return (
    <Fragment>
      {addressData.map((address, index) => {
        const marginBottom = () => {
          const currentIndex = index + 1;
          const condition = currentIndex !== addressData.length;

          if (condition) return 6;
          return 0;
        };

        return (
          <Card key={address._id} sx={{ mb: marginBottom(), px: 2, pt: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 1,
              }}
            >
              <Actions
                customerID={customerID}
                id={address._id}
                addressData={addressData}
                setAddressData={setAddressData}
                toggleEditCustomerDialog={toggleEditCustomerDialog}
              />
            </Box>

            <CardContent sx={{ pt: 0 }}>
              <Grid container rowSpacing={3} columnSpacing={6}>
                <Grid item xs={12} lg={6}>
                  <TableContainer>
                    <Table size="small" sx={{ width: "95%" }}>
                      <TableBody
                        sx={{
                          "& .MuiTableCell-root": {
                            border: 0,
                            pt: 2,
                            pb: 2,
                            pl: "0 !important",
                            pr: "0 !important",
                            "&:first-of-type": {
                              width: 148,
                            },
                          },
                        }}
                      >
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {t("Address")}:
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">
                              {address.address}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {t("Citz")}:
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">
                              {address.city}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {t("ZIP")}:
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">
                              {address.postcode}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <TableContainer>
                    <Table size="small">
                      <TableBody
                        sx={{
                          "& .MuiTableCell-root": {
                            border: 0,
                            pt: 2,
                            pb: 2,
                            pl: "0 !important",
                            pr: "0 !important",
                            "&:first-of-type": {
                              width: 148,
                            },
                          },
                        }}
                      >
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {t("Country code")}:
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">
                              {address.country.code}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {t("Country label")}:
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">
                              {address.country.label}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant="subtitle2"
                              sx={{ whiteSpace: "nowrap" }}
                            >
                              {t("Krajinský Phone")}:
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2">
                              {address.country.phone}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        );
      })}
    </Fragment>
  );
};
