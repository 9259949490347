import {
  Dialog,
  DialogTitle,
  Icon,
  IconButton,
  DialogContent,
  Grid,
  LinearProgress,
  Button,
  InputAdornment,
  Divider, Typography, CircularProgress, Box,
} from '@mui/material';

import yup from '../../../../helpers/customYup';
import { Field, Form, Formik, FormikHelpers } from "formik";
import { TextField, CheckboxWithLabel } from "formik-mui";
import { useEffect, useState } from 'react';

import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../store";

import { createAccount, updateAccount } from '../../../../store/apps/email';
import { MailAccountCreationType } from '../../../../types/mailAccountsType';
import axios from 'axios';
import { useAlertContext } from '../../../../hooks/useAlertContext';
import { useTranslation } from 'react-i18next';

const initialConnectionValues = {
  imap: {
    email: "",
    password: "",
    host: "",
    port: 993,
    tls: true,
  },

  smtp: {
    user: "",
    pass: "",
    host: "",
    port: 465,
    service: "",
  },
};

interface Props {
  open: boolean;
  handleClose: () => void;
  type?: "edit" | "add";
  ids?: {
    imapId: string;
    smtpId: string;
  };
  editableValues?: MailAccountCreationType;
}

const AddAccountsDialog = (props: Props) => {
  const { open, handleClose, type = "add", ids, editableValues } = props;

  const getPasswordSchema = () => {
    if (type === "edit") return yup.string();
    return yup.string()
      .min(5)
      .required();
  };

  const ImapSchema = yup.object({
    email: yup.string().email().required(),
    password: getPasswordSchema(),
    host: yup.string().required(),
    port: yup.number(),
    tls: yup.boolean(),
  });

  const SmtpSchema = yup.object({
    user: yup.string(), // not required due to same value as imap email
    pass: yup.string(), // not required due to same value as imap password
    host: yup.string().required(),
    port: yup.number(),
    service: yup.string(),
    // secure: yup.boolean(),
  });

  const connectionSchema = yup.object({
    imap: ImapSchema,
    smtp: SmtpSchema
  });

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isErrorConnection, setIsErrorConnection] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [connectionErrorMessage, setConnectionErrorMessage] = useState<any[]>([])
  const [isConnection, setIsConnection] = useState(false);

  // hooks
  const dispatch = useDispatch<AppDispatch>();
  const { showErrorAlert, showSuccessAlert } = useAlertContext()
  const { t } = useTranslation();

  const testConnection = async (values: MailAccountCreationType) => {
    const imapConfig = {
      method: "post",
      url: `${process.env.REACT_APP_SERVER_HOST}/imap/test`,
      data: {...values.imap, user: values.imap.email},
      withCredentials: true,
    }

    const smtpConfig = {
      method: "post",
      url: `${process.env.REACT_APP_SERVER_HOST}/smtp/test`,
      data: values.smtp,
      withCredentials: true,
    }

    const imapResp = await axios(imapConfig)
      .then((res) => res)
      .catch((err) => err.response);

    const smtpResp = await axios(smtpConfig)
      .then((res) => res)
      .catch((err) => err.response);

    if (imapResp.status === 500 || smtpResp.status === 500 ) {
      return {
        connectionError: true,
        errorMessage: [imapResp.data.message, smtpResp.data.message],
      }
    }

    return {
      connectionError: false,
      errorMessage: []
    }
  }

  const handleSubmit = async (values: MailAccountCreationType, formikHelpers:FormikHelpers<MailAccountCreationType>) => {
    const { setSubmitting } = formikHelpers;

    values.smtp.user = values.imap.email
    values.smtp.pass = values.imap.password

    const onEditeEmptyPasswordCheck = type === "edit" && values.imap.password === "";

    const { connectionError, errorMessage } = await testConnection(values)
    setSubmitting(false);
    !onEditeEmptyPasswordCheck && setIsErrorConnection(connectionError)
    !onEditeEmptyPasswordCheck && setConnectionErrorMessage(errorMessage)

    if (!connectionError || onEditeEmptyPasswordCheck) {
      setIsConnection(true)

      // add imap
      if (type === "add") {
        await dispatch(createAccount({ ...values }))
        .then((res) => {
          setSubmitting(false);
          showSuccessAlert(t("Account created"))
          handleClose();
        })
        .catch((err) => {
          setSubmitting(false);
          showErrorAlert(t("Failed to create Account"))
          handleClose();
        });
        setIsConnection(false)
      }

      // edit imap
      if (type === "edit" && ids) {
        const updatedValues = values

        // this removes password from payload if empty
        if (values.imap.password === "") {
          //@ts-ignore: to bypass the TS warning for deleting required props
          delete updatedValues.imap.password
          //@ts-ignore
          delete updatedValues.smtp.pass
        }

        await dispatch(updateAccount({ ids, data: updatedValues }))
        .then((res) => {
          setSubmitting(false);
          showSuccessAlert(t("Account updated"))
          handleClose();
        })
        .catch((err) => {
          setSubmitting(false);
          showErrorAlert(t("Failed to update Account"))
          handleClose();
        });
        setIsConnection(false)
      }
    }
  };

  const handleInputTouch = () => {
    setIsErrorConnection(false)
    setConnectionErrorMessage([])
  }

  const onClose = () => {
    setConnectionErrorMessage([]);
    setIsErrorConnection(false);
    setIsConnection(false);
    setShowPassword(false);
    handleClose();
  }

  // show alert if error in test connection
  useEffect(() => {
    if(isErrorConnection) {
      showErrorAlert(t("Nepodarilo sa pripojiť k e-mailovému kontu"))
      setTimeout(() => {
        setIsErrorConnection(false)
      }, 5000)
    }
  }, [isErrorConnection, showErrorAlert, t])

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      scroll="body"
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        {type === "add" ? "Add" : "Edit"} Account
        <IconButton
          size="small"
          onClick={handleClose}
          sx={{ position: "absolute", right: "1rem", top: "1rem" }}
        >
          <Icon>close</Icon>
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ pt: (theme) => `${theme.spacing(1)} !important` }}>
        <Formik
          initialValues={
            type === "add"
              ? initialConnectionValues
              : (editableValues as MailAccountCreationType)
          }
          validationSchema={connectionSchema}
          onSubmit={(values, formikHelpers) =>
            handleSubmit(values, formikHelpers)
          }
        >
          {({ isSubmitting, submitForm }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="body1">General Settings</Typography>
                  <Divider />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    component={TextField}
                    name="imap.email"
                    autoComplete="off"
                    type="email"
                    label="Email"
                    required
                    placeholder="mail@gmail.com"
                    fullWidth
                    onBlur={handleInputTouch}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    component={TextField}
                    name="imap.password"
                    autoComplete="new-password"
                    label={type === "edit" ? "New Password" : "Password"}
                    required={type === "edit" ? false : true}
                    placeholder="some password"
                    fullWidth
                    onBlur={handleInputTouch}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? (
                              <Icon>visibility</Icon>
                            ) : (
                              <Icon>visibility_off</Icon>
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} mt={2}>
                  <Typography variant="body1">
                    IMAP Settings (Receiving Mails)
                  </Typography>
                  <Divider />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    component={TextField}
                    name="imap.host"
                    label="IMAP Host"
                    required
                    placeholder="imap.gmail.com"
                    fullWidth
                    onBlur={handleInputTouch}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    component={TextField}
                    name="imap.port"
                    type="number"
                    label="IMAP Port"
                    fullWidth
                    onBlur={handleInputTouch}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name="imap.tls"
                    Label={{ label: "TLS" }}
                    fullWidth
                    onBlur={handleInputTouch}
                  />
                </Grid>

                <Grid item xs={12} mt={2}>
                  <Typography variant="body1">
                    SMTP Settings (Sending Mails)
                  </Typography>
                  <Divider />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    component={TextField}
                    name="smtp.host"
                    label="SMTP Host"
                    required
                    placeholder="smtp.gmail.com"
                    fullWidth
                    onBlur={handleInputTouch}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    component={TextField}
                    name="smtp.port"
                    type="number"
                    label="SMTP Port"
                    required
                    fullWidth
                    onBlur={handleInputTouch}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Field
                    component={TextField}
                    name="smtp.service"
                    label="SMTP Service"
                    fullWidth
                    onBlur={handleInputTouch}
                  />
                </Grid>

                <Grid item xs={12}>
                  {isSubmitting && <LinearProgress />}
                </Grid>

                <Grid item mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    {type === "add" ? "Add" : "Edit"} Account
                  </Button>

                  {/* {isErrorConnection &&
                    connectionErrorMessage.map((c, i) => (
                      <Typography key={i} mt={2} color="error">
                        {c}
                      </Typography>
                    ))} */}

                  {isConnection && (
                    <Box mt={3} sx={{ display: "flex", alignItems: 'center', gap: 2 }}>
                      <Typography color="green">Success</Typography>
                      <CircularProgress size={25}/>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

export default AddAccountsDialog