import {
  Card,
  Grid,
  Select,
  TextField,
  CardHeader,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { ChangeEvent } from "react";

interface Props {
  t: any;
  customerIsRegisteredFilterValue: string;
  handleCustomerIsRegisteredFilterValue: (e: SelectChangeEvent) => void;
  customerIsRegisteredFilters: string[];
  searchValue: string;
  handleSearchValue: (e: ChangeEvent<HTMLInputElement>) => void;
}

const CustomerListFilters = (props: Props) => {
  const {
    t,
    customerIsRegisteredFilterValue,
    handleCustomerIsRegisteredFilterValue,
    customerIsRegisteredFilters,
    searchValue,
    handleSearchValue,
  } = props;

  return (
    <Card>
      <CardHeader title={t("Filter")} />

      <CardContent>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="customer-registered-select">
                {t("Registered")}
              </InputLabel>

              <Select
                fullWidth
                value={customerIsRegisteredFilterValue}
                sx={{
                  mr: 4,
                  mb: 2,
                  textTransform: "capitalize",
                }}
                label={t("Registered")}
                onChange={handleCustomerIsRegisteredFilterValue}
                labelId="customer-registered-select"
              >
                <MenuItem value="">None</MenuItem>
                {customerIsRegisteredFilters.map((item, i) => (
                  <MenuItem
                    key={i}
                    value={item}
                    sx={{ textTransform: "capitalize" }}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              value={searchValue}
              placeholder={t("Find Customer")}
              onChange={handleSearchValue}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CustomerListFilters;
