import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import chroma from "chroma-js";
import { Area } from "react-easy-crop/types";

import {
  LineItemType,
  OrderLineItemT,
} from "../../../types/orderTypes";

import { getCroppedImg } from "../../../utils/printWallpaperHelpers";
import { PrintThumbImage } from "./printThumbImage";
import { currencyFormatter } from "../../../helpers/currency-formatter";
import { isVariobelLineItem } from "../add-edit/helpers";

interface Props {
  lineItem: OrderLineItemT;
  isVariobelOnlyField: boolean;
}

interface BottomImageTextProps {
  originalHref: string;
  croppedHref: string;
  isWallpaperSpread: boolean;
  qty: number;
}

const BORDER_COLOR = '#F4A600';
const BORDER_WIDTH = "3px";

const RenderPreview = (props: BottomImageTextProps) => {
  const { croppedHref, originalHref, isWallpaperSpread, qty } = props;

  const cropRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    if (cropRef.current && isWallpaperSpread) {
      const numberOfLines = qty;
      const interval = 100 / numberOfLines;

      const element = cropRef.current;

      const currentCropperStyle = element.getAttribute("style");

      const newCropperStyle = `
            ${currentCropperStyle || ""}
            border: ${BORDER_WIDTH} solid ${BORDER_COLOR};
          `;

      element.setAttribute("style", newCropperStyle);

      for (let i = 1; i < numberOfLines; i++) {
        const spreadElement = document.createElement("div");

        const spreadElementStyle = `
            width: ${BORDER_WIDTH};
            background-color: ${BORDER_COLOR};
            height: 100%;
            position: absolute;
            top: 0;
            left: ${interval * i}%;
          `;

          spreadElement.setAttribute("style", spreadElementStyle);

        // Add the spreadElement to the cropElement
        element.appendChild(spreadElement);
      }
    }
  }, [isWallpaperSpread, qty]);

  if (croppedHref) {
    return (
      <div ref={cropRef} style={{ maxWidth: 400 }}>
        <img src={croppedHref} alt="cropped" style={{ width: "100%", height: "100%"}} />
      </div>
    );
  }

  return (
    <div ref={cropRef} style={{ maxWidth: 400 }}>
      <img src={originalHref} alt="original" />
    </div>
  );
}

const BottomImageText = (props: BottomImageTextProps) => {
  const { croppedHref, originalHref } = props;

  const [openModal, setOpenModal] = useState(false);
  const toggleOpenModal = () => setOpenModal((prev) => !prev);

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1.5,
        "& a": {
          fontStyle: "italic",
        },
      }}
    >
      <Typography
        noWrap
        color="primary"
        variant="caption"
        component="a"
        target="_blank"
        href={originalHref}
      >
        ORIGINAL
      </Typography>
      <Typography noWrap variant="caption">
        |
      </Typography>
      <Typography
        noWrap
        color="primary"
        variant="caption"
        component="a"
        target="_blank"
        href={croppedHref}
      >
        CROPPED
      </Typography>
      <Typography noWrap variant="caption">
        |
      </Typography>
      <Button
        disableRipple
        onClick={toggleOpenModal}
        sx={(theme) => ({
          p: 0,
          lineHeight: 0,
          fontSize: "0.75rem",
          fontStyle: "italic",
          minWidth: "unset",
          color: `${theme.palette.primary.main} !important`,
        })}
      >
        PREVIEW
      </Button>

      <Dialog
        open={openModal}
        keepMounted
        onClose={toggleOpenModal}
        sx={{ lineHeight: 0 }}
      >
        <RenderPreview {...props} />
      </Dialog>
    </Box>
  );
}

export const LineItemRow = (props: Props) => {
  const { lineItem, isVariobelOnlyField } = props;

  const [localWallpaper, setLocalWallpaper] = useState({
    originalImage: "",
    croppedImage: "",
  });

  const cmyk = () => {
    if(!isVariobelLineItem(lineItem)) return;

    if (lineItem.print.type === "color") {
      const hex = JSON.parse(lineItem.print.value).hex;
      let _cmyk = chroma(hex)
        .cmyk()
        .map((value) => (value * 100).toFixed(0))
        .toString();
      _cmyk = `(${_cmyk})`.split(",").join(", ");

      return _cmyk;
    }

    return "";
  };

  let originalPrintHref = "";
  let croppedPrintHref = "";
  const [isWallpaperSpread, setIsWallpaperSpread] = useState(false)

  const ownWallpaperThumb = () => {
    if(!isVariobelLineItem(lineItem)) return;

    if (lineItem.print.type !== "ownWallpaper") return;

    const obj = JSON.parse(lineItem.print.value);
    originalPrintHref = obj.original;

    if (Object.hasOwn(obj, "cropped")) {
      croppedPrintHref = obj.cropped;
      return obj.cropped;
    }

    return obj.original;
  };

  // Get wallpaper spread for local and custom wallpaper prints
  useEffect(() => {
    if (!isVariobelOnlyField) return;
    const variobelLineItem = lineItem as LineItemType;

    const getIsWallpaperSpread = () => {
      if (variobelLineItem.print.type === "color") return;

      const obj = JSON.parse(variobelLineItem.print.value);
      if (Object.hasOwn(obj, "isWallpaperSpread")) {
        setIsWallpaperSpread(obj.isWallpaperSpread);
      }
    };

    getIsWallpaperSpread();
  }, [lineItem, isVariobelOnlyField]);

  // Update local wallpaper actual image and cropped image
  useEffect(() => {
    if(!isVariobelOnlyField) return;
    const variobelLineItem = lineItem as LineItemType;


    const getLocalWallpaperImageLinks = async () => {
      if (variobelLineItem.print.type !== "wallpaper") return;

      const value = JSON.parse(variobelLineItem.print.value);
      const originalImage = value.file;

      // parse because of old data, should just be dot notation
      const croppedData = typeof value.cropDetails === 'string' ? JSON.parse(value.cropDetails) : value.cropDetails;

      const croppedImageData = await getCroppedImg(
        originalImage,
        croppedData.croppedAreaPixels as Area,
        croppedData.rotation
      );

      let croppedImage = "";
      if (croppedImageData) {
        croppedImage = croppedImageData.objectURL;
      }

      setLocalWallpaper({ originalImage, croppedImage });
    };

    (async () => await getLocalWallpaperImageLinks())();
  }, [lineItem, isVariobelOnlyField]);

  return (
    <TableRow key={lineItem._id}>
      <TableCell>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* variobel alone */}
          {isVariobelOnlyField && isVariobelLineItem(lineItem) && (
            <PrintThumbImage
              lineItem={lineItem}
              src={ownWallpaperThumb()}
              isWallpaperSpread={isWallpaperSpread}
            />
          )}

          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexDirection: "column",
              gap: 1,
            }}
          >
            {/* variobel alone */}
            {isVariobelLineItem(lineItem) && lineItem.name && (
              <Typography variant="caption" sx={{ fontStyle: "italic" }}>
                {lineItem.name}
              </Typography>
            )}

            <Typography
              sx={{
                fontWeight: 600,
                color: "text.secondary",
              }}
              noWrap
            >
              {lineItem.product.name}
            </Typography>

            {/* variobel alone */}
            {isVariobelOnlyField && isVariobelLineItem(lineItem) && (
              <>
                <Typography variant="body2">{`${lineItem.glassWidth} x ${lineItem.glassHeight}`}</Typography>

                {lineItem.print.type === "color" && (
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Typography noWrap variant="caption">
                      ONYX - {JSON.parse(lineItem.print.value).onyxMatch.onyx}
                    </Typography>
                    <Typography noWrap variant="caption">
                      |
                    </Typography>
                    <Typography noWrap variant="caption">
                      HEX - {JSON.parse(lineItem.print.value).hex}
                    </Typography>
                    <Typography noWrap variant="caption">
                      |
                    </Typography>
                    <Typography noWrap variant="caption">
                      CMYK - {cmyk()}
                    </Typography>
                  </Box>
                )}

                {lineItem.print.type === "ownWallpaper" && (
                  <BottomImageText
                    originalHref={originalPrintHref}
                    croppedHref={croppedPrintHref}
                    isWallpaperSpread={isWallpaperSpread}
                    qty={lineItem.qty}
                  />
                )}

                {lineItem.print.type === "wallpaper" && (
                  <BottomImageText
                    originalHref={localWallpaper.originalImage}
                    croppedHref={localWallpaper.croppedImage}
                    isWallpaperSpread={isWallpaperSpread}
                    qty={lineItem.qty}
                  />
                )}
              </>
            )}
          </Box>
        </Box>
      </TableCell>

      <TableCell>
        {lineItem.qty} {isWallpaperSpread && " (Spread)"}
      </TableCell>

      {/* variobel alone */}
      {isVariobelOnlyField && isVariobelLineItem(lineItem) && (
        <>
          <TableCell sx={{ whiteSpace: "nowrap" }}>
            {(
              (lineItem.glassWidth * lineItem.glassHeight * lineItem.qty) /
              Math.pow(10, 6)
            ).toFixed(2)}{" "}
            m<sup>2</sup>
          </TableCell>
        </>
      )}


      {/* porez alone */}
      {!isVariobelOnlyField && !isVariobelLineItem(lineItem) && (
        <>
          <TableCell>{lineItem.width ?? 0}</TableCell>

          <TableCell>{lineItem.height ?? 0}</TableCell>
        </>
      )}

      <TableCell>{currencyFormatter(lineItem.price)}</TableCell>
    </TableRow>
  );
};
