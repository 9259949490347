// ** Redux Imports
import { Dispatch } from "redux";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

interface DataQueries {
  search: string;
}

interface Redux {
  getState: any;
  dispatch: Dispatch<any>;
}

// ** Fetch Admins
export const fetchData = createAsyncThunk(
  "admin/fetchData",
  async (queries: DataQueries) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_HOST}/admin/get-all?search=${queries.search}`,
      {
        withCredentials: true,
      }
    );

    return response.data;
  }
);

// ** Delete Admin
export const deleteAdmin = createAsyncThunk(
  "admin/deleteAdmin",
  async (id: string, { getState, dispatch }: Redux) => {
    const response = await axios.delete(
      `${process.env.REACT_APP_SERVER_HOST}/admin/delete/${id}`,
      {
        withCredentials: true,
      }
    );
    dispatch(fetchData(getState().admin.queries));

    return response.data;
  }
);

export const getAdmin = createAsyncThunk(
  "admin/get",

  async (id: string, { getState, dispatch }: Redux) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_HOST}/admin/get/${id}`,
      { withCredentials: true }
    );

    return response.data;
  }
);

export const updateAdmin = createAsyncThunk(
  "admin/update",

  async ({ id, data }: { id: string, data: any}, { getState, dispatch }: Redux) => {
    const response = await axios.patch(
      `${process.env.REACT_APP_SERVER_HOST}/admin/update/${id}`,
      data,
      { withCredentials: true }
    );

    await dispatch(fetchData(getState().admin.queries));
    return response.data;
  }
);

export const createAdmin = createAsyncThunk(
  "admin/create",

  async (data: any, { getState, dispatch }: Redux) => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_HOST}/admin/create`,
      data,
      { withCredentials: true }
    );

    await dispatch(fetchData(getState().admin.queries));
    return response.data;
  }
);


export const appAdminsSlice = createSlice({
  name: "appAdmin",
  initialState: {
    data: [],
    total: 0,
    queries: {
      search: ""
    },
    allData: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchData.fulfilled, (state, action) => {
      state.data = action.payload.admins;
      state.total = action.payload.total;
      state.queries = action.payload.queries;
      state.allData = action.payload.allData;
    });
  },
});

export default appAdminsSlice.reducer;
