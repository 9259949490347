// Copied from
// https://bitbucket.org/marcel_simik/variobel/src/comps/ecomm/print/printDialog/tabs/customWallpaperTab/helpers.ts
import { Area } from "react-easy-crop/types";

interface BlobData {
  blob: Blob;
  objectURL: string;
}

const TO_RADIANS = Math.PI / 180;

const createImage = (url: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });
};

// Returns the new bounding area of a rotated rectangle.
const rotateSize = (width: number, height: number, rotation: number) => {
  const rotRad = rotation * TO_RADIANS;

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
};

// This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
export const getCroppedImg = async (
  imageSrc: string,
  pixelCrop: Area,
  rotation: number = 0,
  flip = { horizontal: false, vertical: false }
) => {
  const image: HTMLImageElement = await createImage(imageSrc);
  const canvas: HTMLCanvasElement = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const rotRad = rotation * TO_RADIANS;

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating
  // and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  const croppedCanvas = document.createElement("canvas");

  const croppedCtx = croppedCanvas.getContext("2d");

  if (!croppedCtx) return null;

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width;
  croppedCanvas.height = pixelCrop.height;

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  );

  // As a blob and OBJ-URL
  return new Promise<BlobData>((resolve, reject) => {
    croppedCanvas.toBlob((blob: Blob | null) => {
      if (blob) {
        const objectURL: string = URL.createObjectURL(blob);
        resolve({ blob, objectURL });
      } else {
        reject("Error creating blob");
      }
    }, "image/jpeg");
  });
};