import React from "react";
import { Customer } from "../../../../types/customerTypes";
import {
  Autocomplete,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Button,
  Icon,
  ListItem,
  IconButton
} from "@mui/material";
import { GridSelectionModel } from "@mui/x-data-grid";


interface Props {
  t: any;
  handleAddItem: () => void;
  handleDeleteItem: () => void;
  handleCopyItem: () => void;
  selectedListItems: GridSelectionModel;
  searchValue: string;
  setSearchValue: (value: React.SetStateAction<string>) => void;
  handleOptionClick: (obj: Customer) => void;
  isCreateReady: () => boolean;
  togglePreviewOrder: () => void;
  toggleAddCustomerDialog: () => void;
  options: Customer[];
}

const AddOrderTableHeader = (props: Props) => {
  const {
    t,
    handleAddItem,
    handleDeleteItem,
    handleCopyItem,
    selectedListItems,
    searchValue,
    setSearchValue,
    handleOptionClick,
    isCreateReady,
    togglePreviewOrder,
    toggleAddCustomerDialog,
    options
  } = props;

  return (
    <Box
      sx={{
        px: 5,
        py: 3,
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", gap: 2 }}>
        <IconButton onClick={handleAddItem}>
          <Icon>add</Icon>
        </IconButton>

        <IconButton
          onClick={handleDeleteItem}
          disabled={selectedListItems.length === 0}
        >
          <Icon>delete</Icon>
        </IconButton>

        <IconButton
          onClick={handleCopyItem}
          disabled={selectedListItems.length === 0}
        >
          <Icon>content_copy</Icon>
        </IconButton>
      </Box>

      <Box sx={{ display: "flex", gap: 2 }}>
        <Autocomplete
          freeSolo
          disableClearable
          size="small"
          sx={{
            minWidth: 250,
            "& + .MuiAutocomplete-popper": {
              ...(searchValue.length && {
                overflow: "auto",
                maxHeight: "calc(100vh - 69px)",
                height: 481,
              }),
            },
          }}
          onInputChange={(event, value: string) => setSearchValue(value)}
          onChange={(event, obj) =>
            handleOptionClick(obj as unknown as Customer)
          }
          options={options}
          getOptionLabel={(option: Customer | unknown) => {
            return `${(option as Customer).firstName} ${
              (option as Customer).lastName
            }`;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={`${t("Select Customer")}`}
              required
              size="small"
              sx={{ minWidth: 300 }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment
                    position="end"
                    onClick={toggleAddCustomerDialog}
                  >
                    <Button variant="contained" size="small" sx={{ p: 1 }}>
                      <Icon>add</Icon>
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          )}
          renderOption={(props, option: Customer | unknown) => {
            return (
              <ListItem
                {...props}
                key={(option as Customer)._id}
                className={`suggestion ${props.className}`}
              >
                <Typography m={2}>
                  {(option as Customer).firstName}{" "}
                  {(option as Customer).lastName}
                </Typography>
              </ListItem>
            );
          }}
        />

        <Button
          variant="contained"
          disabled={!isCreateReady()}
          onClick={togglePreviewOrder}
        >
          {t("Preview Order")}
        </Button>
      </Box>
    </Box>
  );
}

export default AddOrderTableHeader