// ** MUI Imports
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// ** Custom Components Imports
import PageHeader from "../../../comps/@core/components/page-header";

// ** Components Imports
import RoleCards from "../../../comps/roles";

const CustomerRolesScreen = () => {
  return (
    <Grid container spacing={6} sx={{
      opacity: 0.7,
      pointerEvents: 'none'
    }}>
      <PageHeader
        title={<Typography variant="h5"> --- WORK-IN-PROGRESS --- </Typography>}
        subtitle={
          <Typography variant="body2">
            A role provided access to predefined menus and features so that
            depending on assigned role an administrator can have access to what
            he need
          </Typography>
        }
      />
      <Grid item xs={12} sx={{ mb: 5 }}>
        <RoleCards />
      </Grid>
    </Grid>
  );
};

export default CustomerRolesScreen;
