import { Typography, Icon } from "@mui/material";
import { GridColumns, GridActionsCellItem } from "@mui/x-data-grid";
// import { Chip } from "mdi-material-ui";
import { Link } from "react-router-dom";
import { OrderStatus, OrderStatusData } from "../../../types/orderStatusTypes";
import { ThemeColor } from "../../@core/layouts/types";
import { Chip } from "../../chip";
import { Dispatch, SetStateAction } from "react";
import { UseFormReturn } from "react-hook-form";

interface CellType {
  row: OrderStatus;
}

interface Props {
  t: any;
  toggleEditOrderStatusDialog: () => void;
  toggleDeleteOrderStatusDialog: () => void;
  setSelectedOrderStatus: Dispatch<SetStateAction<OrderStatus | undefined>>;
  editOrderStatusForm: UseFormReturn<OrderStatusData, any>
}

const getOrderStatusTableColumns = (props: Props): GridColumns<OrderStatus> => {
  const { t, toggleDeleteOrderStatusDialog, toggleEditOrderStatusDialog, setSelectedOrderStatus, editOrderStatusForm  } = props;

  const columns = [
    {
      flex: 0.15,
      minWidth: 180,
      field: "name",
      headerName: t("Name"),
      renderCell: ({ row }: CellType) => (
        <Typography
          variant="body2"
          component={Link}
          to={`/settings/orderStatus/preview/${row._id}`}
        >
          {`${t(row.name)}`}
        </Typography>
      ),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "colorCode",
      headerName: t("Color"),
      renderCell: ({ row }: CellType) => (
        <Chip
          size="small"
          color={(row.colorCode ? row.colorCode : "error") as ThemeColor}
          sx={{ width: 28, height: 28 }}
        />
      ),
      valueGetter: ({ row }: CellType) =>
        row.colorCode ? row.colorCode : "error",
      sortable: false,
      filterable: false,
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "isDefault",
      headerName: t("Default"),
      type: "boolean",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "notifyCustomer",
      headerName: t("Inform Customer"),
      type: "boolean",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "notifyAdmin",
      headerName: t("Notify Admin"),
      type: "boolean",
    },
    {
      flex: 0.1,
      minWidth: 100,
      sortable: false,
      field: "actions",
      headerName: t("Actions"),
      type: "actions",
      getActions: ({ row }: CellType) => [
        <GridActionsCellItem
          showInMenu
          sx={{ "& .MuiListItemIcon-root": { minWidth: 0 } }}
          icon={<Icon fontSize="small">visibility</Icon>}
          label={t("View")}
          // @ts-expect-error
          component={Link}
          to={`/settings/orderStatus/preview/${row._id}`}
        />,
        <GridActionsCellItem
          showInMenu
          sx={{ "& .MuiListItemIcon-root": { minWidth: 0 } }}
          icon={<Icon fontSize="small">edit</Icon>}
          label={t("Edit")}
          onClick={() => {
            editOrderStatusForm.reset({ ...row });
            setSelectedOrderStatus(row);
            toggleEditOrderStatusDialog();
          }}
        />,
        <GridActionsCellItem
          showInMenu
          sx={{ "& .MuiListItemIcon-root": { minWidth: 0 } }}
          icon={<Icon fontSize="small">delete</Icon>}
          label={t("Delete")}
          onClick={() => {
            setSelectedOrderStatus(row);
            toggleDeleteOrderStatusDialog();
          }}
        />,
      ],
    },
  ];

  return columns;
};

export default getOrderStatusTableColumns;
