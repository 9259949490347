// ** React Imports
import { useState, useEffect, useCallback, useMemo, ChangeEvent } from "react";
import { useSearchParams } from "react-router-dom";

// ** Utilities Imports
import debounce from "lodash.debounce";

// ** MUI Imports
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { SelectChangeEvent } from "@mui/material/Select";
import { DataGrid, GridColumns } from "@mui/x-data-grid";

// ** Third Party Imports
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

// ** Store Imports
import { useDispatch, useSelector } from "react-redux";

// ** Actions Imports
import {
  addCustomer,
  deleteCustomer,
  editCustomer,
  fetchData,
} from "../../store/apps/customer";

// ** Custom Components Imports
import { TableHeader } from "../../comps/customers/list/tableHeader";
import { CustomerFormDialog } from "../../comps/customers/customerDialog";
import DeleteCustomerDialog from "../../comps/customers/deleteCustomerDialog";

// ** Types Imports
import { RootState, AppDispatch } from "../../store";
import {
  Customer,
  CustomerData,
  customerSchema,
} from "../../types/customerTypes";
import { clearSpace } from "../../helpers/clearSpace";
import { useAlertContext } from "../../hooks/useAlertContext";
import CustomerListFilters from "../../comps/customers/list/customer-list-filters";
import { format } from "date-fns";
import { useAbility } from "@casl/react";
import { AbilityContext } from "../../comps/layouts/components/acl/Can";
import getCustomerListTableColumns from "../../comps/customers/list/customer-list-table-columns";

export const defaultCustomerFormValues = {
  firstName: "",
  lastName: "",
  isWholesaleCustomer: false,
  email: "",
  password: "",
  phone: "",
  company: {
    name: "",
    ico: "",
    dic: "",
    icdph: "",
  },
  billingAddress: [
    {
      address: "",
      city: "",
      postcode: "",
      country: {
        code: "",
        label: "",
        phone: "",
      },
    },
  ],
  shippingAddress: [],
  gdprConsent: false,
  marketingConsent: false,
  isRegistered: false,
  isVerified: false,
  discount: {
    value: 0,
    prefix: "%",
  },
};

export const CustomerListScreen = () => {
  // ** States
  const [searchValue, setSearchValue] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(10);
  const [deleteCustomerOpen, setDeleteCustomerOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<Customer>();
  const [addCustomerOpen, setAddCustomerOpen] = useState<boolean>(false);
  const [editCustomerOpen, setEditCustomerOpen] = useState<boolean>(false);
  const [isCustomerLoading, setIsCustomerLoading] = useState<boolean>(true);
  const [customerIsRegisteredFilterValue, setCustomerIsRegisteredFilterValue] =
    useState<string>("");
  const [tableData, setTableData] = useState<Customer[]>([]);

  // ** Hooks
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const store = useSelector((state: RootState) => state.customer);
  const addCustomerForm = useForm<CustomerData>({
    defaultValues: defaultCustomerFormValues,
    resolver: yupResolver(customerSchema),
  });
  const editCustomerForm = useForm<CustomerData>({
    defaultValues: defaultCustomerFormValues,
    resolver: yupResolver(customerSchema),
    context: {
      editMode: true,
    },
  });
  const { showErrorAlert, showSuccessAlert } = useAlertContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const ability = useAbility(AbilityContext);

  const debounceFetchData = useMemo(
    () =>
      debounce(async (val: string) => {
        setIsCustomerLoading(true);
        await dispatch(
          fetchData({
            search: val,
          })
        );
        setIsCustomerLoading(false);
      }, 1000),
    [dispatch]
  );

  useEffect(() => {
    debounceFetchData(searchValue);
  }, [debounceFetchData, searchValue]);

  const handleSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value as string);
  };

  const customerIsRegisteredFilters = ["registered", "guest"];

  const handleCustomerIsRegisteredFilterValue = (e: SelectChangeEvent) => {
    const { value } = e.target;
    setCustomerIsRegisteredFilterValue(value);

    const params = Object.fromEntries(searchParams.entries());

    if (value) {
      setSearchParams({ ...params, status: value });
    } else {
      // @ts-ignore
      const { status, ...rest } = params;
      setSearchParams(rest);
    }
  };

  const toggleAddCustomerDialog = () => setAddCustomerOpen(!addCustomerOpen);

  const toggleEditCustomerDialog = useCallback(() => {
    setEditCustomerOpen(!editCustomerOpen);
  }, [editCustomerOpen]);

  const toggleDeleteCustomerDialog = useCallback(() => {
    setDeleteCustomerOpen(!deleteCustomerOpen);
  }, [deleteCustomerOpen]);

  const handleAddCustomer = async (data: CustomerData) => {
    setIsCustomerLoading(true);
    await dispatch(addCustomer({ ...data }))
      .unwrap()
      .then(() => {
        addCustomerForm.reset();
        showSuccessAlert(t("Customer created"));
      })
      .catch(() => {
        showErrorAlert(t("Failed to create Customer"));
      })
      .finally(() => {
        toggleAddCustomerDialog();
        setIsCustomerLoading(false);
      });
  };

  const handleEditCustomer = async (data: CustomerData) => {
    if (selectedCustomer) {
      const isEmpty = clearSpace(String(data.password)).length === 0;

      // prevents empty string from being sent for update
      if (isEmpty || !data.password) {
        delete data.password;
      }

      setIsCustomerLoading(true);
      await dispatch(editCustomer({ id: selectedCustomer._id, data }))
        .unwrap()
        .then(() => {
          editCustomerForm.reset();
          showSuccessAlert(t("Customer updated"));
        })
        .catch(() => {
          showErrorAlert(t("Failed to update Customer"));
        })
        .finally(() => {
          toggleEditCustomerDialog();
          setIsCustomerLoading(false);
        });
    }
  };

  const handleDeleteCustomer = async () => {
    if (selectedCustomer) {
      setIsCustomerLoading(true);
      await dispatch(deleteCustomer(selectedCustomer._id))
        .unwrap()
        .then(() => {
          showSuccessAlert(t("Customer deleted"));
        })
        .catch(() => {
          showErrorAlert(t("Failed to delete Customer"));
        })
        .finally(() => {
          toggleDeleteCustomerDialog();
          setIsCustomerLoading(false);
        });
    }
  };

  // data init / filtering
  useEffect(() => {
    let data = store.data;

    if (customerIsRegisteredFilterValue) {
      data = data.filter((t: Customer) => {
        if (customerIsRegisteredFilterValue === "registered") {
          return t.isRegistered === true;
        }

        if (customerIsRegisteredFilterValue === "guest") {
          return t.isRegistered === false;
        }

        // if no match, dont return item
        return false;
      });
    }

    setTableData(data);
  }, [customerIsRegisteredFilterValue, store.data]);

  // use search params to update filters
  useEffect(() => {
    const params = Object.fromEntries(searchParams.entries());

    if (Object.hasOwn(params, "status")) {
      setCustomerIsRegisteredFilterValue(params["status"]);
    }
  }, [searchParams]);

  const columns = useMemo<GridColumns<Customer>>(
    () =>
      getCustomerListTableColumns({
        t,
        setSelectedCustomer,
        editCustomerForm,
        toggleEditCustomerDialog,
        toggleDeleteCustomerDialog,
        ability,
      }),
    [ability, editCustomerForm, t, toggleDeleteCustomerDialog, toggleEditCustomerDialog]
  );

  const prepareExportData = () => {
    const data = tableData.map((item) => {
      // @ts-ignore
      const { timelineEntries, company, password, __V, ...relevantData } = item;

      return {
        ...relevantData,
      };
    })

    return data
  }

  const exportData = useMemo(prepareExportData, [tableData]);
  const exportFilename = "customers-" + format(new Date(), "dd-MM-yy");

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <CustomerListFilters
          {...{
            t,
            customerIsRegisteredFilterValue,
            handleCustomerIsRegisteredFilterValue,
            customerIsRegisteredFilters,
            searchValue,
            handleSearchValue,
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Card>
          <DataGrid
            autoHeight
            disableSelectionOnClick
            rows={tableData}
            getRowId={(row) => row._id}
            columns={columns}
            loading={isCustomerLoading}
            pagination
            rowsPerPageOptions={[10, 25, 50]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
            components={{ Toolbar: TableHeader }}
            componentsProps={{
              toolbar: {
                toggle: toggleAddCustomerDialog,
                exportData,
                exportFilename,
              },
            }}
            sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
          />
        </Card>
      </Grid>

      <CustomerFormDialog
        title={t("Add customer")}
        form={addCustomerForm}
        open={addCustomerOpen}
        toggle={toggleAddCustomerDialog}
        onSubmit={handleAddCustomer}
      />

      {selectedCustomer && (
        <CustomerFormDialog
          title={t("Edit customer")}
          form={editCustomerForm}
          open={editCustomerOpen}
          toggle={toggleEditCustomerDialog}
          onSubmit={handleEditCustomer}
        />
      )}

      {selectedCustomer && (
        <DeleteCustomerDialog
          open={deleteCustomerOpen}
          handleClose={toggleDeleteCustomerDialog}
          handleDelete={handleDeleteCustomer}
          customer={`${selectedCustomer.firstName} ${selectedCustomer.lastName}`}
        />
      )}
    </Grid>
  );
};
