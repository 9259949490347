import { ButtonBase, Icon } from "@mui/material";
import React from "react";

interface Props {
  // setIsCropMode: React.Dispatch<React.SetStateAction<boolean>>;
  handleWallpaperUpdate: (data: any) => void;
}
const WallpaperSelectBaseOverlay = (props: Props) => {
  const { handleWallpaperUpdate } = props;

  const wallpaperUpdate = () => {
    handleWallpaperUpdate({ isCropMode: true });
  };

  return (
    <ButtonBase
      onClick={wallpaperUpdate}
      sx={{
        height: "100%",
        maxHeight: "450px",
        width: "inherit",
        position: "absolute",
        display: "grid",
        placeItems: "center",
        background: "rgba(0, 0, 0, 0.2)",
        borderRadius: 2,
        marginTop: { xs: "58px", md: 0 },

        "&:hover": {
          "& span": {
            display: "block",
          },
        },
      }}
    >
      <Icon fontSize="large" sx={{ color: "white", display: "none" }}>
        edit
      </Icon>
    </ButtonBase>
  );
};

export default WallpaperSelectBaseOverlay;
