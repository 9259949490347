import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Grid } from "@mui/material";
import { Control, FieldErrors, UseFormSetValue } from "react-hook-form";
import { ProductFormInputs } from "../../../../types/productTypes";
import { updatePriceValueWith } from "../utils/updatePriceValueWith";
import PriceAccordion from "../PriceAccordion";
import { MarginInput, MarginPrefixInput } from "../MarginFields";
import {
  CallbackCalculateTotalWithTax,
  RoundToDecimal,
} from "../utils/helpers";
import ProductTaxFields from "../ProductTaxFields";
import { PricingDivider } from "../SectionDivider";

interface Props {
  control: Control<ProductFormInputs, any>;
  t: any;
  errors: FieldErrors<ProductFormInputs>;

  setValue: UseFormSetValue<ProductFormInputs>;

  watchWholesaleDiscount: ProductFormInputs["wholesaleDiscount"];
  watchWholesaleCustomerMargin: ProductFormInputs["wholesaleCustomerMargin"];

  watchPrice: number;
  tax: number;
}
// Component for rendering a section with wholesale and retail discount fields
export const RegisteredWholesaleCustomersOnSaleFields = ({
  control,
  t,
  errors,

  watchPrice,

  watchWholesaleDiscount,
  watchWholesaleCustomerMargin,

  tax,
  setValue,
}: Props) => {
  const [
    totalPriceWithWholesaleCustomerMargin,
    setTotalPriceWithWholesaleCustomerMargin,
  ] = useState(0);

  const [totalPriceWithWholesaleDiscount, setTotalPriceWithWholesaleDiscount] =
    useState(0);
  const [
    totalPriceWithWholesaleDiscountWithTax,
    setTotalPriceWithWholesaleDiscountWithTax,
  ] = useState(0);

  const calculateTotalWithTax = useCallback(
    (
      priceWithMargin: number,
      setTotalPriceWithTax: Dispatch<SetStateAction<number>>,
      tax: number
    ) =>
      CallbackCalculateTotalWithTax(priceWithMargin, setTotalPriceWithTax, tax),
    []
  );

  const handleTaxFieldsOnChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    setState: Dispatch<SetStateAction<number>>
  ) => {
    setState(Number(event.currentTarget.value));
  };

  const handleTaxFieldsOnBlur = (
    totalDisountPrice: number,
    totalPrice: number,
    setTotalDiscountPrice: Dispatch<SetStateAction<number>>,
    marginPrefix: string,
    formValue: string
  ) => {
    if (totalDisountPrice < totalPrice) {
      setTotalDiscountPrice(totalDisountPrice);

      let newDiscount = totalPrice - totalDisountPrice;

      if (marginPrefix === "%") {
        newDiscount = (newDiscount * 100) / totalPrice;
      }

      newDiscount = RoundToDecimal(newDiscount, 2);
      setValue(formValue, newDiscount);
    } else {
      setTotalDiscountPrice(totalPrice);
      setValue(formValue, 0);
    }
  };

  const handleTaxFieldsOnBlurWithTax = (
    totalDisountPriceWithTax: number,
    totalPrice: number,
    setTotalDiscountPrice: Dispatch<SetStateAction<number>>,
    setTotalDiscountPriceWithTax: Dispatch<SetStateAction<number>>,
    marginPrefix: string,
    formValue: string
  ) => {
    const _tax = totalPrice * (tax / 100);
    let totalPriceWithTax = _tax + totalPrice;
    totalPriceWithTax = RoundToDecimal(totalPriceWithTax, 2);

    if (totalDisountPriceWithTax <= totalPriceWithTax) {
      setTotalDiscountPriceWithTax(totalDisountPriceWithTax);

      let totalDiscountPrice = totalDisountPriceWithTax / ((100 + tax) / 100);
      totalDiscountPrice = RoundToDecimal(totalDiscountPrice, 2);

      setTotalDiscountPrice(totalDiscountPrice);
      let newDiscount = totalPrice - totalDiscountPrice;

      if (marginPrefix === "%") {
        newDiscount = (newDiscount * 100) / totalPrice;
      }

      newDiscount = RoundToDecimal(newDiscount, 2);
      setValue(formValue, newDiscount);
    } else {
      setTotalDiscountPriceWithTax(totalPriceWithTax);
      setValue(formValue, 0);
    }
  };

  // update total Price With Wholesale Customer Margin
  useEffect(() => {
    const margin = updatePriceValueWith(
      watchPrice,
      watchWholesaleCustomerMargin.value,
      watchWholesaleCustomerMargin.prefix
    );
    let total = watchPrice + margin;
    total = RoundToDecimal(total, 2);

    setTotalPriceWithWholesaleCustomerMargin(total);
  }, [watchPrice, watchWholesaleCustomerMargin]);

  // update total Price With wholesale discount
  useEffect(() => {
    const discount = updatePriceValueWith(
      totalPriceWithWholesaleCustomerMargin,
      watchWholesaleDiscount.value,
      watchWholesaleDiscount.prefix
    );
    let total = totalPriceWithWholesaleCustomerMargin - discount;
    total = RoundToDecimal(total, 2);

    setTotalPriceWithWholesaleDiscount(total);
  }, [totalPriceWithWholesaleCustomerMargin, watchWholesaleDiscount]);

  // Below  calculates total prices with tax
  useEffect(() => {
    calculateTotalWithTax(
      totalPriceWithWholesaleDiscount,
      setTotalPriceWithWholesaleDiscountWithTax,
      tax
    );
  }, [tax, totalPriceWithWholesaleDiscount, calculateTotalWithTax]);

  return (
    <Grid item container xs={12} sm={6} spacing={5}>
      <PricingDivider caption={t("Discount")} />

      {/* Wholesale Discount */}
      <Grid item container xs={12} spacing={5}>
        <ProductTaxFields
          t={t}
          value={totalPriceWithWholesaleDiscount}
          valueWithTax={totalPriceWithWholesaleDiscountWithTax}
          onChange={(event) =>
            handleTaxFieldsOnChange(event, setTotalPriceWithWholesaleDiscount)
          }
          onChangeWithTax={(event) =>
            handleTaxFieldsOnChange(
              event,
              setTotalPriceWithWholesaleDiscountWithTax
            )
          }
          onBlur={(event) => {
            handleTaxFieldsOnBlur(
              totalPriceWithWholesaleDiscount,
              totalPriceWithWholesaleCustomerMargin,
              setTotalPriceWithWholesaleDiscount,
              watchWholesaleDiscount.prefix,
              "wholesaleDiscount.value"
            );
          }}
          onBlurWithTax={(event) => {
            handleTaxFieldsOnBlurWithTax(
              totalPriceWithWholesaleDiscountWithTax,
              totalPriceWithWholesaleCustomerMargin,
              setTotalPriceWithWholesaleDiscount,
              setTotalPriceWithWholesaleDiscountWithTax,
              watchWholesaleDiscount.prefix,
              "wholesaleDiscount.value"
            );
          }}
        />
        <Grid item xs={12}>
          <PriceAccordion>
            <>
              <MarginPrefixInput
                label={t("Discount type")}
                namePrefix="wholesaleDiscount.prefix"
                {...{ errors, control, t }}
              />

              <MarginInput
                label={t("Wholesale discount")}
                name="wholesaleDiscount.value"
                prefix={watchWholesaleDiscount.prefix}
                {...{ errors, control, t }}
              />
            </>
          </PriceAccordion>
        </Grid>
      </Grid>
    </Grid>
  );
};
