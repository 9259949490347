import React, { MouseEvent, useState } from "react";
import Translations from "../layouts/components/Translations";
import { Button, ButtonProps, Icon, Menu, MenuItem } from "@mui/material";
import { TableExporterT, supportedTableExports, tableExporter } from "../../utils/table-exporter";

interface Props extends Omit<ButtonProps, "onClick"> {
  exportData: any[];
  exportFilename: string;
}

// const supportedTypes =  TableExporterT;

const TableExporter = (props: Props) => {
  const { exportData, exportFilename, ...rest } = props;

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const data = exportData;
  const filename = exportFilename;
  const exportFile = (type: TableExporterT) => {
    tableExporter(type, data, filename)
  }

  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        startIcon={<Icon>ios_share</Icon>}
        onClick={handleMenuClick}
        aria-expanded={Boolean(menuAnchorEl) ? "true" : undefined}
        aria-controls={
          Boolean(menuAnchorEl) ? "table-export-options" : undefined
        }
        {...rest}
      >
        <Translations text="Export" />
      </Button>

      <Menu
        open={Boolean(menuAnchorEl)}
        anchorEl={menuAnchorEl}
        onClose={handleMenuClose}
        id="table-export-options"
      >
        {supportedTableExports.map((type) => (
          <MenuItem key={type} onClick={() => exportFile(type)}>
            <Translations text={`Download as .${type}`} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default TableExporter;
