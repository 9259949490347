import { useEffect } from "react";

// Custom hook to control order item error variables

export function useOrderItemErrors(
  orderItemDimensions: {
    width: number;
    height: number;
  },
  orderItemWidthInterval: number[],
  orderItemHeightInterval: number[],
  handleWallpaperDetailsUpdate: (data: any) => void,
) {
  useEffect(() => {
    const checkDimensionError = (dimension: number, interval: number[]) => {
      const isError = dimension < Math.min(...interval) || dimension > Math.max(...interval);

      return isError;
    };

    const { width, height } = orderItemDimensions;

    const isWidthError = checkDimensionError(width, orderItemWidthInterval);
    const widthErrorText = `Hodnota musí byť v intervale ${orderItemWidthInterval.join(
      " až "
    )}.`;
    const isHeightError = checkDimensionError(height, orderItemHeightInterval);
    const heightErrorText = `Hodnota musí byť v intervale ${orderItemHeightInterval.join(
      " až "
    )}.`;

    const _isProductDimensionsError = {
      width: { value: isWidthError, msg: isWidthError ? widthErrorText : "" },

      height: {
        value: isHeightError,
        msg: isHeightError ? heightErrorText : "",
      },
    };

    handleWallpaperDetailsUpdate({
      isOrderItemDimensionsError: _isProductDimensionsError
    })
    }, [
    orderItemDimensions,
    orderItemHeightInterval,
    orderItemWidthInterval,
    handleWallpaperDetailsUpdate,
  ]);
}
