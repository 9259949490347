import { Card, Box, CardContent, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ProductT } from "../../../types/productTypes";
import { format } from "date-fns";

interface Props {
  product: ProductT;
  isVariobelOnlyField: boolean,
}

const ViewProductInfo = (props: Props) => {
  const { product, isVariobelOnlyField } = props;
  const { t } = useTranslation();

  return (
    <Card>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          ml: 4
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="h6" component="p">
            {t("Description: ")}

            <Typography component="span">{product.description}</Typography>
          </Typography>
        </Box>

        {isVariobelOnlyField && (
          <Box
            py={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 4,
            }}
          >
            {product.onSale && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ mr: 2, color: "text.primary" }}
                  >
                    {`${t("Sale Price")}:`}
                  </Typography>

                  <Typography variant="body2">
                    {`${product.saleDiscount.prefix} ${product.saleDiscount.value}`}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ mr: 2, color: "text.primary" }}
                  >
                    {`${t("Sale Ends")}:`}
                  </Typography>

                  <Typography variant="body2">
                    {product.saleEnds
                      ? format(
                          new Date(product.saleEnds as Date),
                          "MMM d, yyyy"
                        )
                      : "NA"}
                  </Typography>
                </Box>
              </>
            )}

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{ mr: 2, color: "text.primary" }}
              >
                {`${t("Registered Discount")}:`}
              </Typography>

              <Typography variant="body2">
                {product.customerDiscount.prefix}
                {product.customerDiscount.value}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{ mr: 2, color: "text.primary" }}
              >
                {`${t("Wholesale Discount")}:`}
              </Typography>

              <Typography variant="body2">
                {product.wholesaleDiscount.prefix}
                {product.wholesaleDiscount.value}
              </Typography>
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default ViewProductInfo;
