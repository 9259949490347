// ** React Imports
import { useState, MouseEvent } from "react";

// ** MUI Imports
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha } from "@mui/material/styles";

// ** Third Party Imports
import { useTranslation } from "react-i18next";

// ** Store & Actions Imports
import { useDispatch } from "react-redux";
import { updateOrder } from "../../../store/apps/order";

// ** Types Imports
import { AppDispatch } from "../../../store";
import { OrderType } from "../../../types/orderTypes";
import { OrderStatus } from "../../../types/orderStatusTypes";
import { ThemeColor } from "../../@core/layouts/types";

// ** Custom Components Imports
import { Chip } from "../../chip";

// ** Custom Hook Imports
import { useAlertContext } from "../../../hooks/useAlertContext";
import { Box, CircularProgress } from "@mui/material";
import { sendOrderStatusEmail } from "../../../store/apps/orderStatus";

interface Props {
  row: OrderType;
  orderStatuses: OrderStatus[];
  onStatusChangeCallback?: () => void;
}

const DropdownOrderStatusChip = (props: Props) => {
  const { row, orderStatuses, onStatusChangeCallback } = props;

  // ** States
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isChanging, setIsChanging] = useState(false);

  // ** Hooks
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { showSuccessAlert, showErrorAlert } = useAlertContext();

  const handleOpenMenu = (e: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleStatusNotifications = (order: OrderType) => {
    if (order.orderStatus.notifyCustomer) {
      dispatch(
        sendOrderStatusEmail({
          orderID: order._id,
          recipient: order.customer.email,
        })
      )
      .unwrap()
      .then(() => {
        showSuccessAlert(t('Customer Status Email Sent'))
      })
      .catch(() => {
        showErrorAlert(t('Failed to Send Customer Status Email, Check Console'))
      })
    }

    if (order.orderStatus.notifyAdmin) {
      dispatch(
        sendOrderStatusEmail({
          orderID: order._id,
          recipient: 'info@variobel.sk',
        })
      )
      .unwrap()
      .then(() => {
        showSuccessAlert(t('Admin Status Email Sent'))
      })
      .catch(() => {
        showErrorAlert(t('Failed to Send Admin Status Email, Check Console'))
      })
    }
  };

  const handleOrderStatusChange = (newOrderStatus: OrderStatus) => {
    if (row.orderStatus && row.orderStatus.name !== newOrderStatus.name) {
      setIsChanging(true);

      dispatch(
        updateOrder({
          id: row._id,
          data: {
            orderStatus: newOrderStatus._id,
            orderStatusHistory: [
              ...row.orderStatusHistory,
              {
                status: newOrderStatus._id,
                modifiedOn: new Date(),
              },
            ],
          },
        })
      )
        .unwrap()
        .then((res) => {
          showSuccessAlert(t("Order has been updated"));
          onStatusChangeCallback && onStatusChangeCallback();

          const data = res.order as OrderType;
          handleStatusNotifications(data);
        })
        .catch((err) => {
          console.log(err);
          showErrorAlert(t("Error during update! Check console"));
        })
        .finally(() => {
          setIsChanging(false);
        });
    }
  };

  return (
    <>
      <Chip
        id={row._id}
        skin="light"
        size="small"
        label={
          row.orderStatus ? (
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, py: 1 }}>
              {row.orderStatus.name}
              {isChanging && (
                <CircularProgress
                  color={row.orderStatus.colorCode as ThemeColor}
                  style={{ width: 18, height: 18 }}
                />
              )}
            </Box>
          ) : (
            "undefined"
          )
        }
        color={
          (row.orderStatus ? row.orderStatus.colorCode : "error") as ThemeColor
        }
        sx={{
          textTransform: "capitalize",
          "& .MuiChip-label": { lineHeight: "18px" },
          "&:hover": {
            backgroundColor: (theme) =>
              alpha(
                theme.palette[
                  row.orderStatus && row.orderStatus.colorCode
                    ? (row.orderStatus.colorCode as ThemeColor)
                    : "error"
                ].dark,
                0.12
              ),
          },
        }}
        onClick={handleOpenMenu}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": row._id,
        }}
      >
        {orderStatuses.map((orderStatus) => (
          <MenuItem
            key={orderStatus._id}
            sx={{ textTransform: "capitalize" }}
            onClick={() => {
              handleOrderStatusChange(orderStatus);
              handleCloseMenu();
            }}
          >
            {t(orderStatus.name)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default DropdownOrderStatusChip;
