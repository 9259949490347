// ** MUI Imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";

// ** Third Party Imports
import { useTranslation } from "react-i18next";

// ** Custom Component Imports
import { ProductCard } from "../../products/product-card";

// ** Type Imports
import { ProductType } from "../../../types/productTypes";
import { BasketPriceTypeTemp } from "../../../types/orderTypes";
import { Customer } from "../../../types/customerTypes";

interface SelectProductDialogProps {
  products: ProductType[];
  handleSelectProduct: (newProduct: ProductType) => void;
  open: boolean;
  onClose: () => void;
  customer: Customer;
  basketPrice?: BasketPriceTypeTemp;
}

export const SelectProductDialog = ({
  products,
  handleSelectProduct,
  open,
  onClose,
  customer,
  basketPrice
}: SelectProductDialogProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      maxWidth="lg"
      onClose={onClose}
      aria-labelledby=""
      fullWidth={true}
    >
      <DialogTitle id="">
        <Box display="flex" justifyContent="center" alignItems="center">
          {t("Product selection")}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={5} sx={{ my: 2 }}>
          {products.map((product, index) => (
            <Grid key={product._id} item xl={3} md={4} xs={12}>
              <ProductCard
                product={product}
                handleSelectProduct={handleSelectProduct}
                customer={customer}
                basketPrice={basketPrice}
                key={index}
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
