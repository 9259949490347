import {
  Dispatch,
  MouseEvent,
  SetStateAction, useState
} from "react";
import {
  IconButton,
  Icon,
  Menu,
  MenuItem, Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  LinearProgress
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { OrderInvoiceCard } from "./invoiceCard";
import { OrderType } from "../../../../../types/orderTypes";
import { AppDispatch } from "../../../../../store";
import { useAlertContext } from "../../../../../hooks/useAlertContext";
import { sendOrderStatusEmail } from "../../../../../store/apps/orderStatus";
import OrderDiscountCard from "./orderDiscountCard";

interface ActionsProps {
  data: OrderType;
  setData: Dispatch<SetStateAction<OrderType | null>>;
  handleEditOrderClick: () => void;
}
export const Actions = ({ data, setData, handleEditOrderClick }: ActionsProps) => {
  const orderID = data._id;
  const recipient = data.customer.email;

  const [invoiceOpen, setInvoiceOpen] = useState(false);
  const [orderDiscountOpen, setOrderDiscountOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [sendOrderSummaryOpen, setSendOrderSummaryOpen] = useState(false);
  const [sendingOrderSummary, setSendingOrderSummary] = useState(false);

  const { t } = useTranslation();
  const { showSuccessAlert, showErrorAlert } = useAlertContext();
  const dispatch = useDispatch<AppDispatch>();

  const handleRowOptionsClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRowOptionsClose = () => {
    setAnchorEl(null);
  };

  const toggleInvoiceOpen = () => setInvoiceOpen((prev) => !prev);
  const toggleOrderDiscountOpen = () => setOrderDiscountOpen((prev) => !prev);
  const handleSendOrderSummaryOpen = () => setSendOrderSummaryOpen(true);
  const handleSendOrderSummaryClose = () => setSendOrderSummaryOpen(false);

  const handleSendOrderSummary = () => {
    setSendingOrderSummary(true);

    dispatch(
      sendOrderStatusEmail({
        orderID,
        recipient,
      })
    )
      .unwrap()
      .then(() => {
        showSuccessAlert(t('Customer Status Email Sent'));
        handleSendOrderSummaryClose();
      })
      .catch(() => {
        showErrorAlert(t('Failed to Send Customer Status Email, Check Console'));
      })
      .finally(() => {
        setSendingOrderSummary(false);
      });
  };

  return (
    <>
      <IconButton size="small" onClick={handleRowOptionsClick}>
        <Icon>more_vert</Icon>
      </IconButton>

      <Menu
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleRowOptionsClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleEditOrderClick}>
          {t("Edit order")}
        </MenuItem>
        <MenuItem onClick={toggleOrderDiscountOpen}>
          {t("Discount order")}
        </MenuItem>
        <MenuItem onClick={toggleInvoiceOpen}>{t("View Invoice")}</MenuItem>
        <MenuItem onClick={handleSendOrderSummaryOpen}>
          {t("Send order summary")}
        </MenuItem>
      </Menu>

      <Dialog
        fullWidth
        maxWidth="md"
        scroll="body"
        open={invoiceOpen}
        onClose={toggleInvoiceOpen}
      >
        <OrderInvoiceCard {...{ data }} />
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        scroll="body"
        open={orderDiscountOpen}
        onClose={toggleOrderDiscountOpen}
      >
        <OrderDiscountCard
          {...{ data, setData, handleClose: toggleOrderDiscountOpen }} />
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        scroll="body"
        open={sendOrderSummaryOpen}
        onClose={handleSendOrderSummaryClose}
      >
        <DialogTitle>
          {t("Send order summary")}
          <IconButton
            size="small"
            onClick={handleSendOrderSummaryClose}
            sx={{ position: "absolute", right: "1rem", top: "1rem" }}
          >
            <Icon>close</Icon>
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <DialogContentText>
            {t(
              "Are you sure about sending the updated order details to the client?"
            )}
          </DialogContentText>

          {sendingOrderSummary && <LinearProgress sx={{ mt: 4 }} />}
        </DialogContent>

        <DialogActions>
          <Button
            size="large"
            type="submit"
            sx={{ mr: 2 }}
            variant="contained"
            onClick={handleSendOrderSummary}
          >
            {t("Yes")}
          </Button>
          <Button
            size="large"
            color="secondary"
            variant="outlined"
            onClick={handleSendOrderSummaryClose}
          >
            {t("No")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
