export const sortByKey = (key: string, order: boolean) => (a: any, b: any) => {
  const x = a[key];
  const y = b[key];

  // descending
  if (!order) {
    if (x < y) return 1;
    if (x > y) return -1;
  }

  // ascending
  if (order) {
    if (x < y) return -1;
    if (x > y) return 1;
  }

  // default
  return x - y;
};