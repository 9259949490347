import { Dispatch, SetStateAction } from "react";

import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  Select,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";

import { DataGrid, GridColumns } from "@mui/x-data-grid";

import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

import DatePickerWrapper from "../../../comps/@core/styles/libs/react-datepicker";

import useOrderStatuses from "../../../hooks/useOrderStatuses";

import { DateType } from "../../../types/reactDatepickerTypes";
import { OrderType } from "../../../types/orderTypes";
import DatePickerInput from "../../../comps/utilities/date-picker-input";
import { currencyFormatter } from "../../../helpers/currency-formatter";

interface Props {
  totalArea: number;
  totalPrice: number;
  startDateRange: DateType;
  endDateRange: DateType;
  handleOnChangeRange: (dates: any) => void;
  statusValue: string;
  tableData: OrderType[];
  dates: Date[];
  setDates: Dispatch<SetStateAction<Date[]>>;
  handleStatusValue: (e: SelectChangeEvent) => void;
  isTableLoading: boolean;
  columns: GridColumns<OrderType>;
  pageSize: number;
  setPageSize: Dispatch<SetStateAction<number>>;
}

const ViewProductOrders = (props: Props) => {
  const {
    totalArea,
    totalPrice,
    startDateRange,
    endDateRange,
    handleOnChangeRange,
    statusValue,
    tableData,
    dates,
    setDates,
    handleStatusValue,
    isTableLoading,
    columns,
    pageSize,
    setPageSize,
  } = props;

  const { t } = useTranslation();
  const orderStatusesObj = useOrderStatuses();

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title={t("Filter")} />

          <CardContent>
            <Grid container spacing={6}>
              <Grid item xs={12} sm={6}>
                <DatePickerWrapper>
                  <DatePicker
                    isClearable
                    selectsRange
                    monthsShown={2}
                    endDate={endDateRange}
                    selected={startDateRange}
                    startDate={startDateRange}
                    shouldCloseOnSelect={false}
                    id="date-range-picker-months"
                    onChange={handleOnChangeRange}
                    customInput={
                      <DatePickerInput
                        dates={dates}
                        setDates={setDates}
                        label={t("Dates (range)")}
                        end={endDateRange as number | Date}
                        start={startDateRange as number | Date}
                      />
                    }
                  />
                </DatePickerWrapper>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="order-status-select">
                    {t("Status")}
                  </InputLabel>

                  <Select
                    fullWidth
                    value={statusValue}
                    sx={{ mr: 4 }}
                    label={t("Status")}
                    onChange={handleStatusValue}
                    labelId="order-status-select"
                  >
                    <MenuItem value="">None</MenuItem>
                    {Object.keys(orderStatusesObj).map((orderStatus, i) => (
                      <MenuItem
                        key={i}
                        value={orderStatus}
                        sx={{ textTransform: "capitalize" }}
                      >
                        {orderStatus}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <DataGrid
            autoHeight
            initialState={{
              sorting: {
                sortModel: [{ field: "lastModified", sort: "desc" }],
              },
            }}
            rows={tableData}
            getRowId={(row) => row._id}
            columns={columns}
            loading={isTableLoading}
            disableSelectionOnClick
            pagination
            rowsPerPageOptions={[10, 25, 50]}
            pageSize={Number(pageSize)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            sx={{
              "& .MuiDataGrid-columnHeaders": { borderRadius: 0 },
            }}
          />
        </Card>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "baseline",
          gap: 3,
        }}
        mt={-3}
        mr={4}
      >
        <Typography variant="body2" fontWeight={600}>
          <Typography
            component="span"
            variant="subtitle2"
            fontWeight={400}
            fontSize="0.725rem"
          >
            Total Price:
          </Typography>{" "}
          {currencyFormatter(totalPrice)}
        </Typography>

        <Typography variant="body2" fontWeight={600}>
          <Typography
            component="span"
            variant="subtitle2"
            fontWeight={400}
            fontSize="0.725rem"
          >
            Total Area:
          </Typography>{" "}
          {totalArea.toFixed(2)} m<sup>2</sup>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ViewProductOrders;
