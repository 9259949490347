import { useEffect } from "react";
import { AddOrderListLineItemType, LineItemType } from "../../../../../../../../../types/orderTypes";

// Custom hook to control crop area aspect ratio

export function useCropAspectRatio(
  currentOrderItem: LineItemType | AddOrderListLineItemType | undefined,
  doorsToSpread: number,
  handleWallpaperUpdate: (data: any) => void,
  spreadWallpaper: boolean
) {
  useEffect(() => {
    if (currentOrderItem) {
      const { glassWidth, glassHeight } = currentOrderItem;
      let width = glassWidth;

      if (spreadWallpaper) {
        width *= doorsToSpread;
      }

      const cropAspectRatio = width / glassHeight;

      handleWallpaperUpdate({
        cropAspectRatio
      })
    }
  }, [currentOrderItem, doorsToSpread, handleWallpaperUpdate, spreadWallpaper]);
}
