import React from 'react';
import {
  Grid,
  FormControl,
  FormHelperText, TextField
} from '@mui/material';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { ProductFormInputs } from '../../../types/productTypes';
import DatePicker from 'react-datepicker';

interface Props { name: string; control: Control<ProductFormInputs, any>; label: string; errors: FieldErrors<ProductFormInputs>; watchOnSale: boolean }

// Component for rendering a date picker field
export const DatePickerField = ({ name, control, label, errors, watchOnSale }: Props) => (
  <Grid item xs={8} sm={4}>
    <FormControl fullWidth>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <DatePicker
            disabled={!watchOnSale}
            selected={value}
            showYearDropdown
            showMonthDropdown
            onChange={(e) => onChange(e)}
            placeholderText="MM/DD/YYYY"
            customInput={<TextField
              value={value ? value : null}
              onChange={onChange}
              label={label}
              error={Boolean(errors[name])}
              aria-describedby={`validation-basic-${name}`} />} />
        )} />
      {errors[name] && (
        <FormHelperText sx={{ color: "error.main" }}>
          {`This field cannot be empty`}
        </FormHelperText>
      )}
    </FormControl>
  </Grid>
);
