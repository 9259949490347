import chroma from "chroma-js";

import { Color } from "../../../../types/orderTypes";
import { IFile, PrintTabSelectorType } from "./types";
import { findClosestOnyx } from "../../../../helpers/onyx-matcher";

export const getLocalWallpapers = () => {
  const _localWallpapers: IFile[] = importAllImages(
    require.context("../../../../assets/wallpapers/", false, /\.(png|jpe?g)$/)
  );

  const localWallpapers = _localWallpapers.sort((a, b) => Number(a.order) - Number(b.order));
  return localWallpapers;
};


export const getColorFromProp = (selectedTab: PrintTabSelectorType, selectedValue: string | Color | IFile) => {
  if(selectedTab === "color") {
    return selectedValue as Color
  }

  return {
    hex: "ff0",
    onyxMatch: findClosestOnyx(chroma("#ff0").rgb())
  }
};

export const getCustomWallpaperFromProp = (selectedTab: PrintTabSelectorType, selectedValue: string | Color | IFile) => {
  if (selectedTab !== 'ownWallpaper') return null;

  return { name: "", data: selectedValue, extension: String(selectedValue).split('.').pop() } as IFile;
}

export const getLocalWallpaperFromProp = (selectedTab: PrintTabSelectorType, selectedValue: string | Color | IFile, localWallpapers: IFile[]) => {
  if (selectedTab !== 'wallpaper') {
    return localWallpapers[0];

  }

  const order = localWallpapers.find((wall) => wall.name === selectedValue)?.order

  const wallpaper =  { name: selectedValue, order } as IFile;
  return { ...wallpaper };
}

export const importAllImages = (r: any) => {
  return r.keys().map((item: string) => {
    return {
      name: r(item),
      order: item.replace("./", "").replace(/\.(png|jpe?g)$/, ""), // remove file start
    };
  });
};