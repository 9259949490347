// ** React Imports
import { ReactNode, useContext } from "react";

// ** Component Imports
import { AbilityContext } from "./Can";

// ** Types
import { NavGroup, NavLink } from "../../../@core/layouts/types";
import { defaultACLObj } from "../../../../configs/acl";

interface Props {
  navGroup?: NavGroup;
  children: ReactNode;
}

const CanViewNavGroup = (props: Props) => {
  // ** Props
  const { children, navGroup } = props;

  // ** Hook
  const ability = useContext(AbilityContext);

  const checkForVisibleChild = (arr: NavLink[] | NavGroup[]): boolean => {
    return arr.some((i: NavGroup) => {
      if (i.children) {
        return checkForVisibleChild(i.children);
      } else {
        const aclAbilities = i.acl ?? defaultACLObj;

        return ability?.can(aclAbilities.action, aclAbilities.subject);
      }
    });
  };

  const canViewMenuGroup = (item: NavGroup) => {
    const hasAnyVisibleChild =
      item.children && checkForVisibleChild(item.children);

    const aclAbilities = item.acl ?? defaultACLObj;

    return (
      ability &&
      ability.can(aclAbilities.action, aclAbilities.subject) &&
      hasAnyVisibleChild
    );
  };

  return navGroup && canViewMenuGroup(navGroup) ? <>{children}</> : null;
};

export default CanViewNavGroup;
