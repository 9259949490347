// ** React Imports
import { useState, useEffect, useCallback, useMemo } from "react";

// ** MUI Imports
import { Box, Grid, Card, Icon, Typography, Button } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridRowId } from "@mui/x-data-grid";

// ** Store & Actions Imports
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAccounts,
  deleteAccount,
  disconnectImap,
  connectImap,
  disconnectSmtp,
  autoConnectSmtp,
} from "../../../store/apps/email";

// ** Types Imports
import { RootState, AppDispatch } from "../../../store";
import { MailAccountsType } from "../../../types/mailAccountsType";

// ** Third Party Styles Imports
import "react-datepicker/dist/react-datepicker.css";
import { ThemeColor } from "../../../comps/@core/layouts/types";
import { Chip } from "../../../comps/chip";
import Translations from "../../../comps/layouts/components/Translations";
import { useTranslation } from "react-i18next";
import AddAccountsDialog from "./components/AddAccountsDialog";
import DeleteAccountDialog from "./components/DeleteAccountDialog";
import { useAlertContext } from "../../../hooks/useAlertContext";

interface CellType {
  row: MailAccountsType;
}

interface StatusType {
  [key: string]: ThemeColor;
}

const mailConnectionStatusObj: StatusType = {
  connected: "success",
  disconnected: "error",
};

const MailAccounts = () => {
  // ** State
  // const [value, setValue] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(10);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<MailAccountsType>();

  const [addAccountOpen, setAddAccountOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteAccountOpen, setDeleteAccountOpen] = useState(false);

  //**translations
  const { t } = useTranslation();

  // ** Hooks
  const dispatch = useDispatch<AppDispatch>();
  const { accounts, loading } = useSelector((state: RootState) => state.email);
  const { showErrorAlert, showSuccessAlert }  = useAlertContext();

  useEffect(() => {
    dispatch(
      fetchAccounts({
        search: "",
        mailsPerPage: 10,
        page: 1,
        mailbox: 'INBOX'
      })
    );
  }, [dispatch]);

  const toggleAddAccountDialog = () => setAddAccountOpen(!addAccountOpen);

  const toggleEditDialog = () => setEditDialogOpen((prev) => !prev);

  const toggleDeleteAccountDialog = useCallback(() => {
    setDeleteAccountOpen(!deleteAccountOpen);
  }, [deleteAccountOpen]);

  const handleAccountDelete = () => {
    if (!selectedAccount) return;

    dispatch(
      deleteAccount({
        imapId: selectedAccount.imap._id,
        smtpId: selectedAccount.smtp ? selectedAccount.smtp._id : "",
      })
    )
      .unwrap()
      .then(() => {
        showSuccessAlert(t("Account deleted"));
      })
      .catch(() => {
        showErrorAlert(t("Failed to delete Account"));
      })
      .finally(() => {
        toggleDeleteAccountDialog();
      });
  };

  const handleImapDisconnect = useCallback(
    (row: MailAccountsType) => {
      dispatch(disconnectImap({ id: row.imap._id, user: row.email }))
        .unwrap()
        .then(() => {
          showSuccessAlert(t("IMAP Account disconnected"));
        })
        .catch(() => {
          showErrorAlert(t("Failed to disconnect IMAP Account"));
        });
    },
    [dispatch, showErrorAlert, showSuccessAlert, t]
  );

  const handleSmtpDisconnect = useCallback(
    (row: MailAccountsType) => {
      dispatch(disconnectSmtp({ id: row.smtp?._id ?? "", user: row.email }))
        .unwrap()
        .then(() => {
          showSuccessAlert(t("SMTP Account disconnected"));
        })
        .catch(() => {
          showErrorAlert(t("Failed to disconnect SMTP Account"));
        });
    },
    [dispatch, showErrorAlert, showSuccessAlert, t]
  );

  const handleImapConnect = useCallback(
    (row: MailAccountsType) => {
      dispatch(connectImap({ id: row.imap._id }))
        .unwrap()
        .then(() => {
          showSuccessAlert(t("IMAP Account connected"));
        })
        .catch(() => {
          showErrorAlert(t("Failed to connect IMAP Account"));
        });
    },
    [dispatch, showErrorAlert, showSuccessAlert, t]
  );

  const handleSmtpConnect = useCallback(
    (row: MailAccountsType) => {
      dispatch(autoConnectSmtp({ id: row.smtp?._id ?? "" }))
        .unwrap()
        .then(() => {
          showSuccessAlert(t("SMTP Account disconnected"));
        })
        .catch(() => {
          showErrorAlert(t("Failed to disconnect SMTP Account"));
        });
    },
    [dispatch, showErrorAlert, showSuccessAlert, t]
  );

  const columns = useMemo(
    () => [
      {
        flex: 0.25,
        minWidth: 180,
        field: "email",
        headerName: t("Email"),
        renderCell: ({ row }: CellType) => (
          <Typography variant="body2">{row.email}</Typography>
        ),
      },

      {
        flex: 0.2,
        minWidth: 120,
        field: "connected",
        headerName: t("IMAP connection"),
        renderCell: ({ row }: CellType) => (
          <Chip
            skin="light"
            size="small"
            label={row.imap.connected}
            color={mailConnectionStatusObj[row.imap.connected]}
            sx={{
              textTransform: "capitalize",
              "& .MuiChip-label": { lineHeight: "18px" },
            }}
          />
        ),
      },
      {
        flex: 0.2,
        minWidth: 120,
        field: "smtpConnected",
        headerName: t("SMTP connection"),
        renderCell: ({ row }: CellType) => (
          <Chip
            skin="light"
            size="small"
            label={row.smtp ? row.smtp.connected : "Not-Available"}
            color={
              mailConnectionStatusObj[row.smtp ? row.smtp.connected : "warning"]
            }
            sx={{
              textTransform: "capitalize",
              "& .MuiChip-label": { lineHeight: "18px" },
            }}
          />
        ),
      },
      // {
      //   flex: 0.2,
      //   minWidth: 180,
      //   field: "host",
      //   headerName: t("IMAP Host"),
      //   renderCell: ({ row }: CellType) => (
      //     <Typography variant="body2">{row.host}</Typography>
      //   ),
      // },
      // {
      //   flex: 0.2,
      //   minWidth: 180,
      //   field: "smtpHost",
      //   headerName: t("SMTP Host"),
      //   renderCell: ({ row }: CellType) => (
      //     <Typography variant="body2">{row.host}</Typography>
      //   ),
      // },
      // {
      //   flex: 0.1,
      //   minWidth: 50,
      //   field: "port",
      //   headerName: t("IMAP Port"),
      //   renderCell: ({ row }: CellType) => (
      //     <Typography variant="body2">{row.port}</Typography>
      //   ),
      // },
      // {
      //   flex: 0.1,
      //   minWidth: 50,
      //   field: "smtpPort",
      //   headerName: t("SMTP Port"),
      //   renderCell: ({ row }: CellType) => (
      //     <Typography variant="body2">{row.port}</Typography>
      //   ),
      // },
      {
        flex: 0.1,
        minWidth: 130,
        sortable: false,
        field: "actions",
        headerName: t("Actions"),
        type: "actions",
        getActions: ({ row }: CellType) => [
          <GridActionsCellItem
            sx={{ "& .MuiListItemIcon-root": { minWidth: 0 } }}
            icon={<Icon fontSize="small">delete</Icon>}
            label={t("Delete")}
            onClick={() => {
              setSelectedAccount(row);
              toggleDeleteAccountDialog();
            }}
          />,
          <GridActionsCellItem
            showInMenu
            sx={{ "& .MuiListItemIcon-root": { minWidth: 0 } }}
            label={t("Edit")}
            onClick={() => {
              setSelectedAccount(row);
              toggleEditDialog();
            }}
          />,
          ...(!(row.imap.connected === "connected")
            ? [
                <GridActionsCellItem
                  showInMenu
                  sx={{ "& .MuiListItemIcon-root": { minWidth: 0 } }}
                  label={t("Connect IMAP")}
                  onClick={() => {
                    handleImapConnect(row);
                  }}
                />,
              ]
            : []),
          ...(!(row.smtp !== null && row.smtp.connected === "connected")
            ? [
                <GridActionsCellItem
                  showInMenu
                  sx={{ "& .MuiListItemIcon-root": { minWidth: 0 } }}
                  label={t("Connect SMTP")}
                  onClick={() => {
                    handleSmtpConnect(row);
                  }}
                />,
              ]
            : []),
          ...(row.imap.connected === "connected"
            ? [
                <GridActionsCellItem
                  showInMenu
                  sx={{ "& .MuiListItemIcon-root": { minWidth: 0 } }}
                  label={t("Disconnect IMAP")}
                  onClick={() => {
                    handleImapDisconnect(row);
                  }}
                />,
              ]
            : []),
          ...(row.smtp !== null && row.smtp.connected === "connected"
            ? [
                <GridActionsCellItem
                  showInMenu
                  sx={{ "& .MuiListItemIcon-root": { minWidth: 0 } }}
                  label={t("Disconnect SMTP")}
                  onClick={() => {
                    handleSmtpDisconnect(row);
                  }}
                />,
              ]
            : []),
        ],
      },
    ],
    [
      handleImapConnect,
      handleImapDisconnect,
      handleSmtpConnect,
      handleSmtpDisconnect,
      t,
      toggleDeleteAccountDialog,
    ]
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card>
          {/* TABLE HEADER */}
          <Box
            sx={{
              p: 5,
              pt: 7,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6" fontWeight={600}>
                <Translations text="Email accounts" />
              </Typography>

              <Button
                variant="contained"
                onClick={toggleAddAccountDialog}
                startIcon={<Icon>add</Icon>}
              >
                <Translations text="Pridať konto" />
              </Button>
            </Box>
          </Box>

          {/* TABLE CONTENT */}
          <DataGrid
            autoHeight
            rows={accounts}
            getRowId={(row) => row.id}
            columns={columns}
            disableSelectionOnClick
            checkboxSelection
            onSelectionModelChange={(rows) => setSelectedRows(rows)}
            loading={loading}
            pagination
            rowsPerPageOptions={[10, 25, 50]}
            pageSize={Number(pageSize)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
          />
        </Card>
      </Grid>

      <AddAccountsDialog
        open={addAccountOpen}
        handleClose={toggleAddAccountDialog}
      />

      {selectedAccount && (
        <AddAccountsDialog
          open={editDialogOpen}
          handleClose={toggleEditDialog}
          editableValues={{
            imap: {
              email: selectedAccount.imap.email,
              password: "",
              host: selectedAccount.imap.host,
              port: selectedAccount.imap.port,
              tls: selectedAccount.imap.tls,
            },

            smtp: {
              user: selectedAccount.smtp
                ? selectedAccount.smtp.user
                : selectedAccount.imap.email,
              pass: "",
              host: selectedAccount.smtp ? selectedAccount.smtp.host : "",
              port: selectedAccount.smtp ? selectedAccount.smtp.port : 465,
              service: selectedAccount.smtp ? selectedAccount.smtp.service : "",
            },
          }}
          type="edit"
          ids={{
            imapId: selectedAccount.imap._id,
            smtpId: selectedAccount.smtp?._id ?? "",
          }}
        />
      )}

      {selectedAccount && (
        <DeleteAccountDialog
          open={deleteAccountOpen}
          handleClose={toggleDeleteAccountDialog}
          handleDelete={handleAccountDelete}
          account={selectedAccount.email}
        />
      )}
    </Grid>
  );
};

export default MailAccounts;
