import { RootState } from "../../../../store";
import { CustomerTimelineItemType } from "../../../../types/customerTypes";

import OrderCreatedTimelineItem from "./orderCreatedTimelineItem";
import OrderEntryTimelineItem from "./orderEntryTimelineItem";
import OrderStatusTimelineItem from "./orderStatusTimelineItem";

interface Props extends CustomerTimelineItemType {
  handleFilter: () => void;
  admins: RootState['admin'];
  statuses: any;
}

const OrderRelatedTimelineItem = (props: Props) => {
  const { data, handleFilter, admins, statuses } = props;
  const dataType = data.type;

  if (dataType === "orderCreated") {
    return <OrderCreatedTimelineItem {...{ data, handleFilter }} />;
  }

  if (dataType === "status") {
    return <OrderStatusTimelineItem {...{ data, handleFilter, statuses }} />;
  }

  if (dataType === "orderEntry") {
    return <OrderEntryTimelineItem {...{ data, handleFilter, admins }} />;
  }

  return null;
};

export default OrderRelatedTimelineItem;
