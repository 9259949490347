import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

import { ChevronDown } from "mdi-material-ui";

import {
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";

import {
  ProductTimelineEntryTypeKeyType,
  TimelineEntriesType,
  UpdatedProductTimelineItemType,
  productTimelineEntryType,
} from "../../../../types/customTimelineTypes";
import { Admin } from "../../../../types/adminTypes";

import { Avatar } from "../../../../comps/avatar";

import { getInitials } from "../../../../helpers/get-initials";
import relativeTime from "../../../../helpers/getRelativeTime";
import { Chip } from "../../../../comps/chip";
import { ThemeColor } from "../../../../comps/@core/layouts/types";
import { format } from "date-fns";

interface Props {
  entry: TimelineEntriesType;
  admins: Admin[];
}

const TimelineNoteItem = (props: Props) => {
  const { entry, admins } = props;

  const { type, text, time, admin } = entry;
  const Icon =
    productTimelineEntryType[type as ProductTimelineEntryTypeKeyType];

  const entryAuthor = admins.find((_admin) => _admin._id === admin);

  return (
    <TimelineItem
      sx={{
        minHeight: "60px",
      }}
    >
      <TimelineSeparator>
        <Icon color="primary" fontSize="small" />
        <TimelineConnector />
      </TimelineSeparator>

      <TimelineContent
        sx={{
          mt: 0,
          pt: 0,
          mb: (theme) => `${theme.spacing(3)} !important`,

          "&:hover": {
            cursor: "pointer",
            "& .entry-user": {
              display: "contents",
              transition: "all 0.3s ease-in-out",
            },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              mr: 2,
              fontWeight: 600,
              textTransform: "capitalize",
            }}
          >
            Product <i>{type}</i>
          </Typography>

          {!time && (
            <Typography variant="caption" sx={{ color: "text.disabled" }}>
              NA
            </Typography>
          )}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            {entryAuthor && (
              <Box sx={{ display: "none" }} className="entry-user">
                {entryAuthor.avatar ? (
                  <Avatar
                    alt="Profile Pic"
                    src={entryAuthor.avatar}
                    skin="light"
                    sx={{
                      width: 24,
                      height: 24,
                      fontWeight: 600,
                      fontSize: "0.75rem",
                      textTransform: "uppercase",
                    }}
                  />
                ) : (
                  <Avatar
                    skin="light"
                    sx={{
                      width: 24,
                      height: 24,
                      fontWeight: 600,
                      fontSize: "0.75rem",
                      textTransform: "uppercase",
                    }}
                  >
                    {getInitials(entryAuthor.displayName)}
                  </Avatar>
                )}
                <Typography variant="caption" sx={{ color: "text.disabled" }}>
                  {entryAuthor.displayName} created this{" "}
                </Typography>
              </Box>
            )}

            {time && (
              <Typography variant="caption" sx={{ color: "text.disabled" }}>
                {relativeTime(new Date(), new Date(time))}
              </Typography>
            )}
          </Box>
        </Box>

        <Typography variant="body2" sx={{ mb: 2, textTransform: "capitalize" }}>
          Text: {text}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
};

const TimelineUpdateItem = (props: Props) => {
  const { entry, admins } = props;
  const { type, text, time, admin } = entry;

  const updatedData = JSON.parse(text) as UpdatedProductTimelineItemType[];
  const Icon =
    productTimelineEntryType[type as ProductTimelineEntryTypeKeyType];

  const entryAuthor = admins.find((_admin) => _admin._id === admin);

  return (
    <TimelineItem
      sx={{
        minHeight: "60px",
      }}
    >
      <TimelineSeparator>
        <Icon color="primary" fontSize="small" />
        <TimelineConnector />
      </TimelineSeparator>

      <TimelineContent
        sx={{
          mt: 0,
          pt: 0,
          mb: (theme) => `${theme.spacing(3)} !important`,

          "&:hover": {
            cursor: "pointer",
            "& .entry-user": {
              display: "contents",
              transition: "all 0.3s ease-in-out",
            },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              mr: 2,
              fontWeight: 600,
              textTransform: "capitalize",
            }}
          >
            Product <i>{type}</i>
          </Typography>

          {!time && (
            <Typography variant="caption" sx={{ color: "text.disabled" }}>
              NA
            </Typography>
          )}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            {entryAuthor && (
              <Box sx={{ display: "none" }} className="entry-user">
                {entryAuthor.avatar ? (
                  <Avatar
                    alt="Profile Pic"
                    src={entryAuthor.avatar}
                    skin="light"
                    sx={{
                      width: 24,
                      height: 24,
                      fontWeight: 600,
                      fontSize: "0.75rem",
                      textTransform: "uppercase",
                    }}
                  />
                ) : (
                  <Avatar
                    skin="light"
                    sx={{
                      width: 24,
                      height: 24,
                      fontWeight: 600,
                      fontSize: "0.75rem",
                      textTransform: "uppercase",
                    }}
                  >
                    {getInitials(entryAuthor.displayName)}
                  </Avatar>
                )}
                <Typography variant="caption" sx={{ color: "text.disabled" }}>
                  {entryAuthor.displayName} created this{" "}
                </Typography>
              </Box>
            )}

            {time && (
              <Typography variant="caption" sx={{ color: "text.disabled" }}>
                {relativeTime(new Date(), new Date(time))}
              </Typography>
            )}
          </Box>
        </Box>

        <Accordion
          disableGutters
          sx={{
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary expandIcon={<ChevronDown />} sx={{ pl: 4 }}>
            <Typography variant="body2">
              {updatedData.length} {updatedData.length < 2 ? "field" : "fields"}{" "}
              updated
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
              }}
            >
              {updatedData.map((item) => {
                const title = Object.keys(item)[0];
                const values = item[title];

                const parseValue = (key: "previous" | "current") => {
                  const value = values[key];

                  if (typeof value === "string") {
                    const dateRegex =
                      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
                    const regexCheck = dateRegex.test(value);

                    if (regexCheck) {
                      const date = new Date(value);
                      return format(date, "MMM d, yyyy");
                    }

                    return value;
                  }

                  return JSON.stringify(value);
                };

                return (
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Typography
                      variant="body2"
                      sx={{ textTransform: "capitalize", fontWeight: 600 }}
                    >
                      {title}:
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                        "& .MuiChip-root": {
                          fontSize: "0.70rem",
                          height: "unset",
                          "& .MuiChip-label": { lineHeight: "18px" },
                        },
                      }}
                    >
                      <Chip
                        skin="light"
                        size="small"
                        label="From"
                        color={"info" as ThemeColor}
                      />

                      <Typography variant="body2">
                        {parseValue("previous")}
                      </Typography>

                      <Chip
                        skin="light"
                        size="small"
                        label="To"
                        color={"info" as ThemeColor}
                      />

                      <Typography variant="body2">
                        {parseValue("current")}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </AccordionDetails>
        </Accordion>
      </TimelineContent>
    </TimelineItem>
  );
};

const ViewProductTimelineItems = (props: Props) => {
  const { type, _id } = props.entry;

  if (type === "update") {
    return <TimelineUpdateItem key={String(_id)} {...props} />;
  }

  // entry.type === "note"
  return <TimelineNoteItem key={String(_id)} {...props} />;
};

export default ViewProductTimelineItems;
