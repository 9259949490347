import { Icon, IconButton, Typography } from "@mui/material";
import {
  AddOrderLineItemT,
  BasketPriceTypeTemp,
  OrderLineItemT,
} from "../../../types/orderTypes";
import { Dispatch, SetStateAction, useState } from "react";
import { PorezProductT, ProductType } from "../../../types/productTypes";
import { calculatePPU, handleAddProductLineItemUpdate, isVariobelLineItem } from "./helpers";
import { SelectProductDialog } from "./select-product-dialog";
import { useAlertContext } from "../../../hooks/useAlertContext";
import { useTranslation } from "react-i18next";
import { Customer } from "../../../types/customerTypes";

interface Props<T> {
  lineItem: T;
  products: ProductType[];
  type: "add" | "edit";
  itemList: T[];
  setItemList: Dispatch<SetStateAction<T[]>>;
  tax: number;
  basketPrice?: BasketPriceTypeTemp;
  setBasketPrice?: Dispatch<SetStateAction<BasketPriceTypeTemp>>;
  customer: Customer | null;
}
const ProductCell = <T extends OrderLineItemT | AddOrderLineItemT>(
  props: Props<T>
) => {
  const {
    lineItem,
    type,
    products,
    itemList,
    setItemList,
    tax,
    basketPrice,
    setBasketPrice,
    customer
  } = props;

  const isAddType = type === "add";
  const product = isAddType
    ? (lineItem as AddOrderLineItemT).product
    : (lineItem as OrderLineItemT).product;

  const [openSelectProductDialog, setOpenSelectProductDialog] = useState(false);
  const { showWarningAlert } = useAlertContext();
  const { t } = useTranslation();

  const handleSelectProductDialogClose = () => {
    setOpenSelectProductDialog(false);
  };

  const handleSelectProductOpen = () => {
    if (!customer) {
      return showWarningAlert(t("Please select a customer"))
    }

    return setOpenSelectProductDialog(true);
  };

  const handleAddProduct = (newProduct: ProductType | PorezProductT) => {
    const getLineItem = () => {
      if (!isVariobelLineItem(lineItem)) {
        return {
          ...lineItem,
          product: newProduct,
          price: newProduct.price
        }
      }

      const ppm2 = newProduct.price;
      const ppu = calculatePPU(lineItem.glassWidth, lineItem.glassHeight, ppm2);

      return {
        ...lineItem,
        product: newProduct,
        name: lineItem.name,
        ppu,
        ppm2,
        price: newProduct.price,
      }
    }

    const _lineItem =  getLineItem() as AddOrderLineItemT;

    const _itemList = itemList as AddOrderLineItemT[];
    const _setItemList = setItemList as Dispatch<
      SetStateAction<AddOrderLineItemT[]>
    >;

    if (basketPrice && setBasketPrice) {
      handleAddProductLineItemUpdate(
        _lineItem,
        _itemList,
        _setItemList,
        tax,
        basketPrice,
        setBasketPrice
      );
    }

    handleSelectProductDialogClose();
  };

  const handleEditProduct = (newProduct: ProductType | PorezProductT) => {
    const _itemList = itemList as OrderLineItemT[];
    const _setItemList = setItemList as Dispatch<
      SetStateAction<OrderLineItemT[]>
    >;

    const updatedItemList = _itemList.map((item) => {
      if (item._id === (lineItem as OrderLineItemT)._id) {
        return {
          ...item,
          product: newProduct,
        }
      }

      return item;
    }) as OrderLineItemT[];

    _setItemList(updatedItemList);
    handleSelectProductDialogClose();
  }

  const handleLineItemProductChange = (newProduct: ProductType) => {
    if (type === "add") {
      return handleAddProduct(newProduct);
    }

    return handleEditProduct(newProduct)
  };

  const Content = () => {
    if (!product) {
      return (
        <IconButton
          sx={{ cursor: "pointer" }}
          onClick={handleSelectProductOpen}
        >
          <Icon>add</Icon>
        </IconButton>
      );
    }

    return (
      <Typography
        variant="body2"
        onClick={handleSelectProductOpen}
        sx={{ cursor: "pointer" }}
      >
        {product.name}
      </Typography>
    );
  };

  return (
    <>
      <Content />
      <SelectProductDialog
        products={products}
        customer={customer as Customer}
        handleSelectProduct={handleLineItemProductChange}
        open={openSelectProductDialog}
        onClose={handleSelectProductDialogClose}
        basketPrice={basketPrice}
      />
    </>
  );
};

export default ProductCell;
