import { Box, Backdrop, CircularProgress, useMediaQuery, Theme } from "@mui/material";
import { useState, useEffect } from "react";
import { useSettings } from "../../hooks/useSettings"

const LoadingCircularProgress = ({ loading }: { loading: boolean }) => {
  const { settings } = useSettings();
  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  // adjust position depending on nav being present
  const [navbarAdjustment, setNavbarAdjustment] = useState(0);

  useEffect(() => {
    if (hidden) {
      setNavbarAdjustment(0);
      return;
    }

    if (settings.navCollapsed) {
      setNavbarAdjustment(settings.collapsedNavigationSize);
      return;
    }

    return setNavbarAdjustment(settings.navigationSize);
  }, [
    hidden,
    settings.navCollapsed,
    settings.collapsedNavigationSize,
    settings.navigationSize,
  ]);

  if (!loading) return null;

  return (
    <Box
      sx={{
        minHeight: "200px",
      }}
    >
      <Backdrop open={loading} sx={{ left: navbarAdjustment }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default LoadingCircularProgress;
