import {
  Grid,
  TextField,
  FormControl,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import { Controller } from "react-hook-form";

import { pricingPrefixList } from ".";

interface Props {
  label: any;
  name: any;
  prefix: any;
  namePrefix: any;
  value: any;
  onChange: any;
  error: any;
  control: any;
  errors: any;
  t: any;
  watchOnSale: any;
}

export const MarginPrefixInput = ({
  label,
  namePrefix,
  control,
  errors,
  t,
}: Partial<Props>) => (
  <Grid item xs={12} sm={4}>
    <FormControl fullWidth>
      <Controller
        name={`${namePrefix}`}
        control={control}
        rules={{ required: true }}
        render={({ field: { value, onChange } }) => (
          <FormControl>
            <InputLabel>{label}</InputLabel>
            <Select label={label} value={value} onChange={onChange}>
              {pricingPrefixList.map((prefix, index) => (
                <MenuItem key={index} value={prefix}>
                  {prefix}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />

      {errors[`${namePrefix}`] && (
        <FormHelperText sx={{ color: "error.main" }}>
          {t("This field cannot be empty")}
        </FormHelperText>
      )}
    </FormControl>
  </Grid>
);

export const MarginInput = ({
  label,
  name,
  value,
  onChange,
  prefix,
  error,
  control,
  errors,
  t,
}: Partial<Props>) => (
  <Grid item xs={12} sm={8}>
    <FormControl fullWidth>
      <Controller
        name={name}
        control={control}
        render={({ field: { value, onChange } }) => (
          <TextField
            value={value}
            label={label}
            type="number"
            onChange={onChange}
            placeholder=""
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{prefix}</InputAdornment>
              ),
            }}
            error={Boolean(errors[name])}
          />
        )}
      />
      {errors[name] && (
        <FormHelperText sx={{ color: "error.main" }}>
          {t("This field cannot be empty")}
        </FormHelperText>
      )}
    </FormControl>
  </Grid>
);
