// ** Redux Imports
import { Dispatch } from "redux";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";

// ** Types Imports
import { Customer, CustomerData } from "../../../types/customerTypes";

interface DataQueries {
  search: string;
}

interface ProductOrdersDataQueries {
  after: string;
  before: string;
  status: string;
}

interface Redux {
  getState: any;
  dispatch: Dispatch<any>;
}

// ** Fetch Customers
export const fetchData = createAsyncThunk(
  "customer/fetchData",
  async (queries: DataQueries) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_HOST}/customer/get-all?search=${queries.search}`,
      {
        withCredentials: true,
      }
    );

    return response.data;
  }
);

export const fetchCustomer = createAsyncThunk(
  "customer/fetchCustomer",
  async (id: string) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_HOST}/customer/get/${id}`,
      {
        withCredentials: true,
      }
    );

    return response.data;
  }
);

export const fetchCustomerOrders = createAsyncThunk(
  "customer/fetchCustomerOrders",
  async (id: string) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_HOST}/order/get-all/customer/${id}`,
      {
        withCredentials: true,
      }
    );

    return response.data;
  }
);

export const fetchProductOrders = createAsyncThunk(
  "customer/fetchProductOrders",
  async (args: { id: string; queries: ProductOrdersDataQueries }) => {
    const { id, queries } = args;
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_HOST}/order/get-all/product/${id}?after=${queries.after}&before=${queries.before}&status=${queries.status}`,
      {
        withCredentials: true,
      }
    );

    return response.data;
  }
);

// ** Add Customer
export const addCustomer = createAsyncThunk(
  "customer/addCustomer",
  async (data: CustomerData, { getState, dispatch }: Redux) => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_HOST}/customer/create`,
      data,
      { withCredentials: true }
    );
    dispatch(fetchData(getState().customer.queries));

    return response.data;
  }
);

// ** Delete Customer
export const deleteCustomer = createAsyncThunk(
  "customer/deleteCustomer",
  async (id: string, { getState, dispatch }: Redux) => {
    const response = await axios.delete(
      `${process.env.REACT_APP_SERVER_HOST}/customer/delete/${id}`,
      {
        withCredentials: true,
      }
    );
    dispatch(fetchData(getState().customer.queries));

    return response.data;
  }
);

// ** Edit Customer
export const editCustomer = createAsyncThunk(
  "customer/editCustomer",
  async (
    { id, data }: { id: string; data: CustomerData | Partial<Customer> },
    { getState, dispatch }: Redux
  ) => {
    const response = await axios.patch(
      `${process.env.REACT_APP_SERVER_HOST}/customer/update/${id}`,
      data,
      {
        withCredentials: true,
      }
    );
    dispatch(fetchData(getState().customer.queries));

    return response.data;
  }
);

export const appCustomersSlice = createSlice({
  name: "appCustomer",
  initialState: {
    data: [],
    total: 0,
    queries: {},
    allData: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchData.fulfilled, (state, action) => {
      state.data = action.payload.customers;
      state.total = action.payload.total;
      state.queries = action.payload.queries;
      state.allData = action.payload.allData;
    });
  },
});

export default appCustomersSlice.reducer;
