import yup from "../helpers/customYup";

export const validationSchema = yup.object().shape({
  cs: yup.string().required(),
  en: yup.string().required(),
  sk: yup.string().required(),
});

export interface FormValuesType extends yup.InferType<typeof validationSchema> {}

export interface TranslationType extends FormValuesType  {
  id: number;
}