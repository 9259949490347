// ** React Imports
import { useState, useEffect, useMemo, useCallback } from "react";
import { useSearchParams } from "react-router-dom";

// ** MUI Imports
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { SelectChangeEvent } from "@mui/material/Select";
import { DataGrid, GridColumns } from "@mui/x-data-grid";

// ** Third Party Imports
import { useTranslation } from "react-i18next";
import { matchSorter } from "match-sorter";

// ** Store & Actions Imports
import { useDispatch, useSelector } from "react-redux";
import { fetchData, deleteOrder } from "../../store/apps/order";
import { fetchData as fetchOrderStatus } from "../../store/apps/orderStatus";

// ** Types Imports
import { RootState, AppDispatch } from "../../store";
import { OrderType, OrdersViewModeT } from "../../types/orderTypes";
import { DateType } from "../../types/reactDatepickerTypes";

// ** Custom Components Imports
import { OrderListTableHeader } from "../../comps/order/list/order-list-table-header";
import DeleteOrderDialog from "../../comps/order/deleteOrderDialog";

// ** Custom Hook Imports
import useOrderStatuses from "../../hooks/useOrderStatuses";
import useCompanies from "../../hooks/useCompanies";

// ** Third Party Styles Imports
import "react-datepicker/dist/react-datepicker.css";

// ** Styled Components
import { useAlertContext } from "../../hooks/useAlertContext";
import getOrderListTableColumns from "../../comps/order/list/order-list-table-columns";
import OrderListFilters from "../../comps/order/list/order-list-filters";
import { KanbanBoardStateT } from "../../types/orderStatusTypes";
import KanbanBoard from "../../comps/order/kanban/kanban-board";
import { format } from "date-fns";
import { AbilityContext } from "../../comps/layouts/components/acl/Can";
import { useAbility } from "@casl/react";


export const OrderListScreen = () => {
  // ** State
  const [dates, setDates] = useState<Date[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(10);
  const [statusValue, setStatusValue] = useState<string>("");
  const [companyValue, setCompanyValue] = useState<string>("");
  const [startDateRange, setStartDateRange] = useState<DateType>(null);
  const [endDateRange, setEndDateRange] = useState<DateType>(null);
  const [selectedOrder, setSelectedOrder] = useState<OrderType>();
  const [selectedRows, setSelectedRows] = useState<OrderType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [deleteOrderOpen, setDeleteOrderOpen] = useState<boolean>(false);
  const [orders, setOrders] = useState<OrderType[]>([]);
  const [kanbanBoardState, setKanbanBoardState] = useState<KanbanBoardStateT>([]);
  const [viewMode, setViewMode] = useState<OrdersViewModeT>("kanban")

  // ** Hooks
  const dispatch = useDispatch<AppDispatch>();
  const { order, orderStatus } = useSelector(
    (state: RootState) => state
  );

  const { data: orderStore} = order;
  const { kanbanColumns } = orderStatus;
  const orderStatusesObj = useOrderStatuses();
  const { t } = useTranslation();
  const companies = useCompanies();
  const { showErrorAlert, showSuccessAlert } = useAlertContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const ability = useAbility(AbilityContext);

  const handleViewModeChange =  (event: any, view: OrdersViewModeT) => {
    setViewMode(view)
  }

  const handleFilter = (val: string) => {
    setSearchValue(val);
  };

  const handleStatusValue = (e: SelectChangeEvent) => {
    const { value } = e.target;
    const statuses = Object.keys(orderStatusesObj);
    const index = statuses.findIndex((company) => company === value);

    setStatusValue(value);

    const params = Object.fromEntries(searchParams.entries())

    if (index >= 0) {
      setSearchParams({ ...params, status: String(index) });
    } else {
      // @ts-ignore
      const { status, ...rest } = params;
      setSearchParams(rest)
    }
  };

  const handleSourceValue = (e: SelectChangeEvent) => {
    setCompanyValue(e.target.value);
  };

  // order data init / filtering
  useEffect(() => {
    let data = orderStore;

    if (searchValue) {
      data = matchSorter(data, searchValue, {
        keys: [
          "orderNumber",
          "customer.email",
          "customer.firstName",
          "customer.lastName",
        ],
      });
    }

    setOrders(data);
  }, [orderStore, searchValue]);

  // use search params to update order data filters
  useEffect(() => {
    const params = Object.fromEntries(searchParams.entries());

    if (Object.hasOwn(params, "status")) {
      const statuses = Object.keys(orderStatusesObj);
      const status = statuses[Number(params["status"])];

      setStatusValue(status);
    }
  }, [searchParams, orderStatusesObj])

  // fetch order statuses
  // also populates kanban columns
  useEffect(() => {
    dispatch(fetchOrderStatus());
  }, [dispatch]);

  // get/update order list rows
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      await dispatch(
        fetchData({
          after: startDateRange ? startDateRange.toISOString() : "",
          before: endDateRange ? endDateRange.toISOString() : "",
          search: searchValue,
          status: statusValue,
          company: companyValue,
        })
      );
      setIsLoading(false);
    })();
  }, [dispatch, statusValue, searchValue, startDateRange, endDateRange, companyValue]);

  // build kanban board
  useEffect(() => {
    const boardState = [] as KanbanBoardStateT;
    // sort by kanban position
    const columns  = [...kanbanColumns].sort((a, b) => a.positionInKanban - b.positionInKanban);
    const clonedOrders = [...orders]

    columns.forEach((column) => {
      const matchingOrders = clonedOrders.filter((items) => items.orderStatus?._id === column._id);
    // sort by last modified
      const formatDate = (date: string) => Number(new Date(date));
      matchingOrders.sort((a, b) => formatDate(b.lastModified) - formatDate(a.lastModified))

      boardState.push(matchingOrders)
    })

    setKanbanBoardState(boardState)
  }, [kanbanColumns, orders]);

  const handleDateRangeChange = (dates: any) => {
    const [start, end] = dates;
    if (start !== null && end !== null) {
      setDates(dates);
    }

    setStartDateRange(start);
    setEndDateRange(end);
  };

  const toggleDeleteOrderDialog = useCallback(() => {
    setDeleteOrderOpen(!deleteOrderOpen);
  }, [deleteOrderOpen]);

  const handleDeleteOrder = async () => {
    if (selectedOrder) {
      setIsLoading(true);
      await dispatch(deleteOrder(selectedOrder._id))
        .unwrap()
        .then(() => {
          showSuccessAlert(t("Order deleted"));
        })
        .catch(() => {
          showErrorAlert(t("Failed to delete Order"));
        })
        .finally(() => {
          setIsLoading(false);
          toggleDeleteOrderDialog();
        });
    }
  };

  const columns = useMemo<GridColumns<OrderType>>(
    () =>
      getOrderListTableColumns({
        t,
        companies,
        orderStatus: orderStatus.data,
        setSelectedOrder,
        toggleDeleteOrderDialog,
        ability
      }),
    [companies, t, toggleDeleteOrderDialog, orderStatus, ability]
  );

  const prepareExportData = () => {
    const data = orders.map((item) => {
      //@ts-ignore
      const { timelineEntries, basketPrice, __v, orderStatusHistory, ...relevantData } = item;
      const { orderStatus, lineItems, lastModifiedBy, customer } = relevantData;
      //@ts-ignore
      const { _id, appliedDiscounts, ...restOfPricing } = item.basketPrice

      return {
        ...relevantData,
        orderStatus: orderStatus ? orderStatus.name : "NA",
        lineItems: lineItems.length,
        lastModifiedBy: lastModifiedBy?.displayName,
        customer: customer.firstName + " " + customer.lastName,
        ...restOfPricing
      };
    })

    return data
  }

  const exportData = useMemo(prepareExportData, [orders]);;
  const exportFilename = "orders-" + format(new Date(), "dd-MM-yy");

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <OrderListFilters
          {...{
            t,
            companies,
            companyValue,
            handleSourceValue,
            statusValue,
            handleStatusValue,
            orderStatusesObj,
            endDateRange,
            startDateRange,
            handleDateRangeChange,
            dates,
            setDates,
            searchValue,
            handleSearchFilter: handleFilter
          }}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          ...(viewMode === "kanban"
            ? {
                "& .orders-table": {
                  display: "none",
                },
              }
            : {
                "& .orders-kanban": {
                  display: "none",
                },
              }),
        }}
      >
        <Card>
          <OrderListTableHeader
            {...{
              selectedRows,
              viewMode,
              handleViewModeChange,
              exportData,
              exportFilename
            }}
          />

          <DataGrid
            autoHeight
            initialState={{
              sorting: {
                sortModel: [{ field: "createdAt", sort: "desc" }],
              },
            }}
            rows={orders}
            getRowId={(row) => row._id}
            columns={columns}
            disableSelectionOnClick
            checkboxSelection
            onSelectionModelChange={(ids) => {
              const selectedIDs = new Set(ids);
              setSelectedRows(
                orderStore.filter((row) => selectedIDs.has(row._id))
              );
            }}
            loading={isLoading}
            pagination
            rowsPerPageOptions={[10, 25, 50]}
            pageSize={Number(pageSize)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            className="orders-table"
            sx={{
              "& .MuiDataGrid-columnHeaders": { borderRadius: 0 },
              // display: "none",
            }}
          />
        </Card>

        <KanbanBoard
          {...{
            loading: isLoading,
            kanbanColumns: [...kanbanColumns].sort(
              (a, b) => a.positionInKanban - b.positionInKanban
            ),
            kanbanBoardState,
            setKanbanBoardState,
          }}
        />
      </Grid>

      {selectedOrder && (
        <DeleteOrderDialog
          open={deleteOrderOpen}
          handleClose={toggleDeleteOrderDialog}
          handleDelete={handleDeleteOrder}
          orders={[`#${selectedOrder.orderNumber}`]}
        />
      )}
    </Grid>
  );
};
