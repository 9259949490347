import React from "react";
import BlankLayout from "./@core/layouts/BlankLayout";
import { Typography, Box, BoxProps, styled } from "@mui/material";

const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "90vw",
  },
}));

const ServerDown = () => {
  return (
    <BlankLayout>
      <Box className="content-center">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <BoxWrapper mb={2}>
            <Typography variant="h1" sx={{ color: "rgba(76, 78, 100, 0.87)" }}>
              503
            </Typography>

            <Typography
              mb={0.5}
              variant="h5"
              sx={{
                fontSize: "1.15rem !important",
                color: "rgba(76, 78, 100, 0.87)",
              }}
            >
              Server is not responding
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "rgba(76, 78, 100, 0.68)" }}
            >
              Please check console 👨🏻‍💻
            </Typography>
          </BoxWrapper>

          <img alt="error-illustration" src="/images/pages/500.png" />
        </Box>
      </Box>
    </BlankLayout>
  );
};

export default ServerDown;
