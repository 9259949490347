import {
  TimelineItem,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from "@mui/lab";

import { Box, Typography } from "@mui/material";

import {
  CustomerTimelineEmailsType,
  CustomerTimelineItemType,
} from "../../../types/customerTypes";
import { ThemeColor } from "../../@core/layouts/types";

import { Chip } from "../../chip";

import relativeTime from "../../../helpers/getRelativeTime";

type Props = CustomerTimelineItemType;

const EmailTimelineItem = (props: Props) => {
  const { data } = props;
  const { to, subject, from, box, color, date } =
    data.data as CustomerTimelineEmailsType;

  const chipLabel = box;
  const chipColor = color as ThemeColor;

  return (
    <TimelineItem>
      <TimelineSeparator sx={{ px: "4px" }}>
        <TimelineDot color={chipColor} />
        <TimelineConnector />
      </TimelineSeparator>

      <TimelineContent
        sx={{
          mt: 0,
          mb: (theme) => `${theme.spacing(3)} !important`,
        }}
      >
        <Box
          sx={{
            mb: 3,
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1.5,
            }}
          >
            <Typography sx={{ fontWeight: 600 }}>Mail</Typography>

            <Chip
              skin="light"
              size="small"
              label={chipLabel}
              color={chipColor}
              sx={{
                textTransform: "capitalize",
                "& .MuiChip-label": {
                  lineHeight: "18px",
                },
              }}
            />

            <Typography sx={{ fontWeight: 600 }}>
              {chipLabel === "Received" ? `from ${from}` : `to ${to}`}
            </Typography>
          </Box>

          {!date && (
            <Typography variant="caption" sx={{ color: "text.disabled" }}>
              NA
            </Typography>
          )}

          {date && (
            <Typography variant="caption" sx={{ color: "text.disabled" }}>
              {relativeTime(new Date(), new Date(date))}
            </Typography>
          )}
        </Box>

        <Typography variant="body2">Subject: {subject}</Typography>
      </TimelineContent>
    </TimelineItem>
  );
};

export default EmailTimelineItem;
