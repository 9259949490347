import { TextField, styled } from "@mui/material";

export const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    paddingLeft: theme.spacing(1),
    width: 120,

    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#D0D5DD",
      },
    },

    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px",
        borderColor: "#D0D5DD",
      },
    },
  },

  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#D0D5DD",
  },
})) as typeof TextField;
